import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Radio from '@material-ui/core/Radio';
import { Icon } from '../../components';

const theme = createTheme({});

const BlackRadio = (props) => {
  const { ...other } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <Radio
        icon={<Icon icon={CheckBoxOutlineBlankIcon} style={{ fontSize: 28, fill: '#363636' }} />}
        checkedIcon={<Icon icon={CheckBoxIcon} style={{ fontSize: 28, fill: '#363636' }} />}
        {...other}
      />
    </MuiThemeProvider>
  );
};

export default BlackRadio;
