// Handler for deep links from React Native Store application
export const registerDeepLinkHandler = () => {
  // Parse url params
  const params = new URLSearchParams(window.location.search);

  // Check whether url contain deepLink parameter
  if (params.has('deepLink')) {
    // Handle deep link url
    window.location = decodeURIComponent(params.get('deepLink'));
  }
}