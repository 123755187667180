class Market {
  constructor(props = {}) {
    this.id = props?.id || null;
    this.name = props?.name || null;
  }

  static url(market) {
    return `/market/${market.id}`;
  }

  static getAddress(market, noZip = false, noProvince = false) {
    let address = [];

    market.address1 && address.push(market.address1);
    market.address2 && address.push(market.address2);
    market.city && address.push(market.city);
    !noProvince && market.province && address.push(market.province);
    !noZip && market.zip && address.push(market.zip);

    return address.join(', ');
  }

  static getCity(market) {
    let address = [];

    market.city && address.push(market.city);
    market.province && address.push(market.province);

    return address.join(', ');
  }
}

export default Market;
