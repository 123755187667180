class Product {
  constructor(product = {}, variant = {}) {
    this.raw = product;
    this.variantRaw = variant;
  }

  getProducerId() {
    return this.raw.producer
      ? // Product from product screen
        this.raw.producer.id
      : // Product from search engine
        this.raw.producerId;
  }
}

export default Product;
