import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from 'react-intl';
import {withRouter} from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';
import {
  DialogTitleHighlighted,
  PrimaryButton,
  //PrimaryCheckbox,
} from '../../../components';
import styles from './styles';
//import FormControlLabel from "@material-ui/core/FormControlLabel";

const propTypes = {
  openDraft: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  draft: PropTypes.object.isRequired
};
const defaultProps = {};

class WelcomeBack extends React.Component {

  state = {
    disable: false
  };

  render() {
    const {
      classes,
      onClose,
      history,
      openDraft,
      draft
    } = this.props;

    return (
      <React.Fragment>
        <DialogTitleHighlighted marginBottom={28} title={<FormattedMessage id="welcome.title"/>}/>
        <DialogContent className={classes.wrapper}>
          <p className={classes.question}><FormattedMessage id="welcome.whatToDo"/></p>

          <PrimaryButton className={classes.button}
                         onClick={() => {
                           draft.lineItems.length && openDraft();
                           onClose();
                         }}
                         desc={draft.lineItems.length ?
                           <FormattedMessage id="welcome.continueWithLastDraft"/> :
                           <FormattedMessage id="welcome.createNewOrder"/>} >
            {draft.lineItems.length ?
              <FormattedMessage id="welcome.draftOrder"/> :
              <FormattedMessage id="welcome.createOrder"/>}
          </PrimaryButton>

          <PrimaryButton className={classes.button}
                         onClick={() => {
                           history.push({
                             pathname: '/markets',
                             state: {openRecentlyPurchased: true}
                           });
                           onClose();
                         }}
                         desc={<FormattedMessage id="welcome.recentlyPurchasedItems"/>} >
            <FormattedMessage id="welcome.recentItems"/>
          </PrimaryButton>

          <PrimaryButton className={classes.button}
                         onClick={() => {
                           history.push('/account');
                           onClose();
                         }}
                         desc={<FormattedMessage id="welcome.reorderPrevOrder"/>} >
            <FormattedMessage id="welcome.reorder"/>
          </PrimaryButton>

          {/*<FormControlLabel
            classes={{label: classes.checkboxLabel}}
            control={
              <PrimaryCheckbox
                checked={this.state.disable}
                onChange={(event) => {
                  this.setState({disable: event.target.checked})
                }}
              />
            }
            label="Do not display this screen"
          />*/}

        </DialogContent>
      </React.Fragment>
    );
  }
}

WelcomeBack.propTypes = propTypes;
WelcomeBack.defaultProps = defaultProps;

export default withRouter(
  withStyles(styles, {withTheme: true})(WelcomeBack)
);






