import { saveObjectToStorage, getObjectFromStorage, removeSelectedProducersFromLocalStorage } from '../helpers';

export const genHttpBasicToken = (email, password) => {
  return window.btoa(unescape(encodeURIComponent(email + ':' + password)));
};

/**
 * Checks if token is expired
 * @param {string} jwt - jwt token
 * @returns {boolean}
 */
export function isJWTExpired(jwt) {
  const decoded = jwtDecode(jwt),
    date = new Date();

  return decoded.exp < date.getTime() / 1000;
}

export function isGuestToken(jwt) {
  return jwtDecode(jwt).uid.startsWith('an_');
}

/** Get user token */
export function getToken() {
  return localStorage.getItem('user.token');
}

/** Get user token */
export function getCustomerData() {
  return getObjectFromStorage('user.data');
}

export function isVisited() {
  return localStorage.getItem('isVisited');
}

export function setVisited() {
  localStorage.setItem('isVisited', 'true');
}

/**
 * Set user token
 * @param token - user token
 */
export function setToken(token) {
  return localStorage.setItem('user.token', token);
}

/** Removes user token */
export function removeToken() {
  return localStorage.removeItem('user.token');
}

/** Removes user data */
export function removeCustomerData() {
  localStorage.removeItem('welcome.last');
  return localStorage.removeItem('user.data');
}

/** Removes user data */
export async function removeCustomerDataWithToken() {
  removeToken();
  removeCustomerData();
  removeSelectedProducersFromLocalStorage();
  removeProducerPreviewHash();
}

/**
 * Save customer data
 * @param {object} customer - customer data
 */
export function saveCustomerData(customer) {
  return saveObjectToStorage('user.data', customer);
}

/**
 * Decodes token
 * @param {string} jwt
 * @returns {object} - decoded jwt
 */
export function jwtDecode(jwt) {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(atob(base64));
}

/** Get producer hash preview */
export function getProducerPreviewHash() {
  return localStorage.getItem('producer.previewHash');
}

/**
 * Set producer hash preview
 * @param {string} previewHash
 */
export function setProducerPreviewHash(previewHash) {
  return localStorage.setItem('producer.previewHash', previewHash);
}
/**
 * remove producer hash preview
 */
export function removeProducerPreviewHash() {
  return localStorage.removeItem('producer.previewHash');
}
