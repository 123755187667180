import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { BlackButton, DialogContentWrapper, DialogActionsWrapper } from '../../../../components';
import styles from './styles';

const propTypes = {
  onDone: PropTypes.func.isRequired,
};
const defaultProps = {};

const ServiceChargeInfoStage = ({ classes, onDone, loading }) => {
  return (
    <React.Fragment>
      <DialogContentWrapper padding45 mt70>
        <div className={classes.blockWrapper}>
          <Typography className={classes.message} variant="body1">
            <FormattedMessage id="charge.info.message1" />
          </Typography>

          <Typography className={classes.message} variant="body1">
            <FormattedMessage id="charge.info.message2" />
          </Typography>
        </div>
      </DialogContentWrapper>
      <DialogActionsWrapper padding45>
        <div className={classes.controls}>
          <BlackButton disabled={loading} onClick={onDone} className={classes.control}>
            <FormattedMessage id="global.ok" />
          </BlackButton>
        </div>
      </DialogActionsWrapper>
    </React.Fragment>
  );
};

ServiceChargeInfoStage.propTypes = propTypes;
ServiceChargeInfoStage.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(ServiceChargeInfoStage));
