import { TABS } from './constants';
import { SHIPPING_DELIVERY, SHIPPING_PICKUP } from '../../helpers';

export const getDeliveryType = (tab) => {
  return tab === TABS.PICKUP ? SHIPPING_PICKUP : SHIPPING_DELIVERY;
};

export const isDelivery = (tab) => {
  return tab === TABS.DELIVERY;
};
export const isPickup = (tab) => {
  return tab === TABS.PICKUP;
};

export const getShippingDate = (draft) => {
  return (draft.shipping && draft.shipping.date) || '';
};

export const getShippingUntil = (draft) => {
  return (draft.shipping && draft.shipping.until) || '';
};

export const getShippingType = (draft) => {
  return (draft.shipping && draft.shipping.type) || '';
};

export const isAnyShippingOptionsSelected = (draft, market) => {
  return !!getShippingType(draft) || !!getShippingDate(draft) || !!getLocationIdFromMarket(market);
};

//use for get initial tab
export const getTabByDraft = (draft) => {
  const shippingType = getShippingType(draft);
  return shippingType === SHIPPING_PICKUP ? TABS.PICKUP : TABS.DELIVERY;
};

export const getAddress = (customer) => {
  const address = customer.shippingAddress;
  return `${!!address && !!address.address1 ? address.address1 + ' ' : ''}${
    !!address && !!address.address2 ? address.address2 : ''
  }`;
};

export const getAddressIdFromCustomer = (customer) => {
  const address = customer?.shippingAddress;

  return address?.id;
};

export const getAddressFromCustomerByNewAddressObject = (customer, newAddress) => {
  const shippingAddresses = customer?.shippingAddresses;
  const paramsToCompare = Object.keys(newAddress);

  const sameAddressArray =
    shippingAddresses &&
    shippingAddresses.find((address) => {
      let isAllEqual = true;

      paramsToCompare.forEach((paramName) => {
        if (isAllEqual) {
          isAllEqual = newAddress[paramName] === address[paramName];
        }
      });

      return isAllEqual;
    });

  if (!!sameAddressArray) {
    return sameAddressArray;
  } else {
    return shippingAddresses.at(-1);
  }
};

export const getLocationId = (draft) => {
  return draft?.shipping?.location?.id || '';
};

export const getLocationIdFromMarket = (market) => {
  return (market && market.id) || '';
};

export const handleShippingOptionsErrors = (intl, error) => {
  const code = error?.code || error?.response?.data?.message?.code;
  let message =
    error?.message ||
    error?.response?.data?.message?.message ||
    intl.formatMessage({ id: 'messages.somethingWentWrong' });

  switch (code) {
    case 1001: {
      message = intl.formatMessage({ id: 'error.marketIsNotAvailable' });
      break;
    }
    default: {
      break;
    }
  }

  return message;
};
