import { headerTextStyles } from '../../../components';

const styles = (theme) => ({
  formRoot: {
    margin: 0,
    alignItems: 'left',
    width: '100%',
  },
  formControl: {
    margin: '5px 0',
    alignItems: 'left',
    width: '100%',
  },
  blockWrapper: {
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  controls: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
    marginTop: 20,
    textAlign: 'center',
  },
  paymentMethodsControls: {
    // textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 35,
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
  radioLabelBody: {
    width: '100%',
  },
  addButton: {
    // marginBottom: 10,
    marginTop: 10,
    marginRight: 0,
    marginLeft: 0,
    fontWeight: 700,
    padding: 0,
    fontSize: 12,
  },
  label: {
    textAlign: 'left',
    marginLeft: 50,
  },
  alignLeft: {
    textAlign: 'left',
  },
  select: {},
  serviceChargeMessage: {
    fontSize: '10px',
    color: '#363636',
    marginBottom: 10,
    width: '100%',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'inline',
    // margin: '0 50px',
  },
  controlTextStyle: {
    display: 'inline',
    padding: 0,
    backgroundColor: 'transparent',
    fontSize: 13,
    cursor: 'pointer',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'transparent',
    },
    textDecoration: 'underline',
  },
  paymentTitle: {
    ...headerTextStyles.headerTextStylesFontOnWhite,
    color: '#484848',
    marginBottom: 35,
    fontWeight: 'bold',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#e6e6e6',
    margin: '17px 0 12px 0',
  },
  creditMessage: {
    marginLeft: 45,
    marginTop: 0,
    marginBottom: 20,
    color: '#00000061',
  },
  creditMessageProducer: {
    marginTop: 10,
    marginBottom: 10,
    color: '#00000061',
  },
});

export default styles;
