import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

export const buttonTextStyles = {
  buttonTextStylesBlackButton: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 12,
    fontWeight: 600,
    color: '#F5F5F6',
    lineHeight: '34px',
    margin: 0,
    padding: 0,
  },
  buttonTextStylesWhiteButton: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 12,
    fontWeight: 600,
    color: '#111111',
    lineHeight: '34px',
    margin: 0,
    padding: 0,
  },
};

const useStyles = makeStyles(() => buttonTextStyles);

const ButtonText = (props) => {
  const { className, onBlack, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Typography
      className={classNames([
        onBlack ? classes.buttonTextStylesBlackButton : classes.buttonTextStylesWhiteButton,
        className,
      ])}
      component="p"
      {...otherProps}>
      {props.children}
    </Typography>
  );
};

ButtonText.propTypes = {
  black: PropTypes.bool,
};

export default ButtonText;
