import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { PrimaryLabel } from '../../components';

const propTypes = {
  label: PropTypes.string,
  formControlClassName: PropTypes.string,
};

const theme = createTheme({
  overrides: {
    MuiSelect: {
      root: {
        fontSize: 14,
        color: `#363636`,
      },
      icon: {
        fill: '#68D7A3',
        fontSize: 22,
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
        color: `#363636`,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid #41CD8C`,
        },
        '&$focused:after': {
          borderBottomColor: `#41CD8C`,
        },
        '&$error:after': {
          borderBottomColor: `#41CD8C`,
        },
        '&:before': {
          borderBottom: `1px solid #41CD8C`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid #41CD8C`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #41CD8C`,
        },
      },
    },
  },
});

class PrimarySelect extends React.Component {
  render() {
    const { formControlClassName, helperText, children, label, ...other } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <FormControl className={formControlClassName}>
          {label && <PrimaryLabel>{label}</PrimaryLabel>}
          <Select IconComponent={KeyboardArrowDownIcon} {...other}>
            {children}
          </Select>

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </MuiThemeProvider>
    );
  }
}

PrimarySelect.propTypes = propTypes;

export default PrimarySelect;
