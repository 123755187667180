import React from 'react';
import { ITEM_TYPES, weightToUser } from '../../../../helpers';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import BasicTable from '../../../../components/BasicTable';
import LineItemPrice from '../../../../components/OrderLineItems/LineItemPrice';
import EmptyImage from './assets/no.jpg';

const useStyles = makeStyles((theme) => ({
  root: {},

  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  itemImageWrapper: {
    width: 50,
    height: 50,
    position: 'relative',
    overflow: 'hidden',
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemImage: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 'auto',
  },
  producerName: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  itemMarketName: {},
  itemNameWithVariant: {
    fontSize: 14,
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      borderTop: 0,
    },
  },
  firstCell: {
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      borderTop: 0,
    },
  },
  lastCell: {
    paddingRight: '0px !important',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      borderTop: 0,
    },
  },
}));

const CheckoutItemsList = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { draft, draftObjectAfterComplete } = props;

  const drawItems = () => {
    let items = draft?.lineItems || [];

    if (!draft?.lineItems?.length && draftObjectAfterComplete?.lineItems?.length) {
      items = draftObjectAfterComplete.lineItems;
    }

    let data = [];
    items.forEach(({ producer, items }) => items.forEach((item) => data.push({ ...item, producer })));

    return (
      <BasicTable
        columns={[
          {
            name: 'item',
            title: intl.formatMessage({ id: 'global.item' }),
            theadCellProps: { className: classes.firstCell },
            bodyCellProps: { className: classes.firstCell },
            value: (row) => drawItem(row),
          },
          {
            name: 'quantity',
            title: intl.formatMessage({ id: 'global.qty' }),
            theadCellProps: { className: classes.cell },
            value: (row) => weightToUser(row?.packedQuantity, (v) => v) || 0,
          },
          {
            name: 'total',
            title: intl.formatMessage({ id: 'global.total' }),
            theadCellProps: { className: classes.lastCell },
            bodyCellProps: { className: classes.lastCell },
            value: (row) => <LineItemPrice item={row} />,
          },
        ]}
        data={data}
      />
    );
  };

  const drawItem = (item) => {
    const isSubscription = item.type === ITEM_TYPES.SUBSCRIPTION;

    return (
      <div className={classes.itemWrapper} key={item.id}>
        <div className={classes.itemImageWrapper}>
          <img
            className={classes.itemImage}
            src={item?.image?.thumbSrc || item?.image?.src || EmptyImage}
            alt={getItemName(item)}
          />
        </div>
        <div>
          <div className={classes.producerName}>{item?.producer?.name}</div>
          <div className={classes.itemNameWithVariant}>
            {getItemName(item)}
            {isSubscription && <span>, {intl.formatMessage({ id: `global.every_${item?.subscriptionPeriod}` })}</span>}
          </div>
        </div>
      </div>
    );
  };

  const getItemName = (item) => {
    return `${item?.name?.product || ''}${item.name.variant ? ' - ' + item.name.variant : ''}`;
  };

  return <div className={classes.root}>{drawItems()}</div>;
};

export default CheckoutItemsList;
