import { authConstants } from '../constants';
import {
  initDraft,
  getProducers,
  setCustomer,
  setShippingOptions,
  initProducersFilter,
  marketActions,
  clearProducersFilter,
} from '../actions';
import { setToken, removeCustomerDataWithToken } from '../../helpers';
import { initMarkets } from './producer';
import { apiGetShipping, apiGuestLogin } from '../../api';
import { checkDraftForProblems } from './draft';
import { removeIosLink } from '../configure';

/**
 * Sign in customer as guest
 * @param {function} onReady
 * @param {function} onError
 * @param {object} headers - additional request headers
 * @return {Function}
 */
export function guestSingIn(onReady, onError, headers = {}) {
  return (dispatch) => {
    // Initiate guest user auth
    apiGuestLogin(
      ({ data: { customer, token } }) => {
        dispatch(
          signIn(
            {
              token,
              customer,
              guest: true,
            },
            onReady
          )
        );
      },
      (error) => {
        onError && onError(error);
      },
      headers
    );
  };
}

export function signIn(data, onReady) {
  return (dispatch, getState) => {
    const { token, customer, guest } = data;

    setToken(token);
    dispatch(setCustomer(customer));
    // Init market storage
    dispatch(marketActions.initialize());
    // Init producers filter
    dispatch(initProducersFilter());

    dispatch({
      type: authConstants.SIGN_IN,
      payload: {
        token: token,
        guest: !!guest,
      },
    });

    dispatch(getProducers()).then((receivedProducers) => {
      dispatch(initDraft()).then(() => {
        dispatch(initMarkets()).then(() => {
          const draft = getState().draft.object;

          // Get shipping options
          apiGetShipping(draft.id)
            .then((shippingOptions) => {
              // Save shipping options into REDUX storage
              dispatch(setShippingOptions(shippingOptions.data));
              // Check draft for problems
              dispatch(checkDraftForProblems(draft));

              removeIosLink(getState());
              onReady && onReady(data, receivedProducers);
            })
            .catch((error) => {
              console.log('Something went wrong during the process of getting shipping options', error);
            });
        });
      });
    });
  };
}

/**
 * Sign out customer & sign in it as guest
 * @param forceGuestSignIn
 * @return {Function}
 */
export function signOut(forceGuestSignIn = true) {
  return (dispatch) => {
    removeCustomerDataWithToken();
    dispatch(marketActions.clearMarket());
    dispatch(clearProducersFilter());
    dispatch({ type: authConstants.SIGN_OUT });
    forceGuestSignIn && dispatch(guestSingIn());
  };
}

export function showLogInAndSignUpDialog(dialogStage, options, createBusinessAccount) {
  return (dispatch) => {
    dispatch({
      type: authConstants.OPEN_LOG_IN_AND_SIGN_UP_DIALOG,
      payload: {
        dialogStage,
        options,
        createBusinessAccount,
      },
    });
  };
}

export function closeLogInAndSignUpDialog() {
  return (dispatch) => {
    dispatch({ type: authConstants.CLOSE_LOG_IN_AND_SIGN_UP_DIALOG });
  };
}

export function openForceLoginDialog() {
  return (dispatch) => {
    dispatch({ type: authConstants.OPEN_FORCE_AUTH_DIALOG });
  };
}

export function closeForceLoginDialog() {
  return (dispatch) => {
    dispatch({ type: authConstants.CLOSE_FORCE_AUTH_DIALOG });
  };
}
