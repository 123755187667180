export const isFellowFarmer = () => {
  return process.env.REACT_APP_NAME === 'fellow.farmer';
};

export const isGardsbudet = () => {
  return process.env.REACT_APP_NAME === 'gardsbudet';
};

export const isSalesTaxEnabled = () => {
  return process.env.REACT_APP_ENABLE_SALES_TAX === 'true';
};

export const environmentNames = {
  production: 'production',
  staging: 'staging',
  development: 'development'
}
