
class ShippingEntity {

  constructor (params = {}) {
    this.type = (params && params.type) || null;
    this.location = (params && params.location) || null;
    this.date = (params && params.date) || null;
    this.fulfillmentFee = (params && params.fulfillmentFee) || 0;
    this.deliveryCompany = (params && params.deliveryCompany) || null;
  }

  static delivery() {
    return 'delivery'
  }

  static pickup() {
    return 'pickup'
  }

  isDelivery() {
    return this.type === ShippingEntity.delivery()
  }

  isPickup() {
    return this.type === ShippingEntity.pickup()
  }

  getShippingType () {
    return this.type
      ? this.shipping.type
      : null;
  }

  getLocationName () {
    return this.location
      ? this.location.name
      : null;
  }

  getDeliveryCompanyName () {
    return (this.deliveryCompany && this.deliveryCompany)
      ? this.deliveryCompany.name
      : null;
  }

}

export default ShippingEntity
