import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ErrorBox,
  Spinner,
} from '../../../../components';
import {
  CheckoutAddressForm
} from '../../../../forms';
import styles from './styles';

const propTypes = {
  onAddAddress: PropTypes.func.isRequired,
  type: PropTypes.string,
  onDone: PropTypes.func,
  customer: PropTypes.object,
};
const defaultProps = {};

class AddAddressStage extends React.Component {

  state = {
    error: ""
  };

  render() {
    const {
      loading,
      onDone,
      onAddAddress,
      type,
      customer
    } = this.props;

    return (
      <React.Fragment>
          {this.state.error && (
            <ErrorBox error={this.state.error} />
          )}

          {loading && (
            <Spinner size={40} />
          )}

          <CheckoutAddressForm
            mt70
            error={this.state.error}
            loading={this.state.loadingAddress}
            forceDefault={(customer[`${type}Addresses`].length === 0)}
            onValidated={(data) => {
              this.setState({loadingAddress: true}, () => {
                onAddAddress(type, data, () => {
                  this.setState({loadingAddress: false}, onDone);
                }, () => {
                  this.setState({
                    error: "Something went wrong",
                    loadingAddress: false
                  });
                })
              });
            }}
          />
      </React.Fragment>
    );
  }
}

AddAddressStage.propTypes = propTypes;
AddAddressStage.defaultProps = defaultProps;

export default withStyles(styles)(AddAddressStage);






