import {
  CUSTOMER_SET,
  CUSTOMER_LOADING,
  CUSTOMER_ERROR,
  CUSTOMER_SET_AVAILABLE_ADDRESSES,
  CUSTOMER_CLEAR_AVAILABLE_ADDRESSES,
} from '../constants';

const initialState = {
  object: {},
  availableAddresses: [],
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_SET: {
      const { customer } = action.payload;
      return {
        ...state,
        object: customer,
        loading: false,
        error: false,
      };
    }
    case CUSTOMER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case CUSTOMER_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: error,
      };
    }
    case CUSTOMER_SET_AVAILABLE_ADDRESSES: {
      const { availableAddresses } = action.payload;

      return { ...state, availableAddresses };
    }
    case CUSTOMER_CLEAR_AVAILABLE_ADDRESSES: {
      return { ...state, availableAddresses: [] };
    }
    default:
      return state;
  }
};

export default reducer;
