export const marketConstants = {
  SET_MARKET: 'MARKET:SET_MARKET',
  SET_MARKET_AUTOMATIC: 'MARKET:SET_MARKET_AUTOMATIC',
  SET_MARKET_BY_DRAFT: 'MARKET:SET_MARKET_BY_DRAFT',
  CLEAR_MARKET: 'MARKET:CLEAR_MARKET',

  MARKETS_UPDATER: 'MARKETS_UPDATER',

  // Markets dialog
  OPEN_MARKETS_DIALOG: 'MARKET:OPEN_MARKETS_DIALOG',
  CLOSE_MARKETS_DIALOG: 'MARKET:CLOSE_MARKETS_DIALOG',
};
