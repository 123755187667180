import {useEffect, useState} from 'react';

export function useSimpleAndDoubleClick(actionSimpleClick, actionDoubleClick, delay = 250) {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) actionSimpleClick();
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick();

    return () => clearTimeout(timer);

  }, [click]);

  return () => setClick(prev => prev + 1);
}

export function getBackLink(history, match) {
  let linkToGo;
  const currentScreen = match;

  switch (currentScreen.path) {
    case '/products/type/:type' : {
      linkToGo = '/products'
      break
    }
    case '/products/type/:type/subtype/:subtype' : {
      if (currentScreen) {
        linkToGo = `/products/type/${currentScreen.params.type}`
      } else {
        linkToGo = false;
      }
      break
    }
    case '/product/:id' : {
      const type = history && history.location && history.location.state && history.location.state.type || false;
      const subType = history && history.location && history.location.state && history.location.state.subType || false;

      if (!!type && !!subType) {
        linkToGo = `/products/type/${type}/subtype/${subType}`;
      } else {
        linkToGo = false;
      }

      break
    }
    default: {
      linkToGo = false;
    }
  }

  return linkToGo;
}