import { colors } from '../../helpers';

const styles = (theme) => ({
  headerRoot: {
    zIndex: 10,
    position: 'fixed',
    width: '100%',
  },
  contentRoot: {
    zIndex: 1,
    top: 70,
    [theme.breakpoints.down('sm')]: {
      top: 100,
      marginBottom: 100,
    },
    [theme.breakpoints.down('xs')]: {
      top: 0,
      marginBottom: 0,
    },
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  pageHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #00000029',
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageHeaderWrapperDesktop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageHeader: {
    fontSize: 24,
    fontWeight: 300,
  },
  backButtonWrapper: {},
  container: {
    padding: 8 * 3,
    paddingBottom: 100,
    minHeight: 410,
  },
  subscriptionPaper: {
    padding: 15,
    borderRadius: 6,
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: 22,
    marginBottom: 20,
    paddingLeft: 6,
  },
  errorWrapper: {
    padding: 20,
    margin: '100px auto',
    maxWidth: 400,
  },
  pageTitle: {
    fontSize: 25,
    fontWeight: 500,
    marginTop: 35,
    color: colors.text,
    marginBottom: 20,
  },
});

export default styles;
