import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import BreadcrumbsWrapper from '../../../components/BreadcrumbsWrapper';

const styles = () => ({
  rootWrapperClass: {
    paddingLeft: 0,
    marginBottom: 24,
    marginTop: 24,
  },
  pseudoLink: {
    cursor: 'pointer',
  },
});

const AccountSubscriptionsBreadcrumbs = ({ subscription, classes, intl, onOpenSubscription }) => {
  return (
    <BreadcrumbsWrapper rootWrapperClass={classes.rootWrapperClass}>
      <Typography color="textPrimary" className={classes.pseudoLink} onClick={onOpenSubscription}>
        {intl.formatMessage({ id: 'global.breadcrumbs.subscriptions' })}
      </Typography>
      <Typography color="textPrimary">{subscription.name}</Typography>
    </BreadcrumbsWrapper>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(AccountSubscriptionsBreadcrumbs)));
