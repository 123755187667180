import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

export const textRegularStyles = {
  textRegularStylesFontOnWhite: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    color: '#484848',
    lineHeight: '22px',
    margin: 0,
    padding: 0,
  },
  textRegularStylesFontOnGreen: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    color: '#000',
    lineHeight: '22px',
    margin: 0,
    padding: 0,
  },
};

const useStyles = makeStyles(() => textRegularStyles);

const TextRegular = (props) => {
  const { className, onGreen, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Typography
      className={classNames([
        onGreen ? classes.textRegularStylesFontOnGreen : classes.textRegularStylesFontOnWhite,
        className,
      ])}
      component="p"
      {...otherProps}>
      {props.children}
    </Typography>
  );
};

TextRegular.propTypes = {
  onGreen: PropTypes.bool,
};

export default TextRegular;
