import { colors } from '../../../helpers';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      marginTop: 0,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  downSmHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  w30: {
    width: 30,
  },
  width100px: {
    width: 100,
  },
  width120px: {
    width: 120,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  invoice: {
    color: '#000000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconCell: {
    width: '50px',
  },
  pointer: {
    cursor: 'pointer',
  },
  tableCell: {
    padding: '10px 7px',
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: '14px',
    borderBottom: '1px solid #E0E0E0',
  },
  selectFormControl: {
    width: '80px',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  desktopTable: {
    tableLayout: 'fixed',
  },
  desktopTableBodyCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  table: {
    marginTop: 0,
  },
  title: {
    fontWeight: 'bold',
    marginTop: 0,
    marginBottom: 0,
    fontSize: 25,
  },
  mr10: {
    marginRight: 10,
  },
  titleWrapper: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperTables: {
    backgroundColor: '#F3F2F8',
  },
  tablePaperWrapper: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 6,
    backgroundColor: colors.white,
    marginBottom: 20,
    overflow: 'hidden',
  },
  filterIconWrapper: {
    backgroundColor: colors.white,
    borderRadius: 40,
    padding: 4,
    boxShadow: '0px 3px 6px #00000029',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  desktopFilterWrapper: {},
  ordersWrapper: {
    padding: 25,
  },
  orderContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minHeight: 70,
    borderBottom: '1px solid #E0E0E0',
    marginBottom: 10,
    marginTop: 20,
    '&:last-of-type': {
      borderBottom: 'none',
      marginBottom: 0,
    },
  },
  orderLeftContent: {},
  orderRightContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderText: {
    marginBottom: 5,
  },
  orderMain: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.black,
  },
  orderSecond: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.cartSecondary,
  },
  orderShippingDate: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.black,
  },
  arrowIcon: {
    fontSize: 14,
    fill: '#BFBFBF !important',
    marginLeft: 30,
    marginBottom: 5,
    cursor: 'pointer',
    transition: 'fill 0.3s ease-in-out',
    '&:hover, &:focus, &:active': {
      fill: '#000 !important',
    },
  },
});

export default styles;
