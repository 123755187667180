import axios from 'axios';
import {
  getRequestConfig, REQUEST_TYPES,
  replaceUrlPlaceholder
} from  '../request';
import API_END_POINTS from  '../endpoints';

/**
 * Add payment method
 * @param {method} method
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddPaymentMethod = function (method, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_ADD_PAYMENT_METHOD, 'me', method);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;
  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};

/**
 * Verify payment method
 * @param {string} paymentMethodId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerVerifyPaymentMethod = function (paymentMethodId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_VERIFY_PAYMENT_METHOD, 'pm', paymentMethodId);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;
  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error.response.data.message);
  });
};

/**
 * Set default payment method
 * @param {string} paymentMethodId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerSetDefaultPaymentMethod = function (paymentMethodId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_SET_DEFAULT_PAYMENT_METHOD, 'pm', paymentMethodId);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};

/**
 * Delete customer`s payment method
 * @param {string} paymentMethodId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerDeletePaymentMethod = function (paymentMethodId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_DELETE_PAYMENT_METHOD, 'pm', paymentMethodId);
  let config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};

export const apiVerifyBankAccountWithPlaid = function (publicToken, accountId, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CUSTOMER_VERIFY_BANK_ACCOUNT_WITH_PLAID);

  config.data = {
    public_token: publicToken,
    account_id: accountId,
  };
  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};