import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

class GuestRoute extends Component {
  render() {
    const {authenticated, component: Component, ...rest} = this.props;
    return (
      <Route
        {...rest}
        render={(props = this.props) => (
          (!authenticated) ?
            (<Component {...props} />) :
            (<Redirect to={{
              pathname: '/markets',
              state: {from: props.location},
            }}/>)
        )}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  }
}

export default connect(mapStateToProps)(GuestRoute)
