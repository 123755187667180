import { headerTextStyles } from '../../../components';

const styles = (theme) => ({
  summaryWrapper: {
    width: 200,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  controls: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
    marginTop: 20,
    textAlign: 'center',
  },
  control: {
    borderRadius: 40,
    textTransform: 'uppercase',
    width: '100%',
  },
  navigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1235,
    width: '100%',
    margin: '20px auto 0',

    '& svg': {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  toolbarWrapper: {
    width: '100%',
    height: 70,
    position: 'relative',
    backgroundColor: '#ffffff',
  },
  toolbar: {
    height: 70,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#ffffff',
    width: '100%',

    '& svg': {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  smallTitle: {
    fontFamily: 'Noto Sans HK, sans-serif',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
    display: 'block',
    color: '#363636',
  },
  imageWrapper: {
    marginRight: 10,
    maxWidth: 79,
    maxHeight: 69,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      marginLeft: 15,
    },
  },
  yourOrderImage: {
    width: 'auto',
    height: '100%',
  },
  fs14Bold: {
    fontSize: 14,
    fontWeight: 700,
  },
  fs14: {
    fontSize: 14,
  },
  mb7: {
    marginBottom: 7,
  },
  checkoutTitle: {
    ...headerTextStyles.headerTextStylesFontOnWhite,
    color: '#484848',
    marginBottom: 35,
    fontWeight: 'bold',
  },
  contentWrapper: {
    maxWidth: 1235,
    padding: 15,
    width: '100%',
    margin: '50px auto',

    [theme.breakpoints.down('md')]: {
      margin: '0px auto',
    },
  },
  hr: {
    width: '100%',
    height: 1,
    backgroundColor: '#e6e6e6',
    marginTop: 40,
    marginBottom: 23,
  },
});

export default styles;
