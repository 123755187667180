import { compareNotMomentDates, CUSTOM_LOCATION_IDS, SHIPPING_DELIVERY, SHIPPING_TYPES } from '../../../helpers';
import { SUBSCRIPTION_BILLING_TYPES } from '../../../helpers/constants';

/**
 * Returns address label
 * @param addr
 * @return {string}
 */
export const getAddressLabel = (addr) => {
  if (!addr) {
    return '';
  }
  return (
    (addr.hasOwnProperty('address1') ? addr.address1 + ', ' : '') +
    (addr.hasOwnProperty('city') ? addr.city + ', ' : '') +
    (addr.hasOwnProperty('province') && !!addr.province ? addr.province + ', ' : '') +
    (addr.hasOwnProperty('zip') ? addr.zip : '') +
    (addr.default ? ' (Default address)' : '')
  );
};

export const getAddressSimple = (addr) => {
  if (!addr) {
    return '';
  }

  return (
    (addr.hasOwnProperty('address1') ? addr.address1 + ', ' : '') +
    (addr.hasOwnProperty('city') ? addr.city + ', ' : '') +
    (addr.hasOwnProperty('province') && !!addr.province ? addr.province + '' : '')
  );
};

/**
 * Fetches address by id
 * @param {array} addresses - array of addresses
 * @param {number} id - address id
 * @return {*}
 */
export const getAddressById = (addresses, id) => {
  addresses = addresses || [];
  let address = addresses.filter((address) => {
    return address.id === id;
  });
  return address.length ? address[0] : null;
};

/**
 * Fetches default address
 * @param {array} addresses - array of addresses
 * @param {boolean} any - return any address id if default is not present
 * @return {*}
 */
export const getDefaultAddress = (addresses, any) => {
  addresses = addresses || [];
  let address = addresses.filter((address) => {
    return address.default;
  });
  return address.length ? address[0] : any && addresses.length ? addresses[0] : null;
};

/**
 * Fetches default address id
 * @param {array} addresses - array of addresses
 * @param {boolean} any - return any address id if default is not present
 * @return {*}
 */
export const getDefaultAddressId = (addresses, any) => {
  addresses = addresses || [];
  let address = addresses.filter((address) => {
    return address.default;
  });
  return address.length ? address[0].id : any && addresses.length ? addresses[0].id : null;
};

/**
 * Sort addresses by default property
 * @param {array} addresses
 * @return {Array.<T>}
 */
export const sortAddresses = (addresses) => {
  return addresses.sort((a, b) => {
    return a.default === b.default ? 0 : a.default ? -1 : 1;
  });
};

export const getAvailabilitiesData = (draft, shippingOptions) => {
  let methods = [];
  let uniqueLocations = [];
  let pickupLocations = [];
  let deliveryLocations = [];
  let allDates = [];
  let isAllTypes = false;
  let isAllLocations = false;
  let producerId;

  draft.customAvailabilityLineItems.forEach((item) => {
    producerId = item?.producer?.id || '';
    // if shipping type set to all we should get all data from producer
    if (item.availability.shippingType === SHIPPING_TYPES.SHIPPING_ALL) {
      isAllTypes = true;
    }
    // the same for location all
    if (item.availability.locationId === CUSTOM_LOCATION_IDS.LOCATION_ALL) {
      isAllLocations = true;
    }

    if (
      !methods.includes(item.availability.shippingType) &&
      item.availability.shippingType !== SHIPPING_TYPES.SHIPPING_ALL
    ) {
      methods.push(item.availability.shippingType);
    }
    if (
      !uniqueLocations.includes(item.availability.locationId) &&
      item.availability.locationId !== CUSTOM_LOCATION_IDS.LOCATION_ALL
    ) {
      uniqueLocations.push(item.availability.locationId);
    }
    if (!!item.availability.startDate) {
      allDates.push(item.availability.startDate);
    }
  });

  if (isAllTypes || isAllLocations) {
    const producerShipping = shippingOptions.getCheckoutShippingOptionsByProducerId(producerId);

    isAllTypes &&
      !isAllLocations &&
      producerShipping.forEach((item) => {
        if (!methods.includes(item.type)) {
          methods.push(item.type);
        }
      });

    isAllLocations &&
      producerShipping.forEach((item) => {
        if (!uniqueLocations.includes(item.location.id)) {
          uniqueLocations.push(item.location.id);
        }

        // make types depend of available locations
        if (isAllTypes) {
          if (!methods.includes(item.type)) {
            methods.push(item.type);
          }
        }

        if (item.type === SHIPPING_DELIVERY) {
          !deliveryLocations.includes(item.location.id) && deliveryLocations.push(item.location.id);
        } else {
          !pickupLocations.includes(item.location.id) && pickupLocations.push(item.location.id);
        }
      });
  }

  const firstDate = allDates.length > 1 ? allDates.sort(compareNotMomentDates).reverse() : allDates;

  return { methods, uniqueLocations, pickupLocations, deliveryLocations, date: firstDate[0] };
};

export const getLocationsByType = (availabilitiesData, type) => {
  return type === SHIPPING_DELIVERY ? availabilitiesData.deliveryLocations : availabilitiesData.pickupLocations;
};

export const isAvailabilities = (draft) => {
  return !!draft?.customAvailabilityLineItems?.length;
};

export const getProducersByItems = (draft) => {
  let producers = [];
  let producerIds = [];

  draft.lineItems.forEach((producer) => {
    if (!producerIds.includes(producer.producer.id)) {
      producerIds.push(producer.producer.id);
      producers.push(producer.producer);
    }
  });

  return producers;
};

export function isOnlyRecurring(order) {
  if (order?.onlySubscriptionLineItems && order?.subscriptionLineItems?.length) {
    let result = true;

    order.subscriptionLineItems.forEach((item) => {
      if (item.billingType !== SUBSCRIPTION_BILLING_TYPES.RECURRING) {
        result = false;
      }
    });

    return result;
  }

  return false;
}

export function isSubscription(order) {
  return order?.onlySubscriptionLineItems;
}
