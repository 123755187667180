import { headerTextStyles } from '../../../components';

const DRAWER_WIDTH = 500;
const styles = (theme) => ({
  cartLineItemsWrapper: {
    paddingLeft: 30,
    paddingRight: 30,
    '& *': {
      boxSizing: 'border-box',
    },
    '& p, & a, & input, & button, & span': {
      fontFamily: 'Noto Sans HK, sans-serif',
    },
  },
  buttonWrapper: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 110,
  },
  mobileFixedScrolling: {
    position: 'fixed',
    minHeight: 90,
    backgroundColor: 'white',
    bottom: 0,
    right: 0,
    boxSizing: 'border-box',
    paddingLeft: 30,
    paddingRight: 30,
    width: '100%',
    maxWidth: DRAWER_WIDTH,
    borderTop: '1px solid #E0E0E0',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial',
    },
  },
  producerTitle: {},

  empty: {
    ...headerTextStyles.headerTextStylesFontOnWhite,
    textAlign: 'center',
  },
  imageWrapper: {
    width: 50,
    height: 50,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 15,
  },
  image: {
    height: '100%',
    width: 'auto',
  },
  summaryWrapper: {
    marginTop: 10,
  },
  summaryItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: 18,
    paddingBottom: 18,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  flexColumn: {
    flexDirection: 'column',
  },
  flexColumnCenter: {
    alignItems: 'center',
  },

  hasNotAvailableNotification: {
    padding: 10,
    backgroundColor: '#212121',
    color: '#ffffff',
  },
  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },
  itemNotAvailable: {
    backgroundColor: 'rgba(245, 0, 87, 0.1)',
  },
  notAvailableBadge: {
    color: 'red',
  },
  productName: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#111',
  },
  producer: {
    marginTop: 20,
    fontSize: 18,
    color: '#484848',
    fontWeight: 'bold',
  },
  variantName: {
    fontSize: 12,
    marginBottom: 5,
    color: '#484848',
  },
  info: {
    flex: 8,
    marginRight: 5,
  },
  quantity: {
    flex: 1,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 5,
      paddingBottom: 5,
      width: '100%',
    },
  },
  total: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  subtotal: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  priceWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
  },
  price: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  green: {
    color: '#363636',
  },
  orderNoteItem: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  subType: {
    color: '#855E42',
  },
  orderNoteInputWrapper: {
    width: '100%',
    marginBottom: 10,
  },
  buttonContainer: {
    display: 'inline',
  },
  controlsClass: {
    marginLeft: -10,
    lineHeight: 1,
  },
  controlTextStyle: {
    display: 'inline',
    padding: 0,
    backgroundColor: 'transparent',
    fontSize: 12,
    cursor: 'pointer',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'transparent',
    },
    textDecoration: 'underline',
  },

  itemContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
  },
  itemColumn: {
    display: 'flex',
    flex: 1,
  },
  itemColumnFirst: {
    flex: 7,
    paddingRight: 15,
  },
  itemColumnSecond: {
    justifySelf: 'flex-end',
    flex: 3,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  itemColumnThird: {
    flex: 2,
    paddingLeft: 15,
  },
  actionsWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonSubmit: {
    borderRadius: 40,
    backgroundColor: '#000',
    padding: '20px !important',
    height: 'auto',
    fontWeight: 'bold',
    fontSize: 12,
  },
  scrollAreaWrapper: {},
  normalScrollable: {},
  emptyWrapper: {
    height: 'calc(100% - 90px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

export default styles;
