import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#707070',
        fontSize: 12,
        '&$focused': {
          color: '#707070',
          '&$focused': {
            color: '#41CD8C',
          },
        },
      },
    },
  },
});

const PrimaryLabel = (props) => {
  const { children, ...other } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <InputLabel {...other}>{children}</InputLabel>
    </MuiThemeProvider>
  );
};

export default PrimaryLabel;
