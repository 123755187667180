import moment from 'moment';
import { DAY_SORTER } from './constants';

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * Format date
 * @param date
 * @param {bool} time - should we add time here
 * @return {string}
 */
export const formatDate = (date, time) => {
  return moment(date).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK + (time ? ' hh:mm A' : ''));
};

/**
 * Format date (September 1)
 * @param date
 * @return {string}
 */
export const dateToMMMMD = (date) => {
  return moment(date).format('MMMM D');
};

/**
 * Format date
 * @param date
 * @return {string}
 */
export const dateToMMDY = (date) => {
  return moment.parseZone(date).format('MM/D/Y');
};

/**
 * Searches full date by MM/D/Y part
 * @param {array} dates
 * @param {string} date
 * @param {string} returnType
 * @return {string|boolean}
 */
export const searchDateBYMMDY = (dates, date, returnType) => {
  // Search date by MM/D/Y part
  let result = false;
  for (let dateKey in dates) {
    if (dates.hasOwnProperty(dateKey) && dates[dateKey].formatted.nextMMDY === date) {
      switch (returnType) {
        case 'object': {
          result = dates[dateKey];
          break;
        }
        default: {
          result = dates[dateKey].next;
          break;
        }
      }
      break;
    }
  }
  return result;
};

/**
 * convert array of days and working hours to Mon... 10.00 am 06.00 pm
 * @param {array} days
 * @param {string} hoursFrom
 * @param {string} hoursTo
 */
export const getWorkingDatesHours = (days = [], hoursFrom = '', hoursTo = '') => {
  if (!days || !days.length) return `\u0020`;

  let sortedDays = [];
  DAYS_OF_WEEK.forEach((day) => days.includes(day.toLocaleLowerCase()) && sortedDays.push(day));

  let daysResult = [];
  sortedDays.forEach((day) => {
    daysResult.push(moment().day(day).format('ddd'));
  });

  if (!hoursFrom || !hoursTo) return `\u0020`;

  const from = moment(hoursFrom, 'Hmm').format('h:mm a'),
    to = moment(hoursTo, 'Hmm').format('h:mm a');

  return `${daysResult.join(', ')} ${from} - ${to}`;
};

/**
 * convert array of days to a string comma separated
 * @param {array} days
 */
export const getWorkingDays = (days = []) => {
  if (!days || !days.length) return `\u0020`;

  let sortedDays = [];
  DAYS_OF_WEEK.forEach((day) => days.includes(day.toLocaleLowerCase()) && sortedDays.push(day));

  let daysResult = [];
  sortedDays.forEach((day) => {
    daysResult.push(moment().day(day).format('ddd'));
  });

  return `${daysResult.join(', ')}`;
};

/**
 * convert working hours to 10.00 am 06.00 pm
 * @param {string} hoursFrom
 * @param {string} hoursTo
 */
export const getWorkingHours = (hoursFrom = '', hoursTo = '') => {
  if (!hoursFrom || !hoursTo) return `\u0020`;

  const from = moment(hoursFrom, 'Hmm').format('h:mm a'),
    to = moment(hoursTo, 'Hmm').format('h:mm a');

  return `${from} - ${to}`;
};

/**
 * sort days in right order
 * @param prevFullDayName - full name of the day monday etc.
 * @param nextFullDayName
 * @returns {number}
 */
export const daySorter = (prevFullDayName, nextFullDayName) => {
  return (
    DAY_SORTER[String(prevFullDayName).toLocaleLowerCase()] - DAY_SORTER[String(nextFullDayName).toLocaleLowerCase()]
  );
};
