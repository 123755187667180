import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { Spinner } from '../../../components';
import styles from './styles';

const ALL_PRODUCERS = 'ALL_PRODUCERS';

class ProducersSelect extends React.Component {

  constructor(props) {
    super(props);

    const {
      selectedProducers
    } = this.props;

    this.state = {
      selected: selectedProducers.length
        ? selectedProducers.map(p => p.id)
        : [ALL_PRODUCERS],
      confirm: false,
    };
  }

  _onProducerSelect = (event) => {
    const { value } = event.target;
    const { selected } = this.state;
    let toSelect = [];

    if ( (value.includes(ALL_PRODUCERS) &&
         !selected.includes(ALL_PRODUCERS)) ||
          value.length === 0  ) {
      toSelect = [ALL_PRODUCERS]
    } else {
      toSelect = [...value.filter(v => v !== ALL_PRODUCERS)];
    }

    this.setState({selected: toSelect}, () => {
      this.props.onSelect &&
      this.props.onSelect(toSelect.filter(v => v !== ALL_PRODUCERS));
    });
  };

  getList() {
    const {
      producers,
      intl,
      classes
    } = this.props;

    const {
      selected,
    } = this.state;

    return (
      (producers.length > 0) ?
        <List
          subheader={<ListSubheader disableSticky className={classes.title} component="div">
            <FormattedMessage id="global.producer"/>
          </ListSubheader>}>
          <ListItem style={{ position: 'relative' }} className={classes.item}>
            <Select
              fullWidth
              multiple
              value={selected}
              onChange={this._onProducerSelect}
              inputProps={{name: "producers-select", id: "producers-select"}}
            >

              <MenuItem key={ALL_PRODUCERS} value={ALL_PRODUCERS}>
                {intl.formatMessage({id: 'global.all'})}
              </MenuItem>

              {producers.map(({id, name}) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </ListItem>
        </List> :
        <List>
          <ListItem>
            <ListItemText primary={<FormattedMessage id="producer.producersNotFound"/>}/>
          </ListItem>
        </List>
    )
  };
 

  render() {
    const { loading, showLoader } = this.props;
    return (
      <React.Fragment>
        {loading && showLoader && <Spinner size={30}/>}
        {!loading && this.getList()}
      </React.Fragment>
    );
  }
}

ProducersSelect.propTypes = {
  showLoader: PropTypes.bool,
  order: PropTypes.object.isRequired,
  producers: PropTypes.array.isRequired,
  selectedProducers: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onSelect: PropTypes.func
};

ProducersSelect.defaultProps = {
  showLoader: false,
  loading: true
};

export default withStyles(
  styles, {withTheme: true}
)(injectIntl(ProducersSelect));


