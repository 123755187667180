import {
  cardHeaderStyles,
  headerTextStyles,
  largeTextStyles,
  smallTextStyles,
  textRegularStyles,
} from '../../../../components';

const styles = () => ({
  root: {
    flex: 1,
  },
  inputWrapperClass: {
    paddingLeft: 40,
    paddingRight: 40,
    marginBottom: 20,
  },
  inputRootClass: {
    backgroundColor: '#F5F5F6',
    height: 53,
    borderRadius: 30,
    borderBottom: 0,
    paddingLeft: 10,
  },
  titleClass: {
    ...headerTextStyles.headerTextStylesFontOnWhite,
    color: '#484848',
    marginBottom: 35,
    fontWeight: 'bold',
  },

  addressWrapper: {
    marginBottom: 20,
  },

  chooseAddressLabel: {
    ...largeTextStyles.largeTextStylesFontOnWhite,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },

  marketsSuggestionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  marketsWrapper: {
    width: '100%',
    maxHeight: 320,
    overflowY: 'auto',
  },
  marketWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 70,
    backgroundColor: '#fff',
    transition: 'background 0.3s ease-in-out',
    cursor: 'pointer',
    marginBottom: 10,
    width: '100%',
    borderRadius: 10,
  },
  marketWrapperActive: {
    backgroundColor: '#F5F5F6',
  },
  marketImageWrapper: {
    width: 82,
    minWidth: 82,
    height: 68,
    minHeight: 68,
    borderRadius: 10,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 1,
  },
  marketImage: {
    height: '100%',
    imageRendering: 'pixelated',
  },

  marketContentWrapper: {
    marginLeft: 30,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 30,
  },
  marketName: {
    ...cardHeaderStyles.cardHeaderStylesFontOnWhite,
    marginBottom: 5,
    whiteSpace: 'inherit',
  },
  marketAddress: {
    ...smallTextStyles.smallTextStylesFontOnWhite,
  },

  chooseAddress: {
    position: 'relative',
  },
  searchResultWrapper: {
    position: 'absolute',
    top: 95,
    left: 0,
    width: '100%',
    minHeight: 235,
    backgroundColor: '#fff',
    boxShadow: '0 3px 6px #00000080',
    borderRadius: 5,
    zIndex: 10,
  },
  searchResultItem: {
    ...largeTextStyles.largeTextStylesFontOnWhite,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
    backgroundColor: '#fff',

    '&:focus, &:hover, &:active': {
      backgroundColor: '#F5F5F6',
    },
  },
  searchResultNoResults: {
    ...largeTextStyles.largeTextStylesFontOnWhite,
    width: '100%',
    height: '100%',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchResultLoading: {
    width: '100%',
    height: '100%',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorBoxWrapper: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  addAddress: {
    position: 'relative',
  },
  addAddressLabel: {
    ...largeTextStyles.largeTextStylesFontOnWhite,
    marginBottom: 8,
  },
  addressSelector: {
    position: 'absolute',
    cursor: 'pointer',
    top: 30,
    left: 0,
    width: '100%',
    height: 235,
    backgroundColor: '#fff',
    boxShadow: '0 3px 6px #00000080',
    borderRadius: 5,
    padding: 7,
    boxSizing: 'border-box',
    overflowY: 'auto',
  },

  addAddressLabelName: {
    ...textRegularStyles.textRegularStylesFontOnWhite,
    color: '#2E68C5',
    paddingLeft: 5,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowExpand: {
    fontSize: 18,
  },
  addressSelectorItem: {
    ...largeTextStyles.largeTextStylesFontOnWhite,
    borderRadius: 5,
    padding: 6,
    paddingLeft: 10,
    backgroundColor: '#fff',
    transition: 'background 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5F5F6',
    },
  },
  addressSelectorItemActive: {
    backgroundColor: '#F5F5F6',
  },

  controls: {
    marginTop: 20,
    textAlign: 'center',
    width: '100%',
    marginBottom: 40,
  },
  control: {
    ...smallTextStyles.smallTextStylesFontOnWhite,
    color: '#fff',
    borderRadius: 40,
    textTransform: 'uppercase',
    width: '100%',
    paddingTop: 17,
    paddingBottom: 17,
    height: 50,
  },
});

export default styles;
