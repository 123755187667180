import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import withWidth from '@material-ui/core/withWidth';
import { injectIntl } from 'react-intl';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Icon } from '../../../components';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { dateToMMMMD, SHIPPING_PICKUP } from '../../../helpers';

SubscriptionOrderList.propTypes = {
  orders: PropTypes.array.isRequired,
};

function SubscriptionOrderList(props) {
  const { orders, classes, intl, history } = props;

  if (!orders.length) return null;

  return (
    <div className={classes.tablePaperWrapper}>
      <div className={classes.ordersWrapper}>
        {orders.map((order) => (
          <div key={order.id} className={classes.orderContent}>
            <div className={classes.orderLeftContent}>
              <div className={classNames([classes.orderText, classes.orderMain])}>{order.name.replace('#', '')}</div>

              <div className={classNames([classes.orderText, classes.orderSecond])}>
                {intl.formatMessage({ id: `global.${order.shippingType}` })}&nbsp;
                {order.shippingType === SHIPPING_PICKUP && order.location.name}
              </div>
            </div>
            <div className={classes.orderRightContent}>
              {order?.shippingDate && (
                <div className={classNames([classes.orderText, classes.orderShippingDate])}>
                  {dateToMMMMD(order.shippingDate)}
                </div>
              )}
              {/*<div>{toCurrency(OrderEntity.getOrderGrandTotal(order), intl)}</div>*/}
              <Icon
                icon={ArrowForwardIosIcon}
                className={classes.arrowIcon}
                onClick={() => {
                  history.push(`/order/${order.id}`);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default withRouter(withStyles(styles, { withTheme: true })(withWidth()(injectIntl(SubscriptionOrderList))));
