import React from 'react';
import PropTypes from 'prop-types';
import { withWidth } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { colors, dateToMMMMD, toCurrency, getProducerName, getTotal, getBalance } from '../../../helpers';
import noImg from '../../AccountScene/ProductsSubscriptions/assets/no.jpg';

ProducerSubscriptionProductsList.propTypes = {
  subscription: PropTypes.object.isRequired,
};

ProducerSubscriptionProductsList.defaultProps = {
  subscription: {},
};

const useStyles = makeStyles((theme) => ({
  desktopFilterWrapper: {},
  ordersWrapper: {
    marginTop: 20,
  },
  orderContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minHeight: 70,
    borderBottom: '1px solid #E0E0E0',
    marginBottom: 10,
    marginTop: 20,
  },
  orderLeftContent: {},
  orderRightContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderText: {},
  orderMain: {
    marginBottom: 3,
    fontSize: 12,
    fontWeight: 600,
    color: colors.black,
  },
  orderSecond: {
    marginBottom: 3,
    fontSize: 12,
    fontWeight: 400,
    color: colors.cartSecondary,
  },
  orderPeriod: {
    fontSize: 12,
    fontWeight: 600,
    color: colors.black,
  },
  arrowIcon: {
    fontSize: 14,
    fill: '#BFBFBF !important',
    marginLeft: 30,
    marginBottom: 5,
    cursor: 'pointer',
    transition: 'fill 0.3s ease-in-out',
    '&:hover, &:focus, &:active': {
      fill: '#000 !important',
    },
  },

  tablePaperWrapper: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 6,
    backgroundColor: colors.white,
    marginBottom: 20,
    overflow: 'hidden',
  },
  contentWrapper: {
    padding: 25,
  },
  pageTitle: {
    fontSize: 25,
    fontWeight: 500,
    marginTop: 10,
    color: colors.text,
    marginBottom: 10,
  },
  subscriptionInfo: {
    marginBottom: 3,
    fontSize: 12,
    fontWeight: 500,
    color: '#707070',
  },
  subscriptionImage: {
    marginRight: 10,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 9,
    maxWidth: 55,
    maxHeight: 55,
    width: '100%',
    minWidth: 55,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 55,
      maxHeight: 55,
    },
    '& > img': {
      width: 'auto',
      maxHeight: 55,
      [theme.breakpoints.down('xs')]: {
        maxHeight: 55,
      },
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  bottomSummaryWrapper: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  bottomSummary: {},
  bottomSummaryRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
    fontSize: 12,
    fontWeight: 600,
  },
  leftColumn: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'left',
  },
  rightColumn: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'right',
    marginLeft: 40,
  },
}));

function ProducerSubscriptionProductsList(props) {
  const {
    subscription: { variants = [] },
    subscription,
    intl,
  } = props;

  const classes = useStyles();

  const drawOrder = (order) => {
    const img = order.image ? order.image : noImg;

    return (
      <div key={order.id} className={classes.orderContent}>
        <div className={classes.orderLeftContent}>
          <div className={classes.row}>
            <div className={classes.subscriptionImage}>
              <img src={img} alt={subscription.name} />
            </div>
            <div>
              <div className={classNames([classes.orderText, classes.orderMain])}>
                {!!order?.name ? order.name.replace('#', '') : ''}
              </div>

              {!!order?.amount && (
                <div className={classNames([classes.orderText, classes.orderSecond])}>
                  {intl.formatMessage({ id: 'global.amountShare' }, { amount: toCurrency(order?.amount || 0) })}
                </div>
              )}
              {order?.nextDate && (
                <div className={classNames([classes.orderText, classes.orderSecond])}>
                  {intl.formatMessage({ id: `page.productSubscription.nextOrder` })}:{' '}
                  {dateToMMMMD(order?.nextDate || '')}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.orderRightContent}>
          {(!!order?.period || !!subscription?.period?.type) && (
            <div className={classNames([classes.orderText, classes.orderPeriod])}>
              {intl.formatMessage({ id: `global.${order.period || subscription?.period?.type}` })}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.tablePaperWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.pageTitle}>{getProducerName(subscription)}</div>
        <div className={classes.subscriptionInfo}>
          {intl.formatMessage({ id: 'page.productSubscription.id' })}: {subscription.name}
        </div>
        {subscription?.tags && (
          <div className={classes.subscriptionInfo}>
            {intl.formatMessage({ id: 'page.productSubscription.tags' })}: {subscription.tags.length ? subscription.tags.join(', ') : '-'}
          </div>
        )}
        {subscription?.startDate && (
          <div className={classes.subscriptionInfo}>
            {intl.formatMessage({ id: 'page.productSubscription.startDate' })}: {dateToMMMMD(subscription.startDate)}
          </div>
        )}
        {subscription?.nextDate && (
          <div className={classes.subscriptionInfo}>
            {intl.formatMessage({ id: 'page.productSubscription.nextOrder' })}: {dateToMMMMD(subscription.nextDate)}
          </div>
        )}
        {subscription?.endDate && (
          <div className={classes.subscriptionInfo}>
            {intl.formatMessage({ id: 'page.productSubscription.endDate' })}: {dateToMMMMD(subscription.endDate)}
          </div>
        )}
        <div className={classes.ordersWrapper}>{variants.map((variant) => drawOrder(variant))}</div>
        <div className={classes.bottomSummaryWrapper}>
          <div className={classes.bottomSummary}>
            {subscription?.total && (
              <div className={classes.bottomSummaryRow}>
                <p className={classes.leftColumn}>
                  {intl.formatMessage({ id: 'page.productSubscription.subscriptionAmount' })}
                </p>
                <p className={classes.rightColumn}>{getTotal(subscription, intl)}</p>
              </div>
            )}
            {subscription?.balance && (
              <div className={classes.bottomSummaryRow}>
                <p className={classes.leftColumn}>{intl.formatMessage({ id: 'page.productSubscription.balance' })}</p>
                <p className={classes.rightColumn}>{getBalance(subscription, intl)}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(withWidth()(injectIntl(ProducerSubscriptionProductsList)));
