import axios from 'axios';
import { genHttpBasicToken, isDraftHasItems } from '../../helpers';
import API_END_POINTS from '../endpoints';
import { REQUEST_TYPES, addPriceLevelHeader, addAnonymousTokenHeader, getRequestConfig } from '../request';
import { getStore } from '../../store/configure';

/**
 * Login request
 * @param {string} email
 * @param {string} password
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiLogin = function (email, password, onSuccess, onError) {
  const store = getStore();
  const state = store.getState();
  const draft = state.draft.object;

  let config = addPriceLevelHeader({
    method: REQUEST_TYPES.POST,
    url: process.env.REACT_APP_API_URL + API_END_POINTS.LOGIN,
    headers: {
      Authorization: 'Basic ' + genHttpBasicToken(email, password),
    },
  });

  // We need to rewrite draft only in case guest has someting in cart
  if (isDraftHasItems(draft)) {
    config = addAnonymousTokenHeader(config);
  }

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Guest auth
 * @param {function} onSuccess
 * @param {function} onError
 * @param {object} headers - additional request headers
 */
export const apiGuestLogin = function (onSuccess, onError, headers = {}) {
  const config = {
    method: REQUEST_TYPES.POST,
    url: process.env.REACT_APP_API_URL + API_END_POINTS.LOGIN,
    headers,
  };

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Create invite link
 * @param {object} data
 * @param {string} data.email
 * @param {string|null} data.producerId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCreateInviteLink = function (data, onSuccess, onError) {
  const config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CREATE_PASSWORD_VIA_INVITE);
  const producerId = data.producerId ? '&pid=' + data.producerId : '';

  config.data = {
    email: data.email,
    mode: 'invite',
    'no-email': false,
    url: `${process.env.REACT_APP_URL}create-password?t=token&c=customerId${producerId}`,
  };

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
