import {
  CUSTOMER_LOADING,
  CUSTOMER_ERROR
} from '../constants';
import {
  setCustomer
} from '../actions';
import {
  customerHasSubscriptionForEmail,
  customerGetSubscriptionsByEmail,
} from '../../helpers';
import {
  // Account
  apiCustomerAddAccount,
  apiCustomerPatchAccount,
  apiCustomerDeleteAccount,
  // Email subscriptions
  apiCustomerAddEmailSubscription,
  apiCustomerDeleteEmailSubscription
} from '../../api';

/**
 * Add customer account
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function addAdditionalCustomerAccount(data, onSuccess, onError) {
  return dispatch => {
    dispatch({type: CUSTOMER_LOADING});
    apiCustomerAddAccount(data, (response) => {
      onSuccess && onSuccess();
      dispatch(setCustomer(response.data));

      // Add account to subscriptions
      const customer = response.data;

      // Create subscription for new additional account
      if (!customerHasSubscriptionForEmail(customer, data.username)) {
        apiCustomerAddEmailSubscription({
          email: data.username
        }, () => {}, () => {});
      }

    }, (error) => {
      onError && onError(error);
      dispatch({
        type: CUSTOMER_ERROR,
        payload: {
          error: "Something went wrong while adding customer account"
        }
      });
    });
  }
}

/**
 * Patch customer account
 * @param {object} account
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function patchAdditionalCustomerAccount(account, data, onSuccess, onError) {
  return dispatch => {
    dispatch({type: CUSTOMER_LOADING});
    apiCustomerPatchAccount(account.id, data, (response) => {
      onSuccess && onSuccess();
      dispatch(setCustomer(response.data));

      // Delete subscription for updated customer account
      const customer = response.data;
      let subscriptions = customerGetSubscriptionsByEmail(customer, account.username);
      if (subscriptions.length > 0) {
        subscriptions.forEach((subscription) => {
          apiCustomerDeleteEmailSubscription(subscription.id, () => {}, () => {});
        })
      }

      // Create subscription for updated additional account
      if (!customerHasSubscriptionForEmail(customer, data.username)) {
        apiCustomerAddEmailSubscription({
          email: data.username
        }, () => {}, () => {});
      }

    }, (error) => {
      onError && onError(error);
      dispatch({
        type: CUSTOMER_ERROR,
        payload: {
          error: "Something went wrong while patching customer account"
        }
      });
    });
  }
}

/**
 * delete customer account
 * @param {object} account
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function deleteAdditionalCustomerAccount(account, onSuccess, onError) {
  return dispatch => {
    dispatch({type: CUSTOMER_LOADING});
    apiCustomerDeleteAccount(account.id, (response) => {
      onSuccess && onSuccess();
      dispatch(setCustomer(response.data));

      // Delete subscription for updated customer account
      const customer = response.data;
      let subscriptions = customerGetSubscriptionsByEmail(customer, account.username);
      if (subscriptions.length > 0) {
        subscriptions.forEach((subscription) => {
          apiCustomerDeleteEmailSubscription(subscription.id, () => {}, () => {});
        })
      }

    }, (error) => {
      onError && onError(error);
      dispatch({
        type: CUSTOMER_ERROR,
        payload: {
          error: "Something went wrong while deleting customer account"
        }
      });
    });
  }
}