export function makeMetaLinks() {
  //const removedSlash = pathname.substr(1);
  
  return {
    /*link: [
        {"rel": "canonical", "href": `${process.env.REACT_APP_URL}${removedSlash}`},
      ],
    base: {
      "href": `${process.env.REACT_APP_URL}${removedSlash}`
    }*/
  }
}

/**
 * Updates line item of the draft order
 * @param {string} title - make an original title to
 * @param {string} description
 * @param {string} image
 * @param {string} pagePath
 * @param {bool} withOpenGraph - diasble open graphs
 * @param {bool} makeOriginalTitle - disable over wright title
 */
export function makeMeta(title, description, image, pagePath, withOpenGraph = true, makeOriginalTitle = true) {
  const locale = process.env.REACT_APP_LOCALE === "en" ? "en_us" : "sv_se";
  const imageSrc = image && image.substr(0,1) === '/' ? `${process.env.REACT_APP_URL}${image.substr(1)}` : image;
  
  return(
    {
      ...(makeOriginalTitle && title ? {title: title} : {}),
     meta: [
       {"property": "og:type", "content": "website"},
       {"property": "og:locale", "content": locale},
  
       ...(description ? [{"name": "description", "content": description}] : []),
  
       ...(withOpenGraph && title ? [{"property": "og:title", "content": title}] : []),
       ...(withOpenGraph && description ? [{"property": "og:description", "content": description}] : []),
       ...(withOpenGraph && imageSrc ? [{"property": "og:image", "content": imageSrc}] : []),
       ...(withOpenGraph && imageSrc ? [{"property": "og:image:width", "content": "1200"}] : []),
       ...(withOpenGraph && imageSrc ? [{"property": "og:image:height", "content": "630"}] : []),
  
       //...(withOpenGraph && pagePath ? [{"property": "og:url", "content": `${process.env.REACT_APP_URL}${pagePath.substr(1)}`}] : []),
    ]
  })
}
