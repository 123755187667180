import {
  CUSTOMER_LOADING,
  CUSTOMER_ERROR
} from '../constants';
import {
  apiCustomerAddEmailSubscription,
  apiCustomerPatchEmailSubscription,
  apiCustomerDeleteEmailSubscription
} from '../../api';
import {
  setCustomer
} from '../actions';

/**
 * Add email subscription
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function addCustomerEmailSubscription(data, onSuccess, onError) {
  return dispatch => {
    dispatch({type: CUSTOMER_LOADING});
    apiCustomerAddEmailSubscription(data, (response) => {
      onSuccess && onSuccess();
      dispatch(setCustomer(response.data));
    }, (error) => {
      onError && onError(error);
      dispatch({
        type: CUSTOMER_ERROR,
        payload: {
          error: "Something went wrong while adding customer email subscription"
        }
      });
    });
  }
}

/**
 * Patch email subscription
 * @param {string} id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function patchCustomerEmailSubscription(id, data, onSuccess, onError) {
  return dispatch => {
    dispatch({type: CUSTOMER_LOADING});
    apiCustomerPatchEmailSubscription(id, data, (response) => {
      onSuccess && onSuccess();
      dispatch(setCustomer(response.data));
    }, (error) => {
      onError && onError(error);
      dispatch({
        type: CUSTOMER_ERROR,
        payload: {
          error: "Something went wrong while patching customer email subscription"
        }
      });
    });
  }
}

/**
 * Delete customer email subscription
 * @param {string} id
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function deleteCustomerEmailSubscription(id, onSuccess, onError) {
  return dispatch => {
    dispatch({type: CUSTOMER_LOADING});
    apiCustomerDeleteEmailSubscription(id, (response) => {
      onSuccess && onSuccess();
      dispatch(setCustomer(response.data));
    }, (error) => {
      onError && onError(error);
      dispatch({
        type: CUSTOMER_ERROR,
        payload: {
          error: "Something went wrong while deleting customer email subscription"
        }
      });
    });
  }
}

