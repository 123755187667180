import { toCurrency } from './index';

export function getProducerName(item) {
  if (typeof item?.producer === 'string') return item?.producer;

  if (!!item?.producer?.name) return item?.producer?.name;

  return '';
}

export function getTotal(item, intl) {
  if (!!item?.variantsTotalAmount) return toCurrency(item.variantsTotalAmount, intl);
  if (!!item?.total) return toCurrency(item?.total, intl);

  return null;
}
export function getBalance(item, intl) {
  if (!!item?.amount) return toCurrency(item.amount, intl);
  if (!!item?.balance) return toCurrency(item.balance, intl);

  return null;
}

export function getLocationName(item) {
  if (typeof item.location === 'string') return item.location;

  if (!!item?.market?.name) return item?.market?.name;

  return '';
}
