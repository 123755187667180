import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#707070',
        fontSize: 16,
        '&$focused': {
          color: '#000000',
          '&$focused': {
            color: '#000000',
          },
        },
      },
    },
    MuiInputLabel: {},
  },
});

const BlackLabel = (props) => {
  const { children, styleBold, ...other } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <InputLabel
        style={
          !!styleBold
            ? {
                fontWeight: 'bold',
                color: '#000000',
                fontSize: 19,
              }
            : {}
        }
        {...other}>
        {children}
      </InputLabel>
    </MuiThemeProvider>
  );
};

export default BlackLabel;
