const API_END_POINTS = {
  // Authentication
  LOGIN: 'auth',
  // Producers
  GET_PRODUCERS: 'producers',
  GET_SHIPPING: 'producers/shipping/{dr}',
  GET_MARKETS: 'markets',
  SET_CUSTOMER_MARKETS_GEOLOCATION: 'customers/location',
  PRODUCER_REQUEST_INFO: 'register/apply/producer',
  SET_PREVIEW_PRODUCER: 'anonymous/previewProducer',

  // Draft
  DRAFT_ORDER_GET_LAST: 'drafts/last',
  DRAFT_ORDER_ADD_VARIANT: 'drafts/{dr}/add',
  DRAFT_ORDER_ADD_CUSTOM_LINE_ITEM: 'drafts/{dr}/custom',
  DRAFT_ORDER_REMOVE_LINE_ITEM: 'drafts/{dr}/{it}',
  DRAFT_ORDER_SET_SHIPPING: 'drafts/{dr}/{type}',
  DRAFT_ORDER_COMPLETE: 'drafts/{dr}/complete',
  DRAFT_ORDER_ADD_CUSTOMER: 'drafts/{dr}/customer',
  DRAFT_ORDER_REMOVE_CUSTOMER: 'drafts/{dr}/customer',
  DRAFT_ORDER_ADD_SIGNATURE: 'drafts/{dr}/signature',
  DRAFT_ORDER_ADD_NOTE: 'drafts/{dr}/note',
  DRAFT_ORDER_CLEAR: 'drafts/{dr}/clear',
  DRAFT_ITEMS_CLEAR_ALL: 'drafts/{draftId}/lineItems/clearAll ',
  DRAFT_ORDER_SET_PAYMENT_METHOD: 'drafts/{dr}/payment/invoice',
  DRAFT_ORDER_SET_PREPURCHASE_PAYMENT_METHOD: 'drafts/{dr}/payment/prepurchase',
  DRAFT_SHIPPING_CLEAR: 'drafts/{dr}/shipping/clear',
  DRAFT_CLEAR_UNAVAILABLE_ITEMS: 'drafts/{dr}/lineItems/clear',
  DRAFT_GET_LAST_ERROR: 'drafts/last/error',
  DRAFT_ZIP_VALIDATION: 'address/deliveryCompany/verify',
  DRAFT_ADDRESSES_VALIDATION: 'shipping/markets/{locationId}/availability',
  // Order
  ORDER_GET_LIST: 'orders',
  ORDER_PAY: 'orders/{or}/pay',
  ORDER_REORDER: 'orders/{or}/reorder',
  ORDER_RETRIEVE: 'orders/{or}',
  ORDER_ADD_VARIANT: 'orders/{or}/add',
  ORDER_REMOVE_LINE_ITEM: 'orders/{or}/{it}',
  ORDER_ADD_NOTE: 'orders/{or}/note',
  ORDER_CLEAR: 'orders/{or}/clear',
  ORDER_RESEND_EMAIL: 'orders/{or}/resend-email',
  ORDER_GET_DRIVER_LOCATION: 'orders/{or}/driverLocation',
  // Line item
  DRAFT_ORDER_UPDATE_LINE_ITEM: 'drafts/{dr}/update/{it}',
  ORDER_UPDATE_LINE_ITEM: 'orders/{or}/update/{it}',
  // Customers
  CUSTOMER_CREATE: 'customers',
  CUSTOMER_CREATE_BY_ANONYMOUS: 'anonymous/customers',
  CUSTOMER_RETRIEVE: 'customers/{cr}',
  CUSTOMER_PATCH_ACCOUNT_INFO: 'customers/{cr}',
  CUSTOMER_ADD_ADDRESS: 'customers/addresses/{at}',
  CUSTOMER_PATCH_ADDRESS: 'customers/addresses/{ad}',
  CUSTOMER_DELETE_ADDRESS: 'customers/addresses/{ad}',
  CUSTOMER_VERIFY_EMAIL: 'customers/emails/verify',
  CUSTOMER_RESET_PASSWORD: 'reset-password',
  CUSTOMER_CREATE_PASSWORD: 'create-password',
  CUSTOMER_VERIFY_TOKEN: 'reset-password/{cr}/verify-token',
  CUSTOMER_VERIFY_EMAIL_TOKEN: 'email-login/{cr}/verify-token',
  CUSTOMER_PATCH: 'customers/{cr}',
  CUSTOMER_ADD_DISCOUNT: 'customers/discounts',

  // Payment methods
  CUSTOMER_ADD_PAYMENT_METHOD: 'customers/payment-methods/add/{me}',
  CUSTOMER_VERIFY_PAYMENT_METHOD: 'customers/payment-methods/{pm}/verify',
  CUSTOMER_DELETE_PAYMENT_METHOD: 'customers/payment-methods/{pm}',
  CUSTOMER_SET_DEFAULT_PAYMENT_METHOD: 'customers/payment-methods/{pm}/default',
  CUSTOMER_VERIFY_BANK_ACCOUNT_WITH_PLAID: 'customers/payment-methods/ach-plaid',
  // Authentication
  CUSTOMER_REGISTER_CHECK: 'register/check',
  CUSTOMER_REGISTER_APPLY: 'register/apply/customer',
  // Create password via invite link
  CREATE_PASSWORD_VIA_INVITE: 'customers/invite-reset',
  // Customer accounts
  CUSTOMER_ADD_ACCOUNT: 'customers/accounts',
  CUSTOMER_PATCH_ACCOUNT: 'customers/accounts/{ac}',
  CUSTOMER_DELETE_ACCOUNT: 'customers/accounts/{ac}',
  // Customer subscriptions
  CUSTOMER_ADD_EMAIL_SUBSCRIPTION: 'customers/email-subscriptions',
  CUSTOMER_PATCH_EMAIL_SUBSCRIPTION: 'customers/email-subscriptions/{id}',
  CUSTOMER_DELETE_EMAIL_SUBSCRIPTION: 'customers/email-subscriptions/{id}',
  // Customer products subscriptions
  CUSTOMER_GET_PRODUCTS_SUBSCRIPTIONS: 'subscriptions',
  CUSTOMER_GET_SINGLE_PRODUCT_SUBSCRIPTION: 'subscriptions/{id}',
  // Customer products prepurchases
  CUSTOMER_GET_PRODUCTS_PREPURCHASES: 'prepurchases',
  CUSTOMER_GET_SINGLE_PRODUCT_PREPURCHASE: 'prepurchases/{id}',

  // Products search
  PRODUCTS_SEARCH: 'products/search',
  RECENT_PRODUCTS_SEARCH: 'customers/recent-products/search',
  COLLECTIONS_SEARCH: 'products/collections/{id}/search',
  SUB_TYPES_SEARCH: 'products/subTypes',
  PRODUCTS_SEARCH_GROUPED_BY_SUBTYPE: 'products/grouped/subType',
  SUBTYPES_GROUPED_BY_TYPE_PAGINATED: 'products/subTypes/grouped/type/paginated',
  SUBTYPES_GROUPED_BY_TYPE: 'products/subTypes/grouped/type',

  // Product
  GET_TYPES: 'products/types',
  GET_PRODUCT: 'products/{id}',

  // Public
  PUBLIC_QR_CODE_VERIFY: 'customers/qr/{code}',
  PUBLIC_QR_VERIFY_COWORKER_EMAIL: 'customers/qr/verify-coworker-email',
  PUBLIC_QR_VERIFY_COWORKER_EMAIL_INVITATION: 'customers/qr/verify-coworker-invitation-code',
};

export default API_END_POINTS;
