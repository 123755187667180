import {
 HIT_SET, HIT_UNSET
} from '../constants';

const initialState = {
  hit: null,
  variant: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HIT_SET : {
      const {hit, variant} = action.payload;
      return {
        ...state,
        hit: hit,
        variant: variant,
      }
    }
    case HIT_UNSET : {
      return {
        ...state,
        hit: null,
        variant: null
      }
    }
    default:
      return state;
  }
};

export default reducer;

