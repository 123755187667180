class Producers {
  constructor(producers) {
    this.producers = producers;
  }

  /**
   * Producers getter
   * @return []
   */
  getProducers() {
    return this.producers;
  }

  /**
   * Search producers by ids
   * @param {string[]} producerIds - array of producer ids
   * @return []
   */
  getProducersByIds(producerIds) {
    return this.producers.filter((item) => producerIds.includes(item.id));
  }

  /**
   * Get producer by handle
   * @param {string} handle - producer handle
   * @return {object} - producer object
   */
  getProducerByHandle(handle) {
    return this.producers.find((item) => item.handle === handle);
  }

  /**
   * Search producers by ids
   * @param {string[]} producerIds - array of producer ids
   * @param {boolean} onlyActive - whether we need only active sub types or not
   * @return []
   */
  getProducersSubTypes(producerIds, onlyActive) {
    let subTypes = [];
    const producers = this.getProducersByIds(producerIds);

    // Convert sub types to array
    producers.forEach((producer) => {
      Object.keys(producer.subTypes).forEach((key) => {
        subTypes.push({
          name: key,
          ...producer.subTypes[key],
        });
      });
    });

    // Filter by active
    if (onlyActive) subTypes = subTypes.filter((item) => item.active);

    return subTypes;
  }

  static getProducerBySlug = (producers, slug) => {
    return producers.find((producer) => producer.handle === slug);
  };
}

export default Producers;
