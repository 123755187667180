class ProductsGroupedBySubTypes {

	constructor(data = {}) {
		this.raw = data.data;
		this.pagination = data.meta;
	}

	getGroupsNames() {
		return this.raw ? this.raw.map((item) => item.name) : [];
	}

	getGroupItems(groupName) {
		// TODO: sometimes the raw is undefined, its strange need to fix
		console.log('ProductsGroupedBySubTypes.called', this.raw);
		// We need only products with at least one active variant
		const category = this.raw ? this.raw.filter((item) => item.name === groupName)[0] : [];
		const products = !!category.products ? category.products.data : [];
		return products.filter(item =>
			item.variants &&
			Array.isArray(item.variants) &&
			item.variants.filter(variant => variant.active).length > 0
		);
	}

}

export default ProductsGroupedBySubTypes;
