import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { toCurrency } from '../../../helpers';

const styles = {
  priceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    textAlign: 'right',
    height: '100%',
    width: '100%',
  },
  price: {
    fontSize: 13,
    fontFamily: 'Noto Sans HK, sans-serif',
    fontWeight: 'bold',
  },
  green: {
    color: 'rgb(65, 205, 140)',
  },
  text: {
    color: '#363636',
  },
};

const LineItemPrice = ({ item, classes, fontSize, intl }) => {
  const isNegative = Number.parseInt(item?.discount?.amount) < 0;

  return (
    <React.Fragment>
      {item.prepurchasedPrice && (
        <div className={classes.priceWrapper}>
          <span className={classNames([classes.price, classes.green])} style={{ ...(!!fontSize ? { fontSize } : {}) }}>
            {toCurrency(item.prepurchasedPrice, intl)}
          </span>
        </div>
      )}

      {!item.prepurchasedPrice && (
        <div className={classes.priceWrapper}>
          <span className={classes.price} style={{ ...(!!fontSize ? { fontSize } : {}) }}>
            {toCurrency(item.price, intl)}
          </span>
          {item.discount && item.discount.applied && (
            <span className={classNames([classes.price, isNegative ? classes.text : classes.green])}>
              {isNegative ? '' : '-'}
              {toCurrency(item.discount.amount, intl)}
            </span>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

LineItemPrice.propTypes = {
  item: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

export default withStyles(styles)(injectIntl(LineItemPrice));
