class SearchProductsParams {
  constructor(props = {}) {
    this.name = props.query || '';
    this.onlyUnique = props.onlyUnique || '';
    this.subType = props.subType || '';
    this.productType = props.productType || '';
    this.active = props.active || '';
    this.shippingType = props.shippingType || '';
    this.shippingDate = props.shippingDate || '';
    this.shippingLocationId = props.shippingLocationId || '';
    this.producerIds = props.producerIds || '';
    this.limit = props.limit || 20;
    this.skip = props.skip || 0;
  }

  toData = () => ({
    ...(this.name ? { name: this.name } : {}),
    ...(this.subType ? { subType: this.subType } : {}),
    ...(this.productType ? { productType: this.productType } : {}),
    ...(this.limit ? { limit: this.limit } : {}),
    ...(this.skip ? { skip: this.skip } : {}),
    ...(this.active ? { active: this.active } : {}),
    ...(this.onlyUnique ? { onlyUnique: this.onlyUnique } : {}),
    ...(this.shippingType ? { type: this.shippingType } : {}),
    ...(this.shippingDate ? { date: this.shippingDate } : {}),
    ...(this.shippingLocationId ? { locationId: this.shippingLocationId } : {}),
    ...(this.producerIds ? { producerIds: this.producerIds } : {}),
  });

  clearShipping = (shippingLocationId = null, shippingType = null, shippingDate = null) => {
    this.shippingType = shippingType;
    this.shippingDate = shippingDate;
    this.shippingLocationId = shippingLocationId;
  };
}

export default SearchProductsParams;
