import { CUSTOMER_TYPES } from './constants';

export const PAYMENT_METHODS = {
  bankAccount: 'stripe_ach',
  creditCard: 'stripe_card',
  check: 'check',
  cod: 'cod',
};

/**
 * Checks whether customer has subscription with email
 * @param {object} customer - customer object
 * @param {string} email - email
 * @returns {boolean}
 */
export const customerHasSubscriptionForEmail = (customer, email) => {
  for (let i = 0; i < customer.emailSubscriptions.length; i++) {
    if (customer.emailSubscriptions[i].email === email) {
      return true;
    }
  }
  return false;
};

/**
 * Extracts subscriptions by email
 * @param {object} customer - customer object
 * @param {string} email - email
 * @returns {Array} - array of subscriptions which has specific email
 */
export const customerGetSubscriptionsByEmail = (customer, email) => {
  let subscriptions = [];
  for (let i = 0; i < customer.emailSubscriptions.length; i++) {
    if (customer.emailSubscriptions[i].email === email) {
      subscriptions.push(customer.emailSubscriptions[i]);
    }
  }
  return subscriptions;
};

/**
 * Extracts customer payment cards
 * @param {object} customer - customer object
 * @param {string} id - payment method id
 * @returns {object|boolean} - payment method|false
 */
export const customerGetPaymentMethodById = (customer, id) => {
  for (let i = 0; i < customer.paymentMethods.length; i++) {
    if (customer.paymentMethods[i].id === id) {
      return customer.paymentMethods[i];
    }
  }
  return false;
};

/**
 * Extracts customer payment cards
 * @param {object} customer - customer object
 * @returns {Array} - array of payment cards
 */
export const customerGetCreditCards = (customer) => {
  let cards = [];
  for (let i = 0; i < customer.paymentMethods.length; i++) {
    if (customer.paymentMethods[i].method === 'stripe_card') {
      cards.push(customer.paymentMethods[i]);
    }
  }
  return cards;
};

/**
 * Searches for default payment method
 * @param {object} customer - customer object
 * @returns {object|boolean}
 */
export const customerGetDefaultPaymentMethod = (customer) => {
  for (let i = 0; i < customer.paymentMethods.length; i++) {
    if (customer.paymentMethods[i].default) {
      return customer.paymentMethods[i];
    }
  }
  return false;
};

/**
 * Extracts customer payment cards
 * @param {object} customer - customer object
 * @returns {Array} - array of payment cards
 */
export const customerGetBankAccounts = (customer) => {
  let cards = [];
  for (let i = 0; i < customer.paymentMethods.length; i++) {
    if (customer.paymentMethods[i].method === 'stripe_ach') {
      cards.push(customer.paymentMethods[i]);
    }
  }
  return cards;
};

/**
 * Checks whether customer has payment methods
 * @param {object} customer - customer object
 * @param {string} type - payment method type
 * @returns {boolean} - whether bank accounts is available
 */
export const customerHasPaymentMethod = (customer, type) => {
  for (let i = 0; i < customer.paymentMethods.length; i++) {
    if (customer.paymentMethods[i].method === type) {
      return true;
    }
  }
  return false;
};

/**
 * validate customer address from back end by market id
 * @param {array} availableAddresses - gets from apiDraftAddressesValidation of redux
 * @param {object|string|boolean|null|undefined} address - address object with id or just id
 * @returns {boolean|null} - returns null if no need to validate
 */
export const validateCustomerAddress = (availableAddresses, address) => {
  if (!address) {
    return null;
  }
  const addressId = typeof address === 'string' ? address : address.id;
  const validatedAddress = availableAddresses.find((item) => item.id === addressId);

  if (process.env.REACT_APP_USE_ADDRESS_VERIFICATION === 'false') {
    return true;
  }

  return validatedAddress ? validatedAddress.available : null;
};

export function getCustomerName(customer) {
  if (customer?.type === CUSTOMER_TYPES.WHOLESALE) {
    return customer?.companyName || '';
  }

  if (customer?.type === CUSTOMER_TYPES.RETAIL && (customer?.firstName || customer?.lastName)) {
    return `${customer.firstName ? customer.firstName + ' ' : ''}${customer.lastName}`;
  }

  return '-';
}
