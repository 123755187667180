import React from 'react';
import Typography from '@material-ui/core/Typography';

const StripePaymentCardsRadioGroupItemLabel = (props) => {
  const {account} = props;
  return (
    <React.Fragment>
      <Typography gutterBottom noWrap>
        {account.brand}, {account.expMonth}/{account.expYear}
      </Typography>
      <Typography variant="button" gutterBottom>
        •••• •••• •••• {account.last4}
      </Typography>
    </React.Fragment>
  );
};


export default StripePaymentCardsRadioGroupItemLabel;
