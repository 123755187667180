import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';

const useStyles = makeStyles({
  table: { fontSize: 14, color: '#000' },
  theadRow: { padding: 0, borderTop: '1px solid rgba(224, 224, 224, 1)' },
  tbodyRow: {
    padding: 5,
  },
  lastRow: {
    borderBottom: 0,
  },
});

function BasicTable(props) {
  const classes = useStyles();

  const { data: rows, columns } = props;

  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={`th_${column.name}`}
              {...(!!column.theadCellProps ? { ...column.theadCellProps } : {})}
              className={classNames([classes.theadRow, column?.theadCellProps?.className])}
            >
              {column.title || column.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={row.id}>
            {columns.map((column) => (
              <TableCell
                key={`tc_${row.id}_${column.name}`}
                {...(!!column.bodyCellProps ? { ...column.bodyCellProps } : {})}
                className={classNames([
                  classes.tbodyRow,
                  column?.bodyCellProps?.className,
                  index === rows?.length - 1 && classes.lastRow,
                ])}
              >
                {column.value(row) || ''}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default BasicTable;
