import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

export const smallHeaderStyles = {
  smallHeaderStylesFontOnWhite: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: '#111',
    lineHeight: '26px',
    margin: 0,
    padding: 0,
  },
  smallHeaderStylesFontOnGreen: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
    lineHeight: '26px',
    margin: 0,
    padding: 0,
  },
};

const useStyles = makeStyles(() => smallHeaderStyles);

const SmallHeader = (props) => {
  const { className, onGreen, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Typography
      className={classNames([
        onGreen ? classes.smallHeaderStylesFontOnGreen : classes.smallHeaderStylesFontOnWhite,
        className,
      ])}
      component="h4"
      {...otherProps}>
      {props.children}
    </Typography>
  );
};

SmallHeader.propTypes = {
  onGreen: PropTypes.bool,
};

export default SmallHeader;
