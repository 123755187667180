import {
  isOrderPacked
} from './orders';

export const weightToUser = (weight, prepare) => {
  weight = (weight > 0)
    ? (weight/100)
    : weight

  return prepare
    ? prepare(weight)
    : (weight === 1 ? " " : weight).toString()
};

export const getLineItemQuantity = (item) => {
  const {orderedQuantity, unitQuantity} = item;
  return orderedQuantity/unitQuantity;
};

export const getLineItemPackedQuantity = (item) => {
  const {packedQuantity, unitQuantity} = item;
  return packedQuantity/unitQuantity;
};

export const getLineItemExtendedQuantity = (order, item) => {
  return (isOrderPacked(order)) ?
    `${getLineItemPackedQuantity(item)}/${getLineItemQuantity(item)}` :
    getLineItemQuantity(item)
};

export const getLineItemWeight = (order, item) => {
  const unitQuantity = weightToUser(item.unitQuantity, weight => weight);
  return (isOrderPacked(order)) ?
    `${getLineItemPackedQuantity(item) * unitQuantity}/${getLineItemQuantity(item) * unitQuantity}` :
    getLineItemQuantity(item) * unitQuantity
};

export const getLineItemSystemQuantity = (item, newQuantity) => {
  const {unitQuantity} = item;
  return newQuantity * unitQuantity;
};

export const getSystemQuantity = (unitQuantity, newQuantity) => {
  return newQuantity * unitQuantity;
};

export const weightToSystem = (weight) => {
  return weight*100;
};



