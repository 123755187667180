import { headerTextStyles } from '../../../../components';

const styles = (theme) => ({
  wrapper: {
    paddingBottom: 37,
  },
  summaryWrapper: {
    marginTop: 35,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  blockWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: 500,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  titleIcon: {
    display: 'inline-block',
    marginBottom: 15,
    fontSize: 100,
  },
  row: {
    fontSize: 14,
    marginBottom: 10,
  },
  actionsWrapper: {
    padding: '10px 20px 5px 20px',
  },
  controls: {
    width: '100%',
    marginBottom: 20,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  control: {
    borderRadius: 40,
    width: '100%',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  rectButton: {
    borderRadius: 5,
    width: '100%',
    fontSize: 14,
    marginBottom: 15,
    padding: 15,
    height: 50,
    fontFamily: "'Noto Sans HK', sans-serif",
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0)',
  },
  viewOrderButton: {
    fontSize: 12,
    textDecoration: 'none',
    fontWeight: 500,
    color: '#000',
    fontFamily: "'Noto Sans HK', sans-serif",
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
  },
  googleMap: {
    width: '100%',
    height: 204,
    marginBottom: 15,
  },
  title: {
    ...headerTextStyles.headerTextStylesFontOnWhite,
    color: '#484848',
    fontWeight: 'bold',
    marginBottom: 7,
  },
  fs16: {
    fontSize: 16,
  },
  fw700: {
    fontWeight: 700,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#e6e6e6',
    margin: '10px 0',
  },
});

export default styles;
