import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

export const cardHeaderStyles = {
  cardHeaderStylesFontOnWhite: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 12,
    fontWeight: 600,
    color: '#111',
    margin: 0,
    padding: 0,
    lineHeight: '18px',
  },
};

const useStyles = makeStyles(() => cardHeaderStyles);

const CardHeader = (props) => {
  const { className, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Typography className={classNames([classes.cardHeaderStylesFontOnWhite, className])} component="p" {...otherProps}>
      {props.children}
    </Typography>
  );
};

export default CardHeader;
