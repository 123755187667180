import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#363636',
        '&$focused': {
          color: '#41CD8C',
          '&$focused': {
            color: '#41CD8C',
          },
        },
      },
    },

    MuiInput: {
      root: {
        color: `#363636`,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid #41CD8C`,
        },
        '&$focused:after': {
          borderBottomColor: `#41CD8C`,
        },
        '&$error:after': {
          borderBottomColor: `#41CD8C`,
        },
        '&:before': {
          borderBottom: `1px solid #41CD8C`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid #41CD8C`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #41CD8C`,
        },
      },
    },
  },
});

class PrimaryTextField extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme}>
      <TextField {...this.props}>{this.props.children}</TextField>
    </MuiThemeProvider>
  );
}

export default PrimaryTextField;
