import moment from 'moment';
import { dateToMMDY, SHIPPING_DELIVERY } from '../helpers';

/**
 * TODO: Need to find and remove deprecated functions
 *
 * Shipping options entity
 * Helps to work with shipping options
 */
class ShippingOptions {
  constructor(props = []) {
    this.raw = props || [];
  }

  /**
   * Prepares list of shipping options by order
   * @param {object} order
   * @return []
   */
  getCheckoutShippingOptionsByOrder(order) {
    let options = [];

    // In case order does not have producers we should return all shipping options
    if (!order.producers.length) {
      return this.raw.slice();
    }

    this.raw.forEach((option) => {
      const { producers } = option;
      let similar = 0;
      producers.forEach((optionProducer) => {
        order.producers.forEach((orderProducer) => {
          if (optionProducer.id === orderProducer.id) {
            similar++;
          }
        });
      });

      if (similar === order.producers.length) {
        options.push(option);
      }
    });

    return options;
  }

  /**
   * Prepares list of shipping options by producer id
   * @param {string} id - producer id
   * @return [] - list of shipping options
   */
  getCheckoutShippingOptionsByProducerId(id) {
    let options = [];
    this.raw.forEach((option) => {
      const { producers } = option;

      producers.forEach((optionProducer) => {
        if (optionProducer.id === id) {
          options.push(option);
        }
      });
    });

    return options;
  }

  /**
   * Returns distinct locations
   * @return []
   */
  getDistinctLocations(producersIds = []) {
    let ids = [],
      unique = [];

    this.raw.forEach(({ location: { id, name, image }, producers }) => {
      if (
        ids.indexOf(id) === -1 &&
        (producersIds.length ? producers.some((producer) => producersIds.includes(producer.id)) : true)
      ) {
        unique.push({ id, name, image });
        ids.push(id);
      }
    });

    return unique;
  }

  /**
   * Returns locations by date and type
   * @param {string} shippingType - shipping type
   * @param {string} date - shipping date
   * @param {array} producersIds - selected producers
   * @return []
   */
  getLocationsByDateAndType(shippingType, date, producersIds = []) {
    let ids = [],
      unique = [];

    this.raw.forEach((location) => {
      if (
        location.type === shippingType &&
        location.next === date &&
        (producersIds.length ? location.producers.some((producer) => producersIds.includes(producer.id)) : true)
      ) {
        ids.indexOf(location.location.id) === -1 &&
          unique.push({
            id: location.location.id,
            name: location.location.name,
            image: location.location.image,
          });

        ids.push(location.location.id);
      }
    });

    return unique;
  }

  /**
   * Returns distinct locations
   * @return [] - array of shipping types or false if nothing found by location id
   */
  getShippingTypesByLocation(locationId, producersIds = []) {
    let types = [];
    this.raw.forEach((item) => {
      if (
        item.location &&
        item.location.id === locationId &&
        (producersIds.length ? item.producers.some((producer) => producersIds.includes(producer.id)) : true)
      ) {
        types.indexOf(item.type) === -1 && types.push(item.type);
      }
    });
    return types;
  }

  /**
   * Returns first available shipping type by location
   * @param locationId
   * @return {string|boolean} - first available shipping type of false if nothing found by location id
   */
  getFirstAvailableShippingTypeByLocation(locationId) {
    const types = this.getShippingTypesByLocation(locationId);
    return types.length ? types[0] : false;
  }

  /**
   * Returns location object by locationId, type, date
   * @param locationId
   * @param type
   * @param date
   */
  getLocationObjectByLocationIdTypeDate(locationId, type, date) {
    return this.raw.filter(
      (item) =>
        item.location.id === locationId && item.type === type && moment.parseZone(item.next).format('MM/D/Y') === date
    )[0];
  }

  /**
   * Searches shipping dates by location & type
   * @return {array} - array of objects with formatted dates and additional data
   */
  getShippingDatesByLocationAndType(locationId, type, producersIds = []) {
    let dates = [];
    this.raw.forEach((item) => {
      if (
        item.location &&
        item.location.id === locationId &&
        item.type === type &&
        (producersIds.length ? item.producers.some((producer) => producersIds.includes(producer.id)) : true)
      ) {
        dates.push(ShippingOptions.makeShippingDate(item));
      }
    });

    return dates.sort((a, b) => new Date(a.next) - new Date(b.next));
  }

  static makeShippingDate = (date) => {
    return {
      day: date.day,
      next: date.next,
      until: date.until,
      formatted: {
        next: moment.parseZone(date.next).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK),
        nextWithFullWeekDay: moment
          .parseZone(date.next)
          .format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK),
        nextMMDY: moment.parseZone(date.next).format('MM/D/Y'),
        nextHHMMA: moment.parseZone(date.next).format('hh:mm A'),
        until: moment
          .parseZone(date.until)
          .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK + ', hh:mm A'),
        untilMMDY: moment.parseZone(date.until).format('MM/D/Y'),
        untilHHMMA: moment.parseZone(date.until).format('hh:mm A'),
      },
    };
  };

  /**
   * Searches shipping dates by types
   */
  getShippingDatesByType(type) {
    let dates = [];
    this.raw.forEach((item) => {
      if (item.type === type && dates.findIndex((i) => i.next === item.next) === -1) {
        dates.push({
          day: item.day,
          next: item.next,
          until: item.until,
          formatted: {
            next: moment.parseZone(item.next).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK),
            nextWithFullWeekDay: moment
              .parseZone(item.next)
              .format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK),
            nextMMDY: moment.parseZone(item.next).format('MM/D/Y'),
            nextHHMMA: moment.parseZone(item.next).format('hh:mm A'),
            until: moment
              .parseZone(item.until)
              .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK + ', hh:mm A'),
            untilMMDY: moment.parseZone(item.until).format('MM/D/Y'),
            untilHHMMA: moment.parseZone(item.until).format('hh:mm A'),
          },
        });
      }
    });

    return dates.sort((a, b) => new Date(a.next) - new Date(b.next));
  }

  /**
   * Searches all available shipping dates
   */
  getAllShippingDatesByType(type, producersIds = []) {
    let dates = [];

    this.raw.forEach((item) => {
      if (
        (!!type ? item.type === type : true) &&
        (producersIds.length ? item.producers.some((producer) => producersIds.includes(producer.id)) : true)
      ) {
        if (dates.findIndex((i) => i.next === item.next) === -1) {
          dates.push({
            day: item.day,
            next: item.next,
            until: item.until,
            formatted: {
              next: moment.parseZone(item.next).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK),
              nextWithFullWeekDay: moment
                .parseZone(item.next)
                .format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK),
              nextMMDY: moment.parseZone(item.next).format('MM/D/Y'),
              nextHHMMA: moment.parseZone(item.next).format('hh:mm A'),
              until: moment
                .parseZone(item.until)
                .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK + ', hh:mm A'),
              untilMMDY: moment.parseZone(item.until).format('MM/D/Y'),
              untilHHMMA: moment.parseZone(item.until).format('hh:mm A'),
            },
          });
        }
      }
    });

    return dates.sort((a, b) => new Date(a.next) - new Date(b.next));
  }

  /** Searches all available shipping types */
  getAllShippingTypes(producersIds = []) {
    let types = [];

    this.raw.forEach((item) => {
      if (
        types.indexOf(item.type) === -1 &&
        (producersIds.length ? item.producers.some((producer) => producersIds.includes(producer.id)) : true)
      ) {
        types.push(item.type);
      }
    });

    return types;
  }

  /**
   * Select producers by draft shipping type|date and market
   * @param {ShippingOptions} shippingOptions - shipping options
   * @param {object} draft - draft/order object
   * @param {object} market - market object
   * @return {Array} - array of producers
   */
  static getAvailableProducers(shippingOptions, draft, market) {
    const options = ShippingOptions.getAvailableShippingOptions(shippingOptions, draft, market);
    let producers = [];

    // Collect unique producers
    options.forEach((option) =>
      option.producers.forEach((producer) => {
        !producers.some((item) => item.id === producer.id) && producers.push(producer);
      })
    );

    return producers.sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  /**
   * Select producers by draft shipping type|date and market
   * @param {ShippingOptions} shippingOptions - shipping options
   * @param {object} draft - draft/order object
   * @param {array} producersIds - producers
   * @return {Array} - array of producers
   */
  static getAvailableLocations(shippingOptions, draft, producersIds) {
    const options = ShippingOptions.getAvailableShippingOptions(shippingOptions, draft, null, producersIds);
    let markets = [];

    // Collect unique locations
    options.forEach(
      (option) => !markets.some((item) => item.id === option.location.id) && markets.push(option.location)
    );
    return markets;
  }

  /**
   * Select producers by draft shipping type|date and market
   * @param {ShippingOptions} shippingOptions - shipping options
   * @param {object} draft - draft/order object
   * @param {object} market - market
   * @param {array} producersIds - producers
   * @return {Array} - array of producers
   */
  static getAvailableShippingOptions(shippingOptions, draft, market, producersIds) {
    // Options filtered by order producers
    let options = shippingOptions.getCheckoutShippingOptionsByOrder(draft);

    // Filter by shipping type
    if (draft.shipping && draft.shipping.type)
      options = options.filter((option) => option.type === draft.shipping.type);
    // Filter by shipping date
    if (draft.shipping && draft.shipping.date)
      options = options.filter((option) => dateToMMDY(option.next) === dateToMMDY(draft.shipping.date));
    // Filter by location(market)
    if (market) options = options.filter((option) => option.location.id === market.id);
    // Filter by producer ids
    if (producersIds && Array.isArray(producersIds) && producersIds.length)
      options = options.filter((option) => option.producers.some((item) => producersIds.includes(item.id)));
    // Filter by product day options
    if (!!draft.shipping && !!draft.shipping.productOfferedMarkets) {
      options = options.filter((option) =>
        draft.shipping.productOfferedMarkets.some(
          (market) =>
            market.shippingDay === option.day &&
            market.shippingType === option.type &&
            market.locationId === option.location.id
        )
      );
    }

    return options;
  }

  /**
   * Select available shipping types
   * @param {ShippingOptions} shippingOptions - shipping options
   * @param {object} draft - draft/order object
   * @param {object} market - market
   * @param {array} producersIds - producers
   * @return {Array} - array of producers
   */
  static getAvailableShippingTypes(shippingOptions, draft, market, producersIds) {
    // Options filtered by order producers
    let options = shippingOptions.getCheckoutShippingOptionsByOrder(draft);
    let types = [];

    // Filter by location(market)
    if (market) options = options.filter((option) => option.location.id === market.id);
    // Filter by producer ids
    if (producersIds && Array.isArray(producersIds) && producersIds.length)
      options = options.filter((option) => option.producers.some((item) => producersIds.includes(item.id)));

    // Collect unique locations
    options.forEach((option) => !types.some((type) => type === option.type) && types.push(option.type));

    return types.sort((a, b) => {
      if (a > b) return -1;
      else if (a < b) return 1;
      else return 0;
    });
  }

  /**
   * Select available shipping dates
   * @param {ShippingOptions} shippingOptions - all possible shipping options
   * @param {object} draft - draft/order object
   * @param {object} market - market
   * @param {array} producersIds - producers
   * @return {Array} - array of shipping dates
   */
  static getAvailableShippingDates(shippingOptions, draft, market, producersIds) {
    // Options filtered by order producers
    let options = shippingOptions.getCheckoutShippingOptionsByOrder(draft);
    let dates = [];

    // Filter by shipping type
    if (draft.shipping && draft.shipping.type)
      options = options.filter((option) => option.type === draft.shipping.type);
    // Filter by location(market)
    if (market) options = options.filter((option) => option.location.id === market.id);
    // Filter by producer ids
    if (producersIds && Array.isArray(producersIds) && producersIds.length)
      options = options.filter((option) => option.producers.some((item) => producersIds.includes(item.id)));

    // Collect options with unique dates
    options.forEach(
      (option) => !dates.some((date) => dateToMMDY(date.next) === dateToMMDY(option.next)) && dates.push(option)
    );

    // Sort the array by next dates
    dates.sort((itemPrev, itemNext) => new Date(itemPrev.next) - new Date(itemNext.next));

    return dates.map((item) => ({
      day: item.day,
      next: item.next,
      formatted: {
        next: moment.parseZone(item.next).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK),
        nextWithFullWeekDay: moment
          .parseZone(item.next)
          .format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK),
        nextMMDY: moment.parseZone(item.next).format('MM/D/Y'),
        nextHHMMA: moment.parseZone(item.next).format('hh:mm A'),
      },
    }));
  }

  /**
   * Returns first available shipping date by location & type
   * @param {string} locationId
   * @param {string} type
   * @return {string|boolean} - first available shipping date of false if nothing found by location id and type
   */
  getFirstAvailableShippingDateByLocationAndType(locationId, type) {
    const dates = this.getShippingDatesByLocationAndType(locationId, type);
    return dates.length ? dates[0] : false;
  }

  getFirstAvailableShippingDateByType(type) {
    const dates = this.getShippingDatesByType(type);
    return dates.length ? dates[0] : false;
  }

  /**
   * Get pickup hours for location
   * @return {string}
   */
  static getLocationPickupHours(location) {
    let pickupHours = '';

    if (location && location.pickupStart && location.pickupEnd) {
      let start = moment(location.pickupStart, 'Hmm').format('h:mm A'),
        end = moment(location.pickupEnd, 'Hmm').format('h:mm A');
      pickupHours = `${start} - ${end}`;
    }

    return pickupHours;
  }

  /**
   * Return unique shipping dates by type and producer
   * @param shippingOptions
   * @param type
   * @param producerId
   * @returns array
   */
  static getAvailableShippingDatesByTypeAndProducer(shippingOptions, type, producerId) {
    const byProducer = shippingOptions.raw.filter((option) =>
      option.producers.some((producer) => producer.id === producerId)
    );

    return byProducer.filter((option) => option.type === type);
  }

  getPersonalProducerShippingVariants(producerId) {
    let uniqueDates = [];

    const filteredDates = this.raw.filter((date) => {
      const byType = date.type === SHIPPING_DELIVERY;
      const byProducer = !!producerId ? date.producers.some((producer) => producer.id === producerId) : true;

      return byType && byProducer;
    });

    filteredDates.forEach((date) => {
      if (!uniqueDates.some((savedDate) => savedDate.next === date.next)) {
        uniqueDates.push(date);
      }
    });

    const sortedDates = uniqueDates.sort((a, b) => new Date(a.next) - new Date(b.next));
    return [...sortedDates].splice(0, 2);
  }

  getPersonalProducerShippingDates() {
    return this.raw.map((date) => ({ ...date, ...ShippingOptions.makeShippingDate(date) }));
  }
}

export default ShippingOptions;
