const styles = theme => ({
  formControl: {
    width: '100%',
    margin: "5px 0"
  },
  blockWrapper: {
    maxWidth: 500,
    marginBottom: 30
  },
  controls: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 20
    },
    marginTop: 20,
    textAlign: "center"
  },
  paymentMethodsControls: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 20
    },
    textAlign: "left"
  },
  radioLabelBody: {
    width: '100%'
  },
  addButton: {
    marginBottom: 10,
    marginTop: 10,
    marginRight: 5
  },
});

export default styles;
