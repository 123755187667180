import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { colors, toCurrency } from '../../helpers';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  green: {
    color: 'rgb(65, 205, 140)',
  },
  black: {
    color: colors.black,
  },
  price: {
    fontWeight: 500,
  },
};

const LineItemPrice = ({ item, classes, intl }) => {
  return (
    <React.Fragment>
      {!!item.prepurchasedPrice && (
        <span className={classNames(classes.price, classes.black)}>{toCurrency(item.prepurchasedPrice, intl)}</span>
      )}

      {!!!item.prepurchasedPrice && (
        <span className={classes.wrapper}>
          <span>{toCurrency(item.price, intl)}</span>
          {item.discount && item.discount.applied && (
            <span className={classes.black}>
              {Number.parseInt(item?.discount?.amount) > 0 ? '-' : ''}
              {toCurrency(item.discount.amount, intl)}
            </span>
          )}
        </span>
      )}
    </React.Fragment>
  );
};

LineItemPrice.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withStyles(styles)(injectIntl(LineItemPrice));
