const styles = ({
  wrapper: {
    paddingBottom: 37
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center'
  },
  addresses: {
    textAlign: 'center'
  }
});

export default styles;