import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        fontFamily: "'Noto Sans HK', sans-serif",
        fontWeight: 600,
        fontSize: 12,
        background: '#transparent',
        borderRadius: 33,
        color: '#363636',
        border: '1px solid #70707066',
        transition: 'background 0.5s ease',
        padding: 0,
        '&:hover, &:active, &:focus': {
          color: '#363636',
          border: '1px solid #70707066',
          backgroundColor: '#7070700f',
        },
        '&:disabled': {
          color: '#363636',
          border: '1px solid #70707066',
          background: '#7070700f',
          opacity: 0.5,
        },
      },
      text: {
        padding: '5px 25px',
        textTransform: 'none',
        letterSpacing: 1,
      },
    },
  },
});

class HeaderRoundButton extends React.Component {
  render = () => {
    const hideBorderStyles = {
      textTransform: 'normal',
      border: '0',
      '&:hover, &:active, &:focus': {
        border: '0',
      },
      '&:disabled': {
        border: '0',
      },
    };

    return (
      <MuiThemeProvider theme={theme}>
        <Button {...this.props} style={this.props.variant === 'outlined' ? {} : hideBorderStyles}>
          {this.props.children}
        </Button>
      </MuiThemeProvider>
    );
  };
}

export default HeaderRoundButton;
