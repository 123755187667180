import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { BlackButton, Icon, NewHeader, OrderSummaryV2 } from '../../../components';
import ShippingStage from './ShippingStage/ShippingStage';
// Address
import PatchAddressStage from './PatchAddressStage/PatchAddressStage';
import AddAddressStage from './AddAddressStage/AddAddressStage';
import SelectAddressStage from './SelectAddressStage/SelectAddressStage';
// Payment method
import PaymentMethodStage from './PaymentMethodStage/PaymentMethodStage';
import AddCreditCardStage from './AddCreditCardStage/AddCreditCardStage';
import FulfillmentFeeInfoStage from './FulfillmentFeeInfoStage';
import ServiceChargeInfoStage from './ServiceChargeInfoStage/ServiceChargeInfoStage';
import { PaymentPreference } from '../../Partials';
// Complete
import CompletedStage from './CompletedStage/CompletedStage';
import { BILLING_ADDRESS_TYPE, CHECKOUT_STAGES, SHIPPING_DELIVERY, SHIPPING_PICKUP } from '../../../helpers/constants';
import styles from './styles';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { dateToMMDY, getSalesTax, isSalesTaxEnabled, toCurrency } from '../../../helpers';
import { OrderEntity, ShippingOptions } from '../../../entities';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ErrorBox from '../../../components/ErrorBox/ErrorBox';
import AddressWidthMarketsSelect from './AddressWidthMarketsSelect';
import { isAvailabilities } from './helpers';
import { refreshDraft } from '../../../store/actions';
import CheckoutItemsList from './CheckoutItemsList';

const propTypes = {
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  completed: PropTypes.bool,
  stage: PropTypes.string,
  shippingOptions: PropTypes.object.isRequired,
  draftObjectAfterComplete: PropTypes.object,
  subscriptionIdAfterComplete: PropTypes.string,
  onCancel: PropTypes.func,
  onChangeShipping: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  customer: PropTypes.object.isRequired,
  draft: PropTypes.object.isRequired,
  changePaymentPreferences: PropTypes.func.isRequired,
  // Addresses
  onAddAddress: PropTypes.func.isRequired,
  onPatchAddress: PropTypes.func.isRequired,
  setDraftAddress: PropTypes.func.isRequired,
  // Payment methods
  verifyPaymentMethod: PropTypes.func.isRequired,
  forceSaveShippingOptions: PropTypes.bool,
};

const defaultProps = {
  loading: false,
  deliveryType: 'pickup',
};

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    const { draft, shippingOptions, market, disablePickup } = props;

    this.shippingOptions = new ShippingOptions(shippingOptions.getCheckoutShippingOptionsByOrder(draft));
    const hasAvailabilities = isAvailabilities(draft);

    const { shipping } = draft;

    let shippingLocation = false;
    let shippingDate = false;
    let shippingType = false;

    // Cache locations
    this.locations = this.shippingOptions.getDistinctLocations();

    if (shipping) {
      shippingDate = dateToMMDY(shipping.date);
      shippingType = shipping.type;
      shippingLocation = market && market.id ? market.id : false;
    }

    // Predefine shipping location
    if (!shippingLocation && this.locations.length) {
      shippingLocation = this.locations[0].id;
    }

    // Predefine shipping type
    if (shippingLocation && !shippingType) {
      let shippingTypes = this.shippingOptions.getShippingTypesByLocation(shippingLocation);
      disablePickup && shippingTypes.filter((type) => type !== SHIPPING_PICKUP);

      shippingTypes.length && (shippingType = shippingTypes[0]);
    }

    // Predefine shipping date
    if (shippingLocation && shippingType && !shippingDate) {
      const shippingDates = this.shippingOptions.getShippingDatesByLocationAndType(shippingLocation, shippingType);
      shippingDates.length && (shippingDate = shippingDates[0].formatted.nextMMDY);
    }

    if (hasAvailabilities) {
      shippingLocation = '';
    }

    this.state = {
      addressType: '',
      patchingAddress: null,
      paymentMethod: null,
      paymentMethodType: null,
      paymentMethodId: null,
      stage: this.props.stage || CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE,
      backToFromInfoStage: CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE,
      shippingAddressAsBilling: true,
      shippingLocation,
      shippingType,
      shippingDate,
      orderComplete: false,
      checkoutGlobalError: '',
    };

    this.stageComponent = null;
  }

  onBackClick = () => {
    const { addressType } = this.state;

    if (
      this.state.stage === CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE ||
      this.state.stage === CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE
    ) {
      this.props.onClose();
    }

    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE) {
      this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE });
    }

    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE) {
      addressType === BILLING_ADDRESS_TYPE
        ? this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE })
        : this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE });
    }

    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_ADD_ADDRESS_STAGE) {
      this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE });
    }

    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_PATCH_ADDRESS_STAGE) {
      this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE });
    }

    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_ADD_CREDIT_CARD_STAGE) {
      this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE });
    }

    if (this.state.stage === CHECKOUT_STAGES.FULFILLMENT_INFO) {
      this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE });
    }

    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_SERVICE_CHARGE_INFO_STAGE) {
      this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE });
    }

    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_SELECT_MARKET_STAGE) {
      this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE });
    }
  };

  onShowInfoStage = (infoStage, backToFromInfoStage) => {
    this.setState({
      stage: infoStage,
      backToFromInfoStage: backToFromInfoStage || this.state.stage,
    });
  };

  orderSummary = () => {
    const { intl, draftObjectAfterComplete: order, draft } = this.props;

    return (
      <React.Fragment>
        {this.state.stage !== CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE && !this.state.orderComplete ? (
          <OrderSummaryV2
            order={draft}
            onFulfillmentInfoClick={() => this.onShowInfoStage(CHECKOUT_STAGES.FULFILLMENT_INFO)}
            // containerClass={classes.summaryWrapper}
            subtotal={toCurrency(this.props.draft.subtotal, intl)}
            shipping={this.props.draft.shipping ? toCurrency(this.props.draft.shipping.fee, intl) : 0}
            salesTax={isSalesTaxEnabled() ? getSalesTax(this.props.draft.total, intl) : ''}
            total={toCurrency(this.props.draft.total, intl)}
            paymentMethodType={this.state.paymentMethodType}
            onShowServiceChargeInfo={() => this.onShowInfoStage(CHECKOUT_STAGES.CHECKOUT_SERVICE_CHARGE_INFO_STAGE)}
            refreshDraft={this.props.refreshDraft}
          />
        ) : (
          <OrderSummaryV2
            order={order}
            onFulfillmentInfoClick={() =>
              this.onShowInfoStage(CHECKOUT_STAGES.FULFILLMENT_INFO, CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE)
            }
            // containerClass={classes.summaryWrapper}
            subtotal={toCurrency(order.subtotal, intl)}
            shipping={
              order.shipping.fee && order.shipping.type === SHIPPING_DELIVERY
                ? toCurrency(order.shipping.fee, intl)
                : ''
            }
            salesTax={isSalesTaxEnabled() ? getSalesTax(order.total, intl) : ''}
            total={toCurrency(order.total, intl)}
            grandTotal={OrderEntity.getOrderGrandTotal(order, intl)}
            serviceChargeFee={OrderEntity.getServiceCharge(order, intl)}
            onShowServiceChargeInfo={() =>
              this.onShowInfoStage(
                CHECKOUT_STAGES.CHECKOUT_SERVICE_CHARGE_INFO_STAGE,
                CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE
              )
            }
            refreshDraft={this.props.refreshDraft}
          />
        )}
      </React.Fragment>
    );
  };

  drawTitle = () => {
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE) {
      return <FormattedMessage id="global.checkout" />;
    }
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE) {
      return <FormattedMessage id="address.select" />;
    }
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_PATCH_ADDRESS_STAGE) {
      return this.state.addressType === BILLING_ADDRESS_TYPE ? 'Edit billing address' : 'Edit shipping address';
    }
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_ADD_ADDRESS_STAGE) {
      return this.state.addressType === BILLING_ADDRESS_TYPE ? 'Add billing address' : 'Add shipping address';
    }
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE) {
      return <FormattedMessage id="global.checkout" />;
    }
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_ADD_CREDIT_CARD_STAGE) {
      return <FormattedMessage id="payment.addCreditCard" />;
    }
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE) {
      return <FormattedMessage id={'order.confirmed'} />;
    }
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_PAYMENT_PREFERENCE_STAGE) {
      return <FormattedMessage id="paymentPreferences.title" />;
    }
    if (this.state.stage === CHECKOUT_STAGES.FULFILLMENT_INFO) {
      return <FormattedMessage id="order.fulfillmentFee" />;
    }
    if (this.state.stage === CHECKOUT_STAGES.CHECKOUT_SERVICE_CHARGE_INFO_STAGE) {
      return <FormattedMessage id="global.serviceCharge" />;
    }
  };

  drawContent = () => {
    const {
      loading,
      onClose,
      draft,
      customer,
      onAddAddress,
      onPatchAddress,
      setDraftAddress,
      onComplete,
      onError,
      draftObjectAfterComplete,
      subscriptionIdAfterComplete,
      changePaymentPreferences,
      verifyPaymentMethod,
      shippingOptions,
      shippingError,
      producers,
      disablePickup,
      forceSaveShippingOptions,
    } = this.props;
    const { checkoutGlobalError, shippingLocation, shippingType } = this.state;

    return (
      <>
        {!!checkoutGlobalError && <ErrorBox error={checkoutGlobalError} />}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_SELECT_MARKET_STAGE && (
          <AddressWidthMarketsSelect
            onSelectMarket={(newShippingLocation) => {
              this.setState({ shippingLocation: newShippingLocation, stage: CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE });
            }}
            shippingType={shippingType}
            producers={producers}
            shippingLocation={shippingLocation}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE && (
          <ShippingStage
            stageRef={(ref) => (this.stageComponent = ref)}
            onFulfillmentInfoClick={() =>
              this.onShowInfoStage(CHECKOUT_STAGES.FULFILLMENT_INFO, CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE)
            }
            customer={customer}
            onSetDraftAddress={setDraftAddress}
            shippingError={shippingError}
            shippingOptions={shippingOptions}
            draft={draft}
            onChangeAddress={(type) => {
              this.setState({
                stage: CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE,
                addressType: type,
              });
            }}
            loading={loading}
            onChangeShipping={this.props.onChangeShipping}
            onDone={() => this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE })}
            onError={(errorMessage) => {
              this.setState({ checkoutGlobalError: errorMessage });
            }}
            onSelectMarket={() => {
              this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SELECT_MARKET_STAGE });
            }}
            shippingLocation={shippingLocation}
            shippingType={shippingType}
            onChangeShippingType={(newShippingType) => {
              this.setState({ shippingType: newShippingType });
            }}
            disablePickup={disablePickup}
            forceSaveShippingOptions={forceSaveShippingOptions}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE && (
          <SelectAddressStage
            loading={loading}
            draft={draft}
            type={this.state.addressType}
            onSetDraftAddress={setDraftAddress}
            onDone={() =>
              this.setState({
                stage:
                  this.state.addressType === BILLING_ADDRESS_TYPE
                    ? CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE
                    : CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE,
              })
            }
            onAddAddress={() => this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_ADD_ADDRESS_STAGE })}
            onPatchAddress={(address) => {
              this.setState({
                stage: CHECKOUT_STAGES.CHECKOUT_PATCH_ADDRESS_STAGE,
                patchingAddress: address,
              });
            }}
            customer={customer}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_PATCH_ADDRESS_STAGE && (
          <PatchAddressStage
            loading={loading}
            type={this.state.addressType}
            onPatchAddress={onPatchAddress}
            onDone={() => this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE })}
            address={this.state.patchingAddress}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_ADD_ADDRESS_STAGE && (
          <AddAddressStage
            loading={loading}
            onAddAddress={onAddAddress}
            onDone={() => this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE })}
            type={this.state.addressType}
            customer={customer}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE && (
          <PaymentMethodStage
            stageRef={(ref) => (this.stageComponent = ref)}
            onFulfillmentInfoClick={() =>
              this.onShowInfoStage(CHECKOUT_STAGES.FULFILLMENT_INFO, CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE)
            }
            loading={loading}
            onComplete={onComplete}
            onError={onError}
            onAddCreditCard={() => this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_ADD_CREDIT_CARD_STAGE })}
            onShowServiceChargeInfo={() =>
              this.onShowInfoStage(
                CHECKOUT_STAGES.CHECKOUT_SERVICE_CHARGE_INFO_STAGE,
                CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE
              )
            }
            onVerifyBankAccount={verifyPaymentMethod}
            onClose={onClose}
            onDone={() =>
              this.setState({
                stage: CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE,
                orderComplete: true,
                checkoutGlobalError: '',
              })
            }
            onChangeAddress={(type) => {
              this.setState({
                stage: CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE,
                addressType: type,
              });
            }}
            onSetDraftAddress={setDraftAddress}
            shippingAddressAsBilling={this.state.shippingAddressAsBilling}
            changeShippingAddressAsBilling={(event) =>
              this.setState({ shippingAddressAsBilling: event.target.checked })
            }
            onAddAddress={onAddAddress}
            draft={draft}
            customer={customer}
            producers={producers}
            withOutOrderSummary={true}
            onChange={(paymentMethodType, paymentMethodId) => {
              this.setState({
                paymentMethodType,
                paymentMethodId,
              });
            }}
            selectedMethodId={this.state.paymentMethodId}
            selectedMethodType={this.state.paymentMethodType}
            refreshDraft={this.props.refreshDraft}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_ADD_CREDIT_CARD_STAGE && (
          <AddCreditCardStage
            loading={loading}
            onFirst={() => this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_PAYMENT_PREFERENCE_STAGE })}
            onDone={() => this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE })}
            customer={customer}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE && (
          <CompletedStage
            onFulfillmentInfoClick={() =>
              this.onShowInfoStage(CHECKOUT_STAGES.FULFILLMENT_INFO, CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE)
            }
            order={draftObjectAfterComplete}
            subscriptionId={subscriptionIdAfterComplete}
            draft={draft}
            customer={customer}
            onDone={onClose}
            onClose={onClose}
            stageRef={(ref) => (this.stageComponent = ref)}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_PAYMENT_PREFERENCE_STAGE && (
          <PaymentPreference
            changePaymentPreferences={changePaymentPreferences}
            onDone={() => this.setState({ stage: CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE })}
          />
        )}

        {this.state.stage === CHECKOUT_STAGES.FULFILLMENT_INFO && (
          <FulfillmentFeeInfoStage onDone={() => this.setState({ stage: this.state.backToFromInfoStage })} />
        )}

        {this.state.stage === CHECKOUT_STAGES.CHECKOUT_SERVICE_CHARGE_INFO_STAGE && (
          <ServiceChargeInfoStage onDone={() => this.setState({ stage: this.state.backToFromInfoStage })} />
        )}
      </>
    );
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <Dialog open={true} fullScreen>
        <Hidden xsDown>
          <NewHeader styles={{ position: 'relative' }} />
        </Hidden>

        <React.Fragment>
          <Hidden xsDown>
            <div className={classes.navigationButtons}>
              {this.state.stage !== CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE && (
                <Icon
                  icon={BackIcon}
                  style={{ fill: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
                  className={classes.back}
                  onClick={this.onBackClick}
                />
              )}
            </div>
          </Hidden>

          <Hidden smUp>
            <div className={classes.toolbarWrapper}>
              <div className={classes.toolbar}>
                {this.state.stage !== CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE && (
                  <Icon
                    icon={BackIcon}
                    style={{ fill: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
                    className={classes.back}
                    onClick={this.onBackClick}
                  />
                )}
                <Typography className={classes.smallTitle}>{intl.formatMessage({ id: 'global.checkout' })}</Typography>
                <div>&nbsp;</div>
              </div>
            </div>
          </Hidden>
        </React.Fragment>

        <Grid container className={classes.contentWrapper} justifyContent="space-between">
          <Grid item xs={12} sm={5} md={5} lg={5}>
            {this.drawContent()}
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5}>
            {
              // desktop item list
              <Hidden xsDown>
                <div className={classes.checkoutTitle}>{intl.formatMessage({ id: 'global.yourOrder' })}</div>
                <CheckoutItemsList
                  draft={this.props.draft}
                  draftObjectAfterComplete={this.props.draftObjectAfterComplete}
                />
              </Hidden>
            }

            {
              // mobile items list
              this.state.stage === CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE ||
              this.state.stage === CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE ||
              this.state.stage === CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE ? (
                <Hidden smUp>
                  <CheckoutItemsList
                    draft={this.props.draft}
                    draftObjectAfterComplete={this.props.draftObjectAfterComplete}
                  />
                </Hidden>
              ) : null
            }

            <Hidden smUp>
              {/*<div className={classes.hr} />*/}
              {this.orderSummary()}
            </Hidden>

            <Hidden xsDown>
              <div className={classes.hr} />
              {this.orderSummary()}
            </Hidden>

            {this.state.stage === CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE && (
              <Hidden smUp>
                <div className={classes.controls}>
                  <BlackButton
                    disabled={this.props.patchingAccountInfo}
                    onClick={() => this.stageComponent.onDone()}
                    className={classes.control}>
                    <FormattedMessage id="checkout.nextUpPayment" />
                  </BlackButton>
                </div>
              </Hidden>
            )}
            {this.state.stage === CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE && (
              <Hidden smUp>
                <div className={classes.controls}>
                  <BlackButton
                    disabled={this.props.patchingAccountInfo}
                    onClick={() => this.stageComponent.complete()}
                    className={classes.control}>
                    <FormattedMessage id="global.completeOrder" />
                  </BlackButton>
                </div>
              </Hidden>
            )}
            {this.state.stage === CHECKOUT_STAGES.CHECKOUT_COMPLETED_STAGE && (
              <Hidden smUp>
                <div className={classes.controls}>
                  <BlackButton
                    disabled={this.props.patchingAccountInfo}
                    onClick={this.props.onClose}
                    className={classes.control}>
                    <FormattedMessage id="global.done" />
                  </BlackButton>
                </div>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

Checkout.propTypes = propTypes;
Checkout.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    customer: state.customer.object,
    market: state.market.object,
    allProducers: state.producer.producers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshDraft: (...props) => dispatch(refreshDraft(...props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(withStyles(styles, { withTheme: true })(Checkout))));
