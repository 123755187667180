const styles = theme => ({
  selected: {
    backgroundColor: '#eeeeee'
  },
  default: {},
  title: {
    fontSize: 20,
    color: '#212121',
  },
  empty: {
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: 0
  },
  emptyWrapper: {
    flexDirection: 'column'
  },
  subTypeText: {
    fontSize: 14
  },
  subTypeWrapperSelected: {
    backgroundColor: '#eeeeee',
    paddingTop: 8,
    paddingBottom: 8
  },
  subTypeWrapper: {
    paddingTop: 8,
    paddingBottom: 8
  }
});

export default styles;