import React from "react";
import PropTypes from "prop-types";
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ErrorBox,
  Spinner,
} from '../../../../components';
import {
  CheckoutAddressForm
} from '../../../../forms';
import styles from './styles';

const propTypes = {
  type: PropTypes.string.isRequired,
  onPatchAddress: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
};
const defaultProps = {};

class PatchAddressStage extends React.Component {

  state = {
    error: ""
  };

  render() {
    const {
      loading,
      address,
      onPatchAddress,
      onDone,
    } = this.props;

    return (
      <React.Fragment>
        <>
          {this.state.error && (
            <ErrorBox error={this.state.error} />
          )}
          {loading && (
            <Spinner size={40} />
          )}

          <CheckoutAddressForm
            mt70
            error={this.state.error}
            loading={this.state.loadingAddress}
            address={address}
            onValidated={(id, data) => {
              this.setState({loadingAddress: true}, () => {
                onPatchAddress(id, data, () => {
                  this.setState({loadingAddress: false}, onDone);
                }, () => {
                  this.setState({
                    error: "Something went wrong",
                    loadingAddress: false
                  });
                })
              });
            }}
          />
        </>
      </React.Fragment>
    );
  }
}

PatchAddressStage.propTypes = propTypes;
PatchAddressStage.defaultProps = defaultProps;

export default withStyles(styles)(PatchAddressStage);






