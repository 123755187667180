export const colors = {
  gray: '#e6e6e6',
  black: '#212121',
  fullBlack: '#000',
  white: '#ffffff',
  border: '#e6e6e6',
  lightGray: '#ececec',
  green: '#41CD8C',
  headerText: '#4D4F5C',
  text: '#363636',
  textGray: '#9B9B9D',
  textSecondary: '#1F1F1F',
  cartSecondary: '#707070',
  violet: '#C51162',
  actionLabel: '#A3A6B4',
  lightGreen: '#D6DEAA',
  greyText: '#484848',
  metal: '#576D80',
};
