class Producer {

  static getHumanReadableAddress(producer) {
    let address = [];

    producer.city && address.push(producer.city);
    producer.province && address.push(producer.province);

    return address.join(', ')
  }

  static getHumanReadableTypesList(producer) {
    let names = Object
      .keys(producer.types)
      .map(item => item.trim())
      .sort((a, b) => a.localeCompare(b));
    return names.join(', ');
  }
}

export default Producer;