// Auth stages
export const AUTH_EMAIL_AND_PASSWORD_STAGE = 'email-and-password';
export const AUTH_REGISTER_STAGE = 'register';
export const AUTH_RESTORE_PASSWORD_STAGE = 'restore';
export const AUTH_RESTORE_PASSWORD_FINAL_STAGE = 'restore-final';
export const AUTH_CREATE_PASSWORD_STAGE = 'create-password';
export const AUTH_RESEND_EMAIL_AFTER_REGISTER_STAGE = 'resend-email-after-register';

// Checkout stages
export const CHECKOUT_STAGES = {
  CHECKOUT_SHIPPING_STAGE: 'shipping',
  CHECKOUT_PAYMENT_METHOD_STAGE: 'payment-method',
  CHECKOUT_COMPLETED_STAGE: 'completed',
  CHECKOUT_SELECT_MARKET_STAGE: 'select-market',
  CHECKOUT_SELECT_ADDRESS_STAGE: 'select-address',
  CHECKOUT_PATCH_ADDRESS_STAGE: 'patch-address',
  CHECKOUT_ADD_ADDRESS_STAGE: 'add-address',
  CHECKOUT_ADD_BANK_ACCOUNT_STAGE: 'bank-account',
  CHECKOUT_ADD_CREDIT_CARD_STAGE: 'credit-card',
  CHECKOUT_VERIFY_BANK_ACCOUNT_STAGE: 'verify-bank-account',
  CHECKOUT_PAYMENT_PREFERENCE_STAGE: 'payment-preference',
  FULFILLMENT_INFO: 'fulfillment-info',
  CHECKOUT_SERVICE_CHARGE_INFO_STAGE: 'service-charge-info',
};

// Collection view types
export const COLLECTION_VIEW_TYPE_GRID = 'grid';
export const COLLECTION_VIEW_TYPE_LIST = 'list';
export const COLLECTION_VIEW_TYPE_LIST_COMPACT = 'list-compact';
export const AUTH_CREATE_PASSWORD = 'create-password';
export const AUTH_RESTORE_PASSWORD = 'reset-password';
// Payment
export const PAYMENT_MANUAL = 'payment-manual';
export const PAYMENT_AUTO = 'payment-auto';
// Addresses
export const BILLING_ADDRESS_TYPE = 'billing';
export const DELIVERY_ADDRESS_TYPE = 'shipping';
export const BILLING_ADDRESS = 'billingAddress';
export const BILLING_ADDRESSES = 'billingAddresses';
export const DELIVERY_ADDRESS = 'shippingAddress';
export const DELIVERY_ADDRESSES = 'shippingAddresses';
// Shipping
export const SHIPPING_DELIVERY = 'delivery';
export const SHIPPING_PICKUP = 'pickup';
// Payment types
export const CHECK = 'check';
export const STRIPE_CARD = 'stripe_card';
export const STRIPE_ACH = 'stripe_ach';
// Verified
export const PAYMENT_METHOD_VERIFIED = 'verified';

// User types
export const USER_TYPES = {
  ACCOUNT: 'account',
  CUSTOMER: 'customer',
};
// User types
export const CUSTOMER_TYPES = {
  RETAIL: 'retail',
  WHOLESALE: 'wholesale',
};

// Order statuses
export const ORDER_STATUSES = {
  ORDER_DRAFT: 'draft',
  ORDER_COMPLETED: 'completed',
  ORDER_RECEIVED: 'received',
  ORDER_NEW: 'new',
  ORDER_PACKED: 'packed',
  ORDER_NOT_APPROVED: 'not_approved',
  ORDER_OUT_FOR_DELIVERY: 'out_for_delivery',
  ORDER_CANCELLED: 'cancelled',
  ORDER_UNPAID: 'unpaid',
  ORDER_PENDING: 'pending',
  ORDER_PAID: 'paid',
};

// Payment types
export const PAYMENT_TYPES = {
  CHECK: 'check',
  INVOICE: 'invoice',
  PREPURCHASE: 'prepurchase',
  CASH: 'cash',
  COD: 'cod',
  STRIPE_CARD: 'stripe_card',
  STRIPE_ACH: 'stripe_ach',
};

export const ITEM_TYPES = {
  VARIANT_BASED: 'variant_based',
  CUSTOM: 'custom',
  PREPURCHASED: 'prepurchased',
  SUBSCRIPTION: 'subscription',
};

export const SUBSCRIPTION_BILLING_TYPES = {
  RECURRING: 'recurring',
  UPFRONT: 'upfront',
};

// Shipping
export const SHIPPING_TYPES = {
  SHIPPING_ALL: 'all',
  SHIPPING_DELIVERY: 'delivery',
  SHIPPING_PICKUP: 'pickup',
};
export const CUSTOM_LOCATION_IDS = {
  LOCATION_ALL: 'all',
};

export const DAY_SORTER = {
  // "sunday": 0, // << if sunday is first day of week
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const VIEW_VARIANTS = {
  BOX: true,
  INLINE: false,
  INLINE_NO_IMAGE: 'noImage',
};

export const ADDITIONAL_MENU_ITEMS = {
  UNIQUE_ITEMS: 'uniqueItems',
};

export const SIDE_MENU_ITEM_TYPES = {
  PRODUCTS: 'products',
  UNIQUE_ITEMS: 'uniqueItems',
  NONE: 'none',
};

export const PRODUCERS_AUTO_APPROVE_TYPES = {
  RETAIL: 'autoApproveRetailUser',
  WHOLESALE: 'autoApproveWholesaleUser',
};

export const PERSONAL_PRODUCER_DATE_FORMAT = 'dddd, MMM DD';
