export const DRAFT_CHANGE = 'DRAFT_CHANGE';
export const DRAFT_ADD_VARIANT = 'DRAFT_ADD_VARIANT';
export const DRAFT_ADD_VARIANT_LOADING = 'DRAFT_ADD_VARIANT_LOADING';
export const DRAFT_LOADING = 'DRAFT_LOADING';
export const DRAFT_ERROR = 'DRAFT_ERROR';
export const DRAFT_DISMISS_ERROR = 'DRAFT_DISMISS_ERROR';
export const DRAFT_DISMISS_LAST_ADDED = 'DRAFT_DISMISS_LAST_ADDED';
export const DRAFT_COMPLETE = 'DRAFT_COMPLETE';
export const DRAFT_COMPLETE_LOADING = 'DRAFT_COMPLETE_LOADING';
export const DRAFT_COMPLETE_ERROR = 'DRAFT_COMPLETE_ERROR';
export const DRAFT_SHIPPING_LOADING = 'DRAFT_SHIPPING_LOADING';
export const DRAFT_SHIPPING_OUTDATED_NOTIFY = 'DRAFT_SHIPPING_OUTDATED_NOTIFY';
export const DRAFT_SHIPPING_OUTDATED_DISMISS = 'DRAFT_SHIPPING_OUTDATED_DISMISS';
export const DRAFT_OPEN = 'DRAFT_OPEN';
export const DRAFT_CLOSE = 'DRAFT_CLOSE';
export const DRAFT_TOGGLE = 'DRAFT_TOGGLE';
export const DRAFT_OPEN_SHIPPING_DIALOG = 'DRAFT_OPEN_SHIPPING_DIALOG';
export const DRAFT_CLOSE_SHIPPING_DIALOG = 'DRAFT_CLOSE_SHIPPING_DIALOG';
export const DRAFT_DRAGGABLE_OPEN = 'DRAFT_DRAGGABLE_OPEN';
export const DRAFT_DRAGGABLE_CLOSE = 'DRAFT_DRAGGABLE_CLOSE';
export const ERROR_SUBSCRIPTION_MODAL_OPEN = 'ERROR_SUBSCRIPTION_MODAL_OPEN';
export const ERROR_SUBSCRIPTION_MODAL_CLOSE = 'ERROR_SUBSCRIPTION_MODAL_CLOSE';

export const SET_SHIPPING_DATE_CLIENT_SIDE = 'SET_SHIPPING_DATE_CLIENT_SIDE';

export const draftConstants = {
  DRAFT_CHANGE: 'DRAFT_CHANGE',
  DRAFT_CLEAR: 'DRAFT_CLEAR',
  DRAFT_ADD_VARIANT: 'DRAFT_ADD_VARIANT',
  DRAFT_ADD_VARIANT_LOADING: 'DRAFT_ADD_VARIANT_LOADING',
  DRAFT_LOADING: 'DRAFT_LOADING',
  DRAFT_ERROR: 'DRAFT_ERROR',
  DRAFT_DISMISS_ERROR: 'DRAFT_DISMISS_ERROR',
  DRAFT_DISMISS_LAST_ADDED: 'DRAFT_DISMISS_LAST_ADDED',
  DRAFT_COMPLETE: 'DRAFT_COMPLETE',
  DRAFT_COMPLETE_LOADING: 'DRAFT_COMPLETE_LOADING',
  DRAFT_COMPLETE_ERROR: 'DRAFT_COMPLETE_ERROR',
  DRAFT_SHIPPING_LOADING: 'DRAFT_SHIPPING_LOADING',
  DRAFT_SHIPPING_ERROR: 'DRAFT_SHIPPING_ERROR',
  DRAFT_SHIPPING_OUTDATED_NOTIFY: 'DRAFT_SHIPPING_OUTDATED_NOTIFY',
  DRAFT_SHIPPING_OUTDATED_DISMISS: 'DRAFT_SHIPPING_OUTDATED_DISMISS',
  DRAFT_OPEN: 'DRAFT_OPEN',
  DRAFT_CLOSE: 'DRAFT_CLOSE',
  DRAFT_TOGGLE: 'DRAFT_TOGGLE',
  DRAFT_OPEN_SHIPPING_DIALOG: 'DRAFT_OPEN_SHIPPING_DIALOG',
  DRAFT_CLOSE_SHIPPING_DIALOG: 'DRAFT_CLOSE_SHIPPING_DIALOG',
  DRAFT_OPEN_PRODUCER_DIALOG: 'DRAFT_OPEN_PRODUCER_DIALOG',
  DRAFT_CLOSE_PRODUCER_DIALOG: 'DRAFT_CLOSE_PRODUCER_DIALOG',
  DRAFT_OPEN_UNAVAILABLE_ITEMS_DIALOG: 'DRAFT_OPEN_UNAVAILABLE_ITEMS_DIALOG',
  DRAFT_CLOSE_UNAVAILABLE_ITEMS_DIALOG: 'DRAFT_CLOSE_UNAVAILABLE_ITEMS_DIALOG',

  DRAFT_OPEN_CHANGE_LOCATION_PROBLEM_DIALOG: 'DRAFT_OPEN_CHANGE_LOCATION_PROBLEM_DIALOG',
  DRAFT_CLOSE_CHANGE_LOCATION_PROBLEM_DIALOG: 'DRAFT_CLOSE_CHANGE_LOCATION_PROBLEM_DIALOG',

  DRAFT_OPEN_CLEAR_SHIPPING_DIALOG: 'DRAFT_OPEN_CLEAR_SHIPPING_DIALOG',
  DRAFT_CLOSE_CLEAR_SHIPPING_DIALOG: 'DRAFT_CLOSE_CLEAR_SHIPPING_DIALOG',

  DRAFT_CLEAR_REMOVE_LINE_ITEMS: 'DRAFT_CLEAR_REMOVE_LINE_ITEMS',
  DRAFT_COUNTER: 'DRAFT_COUNTER',

  DRAFT_SET_SHIPPING_TYPE_CLIENT_SIDE: 'DRAFT_SET_SHIPPING_TYPE_CLIENT_SIDE',
  DRAFT_CLEAR_SHIPPING_TYPE_CLIENT_SIDE: 'DRAFT_CLEAR_SHIPPING_TYPE_CLIENT_SIDE',

  DRAFT_DRAGGABLE_OPEN: 'DRAFT_DRAGGABLE_OPEN',
  DRAFT_DRAGGABLE_CLOSE: 'DRAFT_DRAGGABLE_CLOSE',

  ERROR_SUBSCRIPTION_MODAL_OPEN: 'ERROR_SUBSCRIPTION_MODAL_OPEN',
  ERROR_SUBSCRIPTION_MODAL_CLOSE: 'ERROR_SUBSCRIPTION_MODAL_CLOSE',
  SET_SHIPPING_DATE_CLIENT_SIDE: 'SET_SHIPPING_DATE_CLIENT_SIDE',

  SET_IS_PERSONAL_PRODUCER_SELECT: 'SET_IS_PERSONAL_PRODUCER_SELECT',
  SET_PERSONAL_PRODUCER_DRAFT_SHIPPING: 'SET_PERSONAL_PRODUCER_DRAFT_SHIPPING',

  DRAFT_ON_ADD_VARIANT_QUANTITY: 'DRAFT_ON_ADD_VARIANT_QUANTITY',
};
