export const productConstants = {

	// Types
	GET_PRODUCTS_TYPES_LOADING: 'PRODUCT:GET_PRODUCTS_TYPES_LOADING',
	CLEAR_PRODUCTS_TYPES_ERROR: 'PRODUCT:CLEAR_PRODUCTS_TYPES_ERROR',
	GET_PRODUCTS_TYPES_ERROR: 'PRODUCT:GET_PRODUCTS_TYPES_ERROR',
	SET_PRODUCTS_TYPES: 'PRODUCT:SET_PRODUCTS_TYPES',

	// Products
	SEARCH_PRODUCTS_LOADING: 'PRODUCT:SEARCH_PRODUCTS_LOADING',
	SEARCH_PRODUCTS_ERROR: 'PRODUCT:SEARCH_PRODUCTS_ERROR',
	CLEAR_SEARCH_PRODUCTS_ERROR: 'PRODUCT:CLEAR_SEARCH_PRODUCTS_ERROR',
	SET_PRODUCTS: 'PRODUCT:SET_PRODUCTS',

	// Products grouped by subtype
	SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_LOADING: 'PRODUCT:SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_LOADING',
	SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_ERROR: 'PRODUCT:SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_ERROR',
	CLEAR_SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_ERROR: 'PRODUCT:CLEAR_SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_ERROR',
	SET_PRODUCTS_GROUPED_BY_SUBTYPE: 'PRODUCT:SET_PRODUCTS_GROUPED_BY_SUBTYPE',
	
	// Subtypes grouped by type
	GET_SUBTYPES_BY_TYPE_LOADING: 'PRODUCT:GET_SUBTYPES_BY_TYPE_LOADING',
	SET_SUBTYPES_BY_TYPE: 'PRODUCT:SET_SUBTYPES_BY_TYPE',
	GET_SUBTYPES_BY_TYPE_ERROR: 'PRODUCT:GET_SUBTYPES_BY_TYPE_ERROR',
	CLEAR_SUBTYPES_BY_TYPE_ERROR: 'PRODUCT:CLEAR_SUBTYPES_BY_TYPE_ERROR',
	
};



