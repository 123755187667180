import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholder,
  addPriceLevelHeader,
} from  '../request';
import API_END_POINTS from  '../endpoints';

/**
 * Add additional customer account
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddAccount = function (data, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CUSTOMER_ADD_ACCOUNT);
  data = data || {};
  config.data = data;
  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};

/**
 * Patch additional customer account
 * @param {string} id - account id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchAccount = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_PATCH_ACCOUNT, 'ac', id);
  let config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  data = data || {};
  config.data = data;
  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};

/**
 * Patch customer
 * @param {string} accountId - customer id
 * @param {token} token - access token
 * @param {object} data - request data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchAccountWithAccessToken = function (accountId, token, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(
    process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_PATCH_ACCOUNT, 'ac', accountId
  );

  const config = addPriceLevelHeader({
    method: REQUEST_TYPES.PATCH,
    url: url,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: data,
  });

  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};

/**
 * Delete additional customer account
 * @param {string} id - account id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerDeleteAccount = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_DELETE_ACCOUNT, 'ac', id);
  let config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};


