import { producerConstants } from '../constants';
import {
  getObjectFromStorage,
  saveObjectToStorage,
  saveSelectedProducersToLocalStorage,
  //getSelectedProducersFromLocalStorage
} from '../../helpers';
import { apiGetMarkets, apiGetProducers } from '../../api';
import { isAvailabilities } from '../../scenes/Partials/Checkout/helpers';

const PRODUCERS_FILTER = 'producers.filter';

export function getProducers() {
  return (dispatch) => {
    dispatch({ type: producerConstants.PRODUCERS_LOADING });

    return apiGetProducers(
      (response) => {
        if (response.data.length === 0) throw new Error("Looks like this customer doesn't approved for any producer");

        dispatch({
          type: producerConstants.GET_PRODUCERS,
          payload: {
            producers: response.data || [],
          },
        });
      },
      () => {
        dispatch({
          type: producerConstants.PRODUCERS_ERROR,
          payload: {
            error: 'Something went wrong while requesting producers',
          },
        });
      }
    );
  };
}

/**
 * Change selected producers
 * @param {string[]} ids
 * @param {function} onSuccess
 * @return {function(*, *)}
 */
export function changeProducers(ids, onSuccess) {
  return (dispatch, getState) => {
    if (!Array.isArray(ids)) throw new Error('changeProducers -> ids parameter should be an array!');

    dispatch({ type: producerConstants.PRODUCERS_LOADING });

    const producers = getState().producer.producers,
      selectedProducers = producers.filter((p) => ids.includes(p.id));
    // Save producers to local storage
    saveSelectedProducersToLocalStorage(selectedProducers);

    // Change producers on redux level
    dispatch({
      type: producerConstants.CHANGE_PRODUCERS,
      payload: {
        selectedProducers,
      },
    });

    onSuccess && onSuccess();
  };
}

/**
 * Initialize markets
 * @param {boolean} withoutDraftObject
 * @return {function(*, *): Promise<T | never>}
 */
export function initMarkets(withoutDraftObject = false) {
  return (dispatch, getState) => {
    dispatch({ type: producerConstants.MARKETS_LOADING });
    let draftObj = getState().draft.object;

    // Ignore shipping options if itemWithOwn shipping
    const hasOwnShipping = isAvailabilities(draftObj);
    if (hasOwnShipping || withoutDraftObject) {
      draftObj = { shipping: null };
    }

    // Getting markets
    return apiGetMarkets(draftObj)
      .then((response) => {
        const markets = response.data.filter((option) => !!option?.location?.weekDays || !option.location);

        /**
         * We should be sure that selected producers are still exists in markets
         * In case they not valid we should remove them from storage
         */
        /*let prevSelectedProducers = getSelectedProducersFromLocalStorage() || [],
            selectedProducers = [];

        if (prevSelectedProducers.length) {
          // Reconstructing producers list for select
          selectedProducers = prevSelectedProducers.filter(prevProducer => (
            markets.some(market => market.producer.id === prevProducer.id)
          ));

          saveSelectedProducersToLocalStorage(selectedProducers);
        }*/

        dispatch({
          type: producerConstants.MARKETS_SET,
          payload: {
            markets,
            //selectedProducers,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: producerConstants.MARKETS_ERROR,
          payload: { error: 'Something went wrong while getting markets' },
        });
      });
  };
}

export function initProducersFilter() {
  return (dispatch) => {
    let producers = getObjectFromStorage(PRODUCERS_FILTER);
    producers = producers || [];
    saveObjectToStorage(PRODUCERS_FILTER, producers);
    dispatch({ type: producerConstants.SET_FILTER_BY_PRODUCERS, payload: { producers } });
  };
}

export function filterByProducer(producer, replace = false) {
  return (dispatch) => {
    let producers = getObjectFromStorage(PRODUCERS_FILTER);
    if (!replace) {
      producers = producers || [];
      producer && !producers.includes(producer.id) && producers.push(producer.id);
    } else {
      producers = [producer.id];
    }
    saveObjectToStorage(PRODUCERS_FILTER, producers);
    dispatch({ type: producerConstants.SET_FILTER_BY_PRODUCERS, payload: { producers } });
  };
}

export function filterByProducers(producers) {
  return (dispatch) => {
    saveObjectToStorage(PRODUCERS_FILTER, producers);
    dispatch({ type: producerConstants.SET_FILTER_BY_PRODUCERS, payload: { producers } });
  };
}

export function clearProducersFilter() {
  return (dispatch) => {
    localStorage.removeItem(PRODUCERS_FILTER);
    dispatch({ type: producerConstants.SET_FILTER_BY_PRODUCERS, payload: { producers: [] } });
  };
}
