class MinimalSentryInfo {
  constructor(props) {
    this.exception =
      (!!props?.error && new Error(props.error)) || (!!props?.exception && new Error(props.exception)) || '';
    this.errorResponse = props?.error?.response || null;
    this.customer = {
      id: props?.customer?.id || '',
      email: props?.customer?.email || '',
      type: props?.customer?.type || '',
    };
    this.draft = {
      id: props?.draft?.id || '',
      shipping: props?.draft?.shipping || '',
      customer: props?.draft?.customer || '',
    };
    this.location = props.location || null;
  }

  getData = () => {
    const errorResponse = {
      data: this.errorResponse?.data,
      config: {
        url: this.errorResponse?.config?.url,
        data: this.errorResponse?.config?.data,
        params: this.errorResponse?.config?.params,
        method: this.errorResponse?.config?.method,
      },
    };

    const location = {
      hash: this.location?.hash,
      pathname: this.location?.pathname,
      screenX: this.location?.screenX,
      screenY: this.location?.screenY,
      search: this.location?.search,
      target: this.location?.target?.innerHTML,
      type: this.location?.type,
    };

    return {
      exception: this.exception,
      errorResponse: JSON.stringify(errorResponse),
      customer: this.customer,
      draft: {
        id: this.draft?.id,
        shipping: JSON.stringify(this.draft?.shipping),
        customer: JSON.stringify(this.draft?.customer),
      },
      location: JSON.stringify(location),
    };
  };
}

export default MinimalSentryInfo;
