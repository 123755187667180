class ProductsBySubtypesParams {
  
  constructor(props = {}) {
    this.name = props.query || '';
    this.onlyUnique = props.onlyUnique || '';
    this.subType = props.subType || '';
    this.productType = props.productType || '';
    this.active = props.active || '';
    this.shippingType = props.shippingType || '';
    this.shippingDate = props.shippingDate || '';
    this.shippingLocationId = props.shippingLocationId || '';
    this.producerIds = props.producerIds || '';
    this.productGroupLimit = props.productGroupLimit || 20;
    this.subTypeLimit = props.subTypeLimit || 6;
    this.subTypeSkip = props.subTypeSkip || 0;
  }
  
  toData = () => ({
    ...(this.name ? {name: this.name} : {}),
    ...(this.subType ? {subType: this.subType} : {}),
    ...(this.productType ? {productType: this.productType} : {}),
    ...(this.productGroupLimit ? {productGroupLimit: this.productGroupLimit} : {}),
    ...(this.subTypeLimit ? {subTypeLimit: this.subTypeLimit} : {}),
    ...(this.subTypeSkip ? {subTypeSkip: this.subTypeSkip} : {}),
    ...(this.active ? {active: this.active} : {}),
    ...(this.onlyUnique ? {onlyUnique: this.onlyUnique} : {}),
    ...(this.shippingType ? {type: this.shippingType} : {}),
    ...(this.shippingDate ? {date: this.shippingDate} : {}),
    ...(this.shippingLocationId ? {locationId: this.shippingLocationId} : {}),
    ...(this.producerIds ? {producerIds: this.producerIds} : {}),
  })
  
}

export default ProductsBySubtypesParams
