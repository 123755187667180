import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  CUSTOMER_TYPES,
  PAYMENT_METHODS,
  BILLING_ADDRESS,
  BILLING_ADDRESS_TYPE,
  DELIVERY_ADDRESS,
  isCreditAllowed,
  getCreditDescription,
} from '../../../../helpers';
import { ErrorBox, Spinner, BlackButton, DialogActionsWrapper, AddDiscountDialog } from '../../../../components';
import { CheckoutPaymentMethodsRadioGroupV2 } from '../../../Partials';
import styles from './styles';
import { getDefaultAddress } from '../helpers';
import { Hidden, Button } from '@material-ui/core';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  draft: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  producers: PropTypes.array,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onDone: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onVerifyBankAccount: PropTypes.func.isRequired,
  onAddCreditCard: PropTypes.func.isRequired,
  onFulfillmentInfoClick: PropTypes.func.isRequired,
  onShowServiceChargeInfo: PropTypes.func.isRequired,
  selectedMethodId: PropTypes.string,
  selectedMethodType: PropTypes.string,
};
const defaultProps = {};

class PaymentMethodStage extends React.Component {
  constructor(props) {
    super(props);

    const { draft, customer, selectedMethodId, selectedMethodType } = props;

    this.state = {
      error: '',
      paymentMethod: selectedMethodType || '',
      paymentMethodId: selectedMethodId || '',
      shippingAddressAsBilling: this.props.shippingAddressAsBilling || false,
      billingAddresses: customer.billingAddresses,
      billingAddress: draft.customer.billingAddress
        ? draft.customer.billingAddress
        : getDefaultAddress(customer.billingAddresses, false),
      shippingAddress: draft.customer.shippingAddress
        ? draft.customer.shippingAddress
        : getDefaultAddress(customer.shippingAddresses, false),
      discountDialog: false,
    };

    this.props.stageRef && this.props.stageRef(this);
  }

  findBillingAddress = (shippingAddress, billingAddresses) => {
    let addresses = billingAddresses.filter(
      (address) =>
        address.address1 === shippingAddress.address1 &&
        address.city === shippingAddress.city &&
        address.zip === shippingAddress.zip
    );

    return addresses.length ? addresses[0] : false;
  };

  selectBillingAddress = () => {
    const { customer, onAddAddress, onSetDraftAddress } = this.props;
    const { shippingAddress, billingAddresses } = this.state;

    if (!!shippingAddress && !!billingAddresses) {
      const equalBillingAddressToShipping = this.findBillingAddress(shippingAddress, billingAddresses);

      // Create billing address equals to shipping if it doesn't exist
      let address = { id: customer.id };

      if (equalBillingAddressToShipping) {
        address[BILLING_ADDRESS] = { id: equalBillingAddressToShipping.id };
        address[DELIVERY_ADDRESS] = { id: shippingAddress.id };

        onSetDraftAddress(
          address,
          () => {
            this.setState({ billingAddress: equalBillingAddressToShipping });
          },
          (error) => {
            console.log('onSetDraftAddress.error', error);
          }
        );
      } else {
        onAddAddress(BILLING_ADDRESS_TYPE, shippingAddress, (response) => {
          const foundBillingAddress = this.findBillingAddress(shippingAddress, response.billingAddresses);

          address[BILLING_ADDRESS] = { id: foundBillingAddress.id };
          address[DELIVERY_ADDRESS] = { id: shippingAddress.id };

          onSetDraftAddress(
            address,
            () => this.setState({ billingAddress: foundBillingAddress }),
            (error) => console.log('onSetDraftAddress.error', error)
          );
        });
      }
    }
  };

  componentDidMount() {
    if (this.state.shippingAddressAsBilling) {
      this.selectBillingAddress();
    }
  }

  complete = () => {
    const { onDone, onComplete, intl } = this.props;

    let data = {
      paymentMethod: this.state.paymentMethod,
      paymentMethodId: this.state.paymentMethodId,
    };

    if (!this.state.paymentMethod) {
      this.setState({
        error: intl.formatMessage({ id: 'messages.selectPaymentMethod' }),
        loading: false,
      });
    } else {
      this.setState({
        error: '',
      });

      const onSuccess = () => {
        onDone();
      };

      const onError = (error) => {
        if (error.code === 9002 || error.code === 9003) {
          this.setState({ error: error.message, loading: false });
        } else if (error.code === 7219) {
          this.setState({
            error: this.props.intl.formatMessage({ id: 'error.CSASubscriptionAlreadyExists' }),
            loading: false,
          });
        } else if (error.code === 4019) {
          this.setState({ error: error.message, loading: false });
        } else if (error.code === 4037) {
          this.setState({ loading: false });
          this.props.onError();
        } else {
          this.setState({ error: error.message, loading: false });
        }
      };

      onComplete(data, onSuccess, onError);
    }
  };

  // getOrderSummaryProps = () => {
  //   const {
  //     props: { customer, draft },
  //     state: { paymentMethodId },
  //   } = this;
  //
  //   const paymentMethod = customerGetPaymentMethodById(customer, paymentMethodId);
  //
  //   let serviceChargeFee = '',
  //     grandTotal = '',
  //     amountDue = '';
  //   /**
  //    * We should calculate service charge & amount total & amount due
  //    * in case customer selected credit card
  //    */
  //   if (paymentMethod && isCreditCard(paymentMethod)) {
  //     serviceChargeFee = toCoins(getPercentage(process.env.REACT_APP_SERVICE_CHARGE, toCurrency(draft.total)));
  //     grandTotal = draft.total + serviceChargeFee;
  //     amountDue = draft.total + serviceChargeFee;
  //   }
  //
  //   return {
  //     serviceChargeFee,
  //     grandTotal,
  //     amountDue,
  //   };
  // };

  isRetailCreditData = () => {
    const { draft, producers } = this.props;
    const producersIds = draft.producers.map((producer) => producer.id);
    const producersFromDraft = producers.filter((producer) => producersIds.includes(producer.id));
    const creditAllowed = isCreditAllowed(producersFromDraft);
    let creditDescription = '';
    if (creditAllowed) {
      creditDescription = getCreditDescription(producersFromDraft);
    }

    return {
      creditAllowed,
      creditDescription,
    };
  };

  render() {
    const {
      classes,
      loading,
      draft,
      customer,
      onVerifyBankAccount,
      onAddCreditCard,
      onClose,
      onShowServiceChargeInfo,
      selectedMethodId,
      selectedMethodType,
      intl,
    } = this.props;
    const { discountDialog } = this.state;

    const creditData = this.isRetailCreditData();

    return (
      <React.Fragment>
        <div className={classes.paymentMethodLeftSideWrapper}>
          {this.state.error && <ErrorBox error={this.state.error} className={classes.errorBox} />}
          {loading && <Spinner size={40} />}

          <div className={classes.blockWrapper}>
            <CheckoutPaymentMethodsRadioGroupV2
              order={draft}
              onPlaidOpen={onClose}
              onShowServiceChargeInfo={onShowServiceChargeInfo}
              onVerifyBankAccount={onVerifyBankAccount}
              onAddCreditCard={onAddCreditCard}
              allowMethods={[
                ...(customer.type === CUSTOMER_TYPES.RETAIL && creditData.creditAllowed ? [PAYMENT_METHODS.cod] : []),
                ...(customer.type !== CUSTOMER_TYPES.RETAIL ? [PAYMENT_METHODS.check, PAYMENT_METHODS.cod] : []),
                PAYMENT_METHODS.creditCard,
                PAYMENT_METHODS.bankAccount,
              ]}
              creditDescriptionsByMethod={{
                [PAYMENT_METHODS.cod]: !!creditData.creditAllowed ? creditData.creditDescription : '',
              }}
              onChange={(method, methodId) => {
                this.props.onChange && this.props.onChange(method, methodId);

                this.setState({
                  paymentMethod: method,
                  paymentMethodId: methodId,
                });
              }}
              customer={customer}
              selectedMethodId={selectedMethodId}
              selectedMethodType={selectedMethodType}
            />
          </div>

          {/*{!shippingAddressAsBilling ? (*/}
          {/*  <div className={classes.blockWrapper}>*/}
          {/*    <CheckoutAddress*/}
          {/*      emptyButtonLabel={intl.formatMessage({ id: 'address.addBilling' })}*/}
          {/*      wrapperClass={classes.addressWrapper}*/}
          {/*      labelClass={classes.labelClass}*/}
          {/*      addressLabel={getAddressLabel(this.state[BILLING_ADDRESS])}*/}
          {/*      onChange={() => onChangeAddress(BILLING_ADDRESS_TYPE)}*/}
          {/*      label={intl.formatMessage({ id: 'address.billing' })}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  <div className={classes.blockWrapper}>*/}
          {/*    <div className={classes.addressWrapper}>*/}
          {/*      <BlackLabel className={classes.labelClass} style={{ color: '#707070', fontSize: 12 }}>*/}
          {/*        {intl.formatMessage({ id: 'address.billing' })}*/}
          {/*      </BlackLabel>*/}
          {/*      <FormControlLabel*/}
          {/*        control={*/}
          {/*          <BlackCheckbox*/}
          {/*            checked={shippingAddressAsBilling}*/}
          {/*            onChange={(event) => {*/}
          {/*              this.setState({ shippingAddressAsBilling: event.target.checked });*/}
          {/*              changeShippingAddressAsBilling(event);*/}
          {/*            }}*/}
          {/*          />*/}
          {/*        }*/}
          {/*        label={<FormattedMessage id="address.billingSameAsShipping" />}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*<div className={classes.blockWrapper}>*/}
          {/*  <p className={classes.agreement}>*/}
          {/*    <FormattedMessage id="messages.agree" />*/}
          {/*    &nbsp;*/}
          {/*    <FormattedMessage id="global.who`s" />*/}
          {/*    <br />*/}
          {/*    <a className={classes.link} target={'_blank'} href="/terms/terms-of-service">*/}
          {/*      <FormattedMessage id="global.termsOfService" />*/}
          {/*    </a>*/}
          {/*    &nbsp;*/}
          {/*    {intl.formatMessage({ id: 'global.and' }).toLowerCase()}&nbsp;*/}
          {/*    <a className={classes.link} target={'_blank'} href="/terms/privacy-policy">*/}
          {/*      <FormattedMessage id="global.privacyPolicy" />*/}
          {/*    </a>*/}
          {/*  </p>*/}
          {/*</div>*/}
        </div>

        <Hidden xsDown>
          <DialogActionsWrapper padding45>
            <div className={classes.controls}>
              <BlackButton disabled={loading} onClick={() => this.complete()} className={classes.control}>
                <FormattedMessage id="global.completeOrder" />
              </BlackButton>

              {draft?.lineItems?.length === 1 && (
                <>
                  <div className={classes.discountWrapper}>
                    <Button
                      className={classes.discountButton}
                      onClick={() => {
                        this.setState({ discountDialog: true });
                      }}>
                      <p className={classes.discountText}>
                        {intl.formatMessage({ id: 'checkout.discount.addPromoCodeGiftCard' })}
                      </p>
                    </Button>
                  </div>
                  {discountDialog && (
                    <AddDiscountDialog
                      open
                      order={draft}
                      onDone={(onSuccess) => {
                        this.props.refreshDraft(onSuccess, true);
                      }}
                      onClose={() => {
                        this.setState({ discountDialog: false });
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </DialogActionsWrapper>
        </Hidden>
      </React.Fragment>
    );
  }
}

PaymentMethodStage.propTypes = propTypes;
PaymentMethodStage.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(PaymentMethodStage));
