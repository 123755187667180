import React from 'react';
import PropTypes from 'prop-types';
import { NewHeaderContainer } from '../../containers';
import { ErrorBox, Icon } from '../../components';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import SubscriptionSkeleton from './components/SubscriptionSkeleton';
import ProducerSubscriptionProductsList from './components/ProducerSubscriptionProductsList';
import SubscriptionOrderList from './components/SubscriptionOrderList';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import { AccountSubscriptionsBreadcrumbs } from '../AccountScene/AccountBreadcrumbs';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { injectIntl } from 'react-intl';
import { ORDERS_TYPES } from './constants';

const propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  order: PropTypes.object,
};

const SubscriptionView = (props) => {
  const { classes, intl, history, width, error, loading, order } = props;

  const drawHeaderMobile = () => {
    return (
      <Grid container spacing={0}>
        <Hidden only={['md', 'sm', 'xs']}>
          <Grid item lg={3}>
            {null}
          </Grid>
        </Hidden>
        <Grid item lg={6} md={12} xs={12} sm={12}>
          <div className={classes.pageHeaderWrapper}>
            <div className={classes.backButtonWrapper}>
              <IconButton
                onClick={() => {
                  history.push(`/account`, { openTab: 2 });
                }}>
                <Icon icon={ArrowBackIosIcon} className={classes.linkIcon} />
              </IconButton>
            </div>
            <Typography variant={'h1'} component="h1" className={classes.pageHeader}>
              {order.name}
            </Typography>
            <div>&nbsp;</div>
          </div>
        </Grid>
        <Hidden only={['md', 'sm', 'xs']}>
          <Grid item lg={3}>
            {null}
          </Grid>
        </Hidden>
      </Grid>
    );
  };

  const drawHeaderDesktop = () => {
    return (
      <Grid container spacing={10}>
        <Hidden only={['md', 'sm', 'xs']}>
          <Grid item lg={2}>
            {null}
          </Grid>
        </Hidden>
        <Grid item lg={8} md={12} xs={12} sm={12}>
          <div className={classes.pageHeaderWrapperDesktop}>
            <AccountSubscriptionsBreadcrumbs
              subscription={order}
              onOpenSubscription={() => history.push(`/account`, { openTab: 2 })}
            />
            {/*<div className={classes.backButtonWrapper}>*/}
            {/*  <IconButton*/}
            {/*    onClick={() => {*/}
            {/*      history.push(`/account`, { openTab: 2 });*/}
            {/*    }}>*/}
            {/*    <Icon icon={ArrowBackIosIcon} className={classes.linkIcon} />*/}
            {/*  </IconButton>*/}
            {/*</div>*/}
            {/*<Typography variant={'h1'} component="h1" className={classes.pageHeader}>*/}
            {/*  {order.name}*/}
            {/*</Typography>*/}
          </div>
        </Grid>
        <Hidden only={['md', 'sm', 'xs']}>
          <Grid item lg={2}>
            {null}
          </Grid>
        </Hidden>
      </Grid>
    );
  };

  const getOrdersByType = (type) => {
    const orders = order.orders.sort(
      (prev, next) => moment(next.shippingDate).valueOf() - moment(prev.shippingDate).valueOf()
    );
    const currentData = moment().valueOf();

    if (type === ORDERS_TYPES.NEXT) {
      return orders.filter((order) => {
        return moment(order.shippingDate).valueOf() >= currentData;
      });
    }
    if (type === ORDERS_TYPES.PREV) {
      return orders.filter((order) => moment(order.shippingDate).valueOf() < currentData);
    }

    return [];
  };

  return (
    <div className={classes.root}>
      {/* global header */}
      <div className={classes.headerRoot}>
        <NewHeaderContainer />
      </div>

      {error && (
        <div className={classes.errorWrapper}>
          <ErrorBox error={error} />
        </div>
      )}

      <div className={classes.contentRoot}>
        {/* header */}

        {isWidthDown('xs', width) && drawHeaderMobile()}
        {/*content*/}
        <div className={classes.container}>
          {isWidthUp('sm', width) && drawHeaderDesktop()}

          <Grid container spacing={10}>
            <Hidden only={['md', 'sm', 'xs']}>
              <Grid item lg={2}>
                {null}
              </Grid>
            </Hidden>
            <Grid item lg={8} md={12} xs={12} sm={12}>
              {loading && <SubscriptionSkeleton size={50} />}

              {/* content */}

              {!loading && !error && (
                <>
                  <ProducerSubscriptionProductsList subscription={order} />

                  {!!getOrdersByType(ORDERS_TYPES.NEXT).length && (
                    <>
                      <div className={classes.pageTitle} style={{ marginTop: 0 }}>
                        {intl.formatMessage({
                          id: 'page.productSubscription.nextOrder',
                        })}
                      </div>

                      {<SubscriptionOrderList orders={getOrdersByType(ORDERS_TYPES.NEXT)} />}
                    </>
                  )}

                  {!!getOrdersByType(ORDERS_TYPES.PREV).length && (
                    <>
                      <div className={classes.pageTitle}>
                        {intl.formatMessage({
                          id: 'page.productSubscription.previousOrders',
                        })}
                      </div>
                      <Hidden only={['lg', 'xl']}>
                        <div className={classes.sectionHeader}>
                          {intl.formatMessage({ id: 'page.productSubscription.orders' })}
                        </div>
                      </Hidden>

                      {<SubscriptionOrderList orders={getOrdersByType(ORDERS_TYPES.PREV)} />}
                    </>
                  )}
                </>
              )}
            </Grid>
            <Hidden only={['md', 'sm', 'xs']}>
              <Grid item lg={2}>
                {null}
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </div>
    </div>
  );
};

SubscriptionView.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(withWidth()(injectIntl(SubscriptionView)));
