import { getToken } from '../helpers';
import { getStore } from '../store/configure';

export const REQUEST_TYPES = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DEL: 'DELETE',
};

/**
 * Replaces url placeholder with parameter
 * @param {string} url - url
 * @param {string} param - parameter
 * @param {string|number} val
 * @return {string} - url
 */
export const replaceUrlPlaceholder = (url, param, val) => {
  return url.replace('{' + param + '}', val);
};

/**
 * Replaces url placeholders with parameters
 * @param {string} url - url
 * @param {array} data - {param : val, param : val}
 * @return {string} - url
 */
export const replaceUrlPlaceholders = (url, data) => {
  Object.keys(data).forEach((key) => {
    url = replaceUrlPlaceholder(url, key, data[key]);
  });
  return url;
};

/**
 * Generates request config
 * @param {string} method - method type
 * @param {string} endpoint - endpoint name
 * @param {string} token - optional token, by default it will use token from local storage
 */
export const getRequestConfig = (method, endpoint, token = '') => {
  let config = addPriceLevelHeader({
    method: method,
    url: process.env.REACT_APP_API_URL + endpoint,
    headers: {
      Authorization: 'Bearer ' + (token ? token : getToken()),
      'x-group-lineitems': true,
    },
  });

  if (method === REQUEST_TYPES.POST) {
    config.data = {};
  }

  return config;
};

/**
 * Appends price level header to request config
 * @param {object} config - request config
 * @return {*} - request config with price level header
 */
export const addPriceLevelHeader = (config) => {
  const store = getStore();
  const state = store.getState();
  const customer = state.customer.object;
  const authenticated = state.auth.authenticated;

  const level = customer && customer.type && authenticated ? `${customer.type}_preorder` : `retail_preorder`;

  let headers = config.headers || {};
  headers['X-PRICE-LEVEL'] = level;
  config.headers = headers;

  return config;
};

/**
 * Appends anonymous token to request header
 * @param {object} config - request config
 * @return {*} - request config with anonymous token
 */
export const addAnonymousTokenHeader = (config) => {
  let headers = config.headers || {};

  headers['X-ANONYMOUS-TOKEN'] = getStore().getState().auth.token;

  config.headers = headers;

  return config;
};

/**
 * Appends authorization token to request header
 * @param {object} config - request config
 * @return {*} - request config with anonymous token
 */
export const addAuthorizationTokenHeader = (config) => {
  let headers = config.headers || {};

  headers['Authorization'] = `Bearer ${getStore().getState().auth.token}`;

  config.headers = headers;

  return config;
};

/**
 * Generates public request config
 * @param {string} method - method type
 * @param {string} endpoint - endpoint name
 */
export const getPublicRequestConfig = (method, endpoint) => {
  let config = addPriceLevelHeader({
    method: method,
    url: process.env.REACT_APP_EXT_URL + endpoint,
  });

  if (method === REQUEST_TYPES.POST) {
    config.data = {};
  }

  return config;
};
