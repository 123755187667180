import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#707070',
        fontWeight: 400,
        '&$focused': {
          color: '#363636',
          fontWeight: 400,
          '&$focused': {
            color: '#363636',
            fontWeight: 400,
          },
        },
      },
    },
    MuiInput: {
      root: {
        color: `#363636`,
      },
      underline: {
        '&:after': {
          borderBottom: `1px solid #707070`,
        },
        '&$focused:after': {
          borderBottomColor: `#E0E0E0`,
        },
        '&$error:after': {
          borderBottomColor: `#E0E0E0`,
        },
        '&:before': {
          borderBottom: `1px solid #E0E0E0`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid #707070`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #E0E0E0`,
        },
      },
    },
  },
});

const themeBold = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#363636',
        '&$focused': {
          color: '#363636',
          '&$focused': {
            color: '#363636',
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 'bold',
        color: '#000000',
        fontSize: 14,
      },
      shrink: {
        transform: 'translate(0, 1.5px) scale(1)',
      },
    },
    MuiInput: {
      root: {
        color: `#363636`,
        padding: '8px 0px 15px 0px',
      },
      input: {
        padding: 0,
      },
      underline: {
        '&:after': {
          borderBottom: `1px solid #707070`,
        },
        '&$focused:after': {
          borderBottomColor: `#E0E0E0`,
        },
        '&$error:after': {
          borderBottomColor: `#E0E0E0`,
        },
        '&:before': {
          borderBottom: `1px solid #E0E0E0`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid #707070`,
        },
        '&$disabled:before': {
          borderBottom: `1px dotted #E0E0E0`,
        },
      },
    },
  },
});

class BlackTextField extends React.Component {
  render = () => {
    const { shrink = true } = this.props;

    return (
      <MuiThemeProvider theme={this.props.view === 'styleBold' ? themeBold : theme}>
        <TextField InputLabelProps={{ shrink }} {...this.props}>
          {this.props.children}
        </TextField>
      </MuiThemeProvider>
    );
  };
}

export default BlackTextField;
