const styles = theme => ({
  wrapper: {
    paddingBottom: 37,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 100,
      paddingRight: 100
    }
  },
  formControlRoot: {
    marginBottom: 30
  },
  message: {
    marginBottom: 30,
    lineHeight: '25px',
  },
  controls: {
    marginBottom: 20,
    width: '100%',
    textAlign: 'center'
  },
  control: {
    width: '100%',
    textTransform: 'uppercase',
    fontSize: 12
  }
});

export default styles;