const styles = (theme) => ({
  wrapper: {
    paddingBottom: 37,
  },
  summaryWrapper: {
    marginTop: 5,
    marginBottom: 20,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  nonVerified: {
    color: '',
  },
  blockWrapper: {
    display: 'flex',
    width: '100%',
    marginBottom: 30,
    alignItems: 'flex-start',
    justifyContent: 'left',
  },
  link: {
    color: '#707070',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'color .3s ease-in-out',
  },
  agreement: {
    width: '100%',
    fontSize: '12px',
    textAlign: 'center',
    color: '#707070',
    transition: 'color .5s ease-in-out',
    '&:hover': {
      color: '#363636',
      '&>a': {
        color: '#363636',
      },
    },
  },
  controls: {
    marginBottom: 20,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  control: {
    borderRadius: 40,
    width: '100%',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  addressWrapper: {
    textAlign: 'left',
    minWidth: '50%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  labelClass: {
    color: '#707070',
    fontSize: 14,
    textAlign: 'left',
    marginBottom: 5,
  },
  errorBox: {
    marginLeft: 0,
    marginRight: 0,
    whiteSpace: 'pre-wrap',
  },
  paymentMethodLeftSideWrapper: {
    padding: 0,
  },
  discountWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },
  discountButton: {
    cursor: 'pointer',
    marginBottom: 20,
    height: 45,
    borderRadius: 0,
    textTransform: 'none',
    width: '100%',
    boxSizing: 'border-box',
  },
  discountText: {
    fontFamily: 'Noto Sans HK, sans-serif',
    fontSize: 14,
    fontWeight: 700,
    color: '#111111',
    textDecorationLine: 'underline',
  },
});

export default styles;
