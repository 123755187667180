import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { toCurrency } from '../../../helpers';

const styles = (theme) => ({
  item: {
    width: 500,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: 18,
    paddingBottom: 18,
    borderBottom: '1px solid #eeeeee',
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
  },
  info: {
    flex: 8,
    marginRight: 5,
  },
  total: {
    fontSize: 13,
    fontFamily: 'Noto Sans HK, sans-serif',
    fontWeight: 'bold',
  },
  green: {
    color: 'rgb(65, 205, 140)',
  },
  price: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 13,
    textAlign: 'center',
    flex: 1,
    width: 80,
    height: '100%',
    fontWeight: 'bold',
    fontFamily: 'Noto Sans HK, sans-serif',
  },
  summaryItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const CSATotal = ({ order: { payment, amountDue }, classes, intl }) => {
  return (
    <React.Fragment>
      <div className={classes.summaryItem}>
        <div className={classes.info}>
          <Typography className={classNames(classes.total, classes.green)} component="p">
            {payment.name}
          </Typography>
        </div>

        <div className={classNames(classes.price, classes.green)}>{toCurrency(payment.amount, intl)}</div>
      </div>

      <div className={classes.summaryItem}>
        <div className={classes.info}>
          <Typography className={classes.total} component="p">
            {intl.formatMessage({ id: 'global.amountDue' })}
          </Typography>
        </div>

        <div className={classes.price}>{toCurrency(amountDue, intl)}</div>
      </div>
    </React.Fragment>
  );
};

CSATotal.propTypes = {
  order: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(injectIntl(CSATotal));
