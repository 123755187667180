import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder } from '../request';
import API_END_POINTS from '../endpoints';

export const apiCustomerGetAllProductSubscriptions = function (onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.CUSTOMER_GET_PRODUCTS_SUBSCRIPTIONS);
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const apiCustomerGetProductSubscriptionById = function (subId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_GET_SINGLE_PRODUCT_SUBSCRIPTION, 'id', subId);
  let config = getRequestConfig(REQUEST_TYPES.GET, url);

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
