import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import {withStyles} from '@material-ui/core/styles';
import {
  Spinner,
  ErrorBox,
  BlackRadio
} from '../../../components';
import {
  StripeCreditCardForm,
  StripeBankAccountForm
} from '../../../forms';
import {
  customerGetDefaultPaymentMethod,
  customerGetBankAccounts,
  customerGetCreditCards,
  customerGetPaymentMethodById,
  checkNested,
  isEmpty,
  PAYMENT_METHODS,
  CUSTOMER_TYPES
} from '../../../helpers';
import styles from './styles';
import Button from "@material-ui/core/Button";
import StripePaymentCardsRadioGroupItemLabel
  from "../../../components/StripePaymentCardsRadioGroup/StripePaymentCardsRadioGroupItemLabel";
import StripeBankAccountsRadioGroupItemLabel
  from "../../../components/StripeBankAccountsRadioGroup/StripeBankAccountsRadioGroupItemLabel";


class PaymentMethodsRadioGroup extends React.Component {

  constructor(props) {
    super(props);

    let paymentMethodObject = customerGetDefaultPaymentMethod(props.customer);
    let paymentMethod = paymentMethodObject ? paymentMethodObject.method : "";
    let paymentMethodId = paymentMethodObject ? paymentMethodObject.id : "";

    if (!isEmpty(props.order) && checkNested(props.order, 'payment', 'method')) {
      paymentMethod = props.order.payment.method.method;
      paymentMethodId = props.order.payment.method.id;
    }

    props.onChange && props.onChange(paymentMethod, paymentMethodId);

    this.state = {
      selectedPaymentMethod: paymentMethodId ? paymentMethodId : "",
      paymentMethod: paymentMethod,
      paymentMethodId: paymentMethodId,
      addingBankAccount: false,
      addingCreditCard: false,
      error: "",
      loading: false
    };
  }

  render() {
    const {classes, customer, onChange, allowMethods} = this.props;
    const {loading, error} = this.state;

    return (
      <FormControl className={classes.formControl}>

        {loading && <Spinner/>}
        <ErrorBox error={error}/>

        {
          !this.state.addingBankAccount && !this.state.addingCreditCard &&
          <RadioGroup
            aria-label="payment-method"
            name="payment-method"
            className={classes.select}
            value={this.state.selectedPaymentMethod}
            onChange={(e) => {
              const paymentMethod = customerGetPaymentMethodById(customer, e.target.value);
              this.setState({
                addingCreditCard: false,
                addingBankAccount: false,
                selectedPaymentMethod: paymentMethod ? paymentMethod.id : e.target.value,
                paymentMethodId: paymentMethod ? paymentMethod.id : "",
                paymentMethod: paymentMethod ? paymentMethod.method : e.target.value,
              }, () => {
                onChange && onChange(this.state.paymentMethod, this.state.paymentMethodId)
              });
            }}
          >

            {
              allowMethods.indexOf(PAYMENT_METHODS.cod) !== -1 &&
              customer.type === CUSTOMER_TYPES.RETAIL && (
                <FormControlLabel value={PAYMENT_METHODS.cod}
                                  control={<BlackRadio color="primary"/>}
                                  label={<FormattedMessage id="global.COD"/>}
                />
              )
            }

            {
              allowMethods.indexOf(PAYMENT_METHODS.cod) !== -1 &&
              customer.type === CUSTOMER_TYPES.WHOLESALE &&
              customer.allowCredit && (
                <FormControlLabel value={PAYMENT_METHODS.cod}
                                  control={<BlackRadio color="primary"/>}
                                  label={<FormattedMessage id="global.COD"/>}
                />
              )
            }

            {
              allowMethods.indexOf(PAYMENT_METHODS.check) !== -1 &&
              customer.allowCredit && (
                <FormControlLabel value={PAYMENT_METHODS.check}
                                  control={<Radio color="primary"/>}
                                  label={<FormattedMessage id="global.check"/>}
                />
              )
            }

            {allowMethods.indexOf(PAYMENT_METHODS.creditCard) !== -1 &&
            customerGetCreditCards(customer).map((account) => {
              return <FormControlLabel
              key={account.id}
              classes={{label: classes.label}}
              value={account.id}
              control={<Radio color="primary"/>}
              label={<StripePaymentCardsRadioGroupItemLabel account={account}/>}
              />
            })}

            {allowMethods.indexOf(PAYMENT_METHODS.bankAccount) !== -1 &&
            customerGetBankAccounts(customer)
              .filter((account) => account.status === 'verified')
              .map((account) => {
                return <FormControlLabel
                  key={account.id}
                  classes={{label: classes.label}}
                  value={account.id}
                  control={<Radio color="primary"/>}
                  label={<StripeBankAccountsRadioGroupItemLabel account={account}/>}
                />
              })}
          </RadioGroup>
        }

        {
          this.state.addingCreditCard &&
          <StripeCreditCardForm
            dialog={false}
            onAdded={() => this.setState({addingCreditCard: false})}
            onClose={() => this.setState({addingCreditCard: false})}
          />
        }

        {
          this.state.addingBankAccount &&
          <StripeBankAccountForm
            dialog={false}
            onAdded={() => this.setState({addingBankAccount: false})}
            onClose={() => this.setState({addingBankAccount: false})}
          />
        }

        {
          !this.state.addingBankAccount && !this.state.addingCreditCard &&
          <div className={classes.paymentMethodsControls}>
            <Button onClick={() => this.setState({addingCreditCard: true})} className={classes.addButton} variant={"contained"} color="primary">
              <FormattedMessage id="payment.addCreditCard"/>
            </Button>
            <Button onClick={() => this.setState({addingBankAccount: true})} className={classes.addButton} variant={"contained"} color="primary">
              <FormattedMessage id="payment.addBankAccount"/>
            </Button>
          </div>
        }

      </FormControl>
    );
  }
}

PaymentMethodsRadioGroup.propTypes = {
  order: PropTypes.object,
  allowMethods: PropTypes.array,
  onChange: PropTypes.func,
  customer: PropTypes.object.isRequired,
};

PaymentMethodsRadioGroup.defaultProps = {
  order: {},
  allowMethods: [
    PAYMENT_METHODS.cod,
    PAYMENT_METHODS.check,
    PAYMENT_METHODS.creditCard,
    PAYMENT_METHODS.bankAccount
  ]
};

export default withStyles(styles, {withTheme: true})(
  PaymentMethodsRadioGroup
);
