import React from "react";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';

class SubTypeSeparator extends React.Component {
  render() {
    const {classes} = this.props;
    return <p className={classes.root}>{this.props.name}</p>;
  }
}

SubTypeSeparator.propTypes = {
  name: PropTypes.string.isRequired
};

export default withStyles(
  styles, {withTheme: true}
)(SubTypeSeparator);



