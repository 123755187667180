import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
//import ScrollArea from 'react-scrollbar'
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { ProducersSelect, SubTypesList, ListProductHit, SubTypeSeparator } from '../../Partials';
import { DraftHeaderContainer, OrderHeaderContainer } from '../../../containers';
import { ProductBox, SearchHits, Spinner, SearchHitsWrapper, FullScreenDialog, Icon } from '../../../components';
import { NewProductScene } from '../../../scenes';
import {
  getLineItemByVariant,
  // Collection view types
  COLLECTION_VIEW_TYPE_GRID,
  COLLECTION_VIEW_TYPE_LIST_COMPACT,
  // Collection view type helpers
  isList,
  getCollectionViewType,
  setCollectionViewType,
} from '../../../helpers';
import styles from './styles';
import { apiProductsSearch, apiRecentProductsSearch, apiCollectionsSearch } from '../../../api';

const LIST_COUNT_HITS_PER_PAGE = 60;
const GRID_COUNT_HITS_PER_PAGE = 40;

const propTypes = {
  customer: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  activeHit: PropTypes.object,
  activeVariant: PropTypes.object,
  producersLoading: PropTypes.bool,
  producers: PropTypes.array,
  headerType: PropTypes.string.isRequired,
  selectedProducers: PropTypes.array.isRequired,
  onEditOrderDone: PropTypes.func,
  hitSet: PropTypes.func.isRequired,
  hitUnset: PropTypes.func.isRequired,
  changeProducers: PropTypes.func.isRequired,
  setLineItemQuantity: PropTypes.func.isRequired,
  addVariantToOrder: PropTypes.func.isRequired,
};

class EditOrder extends React.Component {
  constructor(props) {
    super(props);

    this.changingLineItemQuantity = null;
    this.processingSubType = '';

    this.state = {
      loading: false,
      myItemsActive: false,
      recentlyPurchasedActive: false,

      hit: null,
      query: '',
      view: getCollectionViewType(),
      selectedCollection: '',
      selectedSubType: '',

      lastAddedProduct: null,
      mobileSubTypesDrawerOpen: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContent) {
    const { selectedProducers: prevProducers } = this.props;
    const { selectedProducers: nextProducers } = nextProps;

    // TODO: Check if it works
    if (prevProducers.map((p) => p.id).join(',') !== nextProducers.map((p) => p.id).join(',')) {
      this.setState({
        myItemsActive: false,
        recentlyPurchasedActive: false,
        query: '',
        selectedCollection: '',
        selectedSubType: '',
      });
    }
  }

  handleSubTypesMobileDrawerToggle = () => {
    this.setState({
      mobileSubTypesDrawerOpen: !this.state.mobileSubTypesDrawerOpen,
    });
  };

  componentDidUpdate(prevProps) {
    const { location, history } = this.props;

    // Welcome dialog open "Recently purchased items"
    if (location.state && location.state.openRecentlyPurchased) {
      history.replace({
        pathname: '/markets',
        state: {},
      });

      this.resetCriteria({
        recentlyPurchasedActive: true,
        selectedSubType: 0,
      });
    }
  }

  resetCriteria = (criteria) => {
    this.setState(criteria);
  };

  getParams = () => {
    const { query, myItemsActive, selectedSubType, selectedCollection } = this.state;

    const { selectedProducers, order } = this.props;

    return {
      query: query,
      limit: isList() ? LIST_COUNT_HITS_PER_PAGE : GRID_COUNT_HITS_PER_PAGE,
      active: true,
      collection: selectedCollection,
      onlyUnique: myItemsActive,
      subType: selectedSubType,

      ...(order.shipping
        ? {
            ...(order.shipping.date
              ? { shippingDate: moment(order.shipping.date).format('YYYY-MM-DD').toLowerCase() }
              : {}),
            ...(order.shipping.type ? { shippingType: order.shipping.type } : {}),
            ...(order.shipping.location ? { shippingLocationId: order.shipping.location.id } : {}),
          }
        : {}),

      producerIds: selectedProducers.map((p) => p.id),
    };
  };

  onAddToCartClicked = (product, variant, quantity, onSuccess) => {
    if (quantity === 0) {
      this.props.addVariantToOrder(product, variant, () => {
        this.setState({ lastAddedProduct: product });
        onSuccess && onSuccess();
      });
    } else {
      const lineItem = getLineItemByVariant(this.props.order, variant);
      if (this.changingLineItemQuantity && this.changingLineItemQuantity.id !== lineItem.id) {
        setTimeout(() => {
          this.onAddToCartClicked(product, variant, quantity);
          onSuccess && onSuccess();
        }, 100);
      } else {
        this.props.hitSet(product, variant);
        onSuccess && onSuccess();
      }
    }
  };

  onProductClick = (e, hit) => {
    e.stopPropagation();
    this.setState({
      hit: hit,
    });
  };

  onHitQuantityChange = (item, quantity) => {
    const onDone = () => {
      this.changingLineItemQuantity = null;
    };
    this.props.setLineItemQuantity(item, quantity, onDone, onDone);
  };

  getRetriever = () => {
    const { recentlyPurchasedActive, selectedCollection } = this.state;

    // Search collection products
    if (selectedCollection) return (...props) => apiCollectionsSearch(selectedCollection, ...props);
    // Search recently purchased products
    if (recentlyPurchasedActive) return apiRecentProductsSearch;
    // Search regular and unique products
    return apiProductsSearch;
  };

  getHits = (view) => {
    const { order, activeHit, activeVariant, producers, customer } = this.props;

    return (
      <SearchHits
        view={view}
        infiniteScroll
        onLoadingStart={() => this.setState({ loading: true })}
        onLoadingEnd={() => this.setState({ loading: false })}
        preFilterHits={(hits) => {
          return hits.filter((hit) => {
            const active = hit.variants.filter((variant) => {
              return variant.active;
            });
            return hit.active && active.length > 0;
          });
        }}
        searchRetriever={this.getRetriever()}
        searchParameters={this.getParams()}
        showEmptyMessage={false}
        getSeparatorCallback={(hit) => {
          if (this.processingSubType !== hit.subType && view !== COLLECTION_VIEW_TYPE_GRID) {
            this.processingSubType = hit.subType;
            return <SubTypeSeparator key={'separator-' + hit.id} name={hit.subType} />;
          }
          return null;
        }}
        getComponentCallback={(hit) => {
          return view === COLLECTION_VIEW_TYPE_GRID ? (
            <ProductBox
              item={hit}
              withRemoveButton={true}
              customer={customer}
              producers={producers}
              key={hit.id}
              order={order}
              activeHit={activeHit}
              activeVariant={activeVariant}
              onStartQuantityChange={(item) => (this.changingLineItemQuantity = item)}
              onQuantityChange={this.onHitQuantityChange}
              onClick={(e) => this.onProductClick(e, hit)}
              onClickOutside={() => {
                this.props.hitUnset();
              }}
              onAddToCartClicked={this.onAddToCartClicked}
            />
          ) : (
            <ListProductHit
              compact={view === COLLECTION_VIEW_TYPE_LIST_COMPACT}
              hit={hit}
              customer={customer}
              producers={producers}
              key={hit.id}
              order={order}
              activeHit={activeHit}
              activeVariant={activeVariant}
              onStartQuantityChange={(item) => (this.changingLineItemQuantity = item)}
              onQuantityChange={this.onHitQuantityChange}
              onClick={(e) => this.onProductClick(e, hit)}
              onClickOutside={() => {
                this.props.hitUnset();
              }}
              onAddToCartClicked={this.onAddToCartClicked}
            />
          );
        }}
      />
    );
  };

  getSidebarContent = () => {
    const { producersLoading, selectedProducers, changeProducers, producers, order } = this.props;

    return (
      <React.Fragment>
        <ProducersSelect
          showLoader={false}
          loading={producersLoading}
          producers={producers}
          order={order}
          selectedProducers={selectedProducers}
          onSelect={changeProducers}
        />

        {/*{!producersLoading && !this.state.checking && (
          <CollectionsList
            myItemsActive={this.state.myItemsActive}
            recentlyPurchasedActive={this.state.recentlyPurchasedActive}
            customer={customer}
            selectedProducers={selectedProducers}
            selectedCollection={this.state.selectedCollection}
            onClickCollection={(collection) => this.resetCriteria({
              myItemsActive: false,
              recentlyPurchasedActive: false,
              query: "",
              selectedSubType: 0,
              selectedCollection: collection,
            })}
            onMyItemsClick={() => this.resetCriteria({
              myItemsActive: true,
              recentlyPurchasedActive: false,
              query: "",
              selectedSubType: 0,
              selectedCollection: "",
            })}
            onRecentlyPurchasedClick={() => this.resetCriteria({
              myItemsActive: false,
              recentlyPurchasedActive: true,
              query: "",
              selectedSubType: 0,
              selectedCollection: "",
            })}
          />
        )}*/}

        <SubTypesList
          order={order}
          selectedProducers={selectedProducers}
          checked={this.state.selectedSubType}
          onChangeSelection={(selected) => {
            window.scrollTo(0, 0);
            this.resetCriteria({
              query: '',
              selectedCollection: '',
              myItemsActive: false,
              recentlyPurchasedActive: false,
              selectedSubType: selected,
            });
          }}
        />
      </React.Fragment>
    );
  };

  render() {
    const { classes, producersLoading, history, intl, headerType } = this.props;

    const { loading } = this.state;

    const headerTypes = {
      draft: DraftHeaderContainer,
      order: OrderHeaderContainer,
    };
    const HeaderTypeComponent = headerTypes[headerType];

    return (
      <div className={classes.root}>
        <HeaderTypeComponent
          onEditOrderDone={this.props.onEditOrderDone}
          leftContent={() => {
            return (
              <React.Fragment>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.handleSubTypesMobileDrawerToggle}
                  className={classNames(classes.hideUpMd)}>
                  <MenuIcon className={classes.iconColor} />
                </IconButton>

                <IconButton
                  color="inherit"
                  onClick={() => history.push('/markets')}
                  className={classNames(classes.hideDownMd)}>
                  <MenuIcon className={classes.iconColor} />
                </IconButton>
              </React.Fragment>
            );
          }}
          centerContent={() => {
            return (
              <div className={classes.searchContainer}>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item className={classes.mdDownHide}>
                    <SearchIcon className={classes.iconColor} />
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <Input
                        autoComplete="off"
                        onChange={(e) =>
                          this.resetCriteria({
                            query: e.target.value,
                            selectedSubType: '',
                            selectedCollection: '',
                            myItemsActive: false,
                            recentlyPurchasedActive: false,
                          })
                        }
                        value={this.state.query}
                        placeholder={intl.formatMessage({ id: 'global.search' })}
                        classes={{
                          root: classes.searchInput,
                          focused: classes.cssFocused,
                          underline: classes.cssUnderline,
                        }}
                        id="custom-css-input"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            );
          }}
        />

        {/*<ResponsiveDrawer
          onClose={this.handleSubTypesMobileDrawerToggle}
          isMobileOpen={this.state.mobileSubTypesDrawerOpen}
          content={() => {
            return (
              <React.Fragment>
                {
                  deviceInfo.isAndroid || deviceInfo.isIOS ?
                    this.getSidebarContent() :
                    <ScrollArea>{this.getSidebarContent()}</ScrollArea>
                }
              </React.Fragment>
            )
          }}
        />*/}

        <FullScreenDialog
          open={!!this.state.hit}
          toolbar={() => (
            <HeaderTypeComponent
              showAccountButton={false}
              showExitButton={false}
              onLogoClick={() => this.setState({ hit: null })}
              showShippingButton={false}
              leftContent={() => {
                return (
                  <IconButton color="inherit" onClick={() => this.setState({ hit: null })} aria-label="Close">
                    <Icon icon={CloseIcon} style={{ fill: '#ffffff' }} />
                  </IconButton>
                );
              }}
            />
          )}
          content={() => <NewProductScene hit={this.state.hit} />}
        />

        <main className={classes.content}>
          <div className={classes.topToolbar} />

          {loading && <Spinner position={'fixed'} />}

          {producersLoading ? (
            <Spinner />
          ) : (
            <div style={{ overflow: 'hidden' }}>
              <SearchHitsWrapper
                view={this.state.view}
                onSwitchView={(view) =>
                  setCollectionViewType(view, () => {
                    this.resetCriteria({ view: view });
                  })
                }>
                {this.getHits(this.state.view, true)}
              </SearchHitsWrapper>
            </div>
          )}
        </main>
      </div>
    );
  }
}

EditOrder.propTypes = propTypes;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(EditOrder)));
