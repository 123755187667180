class Customer {
  constructor(props = {}) {
    this.id = props.id || null;
    this.accounts = Array.isArray(props.accounts) ? props.accounts : [];
    this.active = props.active || false;
    this.allowCredit = props.allowCredit || false;
    this.hasRecentlyPurchased = props.hasRecentlyPurchased || [];
    this.hasUniqueItems = props.hasUniqueItems || [];
    this.autoCharge = props.autoCharge || false;
    this.companyName = props.companyName || '';
    this.billingAddresses = Array.isArray(props.billingAddresses) ? props.billingAddresses : [];
    this.shippingAddresses = Array.isArray(props.shippingAddresses) ? props.shippingAddresses : [];
    this.defaultPaymentMethod = props.defaultPaymentMethod || {};
    this.email = props.email || '';
    this.emailSubscriptions = Array.isArray(props.emailSubscriptions) ? props.emailSubscriptions : [];
    this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    this.location = props.location || { latitude: null, longitude: null };
    this.freeDelivery = props.freeDelivery || false;
    this.paymentMethods = Array.isArray(props.paymentMethods) ? props.paymentMethods : [];
    this.phone = props.phone || '';
    this.terms = props.terms || null;
    this.productsCollections = Array.isArray(props.productsCollections) ? props.productsCollections : [];
    this.defaultShippingAddresssForGeocoding = props.defaultShippingAddresssForGeocoding;

    this.producers = props.producers || [];
    this.type = props.type || 'retail';
    this.passwordDefault = props.passwordDefault || null;
    this.username = props.username || '';
  }

  /**
   * Checks whether customer has recently purchased items for specific producer
   * @param {object[]} selectedProducers
   * @return {number | * | boolean}
   */
  isRecentlyPurchasedAvailable(selectedProducers) {
    // TODO: Add support for multiple producers

    /*return (
      Object.keys(this.hasRecentlyPurchased).length &&
      this.hasRecentlyPurchased[producer.id] &&
      this.hasRecentlyPurchased[producer.id] === true
    )*/

    return false;
  }

  /**
   * Checks whether customer has unique items for specific producer
   * @param {object[]} selectedProducers
   * @return {number | * | boolean}
   */
  isUniqueItemsAvailable(selectedProducers) {
    // TODO: Add support for multiple producers

    /*return (
      Object.keys(this.hasUniqueItems).length &&
      this.hasUniqueItems[producer.id] &&
      this.hasUniqueItems[producer.id] === true
    )*/

    return false;
  }

  static makeStringAddress(addressObj) {
    let addressParts = [];

    addressObj.address1 && addressParts.push(addressObj.address1);
    addressObj.address2 && addressParts.push(addressObj.address2);
    addressObj.city && addressParts.push(addressObj.city);
    addressObj.province && addressParts.push(addressObj.province);
    addressObj.zip && addressParts.push(addressObj.zip);
    addressObj.postalCode && addressParts.push(addressObj.postalCode);

    return addressParts.join(', ');
  }

  static isAddressExist = (customer, address) => {
    if (!customer?.shippingAddresses?.length) return false;

    let isExist = customer.shippingAddresses.filter(
      (item) => item.address1 === address.address1 && item.zip === (address.zip || address.postalCode)
    );
    return isExist.length > 0;
  };

  static findExistAddress = (customer, address) => {
    if (!customer?.shippingAddresses?.length) return false;

    return customer?.shippingAddresses.find(
      (item) => item.address1 === address.address1 && item.zip === (address.zip || address.postalCode)
    );
  };
}

export default Customer;
