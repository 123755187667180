import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from "@material-ui/core/FormControl";
import MUIRadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  PAYMENT_MANUAL,
  PAYMENT_AUTO
} from '../../../helpers/index';
import {
  ErrorBox,
  Spinner,
  BlackRadio,
  BlackButton,
  DialogContentWrapper,
  DialogActionsWrapper
} from '../../../components/index';
import styles from './styles';

const propTypes = {
  changePaymentPreferences: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};
const defaultProps = {};

class PaymentPreference extends React.Component {

  state = {
    error: "",
    loading: false,
    selected: ""
  };

  validate = () => {
    this.setState({loading: true, error: ""}, () => {
      if (!this.state.selected) {
        this.setState({error: "messages.makeYourChoice"})
      } else {
        this.props.changePaymentPreferences(
          this.state.selected,
          () => {
            this.setState({loading: false}, () => {
              this.props.onDone();
            })
          },
          () => {
            this.setState({
              loading: false,
              error: "messages.somethingWentWrong"
            })
          }
        );
      }
    });
  };

  render() {
    const {
      classes,
    } = this.props;

    return (
      <React.Fragment>
        <DialogContentWrapper padding45 mt70>

          {this.state.error && (
            <ErrorBox error={<FormattedMessage id={this.state.error}/>} />
          )}

          {this.state.loading && (
            <Spinner size={40} />
          )}

          <p className={classes.message}>
            <FormattedMessage id="paymentPreferences.message"/>
          </p>

          <FormControl className={classes.formControl}>
            <MUIRadioGroup
              name={"payment-preference"}
              className={classes.select}
              value={this.state.selected}
              onChange={(e) => this.setState({selected: e.target.value})} >

              <FormControlLabel
                key={PAYMENT_AUTO}
                value={PAYMENT_AUTO}
                classes={{
                  root: classes.formControlRoot,
                  label: classes.formControlLabel
                }}
                control={<BlackRadio style={{paddingTop: 0}}/>}
                label={<FormattedMessage id="paymentPreferences.auto"/>}
              />

              <FormControlLabel
                key={PAYMENT_MANUAL}
                value={PAYMENT_MANUAL}
                classes={{
                  root: classes.formControlRoot,
                  label: classes.formControlLabel
                }}
                control={<BlackRadio style={{paddingTop: 0}}/>}
                label={<FormattedMessage id="paymentPreferences.manually"/>}
              />

            </MUIRadioGroup>
          </FormControl>
        </DialogContentWrapper>

        <DialogActionsWrapper padding45>
          <div className={classes.controls}>
            <BlackButton  disabled={this.state.loading}
                          onClick={this.validate}
                          className={classes.control}>
              <FormattedMessage id="paymentPreferences.buttonTitle"/>
            </BlackButton>
          </div>
        </DialogActionsWrapper>

      </React.Fragment>
    );
  }
}

PaymentPreference.propTypes = propTypes;
PaymentPreference.defaultProps = defaultProps;

export default withStyles(styles)(PaymentPreference);






