import {
  SHOW_WELCOME, HIDE_WELCOME
} from '../constants';

const initialState = {
  active: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_WELCOME : {
      return {
        ...state,
        active: true
      }
    }
    case HIDE_WELCOME : {
      return {
        ...state,
        active: false
      }
    }
    default:
      return state;
  }
};

export default reducer;