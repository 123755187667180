/*
  const now = moment();
  const nearest5min = nearestMinutes(5, now);
  const nearest15min = nearestMinutes(15, now);
  const nearest30min = nearestMinutes(30, now);
  const nearestFuture5min = nearestFutureMinutes(5, now);
  const nearestPast5min = nearestPastMinutes(5, now);
*/

const nearestMinutes = (interval, someMoment) => {
  const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

const nearestPastMinutes = (interval, someMoment) => {
  const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

const nearestFutureMinutes = (interval, someMoment) => {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export {
  nearestFutureMinutes,
  nearestMinutes,
  nearestPastMinutes
}