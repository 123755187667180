import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import {
  ErrorBox,
  Spinner,
  CheckoutAddressRadioGroup,
  BlackButton,
  DialogContentWrapper,
  DialogActionsWrapper,
} from '../../../../components';
import {
  BILLING_ADDRESS,
  BILLING_ADDRESS_TYPE,
  BILLING_ADDRESSES,
  DELIVERY_ADDRESS,
  DELIVERY_ADDRESS_TYPE,
  DELIVERY_ADDRESSES,
} from '../../../../helpers';
import { getDefaultAddressId } from '../helpers';
import styles from './styles';

const propTypes = {
  customer: PropTypes.object.isRequired,
  onAddAddress: PropTypes.func.isRequired,
  onPatchAddress: PropTypes.func.isRequired,
  onSetDraftAddress: PropTypes.func.isRequired,
  type: PropTypes.string,
  onDone: PropTypes.func,
};
const defaultProps = {};

class SelectAddressStage extends React.Component {
  constructor(props) {
    super(props);
    const { customer, draft } = props;

    this.state = {
      error: '',
      internalLoading: false,
      address: '',
      billingAddress: draft.customer.billingAddress
        ? draft.customer.billingAddress.id
        : getDefaultAddressId(customer.billingAddresses, true),
      shippingAddress: draft.customer.shippingAddress
        ? draft.customer.shippingAddress.id
        : getDefaultAddressId(customer.shippingAddresses, true),
    };
  }

  componentDidMount() {
    const { customer, type, onAddAddress } = this.props;

    if (customer[`${type}Addresses`].length === 0) {
      onAddAddress();
    }
  }

  onChangeAddress = (type, address) => {
    let state = {};
    state[type] = address;
    this.setState(state);
  };

  saveAddressOnDraft = () => {
    const { onSetDraftAddress, customer, onDone, type } = this.props;

    let address = null;

    if (type === DELIVERY_ADDRESS_TYPE && (this.state[BILLING_ADDRESS] || this.state[DELIVERY_ADDRESS])) {
      address = { id: customer.id };
      if (this.state[DELIVERY_ADDRESS]) {
        address[DELIVERY_ADDRESS] = { id: this.state[DELIVERY_ADDRESS] };
      }

      if (this.state[BILLING_ADDRESS]) {
        address[BILLING_ADDRESS] = { id: this.state[BILLING_ADDRESS] };
      }
    }

    if (type === BILLING_ADDRESS_TYPE && this.state[BILLING_ADDRESS]) {
      address = { id: customer.id };
      if (this.state[DELIVERY_ADDRESS]) {
        address[DELIVERY_ADDRESS] = { id: this.state[DELIVERY_ADDRESS] };
      }

      if (this.state[BILLING_ADDRESS]) {
        address[BILLING_ADDRESS] = { id: this.state[BILLING_ADDRESS] };
      }
    }

    if (address) {
      this.setState({ internalLoading: true, error: '' }, () => {
        onSetDraftAddress(
          address,
          () => {
            this.setState({ internalLoading: false }, onDone);
          },
          (response) => {
            this.setState({
              internalLoading: false,
              error: response.message || 'Something went wrong while saving selected address',
            });
          }
        );
      });
    } else {
      this.setState({ error: 'Address is not selected' });
    }
  };

  render() {
    const { loading, classes, customer, onPatchAddress, onAddAddress, type } = this.props;

    return (
      <React.Fragment>
        <DialogContentWrapper>
          {this.state.error && <ErrorBox error={this.state.error} />}
          {(loading || this.state.internalLoading) && <Spinner size={40} />}

          {type === BILLING_ADDRESS_TYPE && (
            <div className={classes.addresses}>
              <CheckoutAddressRadioGroup
                changeable
                buttonTitle={<FormattedMessage id="address.addBilling" />}
                selected={this.state[BILLING_ADDRESS]}
                addresses={customer[BILLING_ADDRESSES]}
                onPatch={(address) => onPatchAddress(address)}
                onAdd={() => onAddAddress(BILLING_ADDRESS_TYPE)}
                onChange={(address) => this.onChangeAddress(BILLING_ADDRESS, address)}
                title={<FormattedMessage id="address.billing" />}
                name={BILLING_ADDRESSES}
              />
            </div>
          )}

          {type === DELIVERY_ADDRESS_TYPE && (
            <div className={classes.addresses}>
              <CheckoutAddressRadioGroup
                changeable
                buttonTitle={<FormattedMessage id="address.addShipping" />}
                selected={this.state[DELIVERY_ADDRESS]}
                addresses={customer[DELIVERY_ADDRESSES]}
                onPatch={(address) => onPatchAddress(address)}
                onAdd={() => onAddAddress(DELIVERY_ADDRESS_TYPE)}
                onChange={(address) => this.onChangeAddress(DELIVERY_ADDRESS, address)}
                title={<FormattedMessage id="address.shipping" />}
                name={DELIVERY_ADDRESSES}
              />
            </div>
          )}
        </DialogContentWrapper>
        <DialogActionsWrapper padding45>
          <div className={classes.controls}>
            <BlackButton disabled={loading} onClick={this.saveAddressOnDraft} className={classes.control}>
              <FormattedMessage id="address.select" />
            </BlackButton>
          </div>
        </DialogActionsWrapper>
      </React.Fragment>
    );
  }
}

SelectAddressStage.propTypes = propTypes;
SelectAddressStage.defaultProps = defaultProps;

export default withStyles(styles)(SelectAddressStage);
