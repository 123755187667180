import { toCurrency } from './money';

/**
 * Get line item variant name
 * Should be empty if equals to unit or "Default title"
 * @param {object} item
 * @param {string} prefix
 * @return {string}
 */
export const getLineItemVariantName = (item, prefix) => {
  let name = '';
  if (item.name.variant && item.name.variant !== item.unit && item.name.variant !== 'Default Title') {
    name = ' ' + prefix + ' ' + item.name.variant;
  }
  return name;
};

export const getVariantPriceForCustomer = (customer, variant, intl) => {
  const item = ((variant && variant.prices) || []).find((p) => p.level === `${customer.type}_preorder`);

  return item ? toCurrency(item.price, intl).toString() : toCurrency(0, intl).toString();
};

export const getVariantUnit = (variant, emptyIfSameAsName, prefix) => {
  if (emptyIfSameAsName && variant.name === variant.unit) return '';

  prefix = prefix || '';
  return prefix + variant.unit;
};

export const getVariantNameFromItem = (item) => {
  if (!item) return '';
  return !!item?.name?.variant && item.name.variant !== item.unit ? item?.name?.variant : '';
};

export function isVariantInStock(variant) {
  const isInventoryDisabled = !variant?.inventory;

  // Unlimited
  if (isInventoryDisabled) {
    return true;
  }

  // Out of stock (0) or available (> 0)
  return !!variant?.inventory?.quantity;
}
