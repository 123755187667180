import React from 'react'
import PropTypes from 'prop-types'
import {
	FormattedMessage,
	injectIntl
} from 'react-intl'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  BlackButton,
} from '../../../../components'
import styles from './styles'
import {
	DialogContentWrapper,
	DialogActionsWrapper
} from '../../../../components';

const propTypes = {
	onDone: PropTypes.func.isRequired,
};
const defaultProps = {};

const FulfillmentFeeInfoStage = ({ classes,
																	 onDone,
																	 loading }) => {

	return (
		<React.Fragment>
      <DialogContentWrapper padding45 mt70>
      	<div className={classes.blockWrapper}>
					<Typography className={classes.message} variant="body1">
						<FormattedMessage id="order.fulfillmentFeeInfo1"/>
					</Typography>
					<Typography className={classes.message} variant="body1">
						<FormattedMessage id="order.fulfillmentFeeInfo2"/>
					</Typography>
				</div>
      </DialogContentWrapper>
      <DialogActionsWrapper padding45>
				<div className={classes.controls}>
          <BlackButton disabled={loading}
                       onClick={onDone}
                       className={classes.control}>
						<FormattedMessage id="global.ok"/>
					</BlackButton>
				</div>
      </DialogActionsWrapper>
		</React.Fragment>
	)
};

FulfillmentFeeInfoStage.propTypes = propTypes;
FulfillmentFeeInfoStage.defaultProps = defaultProps;

export default withStyles(styles, {withTheme: true})(
	injectIntl(FulfillmentFeeInfoStage)
);






