export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const MAKE_INITIALIZED = 'MAKE_INITIALIZED';
export const MAKE_NON_INITIALIZED = 'MAKE_NON_INITIALIZED';
export const SET_SHIPPING_OPTIONS = 'SET_SHIPPING_OPTIONS';
export const OPEN_CHECKOUT = 'OPEN_CHECKOUT';
export const CLOSE_CHECKOUT = 'CLOSE_CHECKOUT';
export const REFRESH_EVERYTHING_START = 'REFRESH_EVERYTHING_START';
export const REFRESH_EVERYTHING_DONE = 'REFRESH_EVERYTHING_DONE';
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';
export const TOGGLE_INFO_DIALOG = 'TOGGLE_INFO_DIALOG';
export const SET_INFO_DIALOG_CONTENT = 'SET_INFO_DIALOG_CONTENT';
export const TOGGLE_RIGHT_SIDE_MENU_DIALOG = 'TOGGLE_RIGHT_SIDE_MENU_DIALOG';
export const SEND_SENTRY_ERROR = 'SEND_SENTRY_ERROR';
export const CLEAR_SENTRY_ERROR = 'CLEAR_SENTRY_ERROR';

export const commonConstants = {
  REFRESH_SHIPPING_START: 'COMMON:REFRESH_SHIPPING_START',
  REFRESH_SHIPPING_END: 'COMMON:REFRESH_SHIPPING_END',
  REFRESH_SHIPPING_ERROR: 'COMMON:REFRESH_SHIPPING_ERROR',
};
