import React, { useState, useEffect } from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { apiCustomerGetProductSubscriptionById } from '../../api';
import { injectIntl } from 'react-intl';
import SubscriptionView from './SubscriptionView';
import { NotFound } from '../index';
import { withWidth } from '@material-ui/core';

function SubscriptionScene(props) {
  const { match, intl, history } = props;
  const itemId = match.params.id;
  const isSubscription = !!String(itemId).match('sub_');

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    const apiRetriever =
      (isSubscription && apiCustomerGetProductSubscriptionById) ||
      onWrongUrl;

    apiRetriever(
      itemId,
      (response) => {
        setOrder(response.data);
        setLoading(false);
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError(intl.formatMessage({ id: 'messages.somethingWentWrong' }));
        }
        setLoading(false);
      }
    );
  }, []);

  const onWrongUrl = () => {
    setLoading(false);
  };

  if (isSubscription) {
    return <SubscriptionView order={order} loading={loading} error={error} history={history} />;
  }

  return <NotFound noResolver />;
}

export default withStyles(styles, { withTheme: true })(withWidth()(injectIntl(SubscriptionScene)));
