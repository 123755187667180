import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  BlackLinkButton,
  BlackEditableNote,
  BlackQuantitySwitcher,
  BlackButton,
  BadgeColors,
  Badge,
  LineItemCamelPrice,
  SkeletonCartItems,
} from '../../../components';
import {
  toCurrency,
  getLineItemQuantity,
  getLineItemSystemQuantity,
  hasNotAvailableLineItems,
  isSalesTaxEnabled,
  getSalesTax,
  weightToUser,
  getVariantNameFromItem,
} from '../../../helpers';
import EmptyImage from './assets/no.jpg';
import CSATotal from './CSATotal';
import LineItemPrice from './LineItemPrice';
import DiscountTotal from './DiscountTotal';
import { OrderEntity, ShippingEntity } from '../../../entities';
import styles from './styles';
import { Grid } from '@material-ui/core';

class Order extends React.Component {
  state = {
    notAvailableLineItemDialogActive: false,
    editingItemNote: false,
    orderUnderMinimumAmount: false,
    minPrice: 0,
  };

  onCheckout = () => {
    const { order, onCheckout, guest, showSignInDialog } = this.props;

    const { isMeet, minPrice } = OrderEntity.isMeetToMinimumRequiredAmount(order);

    // Problem with minimum order amount
    if (!isMeet) {
      return this.setState({
        orderUnderMinimumAmount: true,
        minPrice,
      });
    }

    // Show SignIn dialog in case customer is guest
    if (guest) {
      showSignInDialog({
        fromCheckout: true,
      });
      return false;
    }

    // Disable checkout in case order has problems with delivery
    if (OrderEntity.isDeliveryNotAvailable(order)) {
      return false;
    }

    // Problem with items availability
    if (OrderEntity.hasNotAvailableLineItems(order)) {
      return this.setState({
        notAvailableLineItemDialogActive: true,
      });
    }

    // Do checkout
    onCheckout();
  };

  lineItems = (order) => {
    const { classes, loading, onDeleteLineItem, onSetLineItemNote, onQuantityChange, onChangeOrderNote, intl } =
      this.props;

    const getPrice = (item) => {
      if (!!item.prepurchasedUnitPrice) {
        return (
          <LineItemCamelPrice
            wrapperClass={classes.green}
            size={12}
            color={'#484848'}
            itemPrice={toCurrency(item.prepurchasedUnitPrice, intl)}>
            {' '}
            / {weightToUser(item.unitQuantity)} {item.unit}
          </LineItemCamelPrice>
        );
      }

      return (
        <LineItemCamelPrice
          size={12}
          itemPrice={toCurrency(item.unitPrice, intl)}
          wrapperClass={classes.green}
          color={'#484848'}>
          {' '}
          / {weightToUser(item.unitQuantity)} {item.unit}
        </LineItemCamelPrice>
      );
    };

    const shippingEntity = new ShippingEntity(order.shipping);

    return (
      <div className={classes.cartLineItemsWrapper}>
        {loading && (
          <SkeletonCartItems
            numberOfItems={
              (order &&
                order.lineItems &&
                order.lineItems.length &&
                order.lineItems[0] &&
                order.lineItems[0].items &&
                order.lineItems[0].items.length) ||
              5
            }
          />
        )}

        {/* start cart items by producer */}
        {!loading && (
          <>
            {order.lineItems.map(({ producer, items }) => (
              <React.Fragment key={producer.id}>
                {/* producer tTitle */}
                <div className={classes.producerTitle} key={producer.id}>
                  <Typography className={classes.producer} component="p">
                    {producer.name}
                  </Typography>
                </div>

                {/* producer items */}
                {items.map((item) => (
                  <div
                    className={classNames(classes.item, item.available ? '' : classes.itemNotAvailable)}
                    key={item.id}>
                    <div className={classes.imageWrapper}>
                      <img
                        className={classes.image}
                        src={item.image ? item.image.thumbSrc : EmptyImage}
                        alt={item.name.product}
                      />
                    </div>

                    <div className={classes.itemContentContainer}>
                      <div className={classNames([classes.itemColumn, classes.itemColumnFirst])}>
                        <div className={classes.info}>
                          <Typography className={classes.productName} component="p">
                            {item.name.product}
                          </Typography>

                          {getVariantNameFromItem(item) && (
                            <Typography className={classes.variantName} component="p" style={{ marginTop: -5 }}>
                              {getVariantNameFromItem(item)}
                            </Typography>
                          )}

                          {item.discount && item.discount.applied && (
                            <Typography className={classNames(classes.productName, classes.green)} component="p">
                              {intl.formatMessage({ id: 'global.discount' })}: {item.discount.definition.name}
                            </Typography>
                          )}

                          <Typography className={classes.variantName} component="p">
                            {item.available ? (
                              getPrice(item)
                            ) : (
                              <Badge color={BadgeColors.danger} title={<FormattedMessage id="global.notAvailable" />} />
                            )}
                          </Typography>

                          <Grid xs={3} item>
                            {/*mobile only quantity */}
                            {item.available && (
                              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                <div className={classes.quantity}>
                                  {
                                    <BlackQuantitySwitcher
                                      withRemoveButton
                                      onRemove={() => {
                                        onDeleteLineItem(item, (order) => {
                                          this.setState({
                                            notAvailableLineItemDialogActive: hasNotAvailableLineItems(order),
                                          });
                                        });
                                      }}
                                      onChange={(newQuantity) => {
                                        this.setState({ orderUnderMinimumAmount: false });
                                        onQuantityChange(item, getLineItemSystemQuantity(item, newQuantity));
                                      }}
                                      quantity={getLineItemQuantity(item)}
                                    />
                                  }
                                </div>
                              </Hidden>
                            )}
                          </Grid>
                          <div className={classes.actionsWrapper}>
                            {item.available && (
                              <BlackEditableNote
                                noIcon
                                note={item.note}
                                buttonClass={classes.controlTextStyle}
                                onCancelEditing={() => this.setState({ editingItemNote: false })}
                                onBeforeEditing={() => this.setState({ editingItemNote: item.id })}
                                onChange={(note) => {
                                  this.setState({ editingItemNote: false });
                                  onSetLineItemNote(note, item);
                                }}
                              />
                            )}

                            {this.state.editingItemNote !== item.id && (
                              <BlackLinkButton
                                text={intl.formatMessage({ id: 'global.remove' })}
                                containerClass={classes.buttonContainer}
                                className={classes.controlTextStyle}
                                onClick={() =>
                                  onDeleteLineItem(item, (order) => {
                                    this.setState({
                                      notAvailableLineItemDialogActive: hasNotAvailableLineItems(order),
                                    });
                                  })
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classNames([classes.itemColumn, classes.itemColumnSecond])}>
                        {/*web only quantity */}
                        {item.available && (
                          <Hidden only={['xs']}>
                            <div className={classes.quantity}>
                              {
                                <BlackQuantitySwitcher
                                  withRemoveButton
                                  onRemove={() => {
                                    onDeleteLineItem(item, (order) => {
                                      this.setState({
                                        notAvailableLineItemDialogActive: hasNotAvailableLineItems(order),
                                      });
                                    });
                                  }}
                                  onChange={(newQuantity) => {
                                    this.setState({ orderUnderMinimumAmount: false });
                                    onQuantityChange(item, getLineItemSystemQuantity(item, newQuantity));
                                  }}
                                  quantity={getLineItemQuantity(item)}
                                />
                              }
                            </div>
                          </Hidden>
                        )}
                      </div>
                      <div className={classNames([classes.itemColumn, classes.itemColumnThird])}>
                        <LineItemPrice order={order} item={item} fontSize={12} />
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </>
        )}
        {/* end cart items */}

        {/* start cart totals */}
        <div className={classes.summaryWrapper}>
          <div className={classes.summaryItem}>
            <div className={classes.info}>
              <Typography className={classes.total} component="p">
                <FormattedMessage id="global.subtotal" />
              </Typography>
            </div>

            <div className={classes.price}>{toCurrency(order.subtotal, intl)}</div>
          </div>

          {order.discount && order.discount.applied && <DiscountTotal order={order} />}

          {order.shipping && (
            <>
              {shippingEntity.isDelivery() && (
                <div className={classes.summaryItem}>
                  <div className={classes.info}>
                    <Typography className={classes.total} component="p">
                      <FormattedMessage id="order.fulfillmentFee" />
                    </Typography>
                  </div>

                  <div className={classes.price}>{toCurrency(shippingEntity.fulfillmentFee, intl)}</div>
                </div>
              )}

              <div className={classes.summaryItem}>
                <div className={classes.info}>
                  <Typography className={classes.total} component="p">
                    <FormattedMessage id="order.shippingFee" />
                  </Typography>
                </div>

                <div className={classes.price}>{toCurrency(order.shipping.fee, intl)}</div>
              </div>
            </>
          )}

          {isSalesTaxEnabled() && (
            <div className={classes.summaryItem}>
              <div className={classes.info}>
                <Typography className={classes.total} component="p">
                  <FormattedMessage id="global.salesTax" />
                </Typography>
              </div>

              <div className={classes.price}>{getSalesTax(order.total, intl)}</div>
            </div>
          )}

          {!!order.taxesTotal && (
            <div className={classes.summaryItem}>
              <div className={classes.info}>
                <Typography className={classes.total} component="p">
                  <FormattedMessage id="global.taxes" />
                </Typography>
              </div>

              <div className={classes.price}>{toCurrency(order.taxesTotal, intl)}</div>
            </div>
          )}

          <div className={classes.summaryItem}>
            <div className={classes.info}>
              <Typography className={classes.total} component="p">
                <FormattedMessage id="global.total" />
              </Typography>
            </div>

            <div className={classes.price}>{toCurrency(order.total, intl)}</div>
          </div>

          {order.prepurchaseOrder && <CSATotal order={order} />}

          <div className={classNames(classes.summaryItem, classes.draftNoteItem)}>
            {
              <BlackEditableNote
                noIcon
                buttonClass={classes.controlTextStyle}
                constrolsClass={classes.controlsClass}
                editWithoutNoteTitle="note.add"
                editWithNoteTitle="note.edit"
                classes={{
                  noteTextWrapper: classes.orderNoteInputWrapper,
                  noteInputWrapper: classes.orderNoteInputWrapper,
                }}
                note={order.note}
                onChange={(note) => onChangeOrderNote(note)}
              />
            }
          </div>
        </div>
      </div>
    );
  };

  drawActions = (order) => {
    const { classes, intl } = this.props;

    return (
      <div className={classNames(classes.summaryItem, classes.flexColumn, classes.flexColumnCenter)}>
        {this.state.notAvailableLineItemDialogActive && (
          <Typography className={classes.hasNotAvailableNotification} variant={'body1'} gutterBottom align={'center'}>
            <FormattedMessage id="messages.notAvailableItemsInCart" />
          </Typography>
        )}

        {this.state.orderUnderMinimumAmount && (
          <Typography
            className={classNames(classes.hasNotAvailableNotification, classes.fullWidth)}
            variant={'body1'}
            gutterBottom
            align={'center'}>
            {intl.formatMessage({ id: 'messages.minimumOrder' }, { amount: this.state.minPrice })}
          </Typography>
        )}

        {this.props.onCheckout && (
          <BlackButton
            onClick={this.onCheckout}
            fullWidth
            disabled={OrderEntity.isDeliveryNotAvailable(order)}
            className={classes.buttonSubmit}>
            <FormattedMessage id="global.checkout" />
          </BlackButton>
        )}

        {this.props.onEditOrderDone && (
          <BlackButton onClick={this.props.onEditOrderDone} fullWidth className={classes.button}>
            <FormattedMessage id="order.update" />
          </BlackButton>
        )}
      </div>
    );
  };

  empty() {
    const { classes } = this.props;
    return (
      <div className={classes.emptyWrapper}>
        <Typography noWrap className={classes.empty} component="p">
          <FormattedMessage id="messages.youCartIsEmpty" />
        </Typography>
      </div>
    );
  }

  render() {
    const { order, classes } = this.props;

    return (
      <>
        {order && order.lineItems.length ? this.lineItems(order) : this.empty()}
        {order && order.lineItems.length ? (
          <div className={classes.buttonWrapper}>
            <div className={classes.mobileFixedScrolling}>{this.drawActions(order)}</div>
          </div>
        ) : null}
      </>
    );
  }
}

Order.propTypes = {
  guest: PropTypes.bool,
  loading: PropTypes.bool,
  onCheckout: PropTypes.func,
  refreshOrder: PropTypes.func,
  onChangeOrderNote: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onSetLineItemNote: PropTypes.func.isRequired,
  onDeleteLineItem: PropTypes.func.isRequired,
  showSignInDialog: PropTypes.func.isRequired,
  onEditOrderDone: PropTypes.func,
  order: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};

Order.defaultProps = {
  loading: false,
};

export default withStyles(styles, { withTheme: true })(injectIntl(Order));
