import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl} from 'react-intl';

const StripeBankAccountsRadioGroupItemLabel = (props) => {
  const {
    account,
  } = props;

  return (
    <React.Fragment>
      <Typography gutterBottom noWrap>
        {account.bankName}, {account.currency.toUpperCase()}
      </Typography>
      <Typography variant="button" gutterBottom>
        •••• •••• •••• {account.last4}&nbsp;
      </Typography>
    </React.Fragment>
  );
};

const styles = {
};

export default withStyles(styles)(
  injectIntl(StripeBankAccountsRadioGroupItemLabel)
);
