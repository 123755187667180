import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import QueryString from 'query-string';
import thunk from 'redux-thunk';
import axios from 'axios';
import {
  CUSTOMER_TYPES,
  getCustomerData,
  getProducerPreviewHash,
  getToken,
  isGuestToken,
  isJWTExpired,
  setToken,
} from '../helpers';
import {
  signIn,
  signOut,
  makeInitialized,
  showWelcome,
  guestSingIn,
  filterByProducer,
  sentrySendError,
} from './actions';
import { apiCustomerRetrieve } from '../api';
import { common, auth, producer, draft, customer, hit, welcome, order, product, market, errors } from './reducers';
import { jwtDecode } from '../helpers/auth';

const initialState = {};
const middleware = [thunk];
const enhancers = [];
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const rootReducer = combineReducers({
  common,
  auth,
  producer,
  draft,
  customer,
  hit,
  welcome,
  order,
  product,
  market,
  errors,
});

let store = {};

const configureStore = (onConfigured, hash) => {
  let token = getToken();
  const producerPreviewHash = hash || getProducerPreviewHash() || null;

  const onReady = () => {
    store.dispatch(makeInitialized());
    onConfigured && onConfigured();
    store.dispatch(showWelcome());
    removeIosLink(store.getState());
  };

  store = createStore(rootReducer, initialState, composedEnhancers);

  if (window.location.pathname.split('/').includes('qr')) {
    onReady();
    return store;
  }

  // Check whether this request for wholesale or not
  if (window.location.search.includes('wholesale=')) {
    document.querySelector("[name='apple-itunes-app']").remove();

    // Sign out current user
    store.dispatch(signOut(false));

    // In case something went wrong from backend side system will redirect customer to /not-found screen
    const onWholesaleError = () => {
      window.location = '/not-found';
    };

    try {
      const wholesaleTokenBase64 = QueryString.parse(window.location.search).wholesale;
      const [producerId, wholesaleToken] = atob(wholesaleTokenBase64).split('.');

      // Sign in guest wholesale
      store.dispatch(
        guestSingIn(
          (data) => {
            const producer = data.customer.approvedProducers.find((producer) => producer.id === producerId);

            // TODO Add producer to filters
            producer && store.dispatch(filterByProducer(producer));
            onReady();
          },
          onWholesaleError,
          {
            'X-Anonymous-Type': CUSTOMER_TYPES.WHOLESALE,
            'X-Anonymous-Wholesale-Hash': wholesaleToken,
            ...(!!producerPreviewHash
              ? {
                  'X-Anonymous-Preview-Producer-Hash': producerPreviewHash,
                }
              : {}),
          }
        )
      );
    } catch (e) {
      onWholesaleError();
    }

    // Return redux store and break rest initialization
    return store;
  }

  // Avoid application initialization in case customer trying to make sign in via email
  if (window.location.pathname.includes('/sign-in-via-link')) {
    token = QueryString.parse(window.location.search).t;
    setToken(token);
  }

  axios.interceptors.response.use(undefined, function (error) {
    // Send all request errors to sentry
    store.dispatch(sentrySendError(error));

    // Handling auth error
    if (error?.response?.status === 403) {
      store.dispatch(signOut());
    }

    return Promise.reject(error);
  });

  if (token && !isJWTExpired(token)) {
    if (!isGuestToken(token)) {
      // Retrieve customer
      apiCustomerRetrieve(
        jwtDecode(token).uid,
        ({ data: customer }) => {
          store.dispatch(
            signIn(
              {
                token,
                customer,
              },
              onReady
            )
          );
        },
        () => {}
      );
    }

    if (isGuestToken(token)) {
      // Retrieve guest customer data from local storage
      // TODO: We need to retrieve customer from backend side
      const customer = getCustomerData();

      store.dispatch(
        signIn(
          {
            token,
            customer,
            guest: true,
          },
          onReady
        )
      );
    }
  } else {
    // TODO: Maybe we need to inform customer that he singed out (token expired)
    // Sing out current customer
    store.dispatch(signOut(false));
    store.dispatch(
      guestSingIn(onReady, () => {}, {
        ...(!!producerPreviewHash
          ? {
              'X-Anonymous-Preview-Producer-Hash': producerPreviewHash,
            }
          : {}),
      })
    );
  }

  return store;
};

export function getStore() {
  return store;
}

export function removeIosLink(state) {
  if (state.customer.object.type === 'wholesale') {
    const metaAppleItunesApp = document.querySelector("[name='apple-itunes-app']");
    metaAppleItunesApp && metaAppleItunesApp.remove();
  }
}

export default configureStore;
