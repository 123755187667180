import {
  producerConstants,
} from '../constants'
import {
  Markets,
  Producers,
} from '../../entities'

const initialState = {
  producers: [],
  producersEntity: new Producers([]),
  markets: [],
  marketsCounter: 0,
  marketsLoading: false,

  // Producers filter
  filterByProducers: [],
  filterByProducersCounter: 0,

  selectedProducers: [],
  loading: true,

  // Errors
  lastError: '',
  marketsError: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case producerConstants.GET_PRODUCERS: {
      const {
        producers
      } = action.payload;

      return {
        ...state,
        producers,
        producersEntity: new Producers(producers),
        loading: false
      }
    }
    case producerConstants.PRODUCERS_ERROR: {
      const {
        error
      } = action.payload;

      return {
        ...state,
        loading: false,
        lastError: error
      }
    }
    case producerConstants.CHANGE_PRODUCERS: {
      const {
        selectedProducers
      } = action.payload;

      return {
        ...state,
        selectedProducers,
        loading: false
      }
    }
    case producerConstants.PRODUCERS_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case producerConstants.MARKETS_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        marketsLoading: false,
        marketsError: error
      }
    }
    case producerConstants.MARKETS_LOADING: {
      return {...state, marketsLoading: true}
    }
    case producerConstants.MARKETS_SET: {
      const {
        markets,
        selectedProducers
      } = action.payload;

      return {
        ...state,
        markets: new Markets(markets),
        marketsLoading: false,
        marketsCounter: ++state.marketsCounter,
        selectedProducers,
        loading: false
      }
    }
    case producerConstants.SET_FILTER_BY_PRODUCERS: {
      return {
        ...state,
        filterByProducers: action.payload.producers,
        filterByProducersCounter: ++state.filterByProducersCounter
      }
    }

    default:
      return state;
  }
};

export default reducer;