import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholders, replaceUrlPlaceholder } from '../request';
import { PAYMENT_METHODS } from '../../helpers';
import API_END_POINTS from '../endpoints';

/**
 * Returns last draft order by id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftGetLast = function (onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.DRAFT_ORDER_GET_LAST);
  return axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds existing customer to draft
 * @param {string} draftOrderId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftAddCustomer = function (draftOrderId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_ADD_CUSTOMER, 'dr', draftOrderId);
  let config = getRequestConfig(REQUEST_TYPES.PUT, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Removes customer from draft order
 * @param {string} draftOrderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftRemoveCustomer = function (draftOrderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_REMOVE_CUSTOMER, 'dr', draftOrderId);
  axios(getRequestConfig(REQUEST_TYPES.DEL, url))
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds variant to cart
 * @param {string} draftOrderId
 * @param {string} variantId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftAddVariant = function (draftOrderId, variantId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_ADD_VARIANT, 'dr', draftOrderId);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  data = data || {};
  data.variantId = variantId;
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Completes draft order
 * @param {string} draftOrderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftComplete = function (draftOrderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_COMPLETE, 'dr', draftOrderId);
  axios(getRequestConfig(REQUEST_TYPES.POST, url))
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Validation zip code
 * @param {string} zipCode - zip code
 * @param {string} locationId - optional location id
 */
export const apiDraftZipValidation = function (zipCode, locationId) {
  return new Promise((resolve, reject) => {
    let config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.DRAFT_ZIP_VALIDATION);

    config.data = {
      zipCode,
    };

    locationId &&
      (config.data = {
        ...config.data,
        locationId,
      });

    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Removes line item from draft
 * @param {string} draftOrderId
 * @param {string} lineItemId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftRemoveLineItem = function (draftOrderId, lineItemId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.DRAFT_ORDER_REMOVE_LINE_ITEM, {
    dr: draftOrderId,
    it: lineItemId,
  });
  axios(getRequestConfig(REQUEST_TYPES.DEL, url))
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds shipping to draft order
 * @param {string} draftOrderId
 * @param {string} type delivery|pickup
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftSetShipping = function (draftOrderId, type, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.DRAFT_ORDER_SET_SHIPPING, {
    dr: draftOrderId,
    type: type,
  });
  let config = getRequestConfig(REQUEST_TYPES.PUT, url);

  // Set request params
  data = data || {};
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Adds note on draft order
 * @param {string} draftOrderId
 * @param {string} note
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftSetNote = function (draftOrderId, note, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_ADD_NOTE, 'dr', draftOrderId);
  let config = getRequestConfig(REQUEST_TYPES.PUT, url);
  config.data = { note: note };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Remove all line items from draft
 * @param {object} order
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftRemoveLineItems = function (order, onSuccess, onError) {
  if (order.lineItems.length) {
    const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_CLEAR, 'dr', order.id);
    let config = getRequestConfig(REQUEST_TYPES.DEL, url);
    axios(config)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  } else {
    onSuccess && onSuccess();
  }
};

/**
 * Remove all line items from draft
 * @param {object} draft
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftRemoveAllLineItems = function (draft, onSuccess, onError) {
  if (draft.lineItems.length) {
    const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ITEMS_CLEAR_ALL, 'draftId', draft.id);
    let config = getRequestConfig(REQUEST_TYPES.DEL, url);
    axios(config)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  } else {
    onSuccess && onSuccess(draft);
  }
};

/**
 * Remove shipping options from draft
 * @param {string} draftOrderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftShippingOptionsClear = function (draftOrderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_SHIPPING_CLEAR, 'dr', draftOrderId);
  let config = getRequestConfig(REQUEST_TYPES.PUT, url);
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Remove all line items from draft
 * @param {object} order
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftRemoveNotAvailableLineItems = function (order, onSuccess, onError) {
  if (order.lineItems.length) {
    let requests = [];
    order.lineItems.forEach(({ items }) => {
      items.forEach((item) => {
        if (!item.available) {
          const url = replaceUrlPlaceholders(API_END_POINTS.DRAFT_ORDER_REMOVE_LINE_ITEM, {
            dr: order.id,
            it: item.id,
          });

          let config = getRequestConfig(REQUEST_TYPES.DEL, url);
          requests.push(axios.delete(config.url, config));
        }
      });
    });

    if (requests.length) {
      Promise.all(requests)
        .then(() => {
          apiDraftGetLast((response) => {
            onSuccess && onSuccess(response.data);
          }, onError);
        })
        .catch((error) => {
          onError && onError(error);
        });
    } else {
      onSuccess && onSuccess(order);
    }
  } else {
    onSuccess && onSuccess(order);
  }
};

/**
 * Add variants to draft
 * @param {object} order
 * @param {array} lineItems
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftAddVariants = function (order, lineItems, onSuccess, onError) {
  if (lineItems.length) {
    let requests = [];
    lineItems.forEach(({ items }) => {
      items.forEach((item) => {
        if (item.available) {
          const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_ADD_VARIANT, 'dr', order.id);
          let config = getRequestConfig(REQUEST_TYPES.POST, url);
          let data = {
            note: item.note,
            quantity: item.orderedQuantity,
            variantId: item.variantId,
          };
          requests.push(axios.post(config.url, data, config));
        }
      });
    });

    Promise.all(requests)
      .then((...res) => {
        onSuccess && onSuccess(res);
      })
      .catch((error) => {
        onError && onError(error);
      });
  } else {
    onSuccess && onSuccess();
  }
};

/**
 * Set payment method for draft
 * @param {object} draft
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftSetPaymentMethod = function (draft, data, onSuccess, onError) {
  const url = draft.prepurchaseOrder
    ? // Use special method for prepurchase orders
      replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_SET_PREPURCHASE_PAYMENT_METHOD, 'dr', draft.id)
    : replaceUrlPlaceholder(API_END_POINTS.DRAFT_ORDER_SET_PAYMENT_METHOD, 'dr', draft.id);

  let config = getRequestConfig(REQUEST_TYPES.POST, url);

  if (
    (data.paymentMethod === PAYMENT_METHODS.bankAccount || data.paymentMethod === PAYMENT_METHODS.creditCard) &&
    typeof data.paymentMethodId === 'undefined'
  ) {
    console.error('Need to set paymentMethodId for payment methods stripe_ach | stripe_card');
    return false;
  }

  switch (data.paymentMethod) {
    case PAYMENT_METHODS.cod: {
      config.data = {
        method: PAYMENT_METHODS.cod,
      };
      break;
    }
    case PAYMENT_METHODS.check: {
      config.data = {
        method: PAYMENT_METHODS.check,
      };
      break;
    }
    case PAYMENT_METHODS.bankAccount: {
      config.data = {
        paymentMethodId: data.paymentMethodId,
      };
      break;
    }
    case PAYMENT_METHODS.creditCard: {
      config.data = {
        paymentMethodId: data.paymentMethodId,
      };
      break;
    }
    default: {
      console.error('Incompatible payment method');
      return false;
    }
  }

  axios(config)
    .then(() => {
      onSuccess && onSuccess();
    })
    .catch((error) => {
      onError && onError(error.response.data);
    });
};

/**
 * Clear Unavailable Items
 * @param {string} draftOrderId.
 * @param {string} producerId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftUnavailableItemsClear = function (draftOrderId, producerId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_CLEAR_UNAVAILABLE_ITEMS, 'dr', draftOrderId);
  let config = getRequestConfig(REQUEST_TYPES.PUT, url);

  config.data = {
    producerId,
  };

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Get last error from draft
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftGetLastError = function (onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.DRAFT_GET_LAST_ERROR);

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Validation user addresses by market id
 * @param {string} locationId - required location id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftAddressesValidation = function (locationId, onSuccess, onError) {
  if (process.env.REACT_APP_USE_ADDRESS_VERIFICATION === 'false') {
    const response = { data: [] };
    onSuccess && onSuccess(response);

    return response;
  }

  return new Promise((resolve, reject) => {
    const url = replaceUrlPlaceholder(API_END_POINTS.DRAFT_ADDRESSES_VALIDATION, 'locationId', locationId);

    let config = getRequestConfig(REQUEST_TYPES.GET, url);

    axios(config)
      .then((response) => {
        onSuccess && onSuccess(response);
        resolve(response);
      })
      .catch((error) => {
        onError && onError(error);
        reject(error);
      });
  });
};
