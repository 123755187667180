import axios from 'axios';
import moment from 'moment';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Search products
 * @param {SearchProductsParams} options - search options
 */
export const apiProductsSearch = function (options, onSuccess) {
  return new Promise((resolve, reject) => {
    let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCTS_SEARCH);

    config.params = options.toData();
    axios(config)
      .then((response) => {
        resolve(response);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Search recently purchased products
 * @param {SearchProductsParams} options - search options
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiRecentProductsSearch = function (options, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.RECENT_PRODUCTS_SEARCH);
  config.params = options.toData();
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data, response.headers);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Search collections
 * @param {string} id - collection id
 * @param {SearchProductsParams} options - search options
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCollectionsSearch = function (id, options, onSuccess, onError) {
  let url = replaceUrlPlaceholder(API_END_POINTS.COLLECTIONS_SEARCH, 'id', id);
  let config = getRequestConfig(REQUEST_TYPES.GET, url);
  config.params = options.toData();
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response.data, response.headers);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Search sub types
 * @param {object} order
 * @param {array} producers
 * @return {Promise<any>}
 */
export const apiSubTypesSearch = (order, producers) =>
  new Promise((resolve, reject) => {
    const { shipping } = order;
    const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.SUB_TYPES_SEARCH);

    shipping &&
      (config.params = {
        ...config.params,
        ...(shipping.date ? { date: moment(shipping.date).format('YYYY-MM-DD').toLowerCase() } : {}),
        ...(shipping.location ? { locationId: shipping.location.id } : {}),
        ...(shipping.type ? { type: shipping.type } : {}),
      });

    producers &&
      (config.params = {
        ...config.params,
        producerIds: producers.map((p) => p.id),
      });

    axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * Search products grouped by subType
 * @param {SearchProductsParams} options - search options
 * @return {Promise<any>|Promise<T | never>}
 */
export const apiGetProductsGroupedBySubType = function (options) {
  return new Promise((resolve, reject) => {
    let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.PRODUCTS_SEARCH_GROUPED_BY_SUBTYPE);

    config.params = options.toData();

    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Search companies via google api
 * @param {string} query
 * @param {function} onSuccess
 * @param {function} onError
 */
export const googleApiSearchCompanies = function (query, onSuccess, onError) {
  let request = {
    type: 'establishment',
    query: `${query}+New+York`,
  };

  let map = new window.google.maps.Map(document.createElement('div'));
  let service = new window.google.maps.places.PlacesService(map);
  service.textSearch(request, (results, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      onSuccess(results);
    } else {
      onError && onError(status);
    }
  });
};
