import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import momentSv from 'moment/locale/sv';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import englishMessages from './translations/en.json';
import swedishMessages from './translations/sv.json';
import { unregister } from './registerServiceWorker';
import { registerDeepLinkHandler } from './registerDeepLinkHandler';
import { RouterWrapper } from './routes';
import { environmentNames } from './helpers';
import configureStore from './store/configure';
import { version as versionJSON } from '../package.json';

const env = process.env.REACT_APP_ENV;
console.info(`App version: ${versionJSON}, App env: ${env}`);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  environment: env,
  enabled: env !== environmentNames.development,
  tracesSampleRate: env === environmentNames.production ? 0.2 : 1,
});

// turn on react polifill https://levelup.gitconnected.com/why-you-should-add-babel-polyfill-for-every-react-application-1997bdb8a524
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Deep link handler
registerDeepLinkHandler();

// eslint-disable-next-line
import styles from './styles/index.css';
import { AppUpdater } from './components';

const store = configureStore();
const theme = createTheme({
  typography: {
    fontFamily: 'Noto Sans HK, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Noto Sans HK, sans-serif',
      },
    },
  },
  fontFamily: 'Noto Sans HK, sans-serif',
  palette: {
    primary: {
      main: '#212121',
    },
    white: {
      main: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
  },
});

const messages = {
  en: englishMessages,
  sv: swedishMessages,
};

const momentLocales = {
  sv: momentSv,
};

moment.tz.setDefault(process.env.REACT_APP_TIME_ZONE);
moment.locale(process.env.REACT_APP_LOCALE, momentLocales[process.env.REACT_APP_LOCALE]);

const Root = () => {
  return (
    <IntlProvider messages={messages[process.env.REACT_APP_LOCALE]} locale={process.env.REACT_APP_LOCALE}>
      <AppUpdater />
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <RouterWrapper store={store} />
        </MuiThemeProvider>
      </Provider>
    </IntlProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
unregister();
