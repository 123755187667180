class ProductTypesParams {

  constructor(props = {}) {
    this.producerIds = props.producerIds || '';
    this.shippingType = props.shippingType || '';
    this.shippingDate = props.shippingDate || '';
    this.shippingLocationId = props.shippingLocationId || '';
  
    this.productType = props.productType || '';
    this.subTypeSkip = props.subTypeSkip || 0;
    this.subTypeLimit = props.subTypeLimit || 20;
    this.productGroupLimit = props.productGroupLimit || 20;
    this.withProducts = props.withProducts || false;
    this.active = props.active || true;
  };

  toData = () => ({
    ...(this.producerIds ? {producerIds: this.producerIds} : {}),
    ...(this.shippingType ? {type: this.shippingType} : {}),
    ...(this.shippingDate ? {date: this.shippingDate} : {}),
    ...(this.shippingLocationId ? {locationId: this.shippingLocationId} : {}),
  
    ...(this.productType ? {productType: this.productType} : {}),
    ...(this.subTypeSkip ? {subTypeSkip: this.subTypeSkip} : {}),
    ...(this.subTypeLimit ? {subTypeLimit: this.subTypeLimit} : {}),
    ...(this.productGroupLimit ? {productGroupLimit: this.productGroupLimit} : {}),
    ...(this.withProducts ? {withProducts: this.withProducts} : {}),
    ...(this.active ? {active: this.active} : {}),
  
  })
}

export default ProductTypesParams



