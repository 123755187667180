import { draftConstants } from '../constants';
import { Product } from '../../entities';

const initialState = {
  object: false,
  draftObjectAfterComplete: {},
  shippingDialog: false,
  producerDialog: false,
  draggableDialog: false,
  shippingDialogForProduct: null,
  onShippingSelected: () => {},
  active: false,

  loading: false,
  completeLoading: false,
  addVariantLoading: false,
  addVariantQuantity: 0,
  shippingLoading: false,

  lastAddedVariant: false,
  lastAddedProduct: false,
  shippingOutdated: false,

  changeLocationProblemDialog: false,
  changeLocationProblemDialogMarket: null,

  clearShippingOptionsDialog: false,

  error: '',
  errorObject: {},
  shippingError: '',
  counter: 0,
  shippingTypeClientSide: '',
  shippingDateClientSide: '',

  errorSubscriptionModal: {
    open: false,
    actionOnDone: () => {},
    product: {},
    variant: {},
    title: '',
    content: '',
  },
  isPersonalProducerSelect: false,
  personalProducerSelectedId: '',
  personalProducerShippingOptions: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case draftConstants.DRAFT_ADD_VARIANT: {
      const { draft, variant, product } = action.payload;

      return {
        ...state,
        object: draft,
        lastAddedVariant: variant,
        lastAddedProduct: product,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        addVariantQuantity: 0,
      };
    }
    case draftConstants.DRAFT_DISMISS_LAST_ADDED: {
      return {
        ...state,
        lastAddedVariant: false,
        completeLoading: false,
        lastAddedProduct: false,
        loading: false,
        addVariantLoading: false,
        shippingLoading: false,
        addVariantQuantity: 0,
      };
    }
    case draftConstants.DRAFT_CHANGE: {
      const { draft } = action.payload;

      return {
        ...state,
        object: draft,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        shippingError: '',
      };
    }
    case draftConstants.DRAFT_COUNTER: {
      return {
        ...state,
        counter: ++state.counter,
      };
    }
    case draftConstants.DRAFT_CLEAR: {
      const { draft } = action.payload;

      return {
        ...state,
        object: draft,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        shippingError: '',
        personalProducerShippingOptions: {},
      };
    }
    case draftConstants.DRAFT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case draftConstants.DRAFT_ADD_VARIANT_LOADING: {
      return {
        ...state,
        addVariantLoading: true,
      };
    }
    case draftConstants.DRAFT_SHIPPING_LOADING: {
      return {
        ...state,
        shippingLoading: true,
      };
    }
    case draftConstants.DRAFT_COMPLETE_LOADING: {
      return {
        ...state,
        completeLoading: true,
      };
    }
    case draftConstants.DRAFT_ERROR: {
      const { error, errorObject } = action.payload;
      return {
        ...state,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        error: error,
        errorObject,
      };
    }
    case draftConstants.DRAFT_SHIPPING_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        shippingError: error,
      };
    }
    case draftConstants.DRAFT_DISMISS_ERROR: {
      return {
        ...state,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        error: '',
      };
    }
    case draftConstants.DRAFT_COMPLETE_ERROR: {
      const { error } = action.payload;
      let errors = state.object.errors.slice();
      errors.push(error);

      return {
        ...state,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        object: { ...state.object, errors },
      };
    }
    case draftConstants.DRAFT_COMPLETE: {
      const { newDraft, draftObjectAfterComplete } = action.payload;

      return {
        ...state,
        object: newDraft,
        draftObjectAfterComplete: draftObjectAfterComplete,

        //reset draft to initial after complete
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,

        lastAddedVariant: false,
        lastAddedProduct: false,

        error: '',
        counter: 0,

        shippingDialog: false,
        producerDialog: false,
        draggableDialog: false,
        shippingDialogForProduct: null,
        active: false,
        onShippingSelected: () => {},
        shippingOutdated: false,
        changeLocationProblemDialog: false,
        changeLocationProblemDialogMarket: null,
        changeLocationProblemDialogAfterClearAction: null,
        clearShippingOptionsDialog: false,
        errorObject: {},
        shippingError: '',
        shippingTypeClientSide: '',
        personalProducerShippingOptions: {},
      };
    }
    case draftConstants.DRAFT_SHIPPING_OUTDATED_NOTIFY: {
      return {
        ...state,
        shippingOutdated: true,
      };
    }
    case draftConstants.DRAFT_SHIPPING_OUTDATED_DISMISS: {
      return {
        ...state,
        shippingOutdated: false,
      };
    }
    case draftConstants.DRAFT_CLOSE: {
      return {
        ...state,
        active: false,
      };
    }
    case draftConstants.DRAFT_TOGGLE: {
      return {
        ...state,
        active: !state.active,
      };
    }
    case draftConstants.DRAFT_OPEN: {
      return {
        ...state,
        active: true,
        onSuccess: action.payload.onSuccess,
      };
    }
    case draftConstants.DRAFT_CLOSE_SHIPPING_DIALOG: {
      return {
        ...state,
        onShippingSelected: () => {},
        shippingDialogForProduct: null,
        shippingDialog: false,
      };
    }
    case draftConstants.DRAFT_OPEN_SHIPPING_DIALOG: {
      return {
        ...state,
        shippingDialogForProduct: action.payload.forProduct
          ? new Product(action.payload.forProduct, action.payload?.forVariant)
          : null,
        onShippingSelected: action.payload.onDone,
        shippingDialog: true,
      };
    }
    case draftConstants.DRAFT_OPEN_PRODUCER_DIALOG: {
      return {
        ...state,
        producerDialog: true,
      };
    }
    case draftConstants.DRAFT_CLOSE_PRODUCER_DIALOG: {
      return {
        ...state,
        producerDialog: false,
      };
    }
    case draftConstants.DRAFT_DRAGGABLE_OPEN: {
      return {
        ...state,
        draggableDialog: true,
      };
    }
    case draftConstants.DRAFT_DRAGGABLE_CLOSE: {
      return {
        ...state,
        draggableDialog: false,
      };
    }
    case draftConstants.DRAFT_OPEN_UNAVAILABLE_ITEMS_DIALOG: {
      return {
        ...state,
        unavailableItemsDialog: true,
      };
    }
    case draftConstants.DRAFT_CLOSE_UNAVAILABLE_ITEMS_DIALOG: {
      return {
        ...state,
        unavailableItemsDialog: false,
        object: action.payload,
      };
    }
    case draftConstants.DRAFT_OPEN_CHANGE_LOCATION_PROBLEM_DIALOG: {
      return {
        ...state,
        changeLocationProblemDialog: true,
        changeLocationProblemDialogMarket: action.payload.market,
        changeLocationProblemDialogAfterClearAction: { do: action.payload.do, onCancel: action.payload.onCancel },
      };
    }
    case draftConstants.DRAFT_CLOSE_CHANGE_LOCATION_PROBLEM_DIALOG: {
      return {
        ...state,
        changeLocationProblemDialog: false,
        changeLocationProblemDialogMarket: null,
        changeLocationProblemDialogAfterClearAction: null,
      };
    }
    case draftConstants.DRAFT_OPEN_CLEAR_SHIPPING_DIALOG: {
      return {
        ...state,
        clearShippingOptionsDialog: true,
      };
    }
    case draftConstants.DRAFT_CLOSE_CLEAR_SHIPPING_DIALOG: {
      return {
        ...state,
        clearShippingOptionsDialog: false,
      };
    }
    case draftConstants.DRAFT_SET_SHIPPING_TYPE_CLIENT_SIDE: {
      return {
        ...state,
        shippingTypeClientSide: action.payload.type,
      };
    }
    case draftConstants.DRAFT_CLEAR_SHIPPING_TYPE_CLIENT_SIDE: {
      return {
        ...state,
        shippingTypeClientSide: '',
      };
    }
    case draftConstants.ERROR_SUBSCRIPTION_MODAL_OPEN:
      return {
        ...state,
        errorSubscriptionModal: {
          open: true,
          product: action.payload.product,
          variant: action.payload.variant,
          actionOnDone: action.payload.actionOnDone,
          title: action.payload.title,
          content: action.payload.content,
        },
      };
    case draftConstants.ERROR_SUBSCRIPTION_MODAL_CLOSE:
      return {
        ...state,
        errorSubscriptionModal: {
          open: false,
          product: {},
          variant: {},
          actionOnDone: () => {},
          title: '',
          content: '',
        },
      };
    case draftConstants.SET_SHIPPING_DATE_CLIENT_SIDE:
      return {
        ...state,
        shippingDateClientSide: action.payload.date,
      };
    case draftConstants.SET_IS_PERSONAL_PRODUCER_SELECT: {
      return {
        ...state,
        isPersonalProducerSelect: !!action.payload.producerId,
        personalProducerSelectedId: action.payload.producerId,
      };
    }
    case draftConstants.SET_PERSONAL_PRODUCER_DRAFT_SHIPPING: {
      return {
        ...state,
        personalProducerShippingOptions: action.payload.personalProducerShippingOptions,
      };
    }
    case draftConstants.DRAFT_ON_ADD_VARIANT_QUANTITY: {
      return {
        ...state,
        addVariantQuantity: action.payload.quantity,
      };
    }
    default:
      return state;
  }
};

export default reducer;
