import { headerTextStyles } from '../../../../components';

const styles = () => ({
  wrapper: {
    paddingBottom: 37,
  },
  controls: {
    margin: '20px 0 20px 0px',
    textAlign: 'center',
    width: '100%',
  },
  addressWrapper: {
    marginBottom: 30,
    textAlign: 'left',
  },
  labelClass: {
    textAlign: 'left',
    marginBottom: 5,
  },
  control: {
    width: '100%',
    fontSize: 12,
    borderRadius: 40,
    textTransform: 'uppercase',
  },
  selectFormControl: {
    width: '100%',
    marginBottom: '20px !important',
    '& > div > div': {
      fontSize: 16,
    },
  },
  noHorizontalMargin: {
    marginLeft: 0,
    marginRight: 0,
  },
  pseudoInput: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 16,
    padding: '8px 0px 15px 0px',
  },
  tabsItem: {
    minWidth: 140,
    display: 'flex',
    minHeight: 'auto',
  },
  tabItem: {
    marginTop: 20,
    color: '#707070 !important',

    '& label': {
      fontSize: '16px !important',
      fontWeight: 700,
      transform: 'scale(1) !important',
      color: '#707070 !important',
    },
  },
  tabItemMargin: {
    margin: '30px 0',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  icon: {
    marginRight: 15,
  },
  checkoutTitle: {
    ...headerTextStyles.headerTextStylesFontOnWhite,
    color: '#484848',
    marginBottom: 35,
    fontWeight: 'bold',
  },
});

export default styles;
