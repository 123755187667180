import React from "react";
import PropTypes from "prop-types";
import withStyles from '@material-ui/core/styles/withStyles';
import { CheckoutStripeCreditCardForm } from '../../../../forms';
import styles from './styles';

const propTypes = {
  customer: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onFirst: PropTypes.func.isRequired,
};

class AddCreditCardStage extends React.Component {

  state = {
    error: ""
  };

  shouldSetPreference = () => {
    const {
      customer,
      onDone,
      onFirst
    } = this.props;

    if (customer.paymentMethods.length < 2 &&
      customer.allowCredit) {
      onFirst();
    } else {
      onDone();
    }
  };

  render() {
    const {
      onDone
    } = this.props;

    return (
      <React.Fragment>
          <CheckoutStripeCreditCardForm dialog={false}
                                        onAdded={this.shouldSetPreference}
                                        onClose={onDone}
          />
      </React.Fragment>
    );
  }
}

AddCreditCardStage.propTypes = propTypes;

export default withStyles(styles)(AddCreditCardStage);






