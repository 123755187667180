import { CUSTOMER_SET, CUSTOMER_LOADING, CUSTOMER_ERROR, CUSTOMER_SET_AVAILABLE_ADDRESSES } from '../constants';
import { saveCustomerData, checkNested, PAYMENT_AUTO, PAYMENT_METHODS } from '../../helpers';
import { Customer } from '../../entities';
import {
  apiCustomerPatchAccountInfo,
  apiCustomerPatch,
  // Addresses
  apiCustomerDeleteAddress,
  apiCustomerPatchAddress,
  apiCustomerAddAddress,
  // Payment methods
  apiCustomerAddPaymentMethod,
  apiCustomerDeletePaymentMethod,
  apiCustomerSetDefaultPaymentMethod,
  apiVerifyBankAccountWithPlaid,
  apiDraftAddressesValidation,
} from '../../api';
import { showNotification } from './common';

/**
 * Set initial customer info
 * (Uses to set customer after authentication)
 * @param {object} customer
 * @return {function(*)}
 */
export function setCustomer(customer) {
  return (dispatch) => {
    saveCustomerData(customer);
    dispatch({
      type: CUSTOMER_SET,
      payload: {
        customer: new Customer(customer),
      },
    });
  };
}

/**
 * Patch customer account info
 * @param {object} customer
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function patchCustomerAccountInfo(customer, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_LOADING });
    apiCustomerPatchAccountInfo(
      customer,
      data,
      (response) => {
        onSuccess && onSuccess();

        dispatch(setCustomer(response.data));
      },
      (error) => {
        onError && onError(error);

        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while changing customer account info',
          },
        });
      }
    );
  };
}

/**
 * Deletes customer address
 * @param id
 * @return {function(*)}
 */
export function deleteCustomerAddress(id) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_LOADING });
    apiCustomerDeleteAddress(
      id,
      (response) => {
        dispatch(setCustomer(response.data));
      },
      () => {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while deleting customer account info',
          },
        });
      }
    );
  };
}

/**
 * Patches customer address
 * @param {string} id
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function patchCustomerAddress(id, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_LOADING });
    apiCustomerPatchAddress(
      id,
      data,
      (response) => {
        //validate customer addresses if has location
        dispatch(updateAvailableAddresses());
        onSuccess && onSuccess();
        dispatch(setCustomer(response.data));
      },
      (error) => {
        onError && onError(error);
        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while patching customer address',
          },
        });
      }
    );
  };
}

/**
 * Add customer address
 * @param {string} type
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function addCustomerAddress(type, data, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_LOADING });
    apiCustomerAddAddress(
      type,
      data,
      (response) => {
        dispatch(setCustomer(response.data));
        onSuccess && onSuccess(response.data);
      },
      (error) => {
        onError && onError(error);
        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while adding customer address',
          },
        });
      }
    );
  };
}

/**
 * Add Stripe Bank Account for current customer
 * @param {object} stripe
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function addCustomerPaymentMethod(stripe, data, onSuccess, onError) {
  return (dispatch, getState) => {
    const customer = getState().customer.object;

    dispatch({ type: CUSTOMER_LOADING });

    stripe
      .createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: data.routing_number,
        account_number: data.account_number,
        account_holder_name: data.account_holder_name,
        account_holder_type: data.account_holder_type,
      })
      .then(function (resp) {
        if (typeof resp !== 'undefined' && resp.hasOwnProperty('error')) {
          onError && onError(resp.error.message);

          dispatch({
            type: CUSTOMER_ERROR,
            payload: {
              error: 'Something went wrong while adding customer payment method',
            },
          });
        } else {
          let requestData = { token: resp.token };
          if (!checkNested(customer, 'paymentMethods') && customer.paymentMethods.length === 0) {
            requestData.default = true;
          }

          apiCustomerAddPaymentMethod(
            PAYMENT_METHODS.bankAccount,
            requestData,
            (response) => {
              onSuccess && onSuccess();
              dispatch(setCustomer(response.data));
            },
            () => {
              onError && onError('Something went wrong while adding customer payment method');
              dispatch({
                type: CUSTOMER_ERROR,
                payload: {
                  error: 'Something went wrong while adding customer payment method',
                },
              });
            }
          );
        }
      });
  };
}

/**
 * Add Stripe credit card for customer
 * @param {object} stripe
 * @param {array} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function addStripeCreditCard(stripe, data, onSuccess, onError) {
  return (dispatch, getState) => {
    const customer = getState().customer.object;

    data = data || {};

    dispatch({ type: CUSTOMER_LOADING });
    stripe.createToken().then(function (payload) {
      if (typeof payload !== 'undefined' && payload.hasOwnProperty('error')) {
        onError && onError(payload.error.message);

        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while adding customer payment method',
          },
        });
      } else {
        data.token = payload.token;
        if (!checkNested(customer, 'paymentMethods') && customer.paymentMethods.length === 0) {
          data.default = true;
        }

        apiCustomerAddPaymentMethod(
          PAYMENT_METHODS.creditCard,
          data,
          (response) => {
            onSuccess && onSuccess();
            dispatch(setCustomer(response.data));
          },
          () => {
            onError && onError('Something went wrong while adding customer payment method');

            dispatch({
              type: CUSTOMER_ERROR,
              payload: {
                error: 'Something went wrong while adding customer payment method',
              },
            });
          }
        );
      }
    });
  };
}

/**
 * Verify customer payment method
 * @param {string} plaidToken
 * @param {object} plaidMetaData
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function verifyCustomerPaymentMethod(plaidToken, plaidMetaData, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_LOADING });
    apiVerifyBankAccountWithPlaid(
      plaidToken,
      plaidMetaData.account_id,
      (response) => {
        onSuccess && onSuccess();
        dispatch(setCustomer(response.data));
      },
      (error) => {
        onError && onError(error);

        if (error && error.response && error.response.data.code === 9100) {
          dispatch(showNotification(error.response.data.message));
        }

        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while verifying customer payment method',
          },
        });
      }
    );
  };
}

/**
 * Delete customer payment method
 * @param {object} paymentMethod
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function deleteCustomerPaymentMethod(paymentMethod, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_LOADING });
    apiCustomerDeletePaymentMethod(
      paymentMethod.id,
      (response) => {
        onSuccess && onSuccess();
        dispatch(setCustomer(response.data));
      },
      (error) => {
        onError && onError(error);
        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while deleting customer payment method',
          },
        });
      }
    );
  };
}

/**
 * Marks customer payment method as default
 * @param {object} paymentMethod
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function makeCustomerPaymentMethodDefault(paymentMethod, onSuccess, onError) {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_LOADING });
    apiCustomerSetDefaultPaymentMethod(
      paymentMethod.id,
      (response) => {
        onSuccess && onSuccess();
        dispatch(setCustomer(response.data));
      },
      (error) => {
        onError && onError(error);
        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while marking customer payment method as default',
          },
        });
      }
    );
  };
}

/**
 * Change customer payments handler
 * @param {string} handler
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*)}
 */
export function changeCustomerPaymentsHandler(handler, onSuccess, onError) {
  return (dispatch, getState) => {
    const customer = getState().customer.object;
    dispatch({ type: CUSTOMER_LOADING });

    const autoCharge = handler === PAYMENT_AUTO;
    apiCustomerPatch(
      customer.id,
      { autoCharge: autoCharge },
      (response) => {
        onSuccess && onSuccess();
        dispatch(setCustomer(response.data));
      },
      (error) => {
        onError && onError(error);
        dispatch({
          type: CUSTOMER_ERROR,
          payload: {
            error: 'Something went wrong while setting payments handler',
          },
        });
      }
    );
  };
}

export function setAvailableAddresses(availableAddresses) {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_SET_AVAILABLE_ADDRESSES,
      payload: {
        availableAddresses,
      },
    });
  };
}

export function updateAvailableAddresses(locationId, onSuccess, onError) {
  return (dispatch, getState) => {
    let locationIdToGet = locationId;

    if (!locationId) {
      const market = getState().market.object;
      locationIdToGet = market ? market.id : '';
    }

    if (!locationIdToGet) {
      setAvailableAddresses([]);
      onSuccess && onSuccess({ data: [] });
      return () => {};
    }

    apiDraftAddressesValidation(
      locationIdToGet,
      (response) => {
        dispatch(setAvailableAddresses(response.data));
        onSuccess && onSuccess(response);
      },
      onError
    );
  };
}
