const styles = {
  wrapper: {
    paddingBottom: 37,
  },
  controls: {
    margin: '0px 0 20px 0px',
    textAlign: 'center',
    width: '100%',
  },
  control: {
    borderRadius: 40,
    width: '100%',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  addresses: {
    textAlign: 'center',
    width: '100%',
  },
};

export default styles;
