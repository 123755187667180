export const MIN_NUMBER_OF_CATEGORIES_PER_ROW = 6;
export const MIN_HEIGHT_OF_CARD_BOX = 265;
export const MIN_WIDTH_OF_CARD_BOX = 245;
export const MIN_HEIGHT_OF_CARD_LIST_VIEW = 100;
export const BOX_VIEW_NUMBER_ITEMS_PER_ROW_VERTICAL = 1;
export const MIN_PRODUCTS_TO_LOAD_IN_GROUP_HORIZONTAL = 8;
export const MIN_PRODUCTS_TO_LOAD_VERTICAL = 20;


export function countCategoriesPerRow() {
  const hasWindow = typeof window !== 'undefined';
  const height = hasWindow ? window.innerHeight : null;
  
  const counted = Math.ceil(height / MIN_HEIGHT_OF_CARD_BOX * BOX_VIEW_NUMBER_ITEMS_PER_ROW_VERTICAL);
  return counted > MIN_NUMBER_OF_CATEGORIES_PER_ROW ? counted : MIN_NUMBER_OF_CATEGORIES_PER_ROW;
}

export function countColumnsOfProductsInGreed() {
  const hasWindow = typeof window !== 'undefined';
  const width = hasWindow ? window.innerWidth : null;
  
  const counted = Math.ceil(width / MIN_WIDTH_OF_CARD_BOX * 2);
  return counted > MIN_PRODUCTS_TO_LOAD_IN_GROUP_HORIZONTAL ? counted : MIN_PRODUCTS_TO_LOAD_IN_GROUP_HORIZONTAL;
}

export function countRowsProductsToLoadInLine() {
  const hasWindow = typeof window !== 'undefined';
  const height = hasWindow ? window.innerHeight : null;
  
  const counted = Math.ceil(height / MIN_HEIGHT_OF_CARD_LIST_VIEW * 2);
  return counted > MIN_PRODUCTS_TO_LOAD_VERTICAL ? counted : MIN_PRODUCTS_TO_LOAD_VERTICAL;
}