export const getSalesTax = (price, intl) => {
  price = (0.12 * (price > 0 ? price / 100 : 0)).toFixed(2);
  if (typeof intl !== 'undefined') {
    price = intl.formatNumber(price, {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: process.env.REACT_APP_CURRENCY,
    });
  }
  return price;
};

export const toCurrency = (price, intl, minimumFractionDigits) => {
  const absPrice = Math.abs(price);
  price = absPrice > 0 ? (absPrice / 100).toString() : price.toString();

  if (typeof intl !== 'undefined') {
    price = intl.formatNumber(price, {
      style: 'currency',
      minimumFractionDigits: minimumFractionDigits || 2,
      currency: process.env.REACT_APP_CURRENCY,
    });
  }
  return price;
};

export const toCoins = (price) => price * 100;

/**
 * Calculates percentage from some value
 * @param {number} percentage
 * @param {number} total
 * @param {number} decimals - count of decimals in result
 * @return {number}
 *
 * Example
 * getPercentage(5, 100)
 * result 5
 */
export const getPercentage = (percentage, total, decimals = 2) => {
  return ((percentage / 100) * total).toFixed(decimals);
};
