export function saveBlobAs(blob, file_name) {
  if (typeof navigator.msSaveBlob == 'function') return navigator.msSaveBlob(blob, file_name);

  let saver = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
  let blobURL = (saver.href = URL.createObjectURL(blob)),
    body = document.body;

  saver.download = file_name;

  body.appendChild(saver);
  saver.dispatchEvent(new MouseEvent('click'));
  body.removeChild(saver);
  URL.revokeObjectURL(blobURL);
}
