import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

export const headerTextStyles = {
  headerTextStylesFontOnWhite: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 25,
    fontWeight: 500,
    color: '#111',
    lineHeight: '34px',
    margin: 0,
    padding: 0,
  },
  headerTextStylesFontOnGreen: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 25,
    fontWeight: 500,
    color: '#000',
    lineHeight: '34px',
    margin: 0,
    padding: 0,
  },
};

const useStyles = makeStyles(() => headerTextStyles);

const HeaderText = (props) => {
  const { className, onGreen, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Typography
      className={classNames([
        onGreen ? classes.headerTextStylesFontOnGreen : classes.headerTextStylesFontOnWhite,
        className,
      ])}
      component="h2"
      {...otherProps}>
      {props.children}
    </Typography>
  );
};

HeaderText.propTypes = {
  onGreen: PropTypes.bool,
};

export default HeaderText;
