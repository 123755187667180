import {
  ORDER_ADD_VARIANT_LOADING,
  ORDER_ERROR,
  ORDER_DISMISS_ERROR,
  ORDER_DISMISS_LAST_ADDED,
  ORDER_ADD_VARIANT,
  ORDER_CHANGE,
  ORDER_LOADING,
  ORDER_TOGGLE,
  ORDER_OPEN,
  ORDER_CLOSE,
} from '../constants';

import {
  // Order
  apiOrderAddVariant,
  apiOrderRemoveLineItem,
  apiOrderRetrieveById,
  apiOrderSetNote,
  apiOrderLineItemSetNote,
  apiOrderLineItemSetQuantity,
} from '../../api';

const error = (dispatch, message) => {
  return () => {
    dispatch({
      type: ORDER_ERROR,
      payload: {error: message}
    });
  }
};

export function initOrder(id) {
  return (dispatch) => {
    // Getting last order order
    return new Promise((resolve, reject) => {
      apiOrderRetrieveById(id, (response) => {
        dispatch({
          type: ORDER_CHANGE,
          payload: {order: response.data}
        });

        resolve();
      }, () => {
        dispatch({
          type: ORDER_ERROR,
          payload: {error: "Something went wrong while getting order"}
        });
      });
    });
  }
}

export function refreshOrder(orderId, onSuccess) {
  return (dispatch) => {
    dispatch({type: ORDER_LOADING});
    // Getting last order order
    return apiOrderRetrieveById(orderId, (response) => {
      dispatch({
        type: ORDER_CHANGE,
        payload: {order: response.data}
      });

      onSuccess && onSuccess();
    }, () => {
      dispatch({
        type: ORDER_ERROR,
        payload: {error: "Something went wrong while refreshing order"}
      });
    });
  }
}

/**
 * Adds variant to order
 * @param {object} product
 * @param {object} variant
 * @param {function} onSuccess
 * @return {function(*=, *)}
 */
export function addVariantToOrder(product, variant, onSuccess) {
  return (dispatch, getState) => {
    const order = getState().order.object;

    dispatch({
      type: ORDER_ADD_VARIANT_LOADING,
      payload: {
        product: product,
        variant: variant
      }
    });

    const onAdded = (order) => {
      dispatch({
        type: ORDER_ADD_VARIANT,
        payload: {
          order: order,
          product: product,
          variant: variant
        }
      });
    };

    const addVariant = (or, vr) => {
      apiOrderAddVariant(or, vr, {}, (response) => {
        onAdded(response.data, variant);
        onSuccess && onSuccess(response.data);
      }, error(dispatch, "Something went wrong while adding variant to order"))
    };

    if (order) {
      // Order exists
      addVariant(order.id, variant.id);
    } else {
      error(dispatch, "Order was not found");
    }
  }
}

export function deleteOrderLineItem(item, onSuccess) {
  return (dispatch, getState) => {
    const order = getState().order.object;
    dispatch({type: ORDER_LOADING});
    apiOrderRemoveLineItem(order.id, item.id, (response) => {

      onSuccess && onSuccess(response.data);

      dispatch({
        type: ORDER_CHANGE,
        payload: {
          order: response.data
        }
      });
    }, error(dispatch, "Something went wrong while deleting product from cart"))
  }
}

export function setOrderLineItemNote(note, item) {
  return (dispatch, getState) => {
    const order = getState().order.object;
    dispatch({type: ORDER_LOADING});
    apiOrderLineItemSetNote(order.id, item.id, note, (response) => {
      dispatch({
        type: ORDER_CHANGE,
        payload: {
          order: response.data
        }
      });
    }, error(dispatch, "Something went wrong while setting line item note"))
  }
}

export function setOrderLineItemQuantity(item, quantity, onDone, onError) {
  return (dispatch, getState) => {
    const order = getState().order.object;
    dispatch({type: ORDER_LOADING});
    apiOrderLineItemSetQuantity(order.id, item.id, quantity, (response) => {

      onDone && onDone();

      dispatch({
        type: ORDER_CHANGE,
        payload: {
          order: response.data
        }
      });
    }, () => {
      error(dispatch, "Something went wrong while setting line item quantity");
      onError && onError();
    })
  }
}

export function setOrderNote(note) {
  return (dispatch, getState) => {
    const order = getState().order.object;
    dispatch({type: ORDER_LOADING});
    apiOrderSetNote(order.id, note, (response) => {
      dispatch({
        type: ORDER_CHANGE,
        payload: {
          order: response.data
        }
      });
    }, error(dispatch, "Something went wrong while setting line item quantity"))
  }
}

export function dismissOrderError() {
  return (dispatch) => {
    dispatch({type: ORDER_DISMISS_ERROR});
  }
}

export function dismissOrderLastAdded() {
  return (dispatch) => {
    dispatch({type: ORDER_DISMISS_LAST_ADDED});
  }
}

export function orderToggle() {
  return (dispatch) => {
    dispatch({type: ORDER_TOGGLE});
  };
}

export function orderOpen() {
  return (dispatch) => {
    dispatch({type: ORDER_OPEN});
  };
}

export function orderClose() {
  return (dispatch) => {
    dispatch({type: ORDER_CLOSE});
  };
}

