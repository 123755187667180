export default class BreadcrumbsConfig {
  constructor(props) {
    this.customBreadcrumbs = props?.customBreadcrumbs || false;
    this.initial = props?.initial || { from: `/`, name: '' };
    this.type = props?.type || '';
    this.subType = props?.subType || '';
  }

  getFrom = () => {
    return this.initial?.from;
  };
  setType = (type) => {
    this.type = type;
  };
  setSubType = (subType) => {
    this.subType = subType;
  };

  getObj = () => {
    return { customBreadcrumbs: this.customBreadcrumbs, initial: this.initial, type: this.type, subType: this.subType };
  };
}
