const styles = (theme) => ({
  root: {
    transition: `border 0.5s ease`,
    borderBottom: `1px solid rgba(112, 112, 112, 0.25)`,
  },
  focused: {
    borderBottom: `1px solid rgba(112, 112, 112, 1)`,
  },
  searchLabel: {
    textAlign: 'center',
    fontSize: 12,
  },
  searchIcon: {
    fontSize: 10,
    marginLeft: -10,
    marginRight: 0,
    color: '#362E24',
  },
  icon: {
    fontSize: 20,
    color: '#362E24',
  },
  searchShippingAddressWrapper: {},
  addressNotification: {
    fontSize: 16,
    marginTop: 20,
    marginBottom: 20,
    color: '#ff0000',
  },
  searchInput: {
    marginRight: 15,
    marginLeft: 5,
    height: 'auto',
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  resultWrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
});

export default styles;
