import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder, replaceUrlPlaceholders } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Get list of orders
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderGetList = function (data, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.ORDER_GET_LIST);

  config.params = {
    ...data,
    limit: data.limit || 10,
    skip: data.skip || 0,
  };

  if (data.producerId) {
    config.params['producerId'] = data.producerId;
  }

  if (data.financialStatus) {
    config.params['financialStatus'] = data.financialStatus;
  }

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Pay for an order
 * @param {array} order
 * @param {string} paymentMethodId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderPay = function (order, paymentMethodId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_PAY, 'or', order.id);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);

  config.data = {
    hash: order.hash,
    paymentMethodId: paymentMethodId,
  };

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Reorder order
 * @param {object} order
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderReorder = function (order, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_REORDER, 'or', order.id);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Retrieve order
 * @param {object} order
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderRetrieve = function (order, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_RETRIEVE, 'or', order.id);
  let config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Retrieve order by id
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderRetrieveById = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_RETRIEVE, 'or', orderId);
  let config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds variant to cart
 * @param {string} orderId
 * @param {string} variantId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderAddVariant = function (orderId, variantId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_ADD_VARIANT, 'or', orderId);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  data = data || {};
  data.variantId = variantId;
  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Removes line item from order
 * @param {string} orderId
 * @param {string} lineItemId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderRemoveLineItem = function (orderId, lineItemId, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.ORDER_REMOVE_LINE_ITEM, {
    or: orderId,
    it: lineItemId,
  });
  axios(getRequestConfig(REQUEST_TYPES.DEL, url))
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Adds note on order
 * @param {string} orderId
 * @param {string} note
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderSetNote = function (orderId, note, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_ADD_NOTE, 'or', orderId);
  let config = getRequestConfig(REQUEST_TYPES.PUT, url);
  config.data = { note: note };
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Remove all line items from order
 * @param {object} order
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderRemoveLineItems = function (order, onSuccess, onError) {
  if (order.lineItems.length) {
    const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_CLEAR, 'or', order.id);
    let config = getRequestConfig(REQUEST_TYPES.DEL, url);
    axios(config)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  } else {
    onSuccess && onSuccess();
  }
};

/**
 * Add variants to order
 * @param {object} order
 * @param {array} lineItems
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderAddVariants = function (order, lineItems, onSuccess, onError) {
  if (lineItems.length) {
    let requests = [];
    lineItems.forEach(({ items }) => {
      items.forEach((item) => {
        if (item.available) {
          const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_ADD_VARIANT, 'or', order.id);
          let config = getRequestConfig(REQUEST_TYPES.POST, url);
          let data = {
            note: item.note,
            quantity: item.orderedQuantity,
            variantId: item.variantId,
          };
          requests.push(axios.post(config.url, data, config));
        }
      });
    });

    Promise.all(requests)
      .then((...res) => {
        onSuccess && onSuccess(res);
      })
      .catch((error) => {
        onError && onError(error);
      });
  } else {
    onSuccess && onSuccess();
  }
};

/**
 * Resend email
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderResendEmail = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_RESEND_EMAIL, 'or', orderId);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  axios(config)
    .then(() => {
      onSuccess();
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * fetch driver's location for order by customer
 * @param {string} orderId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderGetDriverLocation = function (orderId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.ORDER_GET_DRIVER_LOCATION, 'or', orderId);
  let config = getRequestConfig(REQUEST_TYPES.GET, url);
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
