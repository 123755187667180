import moment from 'moment';
import { getStore } from '../store/configure';
import { getLineItemQuantity } from './weight';

export const getHumanReadableShipping = (draft, intl) => {
  const { shipping } = draft;
  if (shipping) {
    const type = intl.formatMessage({ id: 'global.' + shipping.type });
    const location = (shipping.location && shipping.location.name) || '';
    const date = moment.parseZone(shipping.date).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK);
    return `${type}${location && ' / ' + location} / ${date}`;
  }
  return '';
};

export const getHumanReadableShippingDateAndType = (draft, intl) => {
  const { shipping } = draft;

  if (shipping) {
    const type = intl.formatMessage({ id: 'global.' + shipping.type });
    const date = moment.parseZone(shipping.date).format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK);
    return `${type} ${date}`;
  }
  return '';
};

/**
 * Determines quantity of product variant in draft
 * @param {object} draft
 * @param {object} variant
 * @return {number}
 */
export const getQuantityByVariant = (draft, variant) => {
  let quantity = 0;
  draft.lineItems.forEach(({ items }) => {
    items.forEach((item) => {
      if (variant.id === item.variantId) {
        quantity += getLineItemQuantity(item);
      }
    });
  });
  return quantity;
};

/**
 * Get line item by variant
 * @param {object} draft
 * @param {object} variant
 * @return {number|null}
 */
export const getLineItemByVariant = (draft, variant) => {
  let lineItem = null;
  for (let i = 0; i < draft.lineItems.length; i++) {
    for (let j = 0; j < draft.lineItems[i].items.length; j++) {
      if (variant.id === draft.lineItems[i].items[j].variantId) {
        lineItem = draft.lineItems[i].items[j];
        break;
      }
    }
  }
  return lineItem;
};

/**
 * Get quantity Draft Items
 * @param {object} draft
 * @return {number|null}
 */
export const getQuantityDraftItems = (draft) => {
  let itemsNumber = new Set();

  draft.lineItems.forEach((items) => {
    items.items.forEach((item) => {
      itemsNumber.add(item);
    });
  });
  return itemsNumber.size;
};

/**
 * Whether order has available line items or not
 * @return {boolean}
 */
export const hasAvailableLineItems = (order) => {
  let has = false;
  for (let i = 0; i < order.lineItems.length; i++) {
    for (let j = 0; j < order.lineItems[i].items.length; j++) {
      if (order.lineItems[i].items[j].available) {
        has = true;
        break;
      }
    }
  }
  return has;
};

/**
 * Whether order has not available line items or not
 * @return {boolean}
 */
export const hasNotAvailableLineItems = (order) => {
  let has = false;
  for (let i = 0; i < order.lineItems.length; i++) {
    for (let j = 0; j < order.lineItems[i].items.length; j++) {
      if (!order.lineItems[i].items[j].available) {
        has = true;
        break;
      }
    }
  }
  return has;
};

/** @returns {object} */
export const getCurrentDraft = () => {
  const store = getStore();
  const state = store.getState();
  return state.draft.object;
};

export const hasAvailabilities = (draft) => {
  return !!draft?.customAvailabilityLineItems?.length;
};

export function isDraftHasItems(draft) {
  return !!draft?.lineItems?.length;
}

export const hasShippingOptions = (draft) => {
  return !!(draft?.shipping?.location?.id && draft?.shipping?.date && draft?.shipping?.type);
};

export const hasAnyShippingOptions = () => {
  const state = getStore().getState();
  const draft = state.draft.object;
  const clientSideLocation = state.market.object;
  const shippingTypeClientSide = state.draft.shippingTypeClientSide;
  const shippingDateClientSide = state.draft.shippingDateClientSide;

  return (
    !!draft?.shipping?.location?.id ||
    !!draft?.shipping?.date ||
    !!draft?.shipping?.type ||
    !!clientSideLocation ||
    !!shippingTypeClientSide ||
    !!shippingDateClientSide
  );
};
