import {
  DRAFTS_LATS_ERROR
} from '../constants';

/**
 * Set active hit
 * @param {object, array} draftError
 * @return {function(*)}
 */
export function updateDraftError(draftError) {
  return dispatch => {
    dispatch({
      type: DRAFTS_LATS_ERROR,
      payload: {
        draftError
      }
    });
  }
}