import {
  DRAFTS_LATS_ERROR
} from '../constants';

const initialState = {
  draftError: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DRAFTS_LATS_ERROR : {
      const {draftError} = action.payload;
      return {
        ...state,
        draftError
      }
    }
    default:
      return state;
  }
};

export default reducer;

