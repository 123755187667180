import {
  HIT_SET, HIT_UNSET
} from '../constants';

/**
 * Set active hit
 * @param {object} hit
 * @param {object} variant
 * @return {function(*)}
 */
export function hitSet(hit, variant) {
  return dispatch => {
    dispatch({
      type: HIT_SET,
      payload: {
        hit,
        variant
      }
    });
  }
}

export function hitUnset() {
  return dispatch => {
    dispatch({
      type: HIT_UNSET,
    });
  }
}