import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Route } from 'react-router';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import classNames from 'classnames';

const styles = () => ({
  root: {
    color: 'black',
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    '& svg': {
      height: 24,
    },

    '& a': {
      textDecoration: 'none',
      fontSize: 12,
      color: 'black',
      fontFamily: 'Noto Sans HK',
    },

    '& a:visited': {
      textDecoration: 'none',
      fontSize: 12,
      color: 'black',
    },

    '& li': {
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      height: 24,
    },

    '& p': {
      fontSize: 12,
      fontFamily: 'Noto Sans HK',
    },
  },
});

const BreadcrumbsWrapper = ({ classes, children, rootWrapperClass }) => {
  return (
    <div className={classNames([classes.root, rootWrapperClass])}>
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {children}
        </Breadcrumbs>
      </Route>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(injectIntl(BreadcrumbsWrapper));
