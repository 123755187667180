import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import styles from './styles';

const propTypes = {
  desc: PropTypes.any,
};

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Noto Sans HK', sans-serif",
        fontWeight: 400,
        fontSize: 12,
        background: 'linear-gradient(90deg, #5BD3A1, #41CD8C, #0BB998)',
        borderRadius: 33,
        border: 0,
        color: 'rgba(255,255,255, 0.87)',
        height: 37,
        padding: '0 37px !important',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
      },
    },
  },
});

class PrimaryButton extends React.Component {
  render = () => {
    const { classes, desc, className, ...other } = this.props;
    let wrapperClasses = {};
    wrapperClasses[classes.descWrapper] = !!desc;
    if (className) {
      wrapperClasses[className] = true;
    }

    let styles = {};
    if (desc) {
      styles.height = 'auto';
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Button style={styles} className={classNames(wrapperClasses)} {...other}>
          {this.props.children}
          {desc && <span className={classes.desc}>{desc}</span>}
        </Button>
      </MuiThemeProvider>
    );
  };
}

PrimaryButton.propTypes = propTypes;

export default withStyles(styles)(PrimaryButton);
