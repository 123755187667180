import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Noto Sans HK', sans-serif",
        fontWeight: 400,
        fontSize: 12,
        background: '#ffffff',
        borderRadius: 33,
        border: '1px solid #41CD8C',
        color: '#41CD8C',
        height: 37,
        padding: '0 37px !important',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
      },
    },
  },
});

class SecondaryButton extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme}>
      <Button {...this.props}>{this.props.children}</Button>
    </MuiThemeProvider>
  );
}

export default SecondaryButton;
