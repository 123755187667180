import { getStore } from '../store/configure';
import { saveObjectToStorage, getObjectFromStorage } from './storage';

/** @returns {object} */
export const getSelectedProducers = () => {
  const store = getStore();
  const state = store.getState();

  return state.producer.selectedProducers;
};

/**
 * Save selected producers to local storage
 * @param {array} producers
 */
export function saveSelectedProducersToLocalStorage(producers) {
  return saveObjectToStorage('producers.data', producers);
}

/** Get selected producers from local storage */
export function getSelectedProducersFromLocalStorage() {
  return getObjectFromStorage('producers.data');
}

/** Remove selected producers from local storage */
export function removeSelectedProducersFromLocalStorage() {
  return localStorage.removeItem('producers.data');
}

/**
 * Save producer to known list
 * @param {string} producerId
 */
export function markProducerAsKnown(producerId) {
  let known = getKnownProducers();
  if (!known.some((id) => producerId === id)) {
    known.push(producerId);
    saveObjectToStorage('producers.known', known);
  }
}

/**
 * Checks whether producer is unknown
 * @param {string} producerId
 */
export function isProducerKnown(producerId) {
  let known = getKnownProducers();
  return known.some((id) => producerId === id);
}

/**
 * Returns known to customer producers
 * @return {array} known to customer producers
 */
export function getKnownProducers() {
  return getObjectFromStorage('producers.known') || [];
}

/**
 * Return true if all producers in array have allowRetailCredit
 * @param producers - arrayOfProducers
 */
export function isCreditAllowed(producers) {
  return producers.every((producer) => producer.creditAllowed);
}

/**
 * Get credit description from checkout array
 * @param producers - rrayOfProducers
 * @returns {boolean|*}
 */
export function getCreditDescription(producers) {
  const producerWithDescription = producers.find((producer) => !!producer.creditDescription);
  return !!producerWithDescription && producerWithDescription.creditDescription;
}

export function isNorwichMeadows(producer) {
  return producer?.id === 've_5afc76bd9a1c2625520813e4';
}
