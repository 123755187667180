import {
  STRIPE_ACH,
  PAYMENT_METHOD_VERIFIED,
} from '../helpers';

export const paymentMethodBrandToAbbr = (paymentMethod, empty) => {
  empty = paymentMethod.bankName || empty || "";

  if (paymentMethod.brand === 'MasterCard') {
    return "MC";
  }

  if (paymentMethod.brand === 'Visa') {
    return "VISA";
  }

  if (paymentMethod.brand === 'American Express') {
    return "AMEX";
  }

  return empty;
};

export const isPaymentMethodVerified = (paymentMethod) => {
  if (paymentMethod && paymentMethod.method === STRIPE_ACH) {
    return paymentMethod.status === PAYMENT_METHOD_VERIFIED
  }
  return true;
};

/**
 * Checks whether payment method is bank account
 * @param paymentMethod
 * @return {boolean}
 */
export const isBankAccount = (paymentMethod) => (
  paymentMethod.method === 'stripe_ach'
);

/**
 * Checks whether payment method is credit card
 * @param {object} paymentMethod
 * @return {boolean}
 */
export const isCreditCard = (paymentMethod) => (
  paymentMethod.method === 'stripe_card'
);