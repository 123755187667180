import {
	productConstants
} from '../constants';
import {
	ProductTypes,
	Products,
	ProductsGroupedBySubTypes,
	SubtypesGroupedByType
} from '../../entities';

const initialState = {

	// Types
	types: new ProductTypes([]),
	typesError: '',
	typesLoading: false,

	// Products
	products: new Products([]),
	productsError: '',
	productsLoading: false,

	// Products grouped by sub types
	productsGroupedBySubTypes: new ProductsGroupedBySubTypes(),
	productsGroupedBySubTypesError: '',
	productsGroupedBySubTypesLoading: false,
	
	// Subtypes grouped by type
	subtypesGroupedByType: new SubtypesGroupedByType(),
	subtypesGroupedByTypeError: '',
	subtypesGroupedByTypeLoading: false,

};

const reducer = (state = initialState, action) => {

	switch (action.type) {

		// Types
		case productConstants.GET_PRODUCTS_TYPES_LOADING:
			return {...state, types: new ProductTypes([]), typesLoading: true};
		case productConstants.SET_PRODUCTS_TYPES:
			return {...state, types: new ProductTypes(action.payload.types), typesLoading: false};
		case productConstants.GET_PRODUCTS_TYPES_ERROR:
			return {...state, typesError: action.payload.error, typesLoading: false};
		case productConstants.CLEAR_PRODUCTS_TYPES_ERROR:
			return {...state, typesError: ''};

		// Products
		case productConstants.SEARCH_PRODUCTS_LOADING:
			return {...state, products: new Products([]), productsLoading: true};
		case productConstants.SET_PRODUCTS:
			return {...state, products: new Products(action.payload.products), productsLoading: false};
		case productConstants.SEARCH_PRODUCTS_ERROR:
			return {...state, productsError: action.payload.error, productsLoading: false};
		case productConstants.CLEAR_SEARCH_PRODUCTS_ERROR:
			return {...state, productsError: ''};

		// Products grouped by subtype
		case productConstants.SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_LOADING:
			return {...state, productsGroupedBySubTypes: new ProductsGroupedBySubTypes(), productsGroupedBySubTypesLoading: true};
		case productConstants.SET_PRODUCTS_GROUPED_BY_SUBTYPE:
			return {...state, productsGroupedBySubTypes: new ProductsGroupedBySubTypes(action.payload.groups), productsGroupedBySubTypesLoading: false};
		case productConstants.SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_ERROR:
			return {...state, productsGroupedBySubTypesError: action.payload.error, productsGroupedBySubTypesLoading: false};
		case productConstants.CLEAR_SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_ERROR:
			return {...state, productsGroupedBySubTypesError: ''};
			
		// Subtypes grouped by type
		case productConstants.GET_SUBTYPES_BY_TYPE_LOADING:
			return {...state, subtypesGroupedByType: new SubtypesGroupedByType(), subtypesGroupedByTypeLoading: true};
		case productConstants.SET_SUBTYPES_BY_TYPE:
			return {...state, subtypesGroupedByType: new SubtypesGroupedByType(action.payload.types), subtypesGroupedByTypeLoading: false};
		case productConstants.GET_SUBTYPES_BY_TYPE_ERROR:
			return {...state, subtypesGroupedByTypeError: action.payload.error, subtypesGroupedByTypeLoading: false};
		case productConstants.CLEAR_SUBTYPES_BY_TYPE_ERROR:
			return {...state, subtypesGroupedByTypeError: ''};

		default:
			return state;
	}
};

export default reducer;