import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ErrorBox = ({ error, className, classes, onClose, actionComponent }) => {
  return error ? (
    <SnackbarContent
      className={classNames(classes.error, className)}
      message={error}
      {...(onClose
        ? actionComponent
          ? { action: actionComponent }
          : {
              action: (
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              ),
            }
        : {})}
    />
  ) : null;
};

ErrorBox.propTypes = {
  error: PropTypes.any,
  className: PropTypes.string,
  onClose: PropTypes.func,
  actionComponent: PropTypes.element,
};

export default withStyles(styles)(ErrorBox);
