import ShippingEntity from './ShippingEntity';
import { specialProducersMinPriceConfig } from '../helpers';

class OrderEntity {
  static DELIVERY_IS_NOT_AVAILABLE_ERROR_CODE = 4037;

  constructor(props = {}) {
    this.raw = props || {};
  }

  /**
   * Checks whether draft has location
   * @param {object} order
   * @return {boolean}
   */
  static hasLocation(order) {
    return order.shipping && order.shipping.location;
  }

  /**
   * Checks whether draft has location
   * @param {object} order
   * @return {boolean}
   */
  static getLocation(order) {
    return order?.shipping?.location;
  }

  static getServiceCharge(order) {
    let serviceChargeFee = 0;

    if (Array.isArray(order.payments) && order.payments.length) {
      order.payments.forEach((payment) => {
        serviceChargeFee += payment.serviceChargeFee || 0;
      });
    }

    return serviceChargeFee;
  }

  static getOrderGrandTotal(order) {
    let total = 0;

    if (Array.isArray(order.payments) && order.payments.length) {
      order.payments.forEach((payment) => {
        total += payment.amountTotal;
      });
    } else {
      total = order.total;
    }

    return total;
  }

  static getOrdersListGrandTotal(order) {
    let total = 0;

    if (Array.isArray(order.payments) && order.payments.length) {
      order.payments.forEach((payment) => {
        total += payment.amount;
      });
    } else {
      total = order.total;
    }

    return total;
  }

  static getShipping(order) {
    return new ShippingEntity(order.shipping);
  }

  // Calculates count of line items added on order|draft
  static getLineItemsCount(order) {
    let count = 0;
    order.lineItems.forEach(({ items }) => {
      count += items.length;
    });
    return count;
  }

  /**
   * Checks whether order has any errors
   * @param {object} order
   * @return {boolean}
   */
  static hasErrors(order) {
    return (order.errors || []).length > 0;
  }

  /**
   * Fetches error by code
   * @param {object} order
   * @param {int} code
   * @return {string}
   */
  static getErrorByCode(order, code) {
    const error = (order.errors || []).find((e) => e.code === code);

    return error ? error.message : '';
  }

  /**
   * Fetches error extra param by code and param name
   * @param {object} order - order object
   * @param {int} code - error code
   * @param {string} paramName - parameter name
   * @return {*|boolean}
   */
  static getErrorExtraParamByCode(order, code, paramName) {
    const error = (order.errors || []).find((e) => e.code === code),
      extra = error.extra || {};
    return extra[paramName] ? extra[paramName] : false;
  }

  /**
   * Checks whether order has problems with delivery
   * @param {object} order
   * @return {boolean}
   */
  static isDeliveryNotAvailable(order) {
    return (
      OrderEntity.hasErrors(order) &&
      order.errors.some((e) => e.code === OrderEntity.DELIVERY_IS_NOT_AVAILABLE_ERROR_CODE)
    );
  }

  /**
   * Checks whether draft|order total meet minimum required amount
   * @param {object} order
   * @return {object}
   */
  static isMeetToMinimumRequiredAmount(order) {
    const config = specialProducersMinPriceConfig;
    let minProducersPrice = process.env.REACT_APP_MINIMUM_ORDER_AMOUNT;

    if (order?.lineItems?.length === 1) {
      const currentProducer = order?.lineItems[0]?.producer?.id;
      const currentProducerConfig = config && currentProducer && config[currentProducer];

      if (!!currentProducerConfig) {
        minProducersPrice = currentProducerConfig;
      }

      // Sometime discount might be 100% (When we have something is cart but the total is equals to zero)
      if (order.total === 0) {
        return {
          isMeet: true,
          minPrice: minProducersPrice,
        };
      }
    }

    return {
      isMeet: order.total >= parseInt(minProducersPrice) * 100,
      minPrice: minProducersPrice,
    };
  }

  /**
   * Whether order has not available line items or not
   * @param {object} order
   * @return {boolean}
   */
  static hasNotAvailableLineItems = (order) => {
    let has = false;
    for (let i = 0; i < order.lineItems.length; i++) {
      for (let j = 0; j < order.lineItems[i].items.length; j++) {
        if (!order.lineItems[i].items[j].available) {
          has = true;
          break;
        }
      }
    }

    return has;
  };

  /**
   * Checks whether draft has problems with shipping
   * @param {object} order
   * @return {boolean}
   */
  static hasProblemsWithShipping = (order) => {
    return false;
  };

  /**
   * Checks whether two orders has same shipping
   * @param oldOrder
   * @param newOrder
   * @return {boolean}
   */
  static areShippingOptionsEquals(oldOrder, newOrder) {
    const oldShipping = oldOrder.shipping,
      newShipping = newOrder.shipping;

    // Check shipping options for existing (fix the error)
    if (!oldShipping || !newShipping) {
      return false;
    }

    const oldLocation = oldShipping.location,
      newLocation = oldShipping.location;

    return (
      (oldLocation ? oldLocation.id : '') === (newLocation ? newLocation.id : '') &&
      oldShipping.date === newShipping.date &&
      oldShipping.method === newShipping.method
    );
  }

  static getShippingType(draft) {
    return draft?.shipping?.type || '';
  }

  static getShippingDate(draft) {
    return draft?.shipping?.date || '';
  }
}

export default OrderEntity;
