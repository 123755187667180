import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  Spinner,
  ErrorBox,
  StripePaymentCardsRadioGroupItemLabel,
  StripeBankAccountsRadioGroupItemLabel,
  BlackRadio,
  PrimaryFormLabel,
  ThirdButton,
  PlaidLink,
  Icon,
} from '../../../components';
import {
  customerGetBankAccounts,
  customerGetCreditCards,
  customerGetPaymentMethodById,
  PAYMENT_METHODS,
  getProducersWithoutCredit,
} from '../../../helpers';
import { CHECKOUT_STAGES } from '../../../helpers/constants';

import styles from './styles';
import { openCheckout } from '../../../store/actions';
import { getStore } from '../../../store/configure';

const propTypes = {
  order: PropTypes.object.isRequired,
  allowMethods: PropTypes.array,
  onChange: PropTypes.func,
  onVerifyBankAccount: PropTypes.func.isRequired,
  onAddCreditCard: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  onPlaidOpen: PropTypes.func.isRequired,
  onShowServiceChargeInfo: PropTypes.func.isRequired,
  creditDescriptionsByMethod: PropTypes.object,
};

const defaultProps = {
  allowMethods: [PAYMENT_METHODS.check, PAYMENT_METHODS.cod, PAYMENT_METHODS.creditCard, PAYMENT_METHODS.bankAccount],
  creditDescriptionsByMethod: {},
};

class CheckoutPaymentMethodsRadioGroup extends React.Component {
  constructor(props) {
    super(props);

    let paymentMethod = '';
    let paymentMethodId = '';

    if (props.order && props.order.payment && props.order.payment.method) {
      paymentMethod = props.order.payment.method.method;
      paymentMethodId = props.order.payment.method.id;
    }

    props.onChange && props.onChange(paymentMethod, paymentMethodId);

    this.state = {
      selectedPaymentMethod: paymentMethodId ? paymentMethodId : '',
      paymentMethod: paymentMethod,
      paymentMethodId: paymentMethodId,
      error: '',
      loading: false,
    };
  }

  render() {
    const {
      classes,
      customer,
      onChange,
      allowMethods,
      onAddCreditCard,
      onVerifyBankAccount,
      onPlaidOpen,
      intl,
      order,
      onShowServiceChargeInfo,
      creditDescriptionsByMethod,
    } = this.props;

    const { loading, error, selectedPaymentMethod } = this.state;

    const producersWithoutCredit = getProducersWithoutCredit(order, customer);
    const showCreditMessage = !!producersWithoutCredit.length && order.producers.length > 1;

    return (
      <FormControl className={classes.formControl}>
        {loading && <Spinner />}
        <ErrorBox error={error} />

        <PrimaryFormLabel style={{ marginBottom: 10 }} className={classes.alignLeft}>
          {intl.formatMessage({ id: 'payment.method' })}
        </PrimaryFormLabel>

        <RadioGroup
          aria-label="payment-method"
          name="payment-method"
          style={showCreditMessage ? { width: '70%' } : { width: '110%' }}
          className={classes.select}
          value={selectedPaymentMethod}
          onChange={(e) => {
            const paymentMethod = customerGetPaymentMethodById(customer, e.target.value);

            this.setState(
              {
                addingCreditCard: false,
                addingBankAccount: false,
                selectedPaymentMethod: paymentMethod ? paymentMethod.id : e.target.value,
                paymentMethodId: paymentMethod ? paymentMethod.id : '',
                paymentMethod: paymentMethod ? paymentMethod.method : e.target.value,
              },
              () => {
                onChange && onChange(this.state.paymentMethod, this.state.paymentMethodId);
              }
            );
          }}>
          {allowMethods.indexOf(PAYMENT_METHODS.check) !== -1 && (
            <FormControl>
              <FormControlLabel
                value={PAYMENT_METHODS.check}
                disabled={!!producersWithoutCredit.length}
                control={<BlackRadio color="primary" />}
                label={<FormattedMessage id="global.onAccount" />}
              />

              {showCreditMessage && (
                <FormHelperText style={{ marginLeft: 35, marginTop: 0 }}>
                  {intl.formatMessage({ id: 'messages.producerDoesNotAllowCredit1' })}
                  {producersWithoutCredit.map((p) => (
                    <FormHelperText style={{ marginTop: 10, marginBottom: 10 }}>{p.name}</FormHelperText>
                  ))}
                  {intl.formatMessage({ id: 'messages.producerDoesNotAllowCredit2' })}
                </FormHelperText>
              )}
            </FormControl>
          )}

          {!!!producersWithoutCredit.length && allowMethods.indexOf(PAYMENT_METHODS.cod) !== -1 && (
            <FormControlLabel
              value={PAYMENT_METHODS.cod}
              control={<BlackRadio color="primary" />}
              label={creditDescriptionsByMethod[PAYMENT_METHODS.cod] || <FormattedMessage id="global.COD" />}
            />
          )}

          {allowMethods.indexOf(PAYMENT_METHODS.creditCard) !== -1 &&
            customerGetCreditCards(customer).map((account) => (
              <React.Fragment key={account.id}>
                <FormControlLabel
                  classes={{ label: classes.label }}
                  value={account.id}
                  control={<BlackRadio color="primary" />}
                  label={<StripePaymentCardsRadioGroupItemLabel account={account} />}
                />

                {selectedPaymentMethod === account.id && (
                  <p className={classes.serviceChargeMessage}>
                    {intl.formatMessage({ id: 'messages.serviceCharge' })}
                    <IconButton
                      style={{ padding: 0, marginLeft: 5 }}
                      onClick={onShowServiceChargeInfo}
                      aria-label="Info">
                      <Icon style={{ fontSize: '1.2rem', fill: '#363636' }} icon={InfoIcon} />
                    </IconButton>
                  </p>
                )}
              </React.Fragment>
            ))}

          {allowMethods.indexOf(PAYMENT_METHODS.bankAccount) !== -1 &&
            customerGetBankAccounts(customer).map((account) => (
              <FormControlLabel
                key={account.id}
                classes={{ label: classes.label }}
                value={account.id}
                control={<BlackRadio color="primary" />}
                label={<StripeBankAccountsRadioGroupItemLabel account={account} />}
              />
            ))}
        </RadioGroup>

        {!customerGetCreditCards(customer).length &&
          !customerGetBankAccounts(customer).length &&
          allowMethods.indexOf(PAYMENT_METHODS.cod) === -1 &&
          allowMethods.indexOf(PAYMENT_METHODS.check) === -1 &&
          !order.customer.allowCredit && (
            <p>
              <FormattedMessage id={'messages.paymentMethodsEmpty'} />
            </p>
          )}

        <div className={classes.paymentMethodsControls}>
          <ThirdButton onClick={onAddCreditCard} className={classes.addButton}>
            <FormattedMessage id={'payment.addCreditCard'} />
          </ThirdButton>
          <PlaidLink
            buttonComponent={ThirdButton}
            onClick={onPlaidOpen}
            onExit={() => {
              const store = getStore();
              store.dispatch(openCheckout(CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE));
            }}
            product={['auth']}
            buttonComponentProps={{ className: classes.addButton }}
            onSuccess={(...props) => {
              onVerifyBankAccount(...props);
              const store = getStore();
              store.dispatch(openCheckout(CHECKOUT_STAGES.CHECKOUT_PAYMENT_METHOD_STAGE));
            }}>
            <FormattedMessage id="payment.addBankAccount" />
          </PlaidLink>
        </div>
      </FormControl>
    );
  }
}

CheckoutPaymentMethodsRadioGroup.propTypes = propTypes;
CheckoutPaymentMethodsRadioGroup.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(CheckoutPaymentMethodsRadioGroup));
