import axios from 'axios';
import {
  getRequestConfig, REQUEST_TYPES,
  replaceUrlPlaceholders,
  } from  '../request';
import API_END_POINTS from  '../endpoints';

/**
 * Updates line item of the draft order
 * @param {string} draftOrderId
 * @param {string} lineItemId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftLineItemUpdate = function (draftOrderId, lineItemId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.DRAFT_ORDER_UPDATE_LINE_ITEM, {
    'dr': draftOrderId,
    'it': lineItemId
  });

  let config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};

/**
 * Updates line item of the order
 * @param {string} orderId
 * @param {string} lineItemId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderLineItemUpdate = function (orderId, lineItemId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholders(API_END_POINTS.ORDER_UPDATE_LINE_ITEM, {
    'or': orderId,
    'it': lineItemId
  });

  let config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  data = data || {};
  config.data = data;

  axios(config).then((response) => {
    onSuccess(response);
  }).catch((error) => {
    onError(error);
  });
};

/**
 * Adds note for line item
 * @param {string} draftOrderId
 * @param {string} lineItemId
 * @param {string} note
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftLineItemSetNote = function (draftOrderId, lineItemId, note, onSuccess, onError) {
  apiDraftLineItemUpdate(draftOrderId, lineItemId, {note: note}, onSuccess, onError);
};

/**
 * Set line item quantity
 * @param {string} draftOrderId
 * @param {string} lineItemId
 * @param {string} quantity
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiDraftLineItemSetQuantity = function (draftOrderId, lineItemId, quantity, onSuccess, onError) {
  apiDraftLineItemUpdate(draftOrderId, lineItemId, {quantity: quantity}, onSuccess, onError);
};


/**
 * Adds note for line item
 * @param {string} orderId
 * @param {string} lineItemId
 * @param {string} note
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderLineItemSetNote = function (orderId, lineItemId, note, onSuccess, onError) {
  apiOrderLineItemUpdate(orderId, lineItemId, {note: note}, onSuccess, onError);
};

/**
 * Set line item quantity
 * @param {string} orderId
 * @param {string} lineItemId
 * @param {string} quantity
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiOrderLineItemSetQuantity = function (orderId, lineItemId, quantity, onSuccess, onError) {
  apiOrderLineItemUpdate(orderId, lineItemId, {quantity: quantity}, onSuccess, onError);
};