import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import {withStyles} from '@material-ui/core/styles';
import {
  PaymentMethodPanel,
  StripePaymentCardsRadioGroup,
  StripeBankAccountsRadioGroup,
  Spinner, ErrorBox, BlackRadio
} from '../../../components';
import {
  StripeCreditCardForm,
  StripeBankAccountForm
} from '../../../forms';
import {
  customerGetDefaultPaymentMethod,
  customerGetBankAccounts,
  customerGetCreditCards,
  checkNested,
  isEmpty,
  PAYMENT_METHODS, CUSTOMER_TYPES
} from '../../../helpers';
import styles from './styles';

class PaymentTypesRadioGroup extends React.Component {

  constructor(props) {
    super(props);

    let paymentMethodObject = customerGetDefaultPaymentMethod(props.customer);
    let paymentMethod = paymentMethodObject ? paymentMethodObject.method : PAYMENT_METHODS.check;
    let paymentMethodId = paymentMethodObject ? paymentMethodObject.id : "";

    if (!isEmpty(props.order) && checkNested(props.order, 'payment', 'method')) {
      paymentMethod = props.order.payment.method.method;
      paymentMethodId = props.order.payment.method.id;
    }

    props.onChange && props.onChange(paymentMethod, paymentMethodId);

    this.state = {
      paymentMethod: paymentMethod,
      paymentMethodId: paymentMethodId,
      addingBankAccount: false,
      addingCreditCard: false,
      error: "",
      loading: false
    };
  }

  render() {
    const {classes, customer, onChange, allowMethods} = this.props;
    const {loading, error} = this.state;

    return (
      <FormControl className={classes.formControl}>

        {loading && <Spinner/>}
        <ErrorBox error={error}/>

        <RadioGroup
          aria-label="payment-method"
          name="payment-method"
          className={classes.select}
          value={this.state.paymentMethod}
          onChange={(e) => {
            this.setState({
              addingCreditCard: false,
              addingBankAccount: false,
              paymentMethodId: "",
              paymentMethod: e.target.value,
            }, () => {
              onChange && onChange(this.state.paymentMethod, this.state.paymentMethodId)
            });
          }}
        >
          {
            allowMethods.indexOf(PAYMENT_METHODS.cod) !== -1 &&
            customer.type === CUSTOMER_TYPES.RETAIL && (
              <FormControlLabel value={PAYMENT_METHODS.cod}
                                control={<BlackRadio color="primary"/>}
                                label={<FormattedMessage id="global.COD"/>}
              />
            )
          }

          {
            allowMethods.indexOf(PAYMENT_METHODS.cod) !== -1 &&
            customer.type === CUSTOMER_TYPES.WHOLESALE &&
            customer.allowCredit && (
              <FormControlLabel value={PAYMENT_METHODS.cod}
                                control={<BlackRadio color="primary"/>}
                                label={<FormattedMessage id="global.COD"/>}
              />
            )
          }

          {allowMethods.indexOf(PAYMENT_METHODS.check) !== -1 &&
          <FormControlLabel value={PAYMENT_METHODS.check}
                            control={<Radio color="primary"/>} label={<FormattedMessage id="global.check"/>}/>}/>}


          {allowMethods.indexOf(PAYMENT_METHODS.creditCard) !== -1 &&
          <FormControlLabel classes={{label: classes.radioLabelBody}}
                            value={PAYMENT_METHODS.creditCard}
                            control={<Radio color="primary"/>}
                            label={<PaymentMethodPanel
                              onAdd={() => this.setState({addingCreditCard: true})}
                              hideAddButton={this.state.addingCreditCard}
                              addLabel={<FormattedMessage id="payment.addCreditCard"/>}
                              content={() => {
                                return this.state.addingCreditCard ?
                                  <StripeCreditCardForm
                                    dialog={false}
                                    onAdded={() => this.setState({addingCreditCard: false})}
                                    onClose={() => this.setState({addingCreditCard: false})}
                                  /> :
                                  <StripePaymentCardsRadioGroup
                                    selected={this.state.paymentMethodId}
                                    onChange={(id) => {
                                      this.setState({paymentMethodId: id}, () => {
                                        onChange && onChange(PAYMENT_METHODS.creditCard, id);
                                      })
                                    }}
                                    accounts={customerGetCreditCards(customer)}
                                  />
                              }}
                              expanded={this.state.paymentMethod === PAYMENT_METHODS.creditCard}
                              label={<FormattedMessage id="payment.creditCard"/>}/>}
          />}

          {allowMethods.indexOf(PAYMENT_METHODS.bankAccount) !== -1 &&
          <FormControlLabel value={PAYMENT_METHODS.bankAccount}
                            classes={{label: classes.radioLabelBody}}
                            control={<Radio color="primary"/>}
                            label={<PaymentMethodPanel
                              onAdd={() => this.setState({addingBankAccount: true})}
                              hideAddButton={this.state.addingBankAccount}
                              addLabel={<FormattedMessage id="payment.addBankAccount"/>}
                              content={() => {
                                return this.state.addingBankAccount ?
                                  <StripeBankAccountForm
                                    dialog={false}
                                    onAdded={() => this.setState({addingBankAccount: false})}
                                    onClose={() => this.setState({addingBankAccount: false})}
                                  /> :
                                  <StripeBankAccountsRadioGroup
                                    onChange={(id) => {
                                      this.setState({paymentMethodId: id}, () => {
                                        onChange && onChange(PAYMENT_METHODS.bankAccount, id);
                                      })
                                    }}
                                    accounts={customerGetBankAccounts(customer)}
                                    selected={this.state.paymentMethodId}
                                  />
                              }}
                              expanded={this.state.paymentMethod === PAYMENT_METHODS.bankAccount}
                              label={<FormattedMessage id="payment.bankAccount"/>}/>}
          />}
        </RadioGroup>
      </FormControl>
    );
  }

}

PaymentTypesRadioGroup.propTypes = {
  order: PropTypes.object,
  allowMethods: PropTypes.array,
  onChange: PropTypes.func,
  customer: PropTypes.object.isRequired,
};

PaymentTypesRadioGroup.defaultProps = {
  order: {},
  allowMethods: [
    PAYMENT_METHODS.cod,
    PAYMENT_METHODS.check,
    PAYMENT_METHODS.creditCard,
    PAYMENT_METHODS.bankAccount
  ]
};

export default withStyles(styles, {withTheme: true})(
  PaymentTypesRadioGroup
);
