import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '../index';
import { colors } from '../../helpers';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.white,
    margin: 10,
    marginLeft: 30,
    marginRight: 30,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',

    borderRadius: 6,
  },
  viewButtonIconLabel: {},
  viewButtonIconWrapper: {
    borderRadius: 0,
    width: 40,
    height: 40,
    padding: 0,
  },
});

function MyZoomButton(props) {
  const { classes, onZoom, zoom } = props;

  return (
    <div className={classes.buttonWrapper}>
      <IconButton
        onClick={() => {
          if (zoom < 18) {
            onZoom(zoom + 1);
          }
        }}
        classes={{ label: classes.viewButtonIconLabel }}
        className={classes.viewButtonIconWrapper}>
        <Icon icon={AddIcon} className={classes.viewButtonIcon} style={{ fill: '#000000' }} />
      </IconButton>
      <IconButton
        onClick={() => {
          if (zoom > 0) {
            onZoom(zoom - 1);
          }
        }}
        classes={{ label: classes.viewButtonIconLabel }}
        className={classes.viewButtonIconWrapper}>
        <Icon icon={RemoveIcon} className={classes.viewButtonIcon} style={{ fill: '#000000' }} />
      </IconButton>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(MyZoomButton);
