class ProductTypes {

	constructor(types = []) {
		this.raw = types.data;
		this.pagination = types.meta;
	}

	getTypes(search) {
		let types = {}
		
		this.raw && this.raw.forEach((item) => {
			types[item.type] = !types[item.type]
				? {name: item.type, subTypes: [item]}
				: {name: item.type, subTypes: [...types[item.type].subTypes, item]}
		});
		
	//TODO: implement sorting
	
// 		// Sort types ASC by name
// 		const sort = (a, b) => (a.name > b.name) ? 1 : -1;
//
//
// 		if (search) {
// 			return this.raw
// 				.filter(item => item.name.includes(search))
// 				.sort(sort);
// 		}
// if(this.raw){
// 	//
// 		return this.raw.sort(sort);
// }
// 		return [];
		return Object.values(types);
	}
	
	getTypesAsObject = (search) => {
		let types = {}

		this.raw && this.raw.forEach((item) => {
			types[item.type] = !types[item.type]
				? {name: item.type, subTypes: [item], pagination: this.pagination}
				: {name: item.type, subTypes: [...types[item.type].subTypes, item], pagination: this.pagination}
		});
		return types;
	}

	getType(name) {
		return this.raw
			.find(item => item.name === name);
	}
}

export default ProductTypes
