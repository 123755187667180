import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

export const textLinkStyles = {
  textLinkStylesFontOnGreen: {
    fontFamily: "'Noto Sans HK', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    color: '#000',
    lineHeight: '34px',
    margin: 0,
    padding: 0,
    textDecoration: 'underline',
  },
};

const useStyles = makeStyles(() => textLinkStyles);

const TextLink = (props) => {
  const { className, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Typography className={classNames([classes.textLinkStylesFontOnGreen, className])} component="p" {...otherProps}>
      {props.children}
    </Typography>
  );
};

export default TextLink;
