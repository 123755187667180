const styles = theme => ({
  iconColor: {
    fill: '#ffffff'
  },
  whiteIcon: {
    fill: '#ffffff'
  },
  root: {
    flexGrow: 1,
    zIndex: 1,
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column'
    }
  },
  hideUpMd: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hideDownMd: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    minHeight: 200,
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      top: 100,
      marginBottom: 100,
    },
  },
  topToolbar: {
    height: 70,
    [theme.breakpoints.down(950)]: {
      display: 'none'
    }
  },
  mdDownHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  flex: {
    flex: 1,
  },
  logo: {
    height: 30,
    color: '#ffffff'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  selectProducerButton: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  searchContainer: {
    flex: 1,
    marginLeft: 250,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    textAlign: 'center'
  },
  searchInput: {
    color: '#ffffff',
    '&:before': {
      display: 'none',
      backgroundColor: '#ffffff',
    },
    '&:after': {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      transform: 'scaleX(1)'
    },
    [theme.breakpoints.down('md')]: {
      width: 180
    },
    [theme.breakpoints.down('xs')]: {
      width: 100
    },
  },
  input: {
    '&:before': {
      backgroundColor: '#ffffff',
    },
  },
  cssFocused: {
    '&:before': {
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
    },
    '&:after': {
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
    },
  },
  cssLabel: {
    color: "#ffffff",
  },
  cssUnderline: {
    color: "#ffffff",
    '&:after': {
      backgroundColor: '#ffffff',
    },
  },

  myItemsTitle: {
    fontSize: 20,
    color: '#212121',
  },
  myItemsText: {
    fontSize: 14
  },
  myItemsActive: {
    backgroundColor: '#eeeeee',
    paddingTop: 8,
    paddingBottom: 8
  },
  myItems: {
    paddingTop: 8,
    paddingBottom: 8
  }

});

export default styles;