class SubtypesGroupedByType {
  
  constructor(data = {}) {
    this.raw = data.data;
  }
  
  getSubTypes() {
    return this.raw;
  }
  
  getSubTypesAsObject() {
    const raw = this.raw;
    let objectOfSubTypes = {};
    
    raw && raw.forEach((type) => {
      objectOfSubTypes[type.name] = {name: type.name, ...type.subTypes};
    })
    
    return objectOfSubTypes;
  }
  
}

export default SubtypesGroupedByType;