import React, { Component } from 'react';
import { Checkout } from '../../scenes/Partials';
import {
  addCustomerAddress,
  changeCustomerPaymentsHandler,
  changeDraftShipping,
  clearProducersFilter,
  closeCheckout,
  completeDraftOrder,
  dismissShippingOutdatedNotification,
  draftToggle,
  patchCustomerAddress,
  setDraftAddress,
  verifyCustomerPaymentMethod,
} from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class CheckoutDialog extends Component {
  state = {
    checkoutCompleted: false,
  };

  handleCancelCheckout = (redirect) => {
    this.props.closeCheckout();
    this.setState({
      checkoutCompleted: false,
    });

    if (redirect) {
      this.props.history.push(redirect);
    }
  };

  handleCompleteDraft = (data, onSuccess, onError) => {
    const { completeDraft, clearProducersFilter, history, isPersonalProducerSelect } = this.props;
    completeDraft(
      data,
      () => {
        clearProducersFilter();
        onSuccess && onSuccess();
        this.setState({
          checkoutCompleted: true,
        });

        if (!isPersonalProducerSelect) history.push(`/products`);
      },
      onError
    );
  };

  handleCompleteDraftShowError = () => {
    const { draftToggle, closeCheckout } = this.props;

    draftToggle();
    closeCheckout();
  };

  render() {
    const {
      shippingOptions,
      shippingLoading,
      draftObject,
      changeDraftShipping,
      completeLoading,
      customer,
      producers,
      draftObjectAfterComplete,
      subscriptionIdAfterComplete,
      patchAddress,
      addAddress,
      setDraftAddress,
      changePaymentsHandler,
      verifyPaymentMethod,
      shippingOutdated,
      dismissShippingOutdatedNotification,
      shippingError,
      disablePickup,
      forceSaveShippingOptions,
    } = this.props;

    return (
      <Checkout
        stage={this.props.checkout.stage}
        onClose={this.handleCancelCheckout}
        loading={completeLoading || shippingLoading}
        shippingOptions={shippingOptions}
        completed={this.state.checkoutCompleted}
        draftObjectAfterComplete={draftObjectAfterComplete}
        subscriptionIdAfterComplete={subscriptionIdAfterComplete}
        changePaymentPreferences={changePaymentsHandler}
        producers={producers}
        onChangeShipping={(data, onSuccess, onError, skipShippingCheck) => {
          changeDraftShipping(
            data.shippingType,
            data.shippingDate,
            data.shippingLocation,
            onSuccess,
            onError,
            skipShippingCheck
          );
        }}
        onCancel={this.handleCancelCheckout}
        onComplete={this.handleCompleteDraft}
        onError={this.handleCompleteDraftShowError}
        customer={customer}
        onAddAddress={addAddress}
        onPatchAddress={patchAddress}
        setDraftAddress={setDraftAddress}
        verifyPaymentMethod={verifyPaymentMethod}
        draft={draftObject}
        shippingError={shippingError}
        shippingOutdated={shippingOutdated}
        dismissShippingOutdatedNotification={dismissShippingOutdatedNotification}
        disablePickup={disablePickup}
        forceSaveShippingOptions={forceSaveShippingOptions}
      />
    );
  }
}

CheckoutDialog.propTypes = {};

const mapStateToProps = (state) => {
  return {
    shippingOptions: state.common.shippingOptions,
    checkout: state.common.checkout,
    notification: state.common.notification,
    completeLoading: state.draft.completeLoading,
    shippingLoading: state.draft.shippingLoading,
    customer: state.customer.object,
    draftObjectAfterComplete:
      state.draft.draftObjectAfterComplete?.order || state.draft.draftObjectAfterComplete?.draft || {},
    subscriptionIdAfterComplete: state.draft.draftObjectAfterComplete?.subscriptionId,
    draftObject: state.draft.object,
    shippingOutdated: state.draft.shippingOutdated,
    shippingError: state.draft.shippingError,
    producers: state.producer.producers,
    isPersonalProducerSelect: state.draft.isPersonalProducerSelect,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeCheckout: () => dispatch(closeCheckout()),
    changeDraftShipping: (type, date, location, onSuccess, onError, skipShippingCheck) =>
      dispatch(changeDraftShipping(type, date, location, onSuccess, onError, skipShippingCheck)),
    completeDraft: (data, onComplete, onError) => dispatch(completeDraftOrder(data, onComplete, onError)),
    clearProducersFilter: () => dispatch(clearProducersFilter()),
    dismissShippingOutdatedNotification: () => dispatch(dismissShippingOutdatedNotification()),
    draftToggle: () => dispatch(draftToggle()),
    verifyPaymentMethod: (plaidToken, plaidMeta, onSuccess, onError) =>
      dispatch(verifyCustomerPaymentMethod(plaidToken, plaidMeta, onSuccess, onError)),
    setDraftAddress: (address, onSuccess, onError) => dispatch(setDraftAddress(address, onSuccess, onError)),
    patchAddress: (id, data, onSuccess, onError) => dispatch(patchCustomerAddress(id, data, onSuccess, onError)),
    addAddress: (type, data, onSuccess, onError) => dispatch(addCustomerAddress(type, data, onSuccess, onError)),
    changePaymentsHandler: (handler, onSuccess, onError) =>
      dispatch(changeCustomerPaymentsHandler(handler, onSuccess, onError)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutDialog));
