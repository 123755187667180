import React from 'react';
import { load } from '../helpers';

const LoginForm = load(React.lazy(() => import('./LoginForm/LoginForm')));
const StripeCreditCardForm = load(React.lazy(() => import('./StripeCreditCardForm/StripeCreditCardForm')));
const StripeBankAccountForm = load(React.lazy(() => import('./StripeBankAccountForm/StripeBankAccountForm')));
const NewPasswordForm = load(React.lazy(() => import('./NewPasswordForm/NewPasswordForm')));
const AddressForm = load(React.lazy(() => import('./AddressForm/AddressForm')));
const CheckoutAddressForm = load(React.lazy(() => import('./CheckoutAddressForm/CheckoutAddressForm')));
const NewAccountForm = load(React.lazy(() => import('./NewAccountForm/NewAccountForm')));
const CheckoutStripeBankAccountForm = load(
  React.lazy(() => import('./CheckoutStripeBankAccountForm/CheckoutStripeBankAccountForm'))
);
const CheckoutStripeCreditCardForm = load(
  React.lazy(() => import('./CheckoutStripeCreditCardForm/CheckoutStripeCreditCardForm'))
);

export {
  LoginForm,
  NewPasswordForm,
  StripeCreditCardForm,
  StripeBankAccountForm,
  AddressForm,
  CheckoutAddressForm,
  NewAccountForm,
  CheckoutStripeBankAccountForm,
  CheckoutStripeCreditCardForm,
};
