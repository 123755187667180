import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Icon } from '../../components';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const theme = createTheme({});

const BlackCheckbox = (props) => {
  const { ...other } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <Checkbox
        icon={<Icon icon={CheckBoxOutlineBlankIcon} style={{ fontSize: 28, fill: '#363636' }} />}
        checkedIcon={<Icon icon={CheckBoxIcon} style={{ fontSize: 28, fill: '#363636' }} />}
        {...other}
      />
    </MuiThemeProvider>
  );
};

export default BlackCheckbox;
