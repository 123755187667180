import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  MAKE_INITIALIZED,
  MAKE_NON_INITIALIZED,
  SET_SHIPPING_OPTIONS,
  REFRESH_EVERYTHING_DONE,
  REFRESH_EVERYTHING_START,
  TOGGLE_RIGHT_SIDE_MENU_DIALOG,
  commonConstants,
  SEND_SENTRY_ERROR,
  CLEAR_SENTRY_ERROR,
} from '../constants';
import { ShippingOptions } from '../../entities';
import { CHECKOUT_STAGES } from '../../helpers/constants';
import {
  CLOSE_CHECKOUT,
  OPEN_CHECKOUT,
  SET_VIEW_TYPE,
  TOGGLE_INFO_DIALOG,
  SET_INFO_DIALOG_CONTENT,
} from '../constants/common';
import { getObjectFromStorage } from '../../helpers';

const initialState = {
  initialized: false,
  refreshing: false,
  refreshingShipping: false,
  checkout: {
    active: false,
    stage: CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE,
  },
  shippingOptions: null,
  notification: '',
  boxView:
    getObjectFromStorage('viewType.data').boxView !== undefined ? getObjectFromStorage('viewType.data').boxView : true,

  infoDialog: false,
  infoDialogContent: { title: '', content: () => <div /> },
  isOpenRightSideMenu: false,
  sentryError: {
    error: null,
    errorCounter: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHECKOUT: {
      return {
        ...state,
        checkout: {
          stage: action.stage || CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE,
          active: true,
        },
      };
    }
    case CLOSE_CHECKOUT: {
      return {
        ...state,
        checkout: {
          active: false,
          state: CHECKOUT_STAGES.CHECKOUT_SHIPPING_STAGE,
        },
      };
    }
    case MAKE_INITIALIZED: {
      return {
        ...state,
        initialized: true,
      };
    }
    case MAKE_NON_INITIALIZED: {
      return {
        ...state,
        initialized: false,
      };
    }
    case SHOW_NOTIFICATION: {
      const { notification } = action.payload;
      return {
        ...state,
        notification: notification,
      };
    }
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notification: '',
      };
    }
    case SET_SHIPPING_OPTIONS: {
      return {
        ...state,
        shippingOptions: new ShippingOptions(action.payload),
      };
    }
    case REFRESH_EVERYTHING_START: {
      return { ...state, refreshing: true };
    }
    case REFRESH_EVERYTHING_DONE: {
      return { ...state, refreshing: false };
    }
    case commonConstants.REFRESH_SHIPPING_START: {
      return { ...state, refreshingShipping: true };
    }
    case commonConstants.REFRESH_SHIPPING_END: {
      return { ...state, refreshingShipping: false };
    }
    case commonConstants.REFRESH_SHIPPING_ERROR: {
      return { ...state, error: action.payload.error, refreshingShipping: false };
    }
    case SET_VIEW_TYPE: {
      return { ...state, boxView: action.payload };
    }
    case TOGGLE_INFO_DIALOG: {
      return { ...state, infoDialog: !state.infoDialog };
    }
    case SET_INFO_DIALOG_CONTENT: {
      return { ...state, infoDialogContent: action.payload };
    }
    case TOGGLE_RIGHT_SIDE_MENU_DIALOG: {
      return { ...state, isOpenRightSideMenu: !state.isOpenRightSideMenu };
    }
    case SEND_SENTRY_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        sentryError: {
          error: error,
          errorCounter: ++state.sentryError.errorCounter,
        },
      };
    }
    case CLEAR_SENTRY_ERROR: {
      return {
        ...state,
        sentryError: {
          error: null,
          errorCounter: 0,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
