import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import {
  ErrorBox,
  Spinner,
  CheckoutAddress,
  BlackSelect,
  BlackButton,
  ErrorFormattedMessage,
  BlackLabel,
  BlackPhoneField,
} from '../../../../components';
import {
  dateToMMDY,
  searchDateBYMMDY,
  SHIPPING_DELIVERY,
  SHIPPING_PICKUP,
  DELIVERY_ADDRESS,
  BILLING_ADDRESS,
  DELIVERY_ADDRESS_TYPE,
  DELIVERY_ADDRESSES,
  validateField,
  SHIPPING_TYPES,
} from '../../../../helpers';
import {
  getDefaultAddress,
  getAddressLabel,
  isAvailabilities,
  getAvailabilitiesData,
  getProducersByItems,
} from '../helpers';
import styles from './styles';
import { ShippingOptions } from '../../../../entities';
import validationRules from './validation';
import { patchCustomerAccountInfo } from '../../../../store/actions';
import moment from 'moment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ShoppingBag, Truck } from 'react-feather';
import Hidden from '@material-ui/core/Hidden';
import { CHECKOUT_STAGES } from '../../../../helpers/constants';
import { getLocationId } from '../../../../containers/SearchBarWithDelivery/helpers';

const propTypes = {
  loading: PropTypes.bool,
  customer: PropTypes.object.isRequired,
  draft: PropTypes.object.isRequired,
  onChangeShipping: PropTypes.func.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  shippingOptions: PropTypes.object.isRequired,
  onFulfillmentInfoClick: PropTypes.func.isRequired,
  onSetDraftAddress: PropTypes.func.isRequired,
  forceSaveShippingOptions: PropTypes.bool,
};
const defaultProps = {
  loading: false,
};

class ShippingStage extends React.Component {
  constructor(props) {
    super(props);

    const {
      draft,
      customer,
      shippingOptions,
      market,
      allMarkets,
      disablePickup,
      personalProducerShippingOptions,
      isPersonalProducerSelect,
      personalProducerSelectedId,
    } = props;

    const { itemsWithOwnShipping, firstItemToShow, itemShipping } = this.getItemsShipping();

    this.shippingOptions = new ShippingOptions(shippingOptions.getCheckoutShippingOptionsByOrder(draft));

    const { shipping } = draft;

    let shippingLocation = false;
    let shippingDate = false;
    let shippingType = false;

    if (!!isPersonalProducerSelect) {
      this.shippingOptions = new ShippingOptions(
        shippingOptions.getPersonalProducerShippingVariants(personalProducerSelectedId)
      );
      const shippingDates = this.shippingOptions.getPersonalProducerShippingDates(personalProducerSelectedId);

      shippingDate = dateToMMDY(personalProducerShippingOptions.date);
      shippingLocation = personalProducerShippingOptions.location;
      shippingType = personalProducerShippingOptions.type;

      // Preselect first
      if (!shippingDates.some((option) => option.formatted.nextMMDY === shippingDate)) {
        shippingDates.length && (shippingDate = shippingDates[0].formatted.nextMMDY);
      }
    }

    // Cache locations
    this.locations = this.shippingOptions.getDistinctLocations();

    if (shipping) {
      shippingDate = dateToMMDY(shipping.date);
      shippingType = shipping.type;
      shippingLocation = market && market.id ? market.id : false;
    }

    // Predefine shipping location
    if (!shippingLocation && this.locations.length) {
      shippingLocation = this.locations[0].id;
    }

    // Predefine shipping type
    if (shippingLocation && !shippingType) {
      const shippingTypes = this.shippingOptions.getShippingTypesByLocation(shippingLocation);
      shippingTypes.length && (shippingType = shippingTypes[0]);
    }

    // Predefine shipping date
    if (shippingLocation && shippingType && !shippingDate) {
      const shippingDates = this.shippingOptions.getShippingDatesByLocationAndType(shippingLocation, shippingType);
      shippingDates.length && (shippingDate = shippingDates[0].formatted.nextMMDY);
    }

    const hasAvailabilities = isAvailabilities(draft);
    let availabilitiesData = {};

    if (hasAvailabilities) {
      availabilitiesData = getAvailabilitiesData(draft, shippingOptions);

      //get selected OR first shipping type from availabilitiesData
      shippingType =
        props.shippingType ||
        (!!shipping?.type && availabilitiesData.methods.includes(shipping.type)
          ? shipping.type
          : availabilitiesData.methods[0]);
      shippingDate = availabilitiesData.date;

      const availabilitiesLocations = allMarkets.getMarketsByType(shippingType, getProducersByItems(draft));
      shippingLocation =
        props?.shippingLocation ||
        (market && market.id ? market.id : availabilitiesLocations[0] && availabilitiesLocations[0].id);
    }

    let tabsShow = this.shippingOptions.getShippingTypesByLocation(shippingLocation);

    if (disablePickup) {
      tabsShow = [SHIPPING_DELIVERY];
    }

    if (hasAvailabilities) {
      tabsShow = availabilitiesData.methods;
    }

    this.state = {
      error: '',

      tabsShow,
      shippingLocation,
      shippingType,
      shippingDate,
      tabIndex: tabsShow.length > 1 && shippingType === SHIPPING_TYPES.SHIPPING_PICKUP ? 1 : 0,

      billingAddress: draft.customer.billingAddress
        ? draft.customer.billingAddress
        : getDefaultAddress(customer.billingAddresses, false),
      shippingAddress: draft.customer.shippingAddress
        ? draft.customer.shippingAddress
        : getDefaultAddress(customer.shippingAddresses, false),

      // Address
      addressError: '',
      loadingAddress: false,
      addingAddress: null,
      patchingAddress: '',
      shippingAddressError: '',

      // Phone
      phoneNumber: '',
      phoneNumberError: '',
      phoneNumberValid: true,
      patchingAccountInfo: false,

      // Availabilities
      hasAvailabilities,
      availabilitiesData,
      itemsWithOwnShipping,
      itemShipping,
      firstItemToShow,
    };

    props.onChangeShippingType(shippingType);

    this.props.stageRef && this.props.stageRef(this);
  }

  getItemsShipping = () => {
    let itemsWithOwnShipping = false;
    let itemShipping = {};
    let firstItemToShow = {};

    this.props.draft.lineItems.forEach((lineItemsByProducer) => {
      lineItemsByProducer.items.some((item) => {
        if (!!item.availability && item.availability.locationId) {
          itemsWithOwnShipping = true;
          firstItemToShow = item;
          itemShipping = item.availability;
          return false;
        }
      });
    });

    return { itemsWithOwnShipping, firstItemToShow, itemShipping };
  };

  componentDidMount() {
    this.setState({ phoneNumber: this.props.customer.phone });

    if (this.state.hasAvailabilities) {
      this.onChangeShippingOptions();
    }
  }

  componentWillUnmount() {
    this.shippingOptions = null;
    this.locations = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let state = {};
    if (nextProps.draft.customer.billingAddress) {
      state['billingAddress'] = nextProps.draft.customer.billingAddress;
    } else {
      state['billingAddress'] = getDefaultAddress(nextProps.customer.billingAddresses, false);
    }

    if (nextProps.draft.customer.shippingAddress) {
      state['shippingAddress'] = nextProps.draft.customer.shippingAddress;
    } else {
      state['shippingAddress'] = getDefaultAddress(nextProps.customer.shippingAddresses, false);
    }

    this.setState(state);
  }

  onChangeShippingOptions = (onSuccess, onError) => {
    const { intl } = this.props;
    const { shippingType, shippingDate, shippingLocation, hasAvailabilities } = this.state;

    let date;

    if (hasAvailabilities) {
      date = shippingDate;
    } else {
      const dates = this.shippingOptions.getShippingDatesByLocationAndType(shippingLocation, shippingType);
      date = searchDateBYMMDY(dates, shippingDate);
    }
    this.props.onChangeShipping(
      {
        shippingLocation,
        shippingType,
        shippingDate: date,
      },
      () => {
        this.setState({
          error: '',
        });
        onSuccess && onSuccess();
      },
      (draft, error) => {
        // On Error
        const messageId =
          shippingType === SHIPPING_DELIVERY ? 'error.marketIsNotAvailable' : 'messages.somethingWentWrong';
        this.setState({
          error: intl.formatMessage({ id: messageId }),
        });

        onError && onError(draft, error);
      },
      hasAvailabilities
    );
  };

  shippingSelect = (label, key, days, customOnChangeShippingOptions) => {
    const { classes, loading } = this.props;
    const { itemsWithOwnShipping } = this.state;

    return (
      <BlackSelect
        fullWidth
        disabled={loading || itemsWithOwnShipping}
        label={label}
        formControlClassName={classes.selectFormControl}
        value={this.state.shippingDate}
        onChange={(e) => {
          this.setState(
            {
              shippingDate: e.target.value,
            },
            customOnChangeShippingOptions || this.onChangeShippingOptions
          );
        }}
        inputProps={{ name: key, id: key }}>
        {days.map((item, index) => {
          return (
            <MenuItem key={index} value={item.formatted.nextMMDY}>
              {item.formatted.next}
            </MenuItem>
          );
        })}
      </BlackSelect>
    );
  };

  marketsSelect = (label, key, markets) => {
    const { classes, loading, onSelectMarket } = this.props;
    const { shippingLocation, shippingType } = this.state;

    return (
      <div onClick={shippingType === SHIPPING_PICKUP ? onSelectMarket : undefined}>
        <BlackSelect
          fullWidth
          disabled={shippingType === SHIPPING_PICKUP ? true : loading}
          disabledCursor={'pointer'}
          label={label}
          formControlClassName={classes.selectFormControl}
          value={shippingLocation}
          onChange={(e) => {
            this.setState(
              {
                shippingLocation: e.target.value,
              },
              () => {
                this.onChangeShippingOptions();
              }
            );
          }}
          inputProps={{ name: key, id: key }}>
          {markets.map((item, index) => {
            return (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </BlackSelect>
      </div>
    );
  };

  onChangeShippingType = (type) => {
    const { shippingLocation, hasAvailabilities, shippingDate } = this.state;
    const { draft, allMarkets } = this.props;

    let shippingDateMMDY;
    let initialLocationId;
    if (hasAvailabilities) {
      shippingDateMMDY = shippingDate;
      // change pre selected location id on change shipping type
      const selectedLocation = getLocationId(draft);
      const locationsByType = allMarkets.getMarketsByType(type, getProducersByItems(draft));
      const isSelectedLocationIncludesToType = locationsByType.some((loc) => loc.id === selectedLocation);
      initialLocationId =
        !!selectedLocation && isSelectedLocationIncludesToType
          ? selectedLocation
          : locationsByType[0] && locationsByType[0].id;
    } else {
      const dateFromOptions = this.shippingOptions.getFirstAvailableShippingDateByLocationAndType(
        shippingLocation,
        type
      );
      shippingDateMMDY = dateFromOptions.formatted.nextMMDY;
    }

    this.setState(
      {
        shippingType: type,
        shippingDate: shippingDateMMDY,
        ...(hasAvailabilities ? { shippingLocation: initialLocationId } : {}),
      },
      () => {
        this.onChangeShippingOptions();
      }
    );
  };

  validateField(fieldName) {
    const errorFieldName = fieldName + 'Error';
    let state = {};

    state[errorFieldName] = validateField(validationRules, fieldName, this.state[fieldName]);
    this.setState(state);

    return state[errorFieldName];
  }

  onDone = () => {
    const { intl, onDone, customer, patchCustomerAccountInfo, draft, onSetDraftAddress } = this.props;

    const { phoneNumber, phoneNumberValid, shippingAddress, billingAddress, shippingType } = this.state;

    if (shippingType === SHIPPING_TYPES.SHIPPING_DELIVERY) {
      if (!customer[DELIVERY_ADDRESSES].length || !shippingAddress) {
        this.setState({ shippingAddressError: intl.formatMessage({ id: 'validation.address.enterAddress' }) });
        return false;
      }
    }

    this.setState({ patchingAccountInfo: true });

    const phoneProcessor = () => {
      let phoneNumberError = validateField(validationRules, 'phoneNumber', phoneNumber);

      if (!phoneNumberValid && !phoneNumberError) {
        phoneNumberError = 'validation.account.enterCorrectPhoneNumber';
      }

      if (!phoneNumberError) {
        patchCustomerAccountInfo(
          customer,
          { phone: phoneNumber },
          () => {
            this.setState({ patchingAccountInfo: false });
            onDone();
          },
          (error) =>
            this.setState({
              phoneNumberError: error,
              patchingAccountInfo: false,
            })
        );
      } else {
        this.setState({
          phoneNumberError,
          patchingAccountInfo: false,
        });
      }
    };

    if (!draft.customer.shippingAddress && draft.shipping.type === SHIPPING_TYPES.SHIPPING_DELIVERY) {
      let address = { id: customer.id };
      address[DELIVERY_ADDRESS] = { id: shippingAddress.id };
      address[BILLING_ADDRESS] = { id: billingAddress.id };
      onSetDraftAddress(
        address,
        () => {
          phoneProcessor();
        },
        (error) => {
          this.setState({ shippingAddressError: error.message });
        }
      );
    } else {
      phoneProcessor();
    }
  };

  checkAvailabilitiesBeforeDone = () => {
    const { onError, intl, forceSaveShippingOptions } = this.props;
    const { hasAvailabilities } = this.state;

    if (forceSaveShippingOptions) {
      this.onChangePersonalProducerShippingOptions(
        () => {
          this.onDone();
        },
        (draft, error) => {
          const errorCodes = [4003];
          if (!error?.code || error?.code === 1001 || errorCodes.includes(error?.code)) return false;
        }
      );
      return;
    }

    if (hasAvailabilities) {
      this.setState({ loading: true });
      this.onChangeShippingOptions(
        () => {
          this.onDone();
        },
        (draft, error) => {
          // if error status 500
          if (!error?.code || error?.code === 1001) return false;

          if (error?.code === 4037) {
            const selectedDate = moment(draft?.shipping?.date).format('dddd, MMM DD');
            const selectedLocation = draft?.shipping?.location?.name;
            const selectedTypeText = intl.formatMessage({ id: 'global.' + draft.shipping.type });
            const options = `${draft?.shipping?.type ? selectedTypeText : ''}${
              selectedLocation ? ' - ' + selectedLocation : ''
            }${selectedDate ? ' - ' + selectedDate : ''}`;

            const message = intl.formatMessage({ id: 'checkout.shippingOptionsCantBeChange' }, { options });

            onError && onError(message);
          }

          this.onDone();
        }
      );
    } else {
      this.onDone();
    }
  };

  pseudoInput = (label, text) => {
    const { classes } = this.props;
    return (
      <>
        <BlackLabel className={classes.labelClass} style={{ color: '#707070', fontSize: 12 }}>
          {label}
        </BlackLabel>
        <div className={classes.pseudoInput}>
          <span>{text}</span>
        </div>
      </>
    );
  };

  changeTab = (e, value) => {
    this.setState({
      tabIndex: value,
    });
  };

  onChangePersonalProducerShippingOptions = (onSuccess, onError) => {
    const { intl, personalProducerSelectedId } = this.props;
    const { shippingDate, hasAvailabilities } = this.state;

    if (hasAvailabilities) {
      this.onChangeShippingOptions(onSuccess, onError);
      return;
    }

    const dates = this.shippingOptions.getPersonalProducerShippingDates(personalProducerSelectedId);
    const selectedShippingOption = dates.find((option) => option.formatted.nextMMDY === shippingDate);

    this.props.onChangeShipping(
      {
        shippingLocation: selectedShippingOption.location.id,
        shippingType: selectedShippingOption.type,
        shippingDate: selectedShippingOption.next,
      },
      () => {
        this.setState({
          error: '',
        });
        onSuccess && onSuccess();
      },
      (draft, error) => {
        if (!error?.code) {
          this.setState({
            error: intl.formatMessage({ id: 'error.marketIsNotAvailable' }),
          });
          return false;
        }

        // On Error
        if (draft.shipping && draft.shipping.type) {
          this.setState((state) => {
            return { ...state, error: '', shippingType: selectedShippingOption.type };
          });
        } else {
          this.setState({
            error: intl.formatMessage({ id: 'error.marketIsNotAvailable' }),
          });
        }

        onError && onError(draft, error);
      }
    );
  };

  render() {
    const {
      props: {
        classes,
        loading,
        intl,
        shippingError,
        onChangeAddress,
        allMarkets,
        draft,
        isPersonalProducerSelect,
        personalProducerSelectedId,
      },
      state: {
        patchingAccountInfo,
        itemsWithOwnShipping,
        itemShipping,
        shippingLocation,
        shippingType,
        tabIndex,
        tabsShow,

        availabilitiesData,
        hasAvailabilities,
      },
    } = this;
    //
    // const getMarketById = (locationId) => {
    //   const { allProducers } = this.props;
    //   const markets = allProducers.find((producer) => producer.id === firstItemToShow.producer.id).locations;
    //   return markets.find((producerMarket) => producerMarket.id === locationId);
    // };

    return (
      <React.Fragment>
        <ErrorBox error={this.state.error} />
        {!hasAvailabilities && (
          <ErrorBox error={shippingError && shippingError.message} className={classes.noHorizontalMargin} />
        )}
        {(loading || patchingAccountInfo) && <Spinner size={40} />}
        <Hidden mdDown>
          <div className={classes.checkoutTitle}>{intl.formatMessage({ id: 'landing.benefitTitle3' })}</div>
        </Hidden>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.changeTab}
          variant={tabsShow.length > 1 ? 'fullWidth' : 'standard'}>
          {tabsShow.includes('delivery') && (
            <Tab
              classes={{ wrapper: classes.iconContainer }}
              className={classes.tabsItem}
              label="Delivery"
              icon={<Truck className={classes.icon} />}
              onClick={() => this.onChangeShippingType('delivery')}
            />
          )}
          {tabsShow.includes('pickup') && (
            <Tab
              classes={{ wrapper: classes.iconContainer }}
              className={classes.tabsItem}
              label="Pickup"
              icon={<ShoppingBag className={classes.icon} />}
              onClick={() => this.onChangeShippingType('pickup')}
            />
          )}
        </Tabs>
        {tabIndex === 0 && tabsShow.includes('delivery') ? (
          <div className={classes.tabItem}>
            {!itemsWithOwnShipping ? (
              <>
                {!!isPersonalProducerSelect &&
                  this.shippingSelect(
                    intl.formatMessage({ id: 'global.date' }),
                    'personalShippingDeliveryDate',
                    this.shippingOptions.getPersonalProducerShippingDates(personalProducerSelectedId),
                    this.onChangePersonalProducerShippingOptions
                  )}

                {shippingType === SHIPPING_DELIVERY &&
                  !isPersonalProducerSelect &&
                  this.shippingSelect(
                    intl.formatMessage({ id: 'global.date' }),
                    'shippingDeliveryDate',
                    this.shippingOptions.getShippingDatesByLocationAndType(shippingLocation, SHIPPING_DELIVERY)
                  )}

                {shippingType === SHIPPING_PICKUP &&
                  !isPersonalProducerSelect &&
                  this.shippingSelect(
                    intl.formatMessage({ id: 'global.date' }),
                    'shippingPickupDate',
                    this.shippingOptions.getShippingDatesByLocationAndType(shippingLocation, SHIPPING_PICKUP)
                  )}

                {this.props.market && (
                  <div className={classes.tabItemMargin}>
                    {this.pseudoInput('Delivery from:', this.props.market.name)}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={classes.selectFormControl}>
                  {this.pseudoInput(
                    intl.formatMessage({ id: 'shipping.subscriptionStartDate' }),
                    moment
                      .parseZone(availabilitiesData.date)
                      .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK)
                  )}
                </div>
                <div className={classes.selectFormControl}>
                  {this.marketsSelect(
                    intl.formatMessage({ id: 'shipping.deliveryFrom' }),
                    'shippingLocation',
                    allMarkets.getMarketsByType(shippingType, getProducersByItems(draft))
                  )}
                </div>
              </>
            )}

            <div className={classes.tabItemMargin}>
              <CheckoutAddress
                error={this.state.shippingAddressError}
                emptyButtonLabel={intl.formatMessage({ id: 'address.deliveryAddress' })}
                wrapperClass={classes.addressWrapper}
                labelClass={classes.labelClass}
                addressLabel={getAddressLabel(this.state[DELIVERY_ADDRESS])}
                onChange={() => {
                  this.setState({ localStage: CHECKOUT_STAGES.CHECKOUT_SELECT_ADDRESS_STAGE });
                  onChangeAddress(DELIVERY_ADDRESS_TYPE);
                }}
                label={intl.formatMessage({ id: 'address.shipping' })}
                fontSize={16}
              />
            </div>
            <div className={classes.tabItemMargin}>
              <BlackPhoneField
                error={!!this.state.phoneNumberError}
                onBlur={() => this.validateField('phoneNumber')}
                onChange={(value) => this.setState({ phoneNumber: value })}
                value={this.state.phoneNumber}
                // required
                id="phoneNumber"
                label={<FormattedMessage id="global.phoneNumber" />}
                helperText={this.state.phoneNumberError && <ErrorFormattedMessage id={this.state.phoneNumberError} />}
                fullWidth
                view={'styleBold'}
                isValid={(valid) => {
                  this.setState({ phoneNumberValid: valid });
                }}
              />
            </div>
          </div>
        ) : null}
        {tabIndex === 1 || !tabsShow.includes('delivery') ? (
          <div className={classes.tabItem}>
            {!itemsWithOwnShipping ? (
              <>
                {shippingType === SHIPPING_DELIVERY &&
                  this.shippingSelect(
                    intl.formatMessage({ id: 'global.date' }),
                    'shippingDeliveryDate',
                    this.shippingOptions.getShippingDatesByLocationAndType(shippingLocation, SHIPPING_DELIVERY)
                  )}

                {shippingType === SHIPPING_PICKUP &&
                  this.shippingSelect(
                    intl.formatMessage({ id: 'global.date' }),
                    'shippingPickupDate',
                    this.shippingOptions.getShippingDatesByLocationAndType(shippingLocation, SHIPPING_PICKUP)
                  )}

                {this.props.market && (
                  <div className={classes.tabItemMargin}>
                    {this.pseudoInput('Pickup from:', this.props.market.name)}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={classes.selectFormControl}>
                  {this.pseudoInput(
                    intl.formatMessage({ id: 'shipping.subscriptionStartDate' }),
                    moment
                      .parseZone(itemShipping.startDate)
                      .format(process.env.REACT_APP_DATE_FORMAT_WITH_SHORT_DAY_OF_WEEK)
                  )}
                </div>

                <div className={classes.selectFormControl}>
                  {this.marketsSelect(
                    intl.formatMessage({ id: 'shipping.pickupAt' }),
                    'shippingLocation',
                    allMarkets.getMarketsByType(shippingType, getProducersByItems(draft))
                  )}
                </div>
              </>
            )}

            <div className={classes.tabItemMargin}>
              <BlackPhoneField
                error={!!this.state.phoneNumberError}
                onBlur={() => this.validateField('phoneNumber')}
                onChange={(value) => this.setState({ phoneNumber: value })}
                value={this.state.phoneNumber}
                // required
                id="phoneNumber"
                label={<FormattedMessage id="global.phoneNumber" />}
                helperText={this.state.phoneNumberError && <ErrorFormattedMessage id={this.state.phoneNumberError} />}
                fullWidth
                view={'styleBold'}
                isValid={(valid) => {
                  this.setState({ phoneNumberValid: valid });
                }}
              />
            </div>
          </div>
        ) : null}

        <Hidden xsDown>
          <div className={classes.controls}>
            <BlackButton
              disabled={loading || patchingAccountInfo}
              onClick={() => this.checkAvailabilitiesBeforeDone()}
              className={classes.control}>
              <FormattedMessage id="checkout.nextUpPayment" />
            </BlackButton>
          </div>
        </Hidden>
      </React.Fragment>
    );
  }
}

ShippingStage.propTypes = propTypes;
ShippingStage.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    customer: state.customer.object,
    market: state.market.object,
    allProducers: state.producer.producers,
    allMarkets: state.producer.markets,
    draft: state.draft.object,
    personalProducerShippingOptions: state.draft.personalProducerShippingOptions,
    isPersonalProducerSelect: state.draft.isPersonalProducerSelect,
    personalProducerSelectedId: state.draft.personalProducerSelectedId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    patchCustomerAccountInfo: (customer, data, onSuccess, onError) =>
      dispatch(patchCustomerAccountInfo(customer, data, onSuccess, onError)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ShippingStage))));
