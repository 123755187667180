import axios from 'axios';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Add email subscription
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddEmailSubscription = function (data, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CUSTOMER_ADD_EMAIL_SUBSCRIPTION);
  data = data || {};
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Patch email subscription
 * @param {string} id - subscription id
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchEmailSubscription = function (id, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_PATCH_EMAIL_SUBSCRIPTION, 'id', id);
  let config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  data = data || {};
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Delete email subscription
 * @param {string} id - subscription id
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerDeleteEmailSubscription = function (id, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_DELETE_EMAIL_SUBSCRIPTION, 'id', id);
  let config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
