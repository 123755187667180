import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_SENTRY_ERROR } from '../../store/constants';
import { useLocation } from 'react-router';
import MinimalSentryInfo from './MinimalSentryInfo';

const SentryErrorHandler = () => {
  const location = useLocation();
  const { updater, error, customer, draft } = useSelector((store) => ({
    updater: store.common.sentryError.errorCounter,
    error: store.common.sentryError.error,
    customer: store?.customer?.object || {},
    draft: store.draft.object || {},
  }));
  const dispatch = useDispatch();

  const clearSentryError = () => {
    dispatch({
      type: CLEAR_SENTRY_ERROR,
    });
  };

  useEffect(() => {
    !!updater && errorSender(error);

    return () => clearSentryError();
  }, [updater]);

  const errorSender = (error) => {
    const exception = new MinimalSentryInfo({
      error,
      customer,
      draft,
      location,
    }).getData();

    Sentry.setUser(exception.customer);
    // Create a short data message to each event
    Sentry.setContext(`${customer.id}_${draft.id}_${new Date().toISOString()}`, exception);

    Sentry.captureEvent({ message: `${customer.id}_${draft.id}_${new Date().toISOString()}` });
  };

  return null;
};

export default SentryErrorHandler;
