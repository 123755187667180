import React, { Component } from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';

// pages should contain logic for force login
const URLS_NO_REDIRECT = [
  '/track-order/:id',
  '/order/:id'
]

class PrivateRoute extends Component {
  render() {
    const {
      authenticated,
      guest,
      component: Component,
      ...rest
    } = this.props;
    
    if(
      URLS_NO_REDIRECT.includes(this.props.computedMatch.path)
    ) {
      return <Route
        {...rest}
        render={(props = this.props) => (
            (<Component {...props} showAuth={!authenticated || guest} />)
        )}
      />;
    }

    return (
      <Route
        {...rest}
        render={(props = this.props) => (
          (authenticated && !guest) ?
            (<Component {...props} />) :
            (<Redirect to={{
              pathname: '/',
              state: {from: props.location},
            }}/>)
        )}
      />
    )
  }

}

function mapStateToProps(state) {
  return {
    guest: state.auth.guest,
    authenticated: state.auth.authenticated,
  }
}

export default connect(mapStateToProps)(PrivateRoute)
