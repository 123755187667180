class Markets {
  constructor(markets = []) {
    this.raw = markets;
  }

  /**
   * Returns distinct locations
   * @return []
   */
  getMarketById(id) {
    let market = this.raw.find((market) => market.location && market.location.id === id);

    return market ? { ...market.location } : null;
  }

  /**
   * Returns distinct locations
   * @return []
   */
  getMarkets(search, producers) {
    let ids = [],
      unique = [],
      producerSorted;

    if (!!producers && !!producers.length) {
      producerSorted = this.raw.filter((location) => producers.includes(location.producer.id));
    } else {
      producerSorted = this.raw;
    }

    producerSorted.forEach(({ location }) => {
      !ids.includes(location.id) && unique.push({ ...location });
      ids.push(location.id);
    });

    if (search) unique = unique.filter((market) => market.name.toLowerCase().includes(search.toLowerCase()));

    return unique;
  }

  /**
   * Return markets by type and producers
   * @return []
   */
  getMarketsByType(type, producers) {
    let ids = [],
      unique = [],
      filteredMarkets;

    filteredMarkets = this.raw.filter((market) => {
      const typeEqual = !!type ? market.type === type : true;
      const producerEqual = !!producers?.length
        ? producers.some((producer) => producer.id === market.producer.id)
        : true;

      return typeEqual && producerEqual;
    });

    filteredMarkets.forEach(({ location }) => {
      !ids.includes(location.id) && unique.push({ ...location });
      ids.push(location.id);
    });

    return unique;
  }

  /**
   * Returns distinct producers
   * @param {string} locationId - location id to filter results. This parameter is optional
   * @return []
   */
  getProducers(locationId) {
    let ids = [],
      unique = [];
    let markets = locationId ? this.raw.filter((market) => market.location.id === locationId) : this.raw;

    markets.forEach(({ producer }) => {
      !ids.includes(producer.id) && unique.push({ ...producer });

      ids.push(producer.id);
    });

    return unique.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
}

export default Markets;
