import React from 'react';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Spinner } from '../components';

const EDIT_ORDER = '/edit-order';
const REORDER = '/reorder';
const LIST_ORDERS = '/list-orders';
const PAY_ORDER = '/pay-order';

class NotFoundResolver extends React.Component {
  state = {
    resolving: true,
  };

  componentDidMount() {
    const { location, history, noResolver } = this.props;
    if (!noResolver) {
      switch (location.pathname) {
        case EDIT_ORDER: {
          /** Resolved `order` page */
          this.handleOrderEdit();
          break;
        }
        case REORDER: {
          /** Resolved `order` page */
          this.handleReorder();
          break;
        }
        case PAY_ORDER: {
          /** Resolved `order` page */
          this.handlePayReorder();
          break;
        }
        case LIST_ORDERS: {
          /** Resolved `orders` page */
          this.handleListOrders();
          break;
        }
        default: {
          /** Showing `NotFound` scene if nothing resolved */
          history.replace('/404');
          this.setState({ resolving: false });
        }
      }
    } else {
      this.setState({ resolving: false });
    }
  }

  handleGuest = (redirectAfterLogin) => {
    const { location, history } = this.props;

    const email = QueryString.parse(location.search).email;
    const token = QueryString.parse(location.search).jwt;
    const action = QueryString.parse(location.search).action;
    const id = QueryString.parse(location.search).id;
    const type = QueryString.parse(location.search).type;

    if (email && action) {
      history.push({
        pathname: '/',
        state: {
          fromResolver: true,
          action,
          token,
          email,
          type,
          id,
          redirectAfterLogin,
        },
      });

      return;
    }

    this.setState({
      resolving: false,
    });
  };

  handleOrderEdit = () => {
    const { location, history, authenticated } = this.props;

    const orderId = QueryString.parse(location.search).orderId;
    if (!orderId) {
      this.setState({ resolving: false });
      return;
    }

    if (authenticated) {
      history.push('/order/' + orderId);
    } else {
      this.handleGuest('/order/' + orderId);
    }
  };

  handleReorder = () => {
    const { location, history, authenticated } = this.props;

    const orderId = QueryString.parse(location.search).orderId;
    if (!orderId) {
      this.setState({ resolving: false });
      return;
    }

    if (authenticated) {
      history.push('/order/' + orderId);
    } else {
      this.handleGuest('/order/' + orderId);
    }
  };

  handlePayReorder = () => {
    const { location, history, authenticated } = this.props;

    const orderId = QueryString.parse(location.search).orderId;
    if (!orderId) {
      this.setState({ resolving: false });
      return;
    }

    if (authenticated) {
      history.push('/order/' + orderId);
    } else {
      this.handleGuest('/order/' + orderId);
    }
  };

  handleListOrders = () => {
    const { history, authenticated } = this.props;

    if (authenticated) {
      history.push('/account');
    } else {
      this.handleGuest('/account');
    }
  };

  render() {
    const { resolving } = this.state;
    const { children } = this.props;

    return (
      <React.Fragment>
        {resolving && (
          <div style={{ height: '100vh' }}>
            <Spinner />
          </div>
        )}
        {!resolving && children}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, null)(withRouter(NotFoundResolver));
