import { productConstants } from '../constants';
import {
	apiGetTypes,
	apiProductsSearch,
	apiGetProductsGroupedBySubType, apiGetSubTypesByTypes
} from '../../api';

/**
 * Get products types
 * @param {array} producerIds
 * @return {Function}
 */
export function getProductsTypes(producerIds) {
	return dispatch => {
		dispatch({type: productConstants.GET_PRODUCTS_TYPES_LOADING});

		return apiGetTypes(producerIds)
			.then((types) => {
				dispatch({type: productConstants.SET_PRODUCTS_TYPES, payload: {types: types.data}});
			})
			.catch((error) => {
				dispatch({type: productConstants.GET_PRODUCTS_TYPES_ERROR, payload: {error}});
			});
	}
}

/**
 * Get products sub types
 * @param {object} options
 * @return {Function}
 */
export function searchProducts(options) {
	return dispatch => {
		dispatch({type: productConstants.SEARCH_PRODUCTS_LOADING});

		return apiProductsSearch(options)
			.then((response) => {
				dispatch({type: productConstants.SET_PRODUCTS, payload: {products: response.data}});
			})
			.catch((error) => {
				dispatch({type: productConstants.SEARCH_PRODUCTS_ERROR, payload: {error}});
			});
	}
}

/**
 * Search products grouped by sub type
 * @param {object} options
 * @param {function} onSuccess
 * @return {Function}
 */
export function searchProductsGroupedBySubType(options, onSuccess = () => {}) {
	return dispatch => {
		dispatch({type: productConstants.SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_LOADING});

		return apiGetProductsGroupedBySubType(options)
			.then((response) => {
				dispatch({type: productConstants.SET_PRODUCTS_GROUPED_BY_SUBTYPE, payload: {groups: response.data}});
				onSuccess(response.data)
			})
			.catch((error) => {
				dispatch({type: productConstants.SEARCH_PRODUCTS_GROUPED_BY_SUBTYPE_ERROR, payload: {error}});
			});
	}
}

/**
 * Get subtypes types by types
 * @param {any} options
 * @return {Function}
 */
export function getSubTypesGroupedByType(options) {
	return dispatch => {
		dispatch({type: productConstants.GET_SUBTYPES_BY_TYPE_LOADING});
		
		return apiGetSubTypesByTypes(options)
			.then((types) => {
				dispatch({type: productConstants.SET_SUBTYPES_BY_TYPE, payload: {types: types.data}});
			})
			.catch((error) => {
				dispatch({type: productConstants.GET_SUBTYPES_BY_TYPE_ERROR, payload: {error}});
			});
	}
}