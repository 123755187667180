import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import BlackLabel from '../BlackLabel/BlackLabel';

const propTypes = {
  label: PropTypes.string,
  formControlClassName: PropTypes.string,
};

const styles = {
  icon: {
    fontSize: 34,
    top: 0,
    fill: '#E0E0E0',
  },
  select: {
    padding: '8px 0px 15px 0px',
  },
};

const BlackSelect = (props) => {
  const { formControlClassName, classes, helperText, children, label, styleBold, disabledCursor, ...other } = props;

  const theme = createTheme({
    overrides: {
      MuiSelect: {
        root: {
          fontSize: 14,
          color: `#363636`,
          ...(!!disabledCursor
            ? {
                '&$disabled': {
                  cursor: `${disabledCursor} !important`,
                },
              }
            : {}),
        },
        icon: {
          fill: '#363636',
          fontSize: 22,
        },
      },
      MuiInput: {
        root: {
          fontSize: 14,
          color: `#363636`,
          '&:hover': {
            '& svg': {
              fill: '#363636',
            },
          },
          ...(!!disabledCursor
            ? {
                '&$disabled': {
                  cursor: `${disabledCursor} !important`,
                },
              }
            : {}),
        },
        underline: {
          '&:after': {
            borderBottom: `1px solid #707070`,
          },
          '&$focused:after': {
            borderBottomColor: `#E0E0E0`,
          },
          '&$error:after': {
            borderBottomColor: `#E0E0E0`,
          },
          '&:before': {
            borderBottom: `1px solid #E0E0E0`,
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `1px solid #707070`,
          },
          '&$disabled:before': {
            borderBottom: `1px dotted #E0E0E0`,
          },
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl className={formControlClassName}>
        {label && <BlackLabel styleBold={!!styleBold}>{label}</BlackLabel>}
        <Select
          IconComponent={KeyboardArrowDownIcon}
          classes={{ icon: classes.icon, select: !!styleBold && classes.select }}
          {...other}>
          {children}
        </Select>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </MuiThemeProvider>
  );
};

BlackSelect.propTypes = propTypes;

export default withStyles(styles)(BlackSelect);
