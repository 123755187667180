const styles = () => ({
  wrapper: {
    paddingBottom: 37,
  },
  message: {
    marginBottom: 10,
  },
  blockWrapper: {
    width: '100%',
    maxWidth: 500,
    marginBottom: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  controls: {
    marginBottom: 20,
    width: '100%',
    textAlign: 'center',
  },
  control: {
    borderRadius: 40,
    width: '100%',
    fontSize: 12,
    textTransform: 'uppercase',
  },
});

export default styles;
