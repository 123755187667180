import {
  ORDER_CHANGE,
  ORDER_LOADING,
  ORDER_ERROR,
  ORDER_DISMISS_ERROR,
  ORDER_ADD_VARIANT,
  ORDER_DISMISS_LAST_ADDED,
  ORDER_ADD_VARIANT_LOADING,
  ORDER_CLOSE,
  ORDER_OPEN,
  ORDER_TOGGLE,
} from '../constants';

const initialState = {
  object: false,
  active: false,

  loading: false,
  completeLoading: false,
  addVariantLoading: false,

  lastAddedVariant: false,
  lastAddedProduct: false,

  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_ADD_VARIANT: {
      const {order, variant, product} = action.payload;
      return {
        ...state,
        object: order,
        lastAddedVariant: variant,
        lastAddedProduct: product,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false
      }
    }
    case ORDER_DISMISS_LAST_ADDED: {
      return {
        ...state,
        lastAddedVariant: false,
        completeLoading: false,
        lastAddedProduct: false,
        loading: false,
        addVariantLoading: false,
        shippingLoading: false
      }
    }
    case ORDER_CHANGE: {
      const {order} = action.payload;

      return {
        ...state,
        object: order,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false
      }
    }
    case ORDER_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case ORDER_ADD_VARIANT_LOADING: {
      return {
        ...state,
        addVariantLoading: true
      }
    }
    case ORDER_ERROR: {
      const {error} = action.payload;
      return {
        ...state,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        error: error
      }
    }
    case ORDER_DISMISS_ERROR: {
      return {
        ...state,
        loading: false,
        completeLoading: false,
        addVariantLoading: false,
        shippingLoading: false,
        error: ""
      }
    }
    case ORDER_CLOSE: {
      return {
        ...state,
        active: false
      }
    }
    case ORDER_TOGGLE: {
      return {
        ...state,
        active: !state.active
      }
    }
    case ORDER_OPEN: {
      return {
        ...state,
        active: true,
      }
    }
    default:
      return state;
  }
};

export default reducer;

