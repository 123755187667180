/**
 * Checks if object has nested prop
 * @param obj
 * @returns {boolean}
 */
export const checkNested = function (obj /*, level1, level2, ... levelN*/) {
  let args = Array.prototype.slice.call(arguments, 1);
  for (let i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
};

/**
 * Checks whether object is empty or not
 * @param obj
 * @returns {boolean}
 */
export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Make array [0,1,2,3... 99]
 * @param {number} to
 * @returns {number[]}
 */
export const fillArrayNumbersTo = (to = 0) => {
  return Array(to)
    .fill()
    .map((item, index) => index);
};

export const isArrayWithIdsEquals = (arrayOne, arrayTwo) => {
  if (!Array.isArray(arrayOne) || !Array.isArray(arrayTwo)) return false;
  if (arrayOne?.length !== arrayTwo.length) return false;
  let isEquals = true;

  arrayOne.forEach((item, index) => {
    if (!item.id) isEquals = false;

    if (!!arrayTwo[index] && arrayTwo[index].id !== item.id) isEquals = false;
  });

  return isEquals;
};
