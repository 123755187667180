class Products {

	constructor(products = []) {
		this.raw = products.data;
		this.pagination = products.meta;
	}

	getProducts() {
		// TODO: sometimes the raw is undefined, its strange need to fix
		console.log('Products.called', this.raw);
		// We need only products with at least one active variant
		return this.raw ? this.raw.filter(item =>
			item.variants &&
			Array.isArray(item.variants) &&
			item.variants.filter(variant => variant.active).length > 0
		) : [];
	}

}

export default Products;
