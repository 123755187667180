import React from 'react';
import { load } from '../helpers';

const PageLayout = load(React.lazy(() => import('./PageLayout/PageLayout')));
const Header = load(React.lazy(() => import('./Header')));
const FellowFarmerDialog = load(React.lazy(() => import('./FellowFarmerDialog/FellowFarmerDialog')));
const NewFellowFarmerDialog = load(React.lazy(() => import('./NewFellowFarmerDialog/NewFellowFarmerDialog')));
const FellowFarmerDialogActions = load(
  React.lazy(() => import('./FellowFarmerDialogActions/FellowFarmerDialogActions'))
);
const ResponsiveDrawer = load(React.lazy(() => import('./ResponsiveDrawer/ResponsiveDrawer')));
const TemporaryDrawer = load(React.lazy(() => import('./TemporaryDrawer/TemporaryDrawer')));
const SubHeader = load(React.lazy(() => import('./SubHeader/SubHeader')));
const Spinner = load(React.lazy(() => import('./Spinner/Spinner')));
const Notification = load(React.lazy(() => import('./Notification/Notification')));
const LinkButton = load(React.lazy(() => import('./LinkButton/LinkButton')));
const QuantitySwitcher = load(React.lazy(() => import('./QuantitySwitcher/QuantitySwitcher')));
const EditableNote = load(React.lazy(() => import('./EditableNote/EditableNote')));
const AccountAddress = load(React.lazy(() => import('./AccountAddress/AccountAddress')));
const PersonalInfo = load(React.lazy(() => import('./PersonalInfo/PersonalInfo')));
const ErrorBox = load(React.lazy(() => import('./ErrorBox/ErrorBox')));
const MessageBox = load(React.lazy(() => import('./MessageBox/MessageBox')));
const Quantity = load(React.lazy(() => import('./Quantity/Quantity')));
const OutsideAlerter = load(React.lazy(() => import('./OutsideAlerter/OutsideAlerter')));
const OrderSummary = load(React.lazy(() => import('./OrderSummary/OrderSummary')));
const OrderSummaryV2 = load(React.lazy(() => import('./OrderSummary/OrderSummaryV2')));
const OrdersTable = load(React.lazy(() => import('./OrdersTable/OrdersTable')));
const OrderInfo = load(React.lazy(() => import('./OrderInfo/OrderInfo')));
const Badge = load(React.lazy(() => import('./Badge/Badge')));
const BadgeColors = load(React.lazy(() => import('./Badge/BadgeColors')));
const ConfirmContent = load(React.lazy(() => import('./ConfirmContent/ConfirmContent')));
const AdditionalAccountInfo = load(React.lazy(() => import('./AdditionalAccountInfo/AdditionalAccountInfo')));
const EmailSubscriptionsTable = load(React.lazy(() => import('./EmailSubscriptionsTable/SubscriptionsTable')));
const EmailSubscriptionsTableCell = load(React.lazy(() => import('./EmailSubscriptionsTable/TableCell')));
const PaymentMethod = load(React.lazy(() => import('./PaymentMethod/PaymentMethod')));
const StripeBankAccount = load(React.lazy(() => import('./PaymentMethod/StripeBankAccount')));
const StripeCard = load(React.lazy(() => import('./PaymentMethod/StripeBankAccount')));
const StripeBankAccountsRadioGroup = load(
  React.lazy(() => import('./StripeBankAccountsRadioGroup/StripeBankAccountsRadioGroup'))
);
const StripePaymentCardsRadioGroup = load(
  React.lazy(() => import('./StripePaymentCardsRadioGroup/StripePaymentCardsRadioGroup'))
);
const AccountPaymentMethodCardDialog = load(
  React.lazy(() => import('./AccountPaymentMethodCardDialog/AccountPaymentMethodCardDialog'))
);
const BankAccountFormDialog = load(React.lazy(() => import('./AccountPaymentMethodDialog/AccountPaymentMethodDialog')));
const CheckoutPaymentMethodCardDialog = load(
  React.lazy(() => import('./CheckoutPaymentMethodCardDialog/CheckoutPaymentMethodCardDialog'))
);
const CheckoutPaymentMethodBankAccountDialog = load(
  React.lazy(() => import('./CheckoutPaymentMethodBankAccountDialog/CheckoutPaymentMethodBankAccountDialog'))
);
const PaymentMethodPanel = load(React.lazy(() => import('./PaymentMethodPanel/PaymentMethodPanel')));
const DialogTitleHighlighted = load(React.lazy(() => import('./DialogTitleHighlighted/DialogTitleHighlighted')));
const DialogTitleUnderlined = load(React.lazy(() => import('./DialogTitleUnderlined/DialogTitleUnderlined')));
const AccountManuallyOrAutomaticPaymentDialog = load(
  React.lazy(() => import('./AccountManuallyOrAutomaticPaymentDialog/AccountManuallyOrAutomaticPaymentDialog'))
);
const StripePaymentCardsRadioGroupItemLabel = load(
  React.lazy(() => import('./StripePaymentCardsRadioGroup/StripePaymentCardsRadioGroupItemLabel'))
);
const StripeBankAccountsRadioGroupItemLabel = load(
  React.lazy(() => import('./StripeBankAccountsRadioGroup/StripeBankAccountsRadioGroupItemLabel'))
);
const FullScreenDialog = load(React.lazy(() => import('./FullScreenDialog/FullScreenDialog')));
const ResizableImage = load(React.lazy(() => import('./ResizableImage/ResizableImage')));
const AddressRadioGroup = load(React.lazy(() => import('./AddressRadioGroup/AddressRadioGroup')));
const Icon = load(React.lazy(() => import('./Icon/Icon')));
const LandingPageHeader = load(React.lazy(() => import('./LandingPageHeader/LandingPageHeader')));
const CheckoutAddress = load(React.lazy(() => import('./CheckoutAddress/CheckoutAddress')));
const AddressLabel = load(React.lazy(() => import('./AddressRadioGroup/AddressLabel')));
const CheckoutAddressRadioGroup = load(React.lazy(() => import('./CheckoutAddressRadioGroup/AddressRadioGroup')));
const InfoBox = load(React.lazy(() => import('./InfoBox/InfoBox')));
const BackIcon = load(React.lazy(() => import('./BackIcon/BackIcon')));
const OrderLineItems = load(React.lazy(() => import('./OrderLineItems/OrderLineItems')));
const DropDownMenu = load(React.lazy(() => import('./DropDownMenu/DropDownMenu')));
const IconWithTextButton = load(React.lazy(() => import('./IconWithTextButton/IconWithTextButton')));
const EmailSubscriptionsMobile = load(React.lazy(() => import('./EmailSubscriptionsMobile/EmailSubscriptionsMobile')));
const ErrorFormattedMessage = load(React.lazy(() => import('./ErrorFormattedMessage/ErrorFormattedMessage')));
const Footer = load(React.lazy(() => import('./Footer/Footer')));
const SceneTitle = load(React.lazy(() => import('./SceneTitle/SceneTitle')));
const PlaidLink = load(React.lazy(() => import('./PlaidLink/PlaidLink')));
const OutdatedShipping = load(React.lazy(() => import('./OutdatedShipping')));
const Signature = load(React.lazy(() => import('./Signature/Signature')));
const TextButton = load(React.lazy(() => import('./TextButton')));
const OrderProblem = load(React.lazy(() => import('./OrderProblem')));
const GuestHeader = load(React.lazy(() => import('./GuestHeader')));
const HeaderButton = load(React.lazy(() => import('./HeaderButton')));
const MarketBox = load(React.lazy(() => import('./MarketBox/MarketBox')));
const ProducerBox = load(React.lazy(() => import('./ProducerBox/ProducerBox')));
const SubTypeBox = load(React.lazy(() => import('./SubTypeBox/SubTypeBox')));
const SearchInput = load(React.lazy(() => import('./SearchInput')));
const ShippingLocationChip = load(React.lazy(() => import('./ShippingLocationChip')));
const ShippingTypeAndDateChip = load(React.lazy(() => import('./ShippingTypeAndDateChip')));
const SearchHits = load(React.lazy(() => import('./SearchHits/SearchHits')));
const SearchHitsWrapper = load(React.lazy(() => import('./SearchHitsWrapper/SearchHitsWrapper')));
const CarouselArrow = load(React.lazy(() => import('./CarouselArrow')));
const CarouselSlider = load(React.lazy(() => import('./CarouselSlider/CarouselSlider')));
const ProductBox = load(React.lazy(() => import('./ProductBox')));
const ProducersChip = load(React.lazy(() => import('./ProducersChip')));
const LocationSelect = load(React.lazy(() => import('./LocationSelect')));
const ProducersSelector = load(React.lazy(() => import('./ProducersSelector')));
const BlackEditableNote = load(React.lazy(() => import('./BlackEditableNote/BlackEditableNote')));
const BlackInfoBox = load(React.lazy(() => import('./BlackInfoBox/BlackInfoBox')));
const BlackQuantitySwitcher = load(React.lazy(() => import('./BlackQuantitySwitcher/BlackQuantitySwitcher')));
const GooglePlacesSearch = load(React.lazy(() => import('./GooglePlacesSearch')));
const DialogActionsWrapper = load(React.lazy(() => import('./DialogActionsWrapper')));
const DialogContentWrapper = load(React.lazy(() => import('./DialogContentWrapper')));
const MainContainerWrapper = load(React.lazy(() => import('./MainContainerWrapper')));
const GridAsCarouselWrapper = load(React.lazy(() => import('./GridAsCarouselWrapper')));
const GlobalFooter = load(React.lazy(() => import('./GlobalFooter')));
const MobileFooterMenu = load(React.lazy(() => import('./MobileFooterMenu')));
const MobileOnlySearchInput = load(React.lazy(() => import('./MobileOnlySearchInput')));
const SingleLocationLabel = load(React.lazy(() => import('./SingleLocationLabel')));
const ListViewForProducts = load(React.lazy(() => import('./ListViewForProducts')));
const ChangeViewButtons = load(React.lazy(() => import('./ChangeViewButtons')));
const InfiniteVerticalProductsScroll = load(React.lazy(() => import('./InfiniteVerticalProductsScroll')));
const InfiniteVerticalSubTypesScrollBoxView = load(React.lazy(() => import('./InfiniteVerticalSubTypesScrollBoxView')));
const InfiniteHorizontalProductsScroll = load(React.lazy(() => import('./InfiniteHorizontalProductsScroll')));
const SimpleSpinner = load(React.lazy(() => import('./SimpleSpinner')));
const GoogleMapDeliveryTracker = load(React.lazy(() => import('./GoogleMapDeliveryTracker')));
const Accordion = load(React.lazy(() => import('./Accordion/Accordion')));
const SubscriptionBox = load(React.lazy(() => import('./SubscriptionBox')));
const DoubleClickWrapper = load(React.lazy(() => import('./DoubleClickWrapper')));
const CheckoutAvailabilityItem = load(React.lazy(() => import('./CheckoutAvailabilityItem')));
const CheckoutAvailabilityMarket = load(React.lazy(() => import('./CheckoutAvailabilityMarket')));
const MainScreenSwitcher = load(React.lazy(() => import('./MainScreenSwitcher')));
const NewHeader = load(React.lazy(() => import('./NewHeader')));
const NewHeaderButton = load(React.lazy(() => import('./NewHeaderButton')));
const StickyContainer = load(React.lazy(() => import('./StickyContainer')));
const DraggableBottomPanel = load(React.lazy(() => import('./DraggableBottomPanel')));
const ProducerSidebarMarkets = load(React.lazy(() => import('./ProducerSidebarMarkets')));
const LineItemCamelPrice = load(React.lazy(() => import('./LineItemCamelPrice')));
const HtmlRichTextViewer = load(React.lazy(() => import('./HtmlRichTextViewer')));
const InfiniteVerticalLoaderBothSides = load(React.lazy(() => import('./InfiniteVerticalLoaderBothSides')));
const BlackPhoneField = load(React.lazy(() => import('./BlackPhoneField/BlackPhoneField')));
const ProductBoxSkeleton = load(React.lazy(() => import('./ProductBox/skeleton')));
const GreenImageDescription = load(React.lazy(() => import('./GreenImageDescription')));
const AppUpdater = load(React.lazy(() => import('./AppUpdater')));
const SkeletonBoxes = load(React.lazy(() => import('./SkeletonBoxes')));
const TableSkeleton = load(React.lazy(() => import('./TableSkeleton')));
const SkeletonCartItems = load(React.lazy(() => import('./SkeletonCartItems')));
const HorizontalMobileMenu = load(React.lazy(() => import('./HorizontalMobileMenu')));
const CustomScrollBar = load(React.lazy(() => import('./CustomScrollBar')));
const AddDiscountDialog = load(React.lazy(() => import('./AddDiscountDialog')));
const BasicTable = load(React.lazy(() => import('./BasicTable')));

import {
  HeaderText,
  SmallHeader,
  SubHeaderText,
  LargeText,
  TextRegular,
  SmallText,
  CardHeader,
  TextLink,
  ButtonText,
  cardHeaderStyles,
  headerTextStyles,
  largeTextStyles,
  smallHeaderStyles,
  smallTextStyles,
  subHeaderStyles,
  textLinkStyles,
  textRegularStyles,
  buttonTextStyles,
} from './StyledText';

// component with MuiThemeProvider can make issues with css order if lazy
import PrimaryFormLabel from './PrimaryFormLabel/PrimaryFormLabel';
import BlackButton from './BlackButton';
import OutlinedButton from './OutlinedButton';
import BlackCheckbox from './BlackCheckbox/BlackCheckbox';
import BlackLabel from './BlackLabel/BlackLabel';
import BlackRadio from './BlackRadio/BlackRadio';
import BlackSelect from './BlackSelect/BlackSelect';
import BlackTextField from './BlackTextField/BlackTextField';
import BlackThirdButton from './BlackThirdButton/BlackThirdButton';
import FourthButton from './FourthButton/FourthButton';
import HeaderRoundButton from './HeaderRoundButton';
import PrimaryButton from './PrimaryButton/PrimaryButton';
import PrimaryCheckbox from './PrimaryCheckbox/PrimaryCheckbox';
import PrimaryLabel from './PrimaryLabel/PrimaryLabel';
import PrimaryRadio from './PrimaryRadio/PrimaryRadio';
import PrimarySelect from './PrimarySelect/PrimarySelect';
import PrimaryTextField from './PrimaryTextField/PrimaryTextField';
import SecondaryButton from './SecondaryButton/SecondaryButton';
import BlackLinkButton from './BlackLinkButton/BlackLinkButton';
import ThirdButton from './ThirdButton/ThirdButton';

export {
  Accordion,
  PageLayout,
  Header,
  FellowFarmerDialog,
  NewFellowFarmerDialog,
  FellowFarmerDialogActions,
  ResponsiveDrawer,
  TemporaryDrawer,
  SubHeader,
  Spinner,
  Notification,
  LinkButton,
  QuantitySwitcher,
  EditableNote,
  AccountAddress,
  PersonalInfo,
  ErrorBox,
  MessageBox,
  Quantity,
  OutsideAlerter,
  MarketBox,
  ProducerBox,
  SubTypeBox,
  SearchHits,
  SearchHitsWrapper,
  OrderSummary,
  OrderSummaryV2,
  OrdersTable,
  OrderInfo,
  BadgeColors,
  Badge,
  ConfirmContent,
  AdditionalAccountInfo,
  EmailSubscriptionsTable,
  EmailSubscriptionsTableCell,
  StripeBankAccount,
  StripeCard,
  PaymentMethod,
  StripeBankAccountsRadioGroup,
  StripePaymentCardsRadioGroup,
  AccountPaymentMethodCardDialog,
  BankAccountFormDialog,
  PaymentMethodPanel,
  AccountManuallyOrAutomaticPaymentDialog,
  DialogTitleHighlighted,
  DialogTitleUnderlined,
  StripePaymentCardsRadioGroupItemLabel,
  StripeBankAccountsRadioGroupItemLabel,
  FullScreenDialog,
  ResizableImage,
  AddressRadioGroup,
  // Buttons
  PrimaryButton,
  SecondaryButton,
  ThirdButton,
  FourthButton,
  // Label
  PrimaryLabel,
  // Text Fields
  PrimaryTextField,
  Icon,
  // Select
  PrimarySelect,
  // Headers
  LandingPageHeader,
  CheckoutAddress,
  AddressLabel,
  CheckoutAddressRadioGroup,
  PrimaryRadio,
  PrimaryCheckbox,
  PrimaryFormLabel,
  CheckoutPaymentMethodBankAccountDialog,
  CheckoutPaymentMethodCardDialog,
  InfoBox,
  BackIcon,
  OrderLineItems,
  DropDownMenu,
  IconWithTextButton,
  ErrorFormattedMessage,
  EmailSubscriptionsMobile,
  SceneTitle,
  Footer,
  PlaidLink,
  OutdatedShipping,
  Signature,
  BlackButton,
  OutlinedButton,
  OrderProblem,
  GuestHeader,
  HeaderButton,
  SearchInput,
  ShippingLocationChip,
  ShippingTypeAndDateChip,
  CarouselArrow,
  CarouselSlider,
  ProductBox,
  ProducersChip,
  ProducersSelector,
  LocationSelect,
  BlackLinkButton,
  BlackCheckbox,
  BlackEditableNote,
  BlackInfoBox,
  BlackLabel,
  BlackQuantitySwitcher,
  BlackRadio,
  BlackSelect,
  BlackTextField,
  GooglePlacesSearch,
  TextButton,
  HeaderRoundButton,
  BlackThirdButton,
  DialogContentWrapper,
  DialogActionsWrapper,
  MainContainerWrapper,
  GridAsCarouselWrapper,
  GlobalFooter,
  MobileFooterMenu,
  MobileOnlySearchInput,
  SingleLocationLabel,
  ListViewForProducts,
  ChangeViewButtons,
  InfiniteVerticalProductsScroll,
  InfiniteVerticalSubTypesScrollBoxView,
  InfiniteHorizontalProductsScroll,
  SimpleSpinner,
  GoogleMapDeliveryTracker,
  SubscriptionBox,
  DoubleClickWrapper,
  CheckoutAvailabilityItem,
  CheckoutAvailabilityMarket,
  MainScreenSwitcher,
  NewHeader,
  NewHeaderButton,
  StickyContainer,
  DraggableBottomPanel,
  ProducerSidebarMarkets,
  LineItemCamelPrice,
  HtmlRichTextViewer,
  InfiniteVerticalLoaderBothSides,
  BlackPhoneField,
  ProductBoxSkeleton,
  SkeletonBoxes,
  TableSkeleton,
  SkeletonCartItems,
  GreenImageDescription,
  // styled text
  HeaderText,
  SmallHeader,
  SubHeaderText,
  LargeText,
  TextRegular,
  SmallText,
  CardHeader,
  TextLink,
  ButtonText,
  cardHeaderStyles,
  headerTextStyles,
  largeTextStyles,
  smallHeaderStyles,
  smallTextStyles,
  subHeaderStyles,
  textLinkStyles,
  textRegularStyles,
  buttonTextStyles,
  AppUpdater,
  HorizontalMobileMenu,
  CustomScrollBar,
  AddDiscountDialog,
  BasicTable,
};
