const styles = theme => ({
  hitImage: {
    maxWidth: 100,
    marginRight: 10
  },
  listItem: {
    minHeight: 30,
    alignItems: 'flex-start',
    borderBottom: '1px solid #eeeeee',
    paddingLeft: 18
  },
  description: {
    fontSize: 12,
    marginTop: 0,
    marginBottom: 5,
  },
  productName: {
    cursor: 'pointer',
    fontSize: 14,
    marginTop: 0,
    marginBottom: 5,
    fontWeight: 'bold'
  },
  producerName: {
    fontSize: 12,
  },
  productNameCompact: {
    display: 'inline',
    fontSize: 14,
    marginTop: 0,
    marginBottom: 5,
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  productInfo: {
    flex: 1,
    justifyContent: 'row',
    alignItems: 'flex-start',
  },
  productInfoCompact: {
    extend: 'productInfo',
    flexDirection: 'column',
    alignItems: 'center',
  },
  price: {
    display: 'inline',
    fontSize: 12,
    padding: 0,
    marginBottom: 5,
    textAlign: 'left',
  },
  priceCompact: {
    extend: 'price',
    marginRight: 10
  },
  variantNameInput: {
    fontSize: 12
  },
  select: {
    marginLeft: 10,
    width: 100
  },
  addToCart: {
    width: 30,
    height: 30,
    marginLeft: 'auto',
    minHeight: 30,
    '&:hover': {
      boxShadow: '0 0 10px rgba(238,238,238,0.8)'
    },
    zIndex: 50,
  },
  quantity: {
    position: 'absolute',
    right: 10,
    top: 10,
    backgroundColor: '#ffffff',
    borderRadius: 6,
    boxShadow: '0 0 10px rgba(0,0,0,0.8)',
    zIndex: 100,
  },
  quantitySwitcherRoot: {
    justifyContent: 'center',
    width: 'auto'
  }
});

export default styles;
