import { CUSTOMER_CLEAR_AVAILABLE_ADDRESSES, marketConstants } from '../constants';
import { getObjectFromStorage, saveObjectToStorage } from '../../helpers';
import { OrderEntity } from '../../entities';
import { updateAvailableAddresses } from './customer';

const STORAGE_KEY = 'market.data';

export const marketActions = {
  /**
   * Init market
   * @return {function(*)}
   */
  initialize: () => (dispatch) => {
    const market = getObjectFromStorage(STORAGE_KEY);

    market && dispatch(marketActions.setMarket(market));
  },

  /**
   * Set market
   * @param {object} market
   * @param {function} onSuccess
   * @param {boolean} silent
   * @return {function(*)}
   */
  setMarket:
    (market, onSuccess, silent = false) =>
    (dispatch) => {
      dispatch(updateAvailableAddresses(market.id));

      dispatch({
        type: marketConstants.SET_MARKET,
        payload: {
          market,
        },
      });

      !silent && dispatch({ type: marketConstants.MARKETS_UPDATER });
      saveObjectToStorage(STORAGE_KEY, market);
      onSuccess && onSuccess(market);
    },

  /**
   * Set preselected market automatically
   * @param {object} market
   * @param {function} onSuccess
   * @return {function(*)}
   */
  setMarketAutomatic: (market, onSuccess) => (dispatch) => {
    dispatch({
      type: marketConstants.SET_MARKET_AUTOMATIC,
      payload: {
        market,
      },
    });

    onSuccess && onSuccess(market);
  },

  /**
   * Set market by draft
   * @param {object} draft
   * @param {function} onSuccess
   * @param {boolean} silent
   * @return {function(*)}
   */
  setMarketByDraft: (draft, onSuccess, silent) => (dispatch) => {
    // In case draft has location we should set it to Redux market container
    OrderEntity.hasLocation(draft) &&
      dispatch(marketActions.setMarket(OrderEntity.getLocation(draft), onSuccess, silent));
  },

  /**
   * Clear market
   * @return {function(*)}
   */
  clearMarket: () => (dispatch) => {
    dispatch({ type: marketConstants.CLEAR_MARKET });
    dispatch({ type: marketConstants.MARKETS_UPDATER });
    dispatch({ type: CUSTOMER_CLEAR_AVAILABLE_ADDRESSES });
    localStorage.removeItem(STORAGE_KEY);
  },

  silentClearClientSideMarket: () => (dispatch) => {
    dispatch({ type: marketConstants.CLEAR_MARKET });
    localStorage.removeItem(STORAGE_KEY);
  },

  // Markets dialog
  openMarketsDialog: (forProduct, onDone) => (dispatch) => {
    dispatch({
      type: marketConstants.OPEN_MARKETS_DIALOG,
      payload: {
        forProduct,
        onDone: onDone ? onDone : () => {},
      },
    });
  },

  closeMarketsDialog: () => (dispatch) => dispatch({ type: marketConstants.CLOSE_MARKETS_DIALOG }),
};
