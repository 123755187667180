export const ORDER_CHANGE = 'ORDER_CHANGE';
export const ORDER_ADD_VARIANT = 'ORDER_ADD_VARIANT';
export const ORDER_ADD_VARIANT_LOADING = 'ORDER_ADD_VARIANT_LOADING';
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_ERROR = 'ORDER_ERROR';
export const ORDER_DISMISS_ERROR = 'ORDER_DISMISS_ERROR';
export const ORDER_DISMISS_LAST_ADDED = 'ORDER_DISMISS_LAST_ADDED';
export const ORDER_OPEN = 'ORDER_OPEN';
export const ORDER_CLOSE = 'ORDER_CLOSE';
export const ORDER_TOGGLE = 'ORDER_TOGGLE';




