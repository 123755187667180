class ProductVariant {

	constructor(variant = {}) {
		this.active = variant.active
			? variant.active
			: false;
		this.customerIds = variant.customerIds || [];
		this.id = variant.id || '';
		this.name = variant.name || '';
		this.prices = variant.prices || [];
		this.type = variant.type || '';
		this.unit = variant.unit || '';
		this.unitQuantity = variant.unitQuantity || ''
	}

	static getVariantUnit = (variant, emptyIfSameAsName, prefix, emptyIfEmptyName) => {
		variant = new ProductVariant(variant);

		if (emptyIfEmptyName && !variant.name)
			return '';

		if (emptyIfSameAsName && (variant.name === variant.unit))
			return '';

		prefix = prefix || "";
		return prefix + variant.unit;
	}

}

export default ProductVariant;
