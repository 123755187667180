import { getPublicRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

export const apiCustomerQrCodeVerify = function (code, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PUBLIC_QR_CODE_VERIFY, 'code', code);
  let config = getPublicRequestConfig(REQUEST_TYPES.GET, url);

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * register coworker by email
 * @param data
 * @param {string} data.fromQrCode - is required
 * @param {string} data.email - is required
 * @param onSuccess
 * @param onError
 */
export const apiCustomerRegisterCoworkerByEmail = function (data, onSuccess, onError) {
  let config = getPublicRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PUBLIC_QR_VERIFY_COWORKER_EMAIL);

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * register coworker by email
 * @param data
 * @param {string} data.fromQrCode - is required
 * @param {string} data.verificationCode - is required
 * @param onSuccess
 * @param onError
 */
export const apiCustomerRegisterCoworkerByEmailCodeVerify = function (data, onSuccess, onError) {
  let config = getPublicRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PUBLIC_QR_VERIFY_COWORKER_EMAIL_INVITATION);

  config.data = data;

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
