import axios from 'axios';
import { getRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Get types
 * @param {ProductTypesParams} options - options of search types
 * @return {Promise<any>|Promise<T | never>}
 */
export const apiGetTypes = function (options) {
  return new Promise((resolve, reject) => {
    const config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_TYPES);

    config.params = options.toData();

    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Get product
 * @param {string} id - product id
 * @param {string} locationId - location id
 * @return {Promise<any>|Promise<T | never>}
 */
export const apiGetProductById = function (id, locationId) {
  return new Promise((resolve, reject) => {
    let url = replaceUrlPlaceholder(API_END_POINTS.GET_PRODUCT, 'id', id);
    let config = getRequestConfig(REQUEST_TYPES.GET, url);
    let params = {};

    locationId && (params = { ...params, ...{ locationId } });

    config.params = params;

    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Subtypes by types paginated
 * @param {SubtypesGroupedByTypeParams} options - search options
 */
export const apiGetSubTypesByTypes = function (options) {
  return new Promise((resolve, reject) => {
    let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.SUBTYPES_GROUPED_BY_TYPE_PAGINATED);

    config.params = options.toData();
    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Subtypes by types all
 * @param {SubtypesGroupedByTypeParams} options - search options
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetAllSubTypesGroupedByTypes = function (options, onSuccess, onError) {
  return new Promise((resolve, reject) => {
    let config = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.SUBTYPES_GROUPED_BY_TYPE);

    config.params = options.toData();
    axios(config)
      .then((response) => {
        onSuccess && onSuccess(response);
        resolve(response);
      })
      .catch((error) => {
        onError && onError(error);
        reject(error);
      });
  });
};
