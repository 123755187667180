import { draftConstants } from '../constants';
import {
  // Draft
  apiDraftAddVariant,
  apiDraftRemoveLineItem,
  apiDraftSetShipping,
  apiDraftComplete,
  apiDraftGetLast,
  apiDraftSetNote,
  apiDraftAddCustomer,
  apiDraftSetPaymentMethod,
  // Orders
  apiOrderReorder,
  // Line item
  apiDraftLineItemSetNote,
  apiDraftLineItemSetQuantity,
  apiDraftShippingOptionsClear,
  apiDraftRemoveLineItems,
  apiDraftRemoveAllLineItems,
} from '../../api';
import {
  showNotification,
  initMarkets,
  changeProducers,
  marketActions,
  refreshShipping,
  silenceRefreshShippingOptions,
} from '../actions';
import { OrderEntity } from '../../entities';
import { getObjectFromStorage, saveObjectToStorage } from '../../helpers';
import { clearCache } from 'react-router-cache-route';

const STORAGE_SHIPPING_TYPE_KEY = 'draft.shipping.type';
const STORAGE_PERSONAL_PRODUCER_SHIPPING_OPTIONS_KEY = 'draft.personalProducerShippingOptions';

const error = (dispatch, message) => {
  return (errorObject) => {
    dispatch({
      type: draftConstants.DRAFT_ERROR,
      payload: {
        error: message,
        errorObject: (errorObject && errorObject.response && errorObject.response.data) || {},
      },
    });
  };
};

export function initDraft(onSuccess) {
  return (dispatch) => {
    const shippingTypeClientSide = getObjectFromStorage(STORAGE_SHIPPING_TYPE_KEY);
    const personalProducerShippingOptions = getObjectFromStorage(STORAGE_PERSONAL_PRODUCER_SHIPPING_OPTIONS_KEY);

    // Getting last draft order
    return apiDraftGetLast(
      (response) => {
        const draft = response.data;

        // Set market by draft
        dispatch(marketActions.setMarketByDraft(draft));

        shippingTypeClientSide && dispatch(setShippingTypeClientSide(shippingTypeClientSide));
        personalProducerShippingOptions &&
          dispatch(savePersonalProducerShippingOptions(personalProducerShippingOptions));

        dispatch({
          type: draftConstants.DRAFT_CHANGE,
          payload: { draft },
        });
        onSuccess && onSuccess();
      },
      () => {
        dispatch({
          type: draftConstants.DRAFT_ERROR,
          payload: { error: 'Something went wrong while getting your last order' },
        });
      }
    );
  };
}

export function setDraft(draft) {
  return (dispatch) => {
    // Set market by draft
    dispatch(marketActions.setMarketByDraft(draft));

    dispatch({
      type: draftConstants.DRAFT_CHANGE,
      payload: { draft: draft },
    });
  };
}

export function refreshDraft(onSuccess, silent = false) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;

    dispatch({ type: draftConstants.DRAFT_LOADING });
    // Getting last draft order
    return apiDraftGetLast(
      (response) => {
        // Checks whether shipping options changes and show notification
        if (!OrderEntity.areShippingOptionsEquals(draft, response.data)) {
          dispatch({ type: draftConstants.DRAFT_SHIPPING_OUTDATED_NOTIFY });
        }

        // Set market by draft
        dispatch(marketActions.setMarketByDraft(response.data, () => {}, silent));

        dispatch({
          type: draftConstants.DRAFT_CHANGE,
          payload: { draft: response.data },
        });

        // Check draft for problems
        dispatch(checkDraftForProblems(response.data));

        onSuccess && onSuccess();
      },
      () => {
        dispatch({
          type: draftConstants.DRAFT_ERROR,
          payload: { error: 'Something went wrong while refreshing order' },
        });
      }
    );
  };
}

/**
 * Adds variant to draft
 * @param {object} product
 * @param {object} variant
 * @param {function} onSuccess
 * @param {function} dialogWillOpen
 * @param {function} onError
 * @return {function(*=, *)}
 */
export function addVariantToDraft(product, variant, onSuccess, dialogWillOpen, onError) {
  const variantWithOwnShipping =
    (!!variant.availabilities && !!variant.availabilities.length) || !!product?.skipShippingCheck;

  return (dispatch, getState) => {
    const draftObj = getState().draft;
    const draft = draftObj.object;

    // disable showing shipping options dialogs on subscription variant
    if (!variantWithOwnShipping) {
      if (!draft.shipping) {
        dialogWillOpen && dialogWillOpen();

        dispatch(
          draftOpenShippingDialog(product, variant, () => dispatch(addVariantToDraft(product, variant, onSuccess)))
        );
        return;
      }

      if (draft.shipping && !draft.shipping.location) {
        dialogWillOpen && dialogWillOpen();

        dispatch(
          marketActions.openMarketsDialog(product, () => dispatch(addVariantToDraft(product, variant, onSuccess)))
        );
        return;
      }
    }

    dispatch({
      type: draftConstants.DRAFT_ADD_VARIANT_LOADING,
      payload: {
        product: product,
        variant: variant,
      },
    });

    const onAddSubscriptionItem = () => {
      const state = getState();
      const shippingOptionsMarket = state?.market?.object?.id;
      const shippingTypeClientSide = state?.draft?.shippingTypeClientSide;
      const shippingDateClientSide = state?.draft?.shippingDateClientSide;
      const subscriptionLineItems = state?.draft?.object?.subscriptionLineItems;

      return new Promise((resolve, reject) => {
        if (variantWithOwnShipping) {
          // if we have already subscriptions line items on cart we don't need to clean up
          if (subscriptionLineItems?.length) {
            resolve();
            return;
          }

          if (!!shippingOptionsMarket || !!shippingTypeClientSide || !!shippingDateClientSide) {
            dispatch(clearShippingTypeClientSide());
            dispatch(marketActions.silentClearClientSideMarket());
            dispatch(saveShippingDateClientSide(null));
          }

          if (!!draft.shipping) {
            dispatch(
              clearDraftShipping(
                () => {
                  dispatch(addVariantToDraft(product, variant, onSuccess));
                  resolve();
                },
                (e) => {
                  error(dispatch, 'Something went wrong while adding variant to draft')(e);
                  reject(e);
                }
              )
            );
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    };

    const onAdded = (draft) => {
      dispatch({
        type: draftConstants.DRAFT_ADD_VARIANT,
        payload: {
          draft: draft,
          product: product,
          variant: variant,
        },
      });
      onSuccess && onSuccess(draft);
    };

    function addVariant(dr, vr) {
      apiDraftAddVariant(
        dr,
        vr,
        {
          ...(variantWithOwnShipping ? { skipShippingCheck: true } : {}),
          ...(draftObj?.addVariantQuantity ? { quantity: draftObj.addVariantQuantity } : {}),
        },
        async (response) => {
          // Check draft for problems
          dispatch(checkDraftForProblems(response.data));

          await onAddSubscriptionItem();

          onAdded(response.data, variant);
        },
        (e) => dispatch(addVariantErrorHandler(e, product, variant, onError))
      );
    }

    if (draft) {
      // Draft exists
      addVariant(draft.id, variant.id);
    } else {
      error(dispatch, 'Draft order was not found')();
      onError && onError();
    }
  };
}

function addVariantErrorHandler(e, product, variant, onError) {
  return (dispatch) => {
    const code = e?.response?.data?.code || 0;

    switch (code) {
      case 4037: {
        // adding item to unsupported shipping options
        dispatch({
          type: draftConstants.ERROR_SUBSCRIPTION_MODAL_OPEN,
          payload: {
            product,
            variant,
            actionOnDone: (onSuccess, onError) => {
              dispatch(onCreateNewCart(product, variant, onSuccess, onError));
            },
            title: 'global.startNewCartDialogTitle',
            content: 'global.startNewCartDialogContent',
          },
        });
        break;
      }
      case 7220:
      case 7241:
      case 1632760917:
      case 7250: {
        dispatch({
          type: draftConstants.ERROR_SUBSCRIPTION_MODAL_OPEN,
          payload: {
            product,
            variant,
            title: 'error.addSubscriptionNotAllowedToFilledDraft',
            content: 'error.YouCanNotCombineSubscriptions',
          },
        });
        break;
      }
      case 7222:
      case 7240: {
        dispatch({
          type: draftConstants.ERROR_SUBSCRIPTION_MODAL_OPEN,
          payload: {
            product,
            variant,
            title: 'error.onlyOneSubscriptionAllowedInDraft',
            content: 'error.youCanOnlyBuyOneSubscription',
          },
        });
        break;
      }
      case 1633975652: {
        dispatch({
          type: draftConstants.ERROR_SUBSCRIPTION_MODAL_OPEN,
          payload: {
            product,
            variant,
            actionOnDone: (onSuccess, onError) => {
              dispatch(onCreateNewCart(product, variant, onSuccess, onError));
            },
            title: 'global.startNewCartDialogTitle',
            content: 'error.cannotMixSubscriptionsFromDifferentProducers',
          },
        });
        break;
      }
      case 1655988833: {
        dispatch({
          type: draftConstants.ERROR_SUBSCRIPTION_MODAL_OPEN,
          payload: {
            product,
            variant,
            actionOnDone: (onSuccess, onError) => {
              dispatch(onCreateNewCart(product, variant, onSuccess, onError));
            },
            title: 'global.startNewCartDialogTitle',
            content: 'error.cannotMixMultipleBillingTypesInOneSubscriptionOrder',
          },
        });
        break;
      }
      default: {
        error(dispatch, 'Something went wrong while adding variant to draft')(e);
      }
    }

    onError && onError(e);
  };
}

export function onCreateNewCart(product, variant, onSuccess, onError) {
  return (dispatch, getState) => {
    const state = getState();
    const draft = getState().draft.object;

    const shippingOptionsMarket = state?.market?.object?.id;
    const shippingTypeClientSide = state?.draft?.shippingTypeClientSide;
    const shippingDateClientSide = state?.draft?.shippingDateClientSide;
    if (!!shippingOptionsMarket || !!shippingTypeClientSide || !!shippingDateClientSide) {
      dispatch(clearShippingTypeClientSide());
      dispatch(marketActions.silentClearClientSideMarket());
      dispatch(saveShippingDateClientSide(null));
    }
    if (!!draft.shipping) {
      dispatch(
        clearDraftShipping(
          () => {
            dispatch(addVariantToDraft(product, variant, onSuccess, onError));
          },
          (e) => {
            error(dispatch, 'Something went wrong while adding variant to draft')(e);
            onError();
          },
          true
        )
      );
    } else if (draft.lineItems?.length) {
      // if we don't have shipping we need to clear all items instead
      dispatch({ type: draftConstants.DRAFT_SHIPPING_LOADING });

      apiDraftRemoveAllLineItems(
        draft,
        (response) => {
          dispatch({
            type: draftConstants.DRAFT_CHANGE,
            payload: { draft: response.data },
          });
          dispatch(addVariantToDraft(product, variant, onSuccess, onError));
        },
        (e) => {
          error(dispatch, 'Something went wrong while adding variant to draft')(e);
          onError();
        }
      );
    }
  };
}

export function showFulfilledDraftModal(product, variant) {
  return (dispatch) => {
    dispatch({
      type: draftConstants.ERROR_SUBSCRIPTION_MODAL_OPEN,
      payload: {
        product,
        variant,
        title: 'error.addSubscriptionNotAllowedToFilledDraft',
        content: 'error.youCanNotCombineSubscriptionsWithItems',
      },
    });
  };
}

export function deleteDraftLineItem(item, onSuccess) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;
    dispatch({ type: draftConstants.DRAFT_LOADING });
    apiDraftRemoveLineItem(
      draft.id,
      item.id,
      (response) => {
        onSuccess && onSuccess(response.data);

        // Clear shipping options if we remove last line item
        if (!response?.data?.lineItems?.length) {
          dispatch(clearShippingTypeClientSide());
          dispatch(saveShippingDateClientSide(null));
          dispatch(clearDraftShipping(undefined, undefined));
        } else {
          dispatch({
            type: draftConstants.DRAFT_CHANGE,
            payload: {
              draft: response.data,
            },
          });
        }
      },
      error(dispatch, 'Something went wrong while deleting product from cart')
    );
  };
}

export function setLineItemNote(note, item) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;
    dispatch({ type: draftConstants.DRAFT_LOADING });
    apiDraftLineItemSetNote(
      draft.id,
      item.id,
      note,
      (response) => {
        dispatch({
          type: draftConstants.DRAFT_CHANGE,
          payload: {
            draft: response.data,
          },
        });
      },
      error(dispatch, 'Something went wrong while setting line item note')
    );
  };
}

export function setLineItemQuantity(item, quantity, onDone, onError) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;
    dispatch({ type: draftConstants.DRAFT_LOADING });
    apiDraftLineItemSetQuantity(
      draft.id,
      item.id,
      quantity,
      (response) => {
        dispatch({
          type: draftConstants.DRAFT_CHANGE,
          payload: {
            draft: response.data,
          },
        });

        onDone && onDone(response);
      },
      (e) => {
        error(dispatch, 'Something went wrong while setting line item quantity')(e);
        onError && onError(e);
      }
    );
  };
}

export function setDraftNote(note) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;
    dispatch({ type: draftConstants.DRAFT_LOADING });
    apiDraftSetNote(
      draft.id,
      note,
      (response) => {
        dispatch({
          type: draftConstants.DRAFT_CHANGE,
          payload: {
            draft: response.data,
          },
        });
      },
      error(dispatch, 'Something went wrong while setting line item quantity')
    );
  };
}

export function dismissDraftError() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_DISMISS_ERROR });
  };
}

export function dismissDraftLastAdded() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_DISMISS_LAST_ADDED });
  };
}

export function completeDraftOrder(data, onCompleted, onError) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;

    dispatch({ type: draftConstants.DRAFT_COMPLETE_LOADING });

    const completeError = (error, message) => {
      let errorObj = {};

      if (!error?.response?.data?.code) {
        errorObj['message'] = message || 'Something went wrong';
        errorObj['code'] = 0;
      } else {
        errorObj = error?.response?.data;
      }

      if (!errorObj?.message && !!message) {
        errorObj['message'] = message;
      }

      onError && onError(errorObj);

      return () => {
        dispatch({
          type: draftConstants.DRAFT_COMPLETE_ERROR,
          payload: {
            error: errorObj,
          },
        });
      };
    };

    // TODO: Refactor callback hell
    const complete = () => {
      apiDraftSetPaymentMethod(
        draft,
        data,
        () => {
          apiDraftComplete(
            draft.id,
            (draftObjectAfterComplete) => {
              apiDraftGetLast(
                (response) => {
                  dispatch({
                    type: draftConstants.DRAFT_COMPLETE,
                    payload: {
                      newDraft: response.data,
                      draftObjectAfterComplete: draftObjectAfterComplete.data,
                    },
                  });

                  localStorage.removeItem(STORAGE_PERSONAL_PRODUCER_SHIPPING_OPTIONS_KEY);

                  // Clear market
                  // dispatch(marketActions.clearMarket());
                  onCompleted && onCompleted();
                },
                (error) => completeError(error, 'Something went wrong while getting last draft order')()
              );
            },
            (error) => completeError(error, 'Something went wrong while completing draft order')()
          );
        },
        (error) => completeError(error)()
      );
    };

    complete();
  };
}

/**
 * Save address on draft
 * @param address
 * @param {Function} onSuccess
 * @param {Function} onError
 * @return {Function}
 */
export function setDraftAddress(address, onSuccess, onError) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;
    dispatch({ type: draftConstants.DRAFT_LOADING });
    apiDraftAddCustomer(
      draft.id,
      address,
      (response) => {
        dispatch({
          type: draftConstants.DRAFT_CHANGE,
          payload: {
            draft: response.data,
          },
        });

        onSuccess && onSuccess(response.data);
      },
      (response) => {
        error(
          dispatch,
          response.response.data.message || 'Something went wrong while saving customer info on draft'
        )(response);
        onError && onError(response.response.data);
      }
    );
  };
}

/**
 * Checks whether draft has some problems and force
 * app actions in case any problems found
 * @param {object} draft
 * @return {Function}
 */
export function checkDraftForProblems(draft) {
  return (dispatch) => {
    // Open draft shipping dialog in case we have problems with shipping options
    if (OrderEntity.hasProblemsWithShipping(draft)) {
      dispatch(draftOpenShippingDialog());
    }

    // Open draft in case we have problems with delivery
    // if (OrderEntity.isDeliveryNotAvailable(draft)) {
    //   dispatch(draftOpen());
    // }
  };
}

/**
 * Changes shipping type and date
 * @param {string} type
 * @param {string} date
 * @param {string} locationId
 * @param {function} onSuccess
 * @param {function} onError
 * @param {boolean|undefined} skipShippingCheck
 * @return {function(*, *)}
 */
export function changeDraftShipping(type, date, locationId, onSuccess, onError, skipShippingCheck) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;

    dispatch({ type: draftConstants.DRAFT_SHIPPING_LOADING });

    const shippingData = {
      fee: 0,
      date,
      locationId,
      ...(skipShippingCheck && { skipShippingCheck }),
    };

    apiDraftSetShipping(
      draft.id,
      type,
      shippingData,
      (response) => {
        dispatch({
          type: draftConstants.DRAFT_CHANGE,
          payload: { draft: response.data },
        });

        // Check draft for problems
        dispatch(checkDraftForProblems(response.data));
        // Init markets, this will update producers select
        dispatch(initMarkets());
        // Show all producers available for markets
        dispatch(changeProducers([]));

        // Set market by draft
        dispatch(marketActions.setMarketByDraft(response.data, undefined, true));
        // Set draft update counter
        dispatch({ type: draftConstants.DRAFT_COUNTER });

        onSuccess && onSuccess(response.data);
      },
      (error) => {
        onError && onError(draft, error.response.data);

        dispatch({
          type: draftConstants.DRAFT_SHIPPING_ERROR,
          payload: { error: error.response.data },
        });
      }
    );
  };
}

/**
 * Clear shipping type, date and location
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*, *)}
 */
export function clearDraftShipping(onSuccess, onError) {
  return (dispatch, getState) => {
    const draft = getState().draft.object;
    dispatch({ type: draftConstants.DRAFT_SHIPPING_LOADING });
    localStorage.removeItem(STORAGE_PERSONAL_PRODUCER_SHIPPING_OPTIONS_KEY);

    clearCache();

    apiDraftShippingOptionsClear(
      draft.id,
      (response) => {
        dispatch({
          type: draftConstants.DRAFT_CLEAR,
          payload: { draft: response.data },
        });

        // Clear market as well as draft shipping options
        dispatch(marketActions.clearMarket());
        // Reload markets
        dispatch(initMarkets(true));
        // Reload shipping options
        dispatch(silenceRefreshShippingOptions());

        onSuccess && onSuccess(response.data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
}

export function dismissShippingOutdatedNotification() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_SHIPPING_OUTDATED_DISMISS });
  };
}

export function draftToggle() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_TOGGLE });
  };
}

export function draftOpen(onSuccess) {
  return (dispatch) => {
    dispatch({
      type: draftConstants.DRAFT_OPEN,
      payload: {
        onSuccess: onSuccess ? onSuccess : () => {},
      },
    });
  };
}

export function draftClose() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_CLOSE });
  };
}

export function draftOpenShippingDialog(forProduct, forVariant, onDone) {
  return (dispatch) => {
    dispatch(
      refreshShipping(() => {
        dispatch({
          type: draftConstants.DRAFT_OPEN_SHIPPING_DIALOG,
          payload: {
            forProduct,
            forVariant,
            onDone: onDone ? onDone : () => {},
          },
        });
      })
    );
  };
}

export function draftCloseShippingDialog() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_CLOSE_SHIPPING_DIALOG });
  };
}

export function draftOpenProducerDialog() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_OPEN_PRODUCER_DIALOG });
  };
}

export function draftCloseProducerDialog() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_CLOSE_PRODUCER_DIALOG });
  };
}

export function draftOpenDraggableDialog() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_DRAGGABLE_OPEN });
  };
}

export function draftCloseDraggableDialog() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_DRAGGABLE_CLOSE });
  };
}

export function draftOpenUnavailableItemsDialog() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_OPEN_UNAVAILABLE_ITEMS_DIALOG });
  };
}

export function draftCloseUnavailableItemsDialog(draft) {
  return (dispatch) => {
    dispatch({
      type: draftConstants.DRAFT_CLOSE_UNAVAILABLE_ITEMS_DIALOG,
      payload: draft,
    });
  };
}

/**
 * Make reorder
 * @param {object} order
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*, *)}
 */
export function makeReorder(order, onSuccess, onError) {
  return (dispatch) => {
    apiOrderReorder(
      order,
      (response) => {
        dispatch({
          type: draftConstants.DRAFT_CHANGE,
          payload: { draft: response.data },
        });

        // Init markets, this will update producers select
        dispatch(initMarkets());
        // Show all producers available for markets
        dispatch(changeProducers([]));
        // Check draft for problems
        dispatch(checkDraftForProblems(response.data));

        // Set market by draft
        dispatch(marketActions.setMarketByDraft(response.data));

        onSuccess && onSuccess();
      },
      (error) => {
        if (error && error.response && error.response.data.code === 4016) {
          dispatch(showNotification('intl:messages.canNotReorder4016'));
        }

        onError(error);
      }
    );
  };
}

/**
 * Remove line items
 * @param {object} order
 * @param {function} onSuccess
 * @param {function} onError
 * @return {function(*, *)}
 */
export function removeLineItems(order, onSuccess, onError) {
  return () => {
    apiDraftRemoveLineItems(
      order,
      (response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
}

export function draftOpenLocationProblemDialog(market, afterClear, onCancel) {
  return (dispatch) =>
    dispatch({
      type: draftConstants.DRAFT_OPEN_CHANGE_LOCATION_PROBLEM_DIALOG,
      payload: { market, do: afterClear, onCancel },
    });
}

export function draftCloseLocationProblemDialog() {
  return (dispatch) => dispatch({ type: draftConstants.DRAFT_CLOSE_CHANGE_LOCATION_PROBLEM_DIALOG });
}

export function draftOpenClearShippingOptionsDialog() {
  return (dispatch) =>
    dispatch({
      type: draftConstants.DRAFT_OPEN_CLEAR_SHIPPING_DIALOG,
    });
}

export function draftCloseClearShippingOptionsDialog() {
  return (dispatch) => dispatch({ type: draftConstants.DRAFT_CLOSE_CLEAR_SHIPPING_DIALOG });
}

export function setShippingTypeClientSide(type) {
  return (dispatch) => {
    dispatch({
      type: draftConstants.DRAFT_SET_SHIPPING_TYPE_CLIENT_SIDE,
      payload: {
        type,
      },
    });

    saveObjectToStorage(STORAGE_SHIPPING_TYPE_KEY, type);
  };
}

export function clearShippingTypeClientSide() {
  return (dispatch) => {
    dispatch({ type: draftConstants.DRAFT_CLEAR_SHIPPING_TYPE_CLIENT_SIDE });
    localStorage.removeItem(STORAGE_SHIPPING_TYPE_KEY);
    localStorage.removeItem(STORAGE_PERSONAL_PRODUCER_SHIPPING_OPTIONS_KEY);
  };
}

export function saveShippingDateClientSide(date) {
  return (dispatch) => {
    dispatch({
      type: draftConstants.SET_SHIPPING_DATE_CLIENT_SIDE,
      payload: {
        date,
      },
    });
    saveObjectToStorage(STORAGE_SHIPPING_TYPE_KEY, date);
  };
}

export function setIsPersonalProducerSelectBehavior(producerId) {
  return (dispatch) => {
    dispatch({
      type: draftConstants.SET_IS_PERSONAL_PRODUCER_SELECT,
      payload: {
        producerId: producerId,
      },
    });
  };
}
export function savePersonalProducerShippingOptions(personalProducerShippingOptions) {
  return (dispatch) => {
    dispatch({
      type: draftConstants.SET_PERSONAL_PRODUCER_DRAFT_SHIPPING,
      payload: {
        personalProducerShippingOptions,
      },
    });
    saveObjectToStorage(STORAGE_PERSONAL_PRODUCER_SHIPPING_OPTIONS_KEY, personalProducerShippingOptions);
  };
}

export function setOnAddVariantQuantity(newQuantity) {
  return (dispatch) => {
    dispatch({
      type: draftConstants.DRAFT_ON_ADD_VARIANT_QUANTITY,
      payload: {
        quantity: newQuantity,
      },
    });
  };
}
