import { CUSTOMER_TYPES } from './constants';

/**
 * Find Delivery and Pickup Days from producers=>shippingDays by Market Id and CustomerType
 * @param {array} producers
 * @param {string} marketId
 * @param {string} customerType
 * @return {array}
 */
export const getDeliveryAndPickupDays = (producers, marketId, customerType) => {
  const deliveryDays = new Set();
  const pickupDays = new Set();
  const type = customerType === CUSTOMER_TYPES.WHOLESALE ? CUSTOMER_TYPES.WHOLESALE : CUSTOMER_TYPES.RETAIL;
  producers.forEach((producer) => {
    producer.shippingDays.forEach((day) => {
      day.locations.forEach((location) => {
        if (location.locationId === marketId && location[type].delivery) {
          deliveryDays.add(day.day);
        }
        if (location.locationId === marketId && location[type].pickup) {
          pickupDays.add(day.day);
        }
      });
    });
  });
  return {
    deliveryDays: [...deliveryDays],
    pickupDays: [...pickupDays],
  };
};
