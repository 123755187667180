import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import { Icon } from '../../components';

const theme = createTheme({});

const PrimaryCheckbox = (props) => {
  const { ...other } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <Checkbox
        icon={<Icon icon={RadioButtonUnchecked} style={{ fontSize: 28 }} />}
        checkedIcon={<Icon icon={CheckCircleIcon} style={{ fontSize: 28 }} />}
        {...other}
      />
    </MuiThemeProvider>
  );
};

export default PrimaryCheckbox;
