const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 30,
    paddingBottom: 0,
  },
  title: {
    marginBottom: 29
  },
  question: {
    textAlign: 'center',
    marginBottom: 28,
  },
  button: {
    marginBottom: 25,
    minWidth: 240,
  },
  checkboxLabel: {
    color: '#777775',
    fontSize: 12
  }
});

export default styles;