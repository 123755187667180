class ProductType {

	constructor(type = {}) {
		this.name = type.name || '';
		this.subTypes = type.subTypes || [];
	}

	getSubTypes(search) {
		if (search)
			return this.subTypes
				.filter(item => item.name.includes(search));

		return this.subTypes;
	}

}

export default ProductType
