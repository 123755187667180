export const hashToParams = (hash) => {
  if (!hash?.length) return {};

  const withoutHashSymbol = hash.substr(1, hash.length);

  const paramsArray = withoutHashSymbol.split('&');

  let result = {};

  paramsArray.forEach((item) => {
    const separated = item.split('=');
    result[separated[0]] = decodeURIComponent(separated[1]);
  });

  return result;
};
