const styles = theme => ({
  formControl: {
    margin: "5px 0",
    alignItems: 'left',
    width: '100%'
  },
  blockWrapper: {
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  controls: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 20
    },
    marginTop: 20,
    textAlign: "center"
  },
  paymentMethodsControls: {
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      marginBottom: 20
    },
  },
  radioLabelBody: {
    width: '100%'
  },
  addButton: {
    marginBottom: 10,
    marginTop: 10,
    marginRight: 5
  },
  label: {
    textAlign: 'left'
  },
  alignLeft: {
    textAlign: 'left'
  },
  select: {

  },
  serviceChargeMessage: {
    fontSize: '10px',
    color: '#363636',
    marginBottom: 10,
    width: '100%',
    textAlign: 'center',
  }
});

export default styles;
