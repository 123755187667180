import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const BlackLinkButton = ({ onClick, classes, text, className, iconComponent, containerClass }) => {
  return (
    <div className={classNames([classes.container, containerClass])}>
      {iconComponent && iconComponent()}

      <button onClick={(e) => onClick && onClick(e)} className={classNames(classes.button, className)}>
        {text}
      </button>
    </div>
  );
};

BlackLinkButton.propTypes = {
  iconComponent: PropTypes.func,
  text: PropTypes.string,
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

BlackLinkButton.defaultProps = {
  className: '',
};

export default withStyles(styles)(BlackLinkButton);
