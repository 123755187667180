/**
 * Search companies via google api
 * @param {string} query
 * @param {function} onSuccess
 * @param {function} onError
 */
export const googleApiSearchPlaces = function (query, onSuccess, onError) {
	let request = {query: `${query}+New+York`},
		map = new window.google.maps.Map(document.createElement('div')),
		service = new window.google.maps.places.PlacesService(map);

	service.textSearch(request, (results, status) => {
		(status === window.google.maps.places.PlacesServiceStatus.OK)
			? onSuccess && onSuccess(results)
			: onError && onError(status);
	});
};

/**
 * Removes country code from address
 * @param {string} address
 */
export const getFormattedAddressWithoutCountry = function (address) {
	let parts = (address || '').split(', ');
	parts.pop();
	return parts.join(', ');
};