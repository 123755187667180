import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Quantity, QuantitySwitcher } from '../../../components';
import {
  getVariantUnit,
  getQuantityByVariant,
  getLineItemByVariant,
  getLineItemSystemQuantity,
  capitalizeFirstLetter,
  weightToUser,
  getVariantPriceForCustomer,
} from '../../../helpers';
import EmptyImage from './assets/no.jpg';
import styles from './styles';

class ListProductHit extends React.Component {
  constructor(props) {
    super(props);

    const { producers, hit } = this.props;

    const variants = props.hit.variants.filter((variant) => variant.name !== 'Default Title' && variant.active);

    const activeVariants = variants.filter((variant) => {
      return props.activeVariant && variant.id === props.activeVariant.id;
    });

    this.state = {
      producer: producers.find((p) => hit.producerId === p.id),
      variants,
      selectedVariant: activeVariants.length ? activeVariants[0] : variants[0],
    };
  }

  _onVariantChange = (event) => {
    const variants = this.props.hit.variants || [];
    for (let i = 0; i < variants.length; i++) {
      if (event.target.value === variants[i].id) {
        this.setState({
          selectedVariant: variants[i],
        });
        break;
      }
    }
  };

  _handleAddToCart = (e, hit, quantity) => {
    e.stopPropagation();
    const { onAddToCartClicked } = this.props;
    onAddToCartClicked(hit, this.state.selectedVariant, quantity);
  };

  getHitImage() {
    const { hit, compact, classes } = this.props;
    if (!compact) {
      return (
        <img
          className={classes.hitImage}
          src={hit.thumb ? hit.thumb : hit.image ? hit.image : EmptyImage}
          alt={hit.name}
        />
      );
    }
    return null;
  }

  render() {
    const {
      classes,
      hit,
      activeVariant,
      compact,
      onClick,
      order,
      onClickOutside,
      onQuantityChange,
      onStartQuantityChange,
      intl,
      customer,
    } = this.props;

    const { producer, variants } = this.state;

    const quantity = getQuantityByVariant(order, this.state.selectedVariant);
    const lineItem = getLineItemByVariant(order, this.state.selectedVariant);

    return (
      <ListItem className={classes.listItem}>
        {this.getHitImage()}

        <div className={compact ? classes.productInfoCompact : classes.productInfo}>
          <h3 onClick={onClick} className={compact ? classes.productNameCompact : classes.productName}>
            {capitalizeFirstLetter(hit.name)}
          </h3>
          {producer && (
            <Typography className={classes.producerName} component="p">
              {producer.name}
            </Typography>
          )}
          {hit.description && <p className={classes.description}>{hit.description}</p>}
          <Typography className={classes.price} component="p">
            {getVariantPriceForCustomer(customer, this.state.selectedVariant, intl)} /{' '}
            {weightToUser(this.state.selectedVariant.unitQuantity)} {getVariantUnit(this.state.selectedVariant, true)}
          </Typography>
          {variants.length > 1 ? (
            <Select
              className={classes.select}
              value={this.state.selectedVariant.id}
              onChange={this._onVariantChange}
              input={
                <Input
                  classes={{
                    root: classes.variantNameInput,
                  }}
                  name={`variant-id-${hit.id}`}
                  id={`variant-id-${hit.id}`}
                />
              }>
              {variants.map((variant) => {
                return (
                  <MenuItem key={variant.id} value={variant.id}>
                    {variant.name || variant.unit}
                  </MenuItem>
                );
              })}
            </Select>
          ) : variants.length > 0 ? (
            <Typography className={classes.price} component="p">
              {variants[0].name}
            </Typography>
          ) : (
            ''
          )}
        </div>

        <div className={classes.addToCart}>
          {activeVariant && this.state.selectedVariant.id === activeVariant.id && (
            <div className={classes.quantity}>
              {
                <QuantitySwitcher
                  hideDelay={2000}
                  onStartChange={() => onStartQuantityChange(lineItem)}
                  onClose={onClickOutside}
                  className={classes.quantitySwitcherRoot}
                  onChange={(newQuantity) =>
                    onQuantityChange(lineItem, getLineItemSystemQuantity(lineItem, newQuantity))
                  }
                  quantity={quantity}
                />
              }
            </div>
          )}

          <Fab
            size="small"
            className={classes.addToCart}
            onClick={(e) => this._handleAddToCart(e, hit, quantity)}
            color="primary"
            aria-label="add">
            {quantity ? <Quantity quantity={quantity} /> : <AddIcon classes={{ root: classes.addToCartIcon }} />}
          </Fab>
        </div>
      </ListItem>
    );
  }
}

ListProductHit.propTypes = {
  customer: PropTypes.object.isRequired,
  producers: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
  activeHit: PropTypes.object,
  activeVariant: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onStartQuantityChange: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  hit: PropTypes.object.isRequired,
  onAddToCartClicked: PropTypes.func.isRequired,
};

ListProductHit.defaultProps = {
  compact: false,
};

export default withStyles(styles, { withTheme: true })(injectIntl(ListProductHit));
