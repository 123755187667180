import React from 'react';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/core';
import styles from '../styles';
import { Search } from 'react-feather';


const SearchInput = ({
  onChange,
  id,
  classes,
  value,
  inputClass,
  onClear,
  showSelectButton,
  onSelectAddress,
  onMouseDown = (e) => e.stopPropagation(),
  inputClasses,
  ...other
}) => (
  <Input
    id={id}
    type={'text'}
    value={value}
    disableUnderline
    onMouseDown={onMouseDown}
    classes={{
      root: `${classes.root}${inputClass ? ` ${inputClass}` : ``}`,
      input: classes.searchInput,
      focused: classes.focused,
      ...inputClasses,
    }}
    onChange={(e) => onChange(e.target.value)}
    startAdornment={(() => (
      <InputAdornment position={'start'} className={classes.searchIcon}>
        <IconButton onClick={() => onChange(value)}>
          <Search />
        </IconButton>
      </InputAdornment>
    ))()}
    endAdornment={
      (() => {
        return (value && !showSelectButton)
          ? (
            <InputAdornment position={'start'} className={classes.searchIcon}>
              <IconButton onClick={onClear}>
                <ClearIcon className={classes.icon} />
              </IconButton>
            </InputAdornment>
          )
          : showSelectButton ? (
            <InputAdornment position={'start'} className={classes.searchIcon}>
              <IconButton onClick={onSelectAddress}>
                <ArrowForwardIosIcon className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ) : null;
      })()
    }
    {...other}
  />
);

export default withStyles(
  styles,
  { withTheme: true })(
  injectIntl(SearchInput),
);
