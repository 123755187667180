export const producerConstants = {
	GET_PRODUCERS: 'PRODUCER:GET_PRODUCERS',
	PRODUCERS_ERROR: 'PRODUCER:GET_PRODUCERS_ERROR',
	PRODUCERS_LOADING: 'PRODUCER:PRODUCERS_LOADING',
	CHANGE_PRODUCERS: 'PRODUCER:CHANGE_PRODUCERS',
	MARKETS_LOADING: 'PRODUCER:MARKETS_LOADING',
	MARKETS_SET: 'PRODUCER:MARKETS_SET',
	MARKETS_ERROR: 'PRODUCER:MARKETS_ERROR',

	// Producer filters
	SET_FILTER_BY_PRODUCERS: 'PRODUCER:FILTER_BY_PRODUCER',
};



