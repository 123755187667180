import {
  SHOW_WELCOME, HIDE_WELCOME
} from '../constants';
import moment from 'moment';
import {apiOrderGetList} from '../../api';

export function showWelcome() {
  return (dispatch, getState) => {
    // If welcome dialog turned on
    if (process.env.REACT_APP_SHOW_WELCOME === '1') {
      // Welcome dialog might be shown one time per 24 hours
      const limit = 60 * 60 * 24;

      const last = parseInt(localStorage.getItem('welcome.last'), 10) || parseInt(moment().unix(), 10) - (limit * 2);
      const current = parseInt(moment().unix(), 10);
      const diff = current - last;

      if (diff > limit) {
        // We should show welcome dialog only if customer has orders
        apiOrderGetList({
          skip: 0,
          limit: 1
        }, (response) => {
          if (response.data.length > 0) {
            localStorage.setItem('welcome.last', current);
            dispatch({type: SHOW_WELCOME});
          }
        }, () => {
          // On response error
        });
      }
    }
  }
}

export function hideWelcome() {
  return (dispatch) => {
    dispatch({type: HIDE_WELCOME});
  };
}