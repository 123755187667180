import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Noto Sans HK', sans-serif",
        fontWeight: 400,
        fontSize: 12,
        backgroundColor: 'transparent',
        borderRadius: 6,
        color: '#363636',
        border: '1px solid #70707066',
        height: 43,
        transition: 'all 0.5s ease',
        padding: '0 37px !important',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
        '&:hover': {
          color: '#363636',
          border: '1px solid #70707066',
        },
      },
    },
  },
});

class OutlinedButton extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme}>
      <Button {...this.props}>{this.props.children}</Button>
    </MuiThemeProvider>
  );
}

export default OutlinedButton;
