import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { toCurrency } from '../../../helpers';
import classNames from 'classnames';

const styles = (theme) => ({
  item: {
    width: 500,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: 18,
    paddingBottom: 18,
    borderBottom: '1px solid #eeeeee',
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
  },
  info: {
    flex: 8,
    marginRight: 5,
  },
  total: {
    fontSize: 13,
    fontFamily: 'Noto Sans HK, sans-serif',
    fontWeight: 'bold',
  },
  price: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flex: 1,
    width: 80,
    height: '100%',
    fontSize: 13,
    fontFamily: 'Noto Sans HK, sans-serif',
    fontWeight: 'bold',
  },
  discountPositive: {
    color: 'rgb(65, 205, 140)',
  },
  discountNegative: {
    color: '#363636',
  },
  summaryItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const DiscountTotal = ({ order: { discount }, classes, intl }) => {
  const isNegative = Number.parseInt(discount.amount) < 0;
  return (
    <React.Fragment>
      <div className={classes.summaryItem}>
        <div className={classes.info}>
          <Typography
            className={classNames([classes.total, isNegative ? classes.discountNegative : classes.discountPositive])}
            component="p">
            {intl.formatMessage({ id: 'global.discount' })}: {discount.definition.name}
          </Typography>
        </div>

        <div className={classNames([classes.price, isNegative ? classes.discountNegative : classes.discountPositive])}>
          {Number.parseInt(discount.amount) > 0 ? '-' : ''}
          {toCurrency(discount.amount, intl)}
        </div>
      </div>
    </React.Fragment>
  );
};

DiscountTotal.propTypes = {
  order: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(injectIntl(DiscountTotal));
