import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { BlackButton, Spinner } from '../../../../components';
import SearchInput from '../../../../components/GooglePlacesSearch/components/SearchInput';
import { Customer, Market } from '../../../../entities';
import EmptyImage from './assets/no.jpg';
import styles from './styles';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import { googleApiSearchPlaces } from '../../../../components/GooglePlacesSearch/helpers';
import { DELIVERY_ADDRESS, DELIVERY_ADDRESS_TYPE, parseGoogleFormatterAddress } from '../../../../helpers';
import {
  addCustomerAddress as _addCustomerAddress,
  initMarkets,
  setDraftAddress as _setDraftAddress,
} from '../../../../store/actions';
import { apiSetCustomerGeolocation } from '../../../../api';
import { getAddressSimple, getProducersByItems } from '../helpers';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorBox from '../../../../components/ErrorBox/ErrorBox';

const propTypes = {
  onSelectMarket: PropTypes.func,
  shippingType: PropTypes.string,
};
const defaultProps = {};

const useStyles = makeStyles(styles);

const ADDRESS_WITH_MARKETS_STEPS = {
  CHOOSE_ADDRESS: 'chooseAddress',
  ADD_ADDRESS: 'addAddress',
};

const AddressWidthMarketsSelect = (props) => {
  const {
    customer,
    markets,
    draft,
    onSetDraftAddress,
    onAddAddress,
    onInitMarkets,
    onSelectMarket,
    shippingType,
    shippingLocation,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const searchInput = useRef(null);

  const [step, setStep] = useState(ADDRESS_WITH_MARKETS_STEPS.CHOOSE_ADDRESS);
  const [search, setSearch] = useState('');
  const [searchNoUpdate, setSearchNoUpdate] = useState(false);
  const [error, setError] = useState('');
  const [selectedMarket, setSelectedMarket] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsLoading, setSearchResultsLoading] = useState(true);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [marketLoading, setMarketLoading] = useState(false);
  const [selectAddressOpened, setSelectAddressOpened] = useState(false);
  const [addressSelectLoading, setAddressSelectLoading] = useState(false);

  useEffect(() => {
    // select market
    const firstMarket = markets.getMarketsByType(shippingType, getProducersByItems(draft))[0];
    setSelectedMarket(!!shippingLocation ? shippingLocation : firstMarket?.id || '');
  }, []);

  useEffect(() => {
    if (searchNoUpdate) return false;

    setSearchResultsLoading(true);
    setShowSearchResults(true);
    const timer = setTimeout(() => {
      if (search) {
        makeRequest();
      } else {
        setSearchResults([]);
        setSearchResultsLoading(false);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [search, searchNoUpdate]);

  const makeRequest = () => {
    googleApiSearchPlaces(
      search,
      (results) => {
        let newOptions;
        let resultArr = [];
        let savedIds = [];

        // We need only first 5 results without duplicates
        if (results) {
          results.forEach((gAddress, index) => {
            if (!savedIds.includes(gAddress.place_id) && index < 5) {
              savedIds.push(gAddress.place_id);
              resultArr.push(gAddress);
            }
          });

          newOptions = [...resultArr];
        } else {
          newOptions = [];
        }
        setSearchResultsLoading(false);
        setSearchResults(newOptions);
      },
      () => {
        setSearchResultsLoading(false);
        setSearchResults([]);
      }
    );
  };

  const onChange = (newSearch) => {
    setSearchNoUpdate(false);
    setSearch(newSearch);
    setError('');
  };
  const onClear = () => {
    setSearch('');
    setError('');
  };

  const onDone = () => {
    onSelectMarket(selectedMarket);
  };

  const onMarketClick = (marketId) => {
    setSelectedMarket(marketId);
  };

  const onAddressSelect = (gAddress) => {
    let address = parseGoogleFormatterAddress(gAddress.formatted_address);

    setSearchNoUpdate(true);
    setSearch(gAddress.formatted_address);
    setSearchResultsLoading(true);

    if (!address.city || !address.address1 || !(address.postalCode || address.zip)) {
      setError(intl.formatMessage({ id: 'address.validationNotification' }));
      setInternalLoading(false);
      setAddressSelectLoading(false);
      setSearchNoUpdate(false);
      setSearchResultsLoading(false);

      return false;
    }

    !Customer.isAddressExist(customer, address)
      ? addAddress(address, gAddress)
      : setDraftAddress(Customer.findExistAddress(customer, address), () => {}, gAddress);
  };

  const setDraftAddress = (location, callback, gAddress) => {
    const locationId = location?.id;

    if (!locationId) return;

    let address = { id: customer.id, [DELIVERY_ADDRESS]: { id: locationId } };

    if (address) {
      setInternalLoading(true);
      setAddressSelectLoading(true);

      onSetDraftAddress(
        address,
        (newDraft) => {
          callback && callback();

          setShowSearchResults(false);

          setInternalLoading(false);
          setAddressSelectLoading(false);
          setSearchResultsLoading(false);
          setStep(ADDRESS_WITH_MARKETS_STEPS.CHOOSE_ADDRESS);

          updateMarketsOrder(newDraft, gAddress);
        },
        (response) => {
          setError(response?.message || 'Something went wrong while saving selected address');
          setInternalLoading(false);
        }
      );
    } else {
      setError('Address is not selected');
    }
  };

  const addAddress = (address, gAddress) => {
    const formattedAddress = {
      address1: address.address1,
      zip: address.zip || address.postalCode,
      city: address.city,
      address2: address.address2,
      province: address.province,
      default: address.default,
    };

    onAddAddress(
      DELIVERY_ADDRESS_TYPE,
      formattedAddress,
      (response) => {
        setDraftAddress(Customer.findExistAddress(response, formattedAddress), () => {}, gAddress);
      },
      () => {
        setError('Something went wrong');
        setInternalLoading(false);
      }
    );
  };

  const updateMarketsOrder = (newDraft, gAddress) => {
    const currentSelectedShippingAddress = newDraft?.customer?.shippingAddress;

    let coords = {};

    if (currentSelectedShippingAddress.latitude && currentSelectedShippingAddress.longitude) {
      coords = {
        latitude: currentSelectedShippingAddress.latitude,
        longitude: currentSelectedShippingAddress.longitude,
      };
    } else if (!!gAddress) {
      coords = {
        latitude: gAddress?.geometry?.location?.lat(),
        longitude: gAddress?.geometry?.location?.lng(),
      };
    }

    if (coords.latitude && coords.longitude) {
      setMarketLoading(true);
      apiSetCustomerGeolocation(
        coords,
        () => {
          setMarketLoading(false);

          onInitMarkets();

          setStep(ADDRESS_WITH_MARKETS_STEPS.CHOOSE_ADDRESS);
        },
        (error) => {
          setMarketLoading(false);

          setError(error?.response?.data?.message);
        }
      );
    }
  };

  const getSelectedAddress = () => {
    const { shippingAddresses } = customer;
    const selectedShippingAddressId = draft?.customer?.shippingAddress?.id;

    return !!selectedShippingAddressId
      ? shippingAddresses.find((item) => item.id === selectedShippingAddressId)
      : shippingAddresses[0];
  };

  const getLocationSwitcher = () => {
    const { shippingAddresses } = customer;

    if (!shippingAddresses?.length) return setStep(ADDRESS_WITH_MARKETS_STEPS.ADD_ADDRESS);

    const selectedShippingAddress = getSelectedAddress();

    return (
      <Typography
        component={'span'}
        noWrap={true}
        className={classes.addAddressLabelName}
        onClick={() => {
          setSelectAddressOpened(true);
        }}>
        {getAddressSimple(selectedShippingAddress)}

        {selectAddressOpened ? (
          <ExpandLessIcon className={classes.arrowExpand} />
        ) : (
          <ExpandMoreIcon className={classes.arrowExpand} />
        )}
      </Typography>
    );
  };

  const getLocationSelector = () => {
    const { shippingAddresses } = customer;

    const selectedShippingAddress = getSelectedAddress();

    return (
      <ClickAwayListener
        onClickAway={() => {
          setSelectAddressOpened(false);
        }}>
        <div className={classNames([classes.addressSelector, 'noSelect'])}>
          {!!addressSelectLoading && (
            <div className={classes.searchResultLoading}>
              <Spinner size={50} />
            </div>
          )}

          {!addressSelectLoading && (
            <>
              {shippingAddresses.map((address) => (
                <div
                  key={address.id}
                  className={classNames([
                    classes.addressSelectorItem,
                    address?.id === selectedShippingAddress?.id && classes.addressSelectorItemActive,
                  ])}
                  onClick={() => {
                    setDraftAddress(address, () => {
                      setSelectAddressOpened(false);
                    });
                  }}>
                  {getAddressSimple(address)}
                </div>
              ))}

              <div
                key={'addAddress'}
                className={classNames([classes.addressSelectorItem])}
                onClick={() => {
                  setStep(ADDRESS_WITH_MARKETS_STEPS.ADD_ADDRESS);
                  setSelectAddressOpened(false);
                }}>
                {intl.formatMessage({ id: 'checkout.marketSelect.addOneMoreAddress' })}
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <p className={classes.titleClass}>{intl.formatMessage({ id: 'checkout.marketSelect.title' })}</p>
      </Hidden>

      <div className={classes.addressWrapper}>
        {step === ADDRESS_WITH_MARKETS_STEPS.CHOOSE_ADDRESS && (
          <div className={classes.chooseAddress}>
            <p className={classes.chooseAddressLabel}>
              {intl.formatMessage({ id: 'checkout.marketSelect.showingLocationNear' })}
              {getLocationSwitcher()}
            </p>
            {selectAddressOpened && getLocationSelector()}
          </div>
        )}
        {step === ADDRESS_WITH_MARKETS_STEPS.ADD_ADDRESS && (
          <div className={classes.addAddress}>
            <p className={classes.addAddressLabel}>
              {intl.formatMessage({ id: 'checkout.marketSelect.showingLocationNear' })}
            </p>

            <SearchInput
              value={search}
              fullWidth
              inputClasses={{ root: classes.inputRootClass }}
              inputRef={searchInput}
              id={`search-address`}
              onClear={onClear}
              onChange={onChange}
              placeholder={intl.formatMessage({ id: 'checkout.marketSelect.addAddress' })}
            />
            {!!search && showSearchResults && (
              <div className={classNames([classes.searchResultWrapper, 'noSelect'])}>
                {!!searchResultsLoading && (
                  <div className={classes.searchResultLoading}>
                    <Spinner size={50} />
                  </div>
                )}

                {!!error && (
                  <div className={classes.errorBoxWrapper}>
                    <ErrorBox error={error} />
                  </div>
                )}

                {!searchResultsLoading && !searchResults.length && (
                  <p className={classes.searchResultNoResults}>
                    {intl.formatMessage({ id: 'checkout.marketSelect.searchAddressNoResults' })}
                  </p>
                )}

                {!searchResultsLoading &&
                  !!searchResults.length &&
                  searchResults.map((gAddress) => (
                    <div
                      className={classes.searchResultItem}
                      key={gAddress.place_id}
                      onClick={() => onAddressSelect(gAddress)}>
                      {gAddress.formatted_address}
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div className={classes.marketsSuggestionWrapper}>
        {!!marketLoading && (
          <div className={classes.marketsLoadingWrapper}>
            <Spinner size={50} />
          </div>
        )}
        {!marketLoading && (
          <>
            <div className={classes.marketsWrapper}>
              {markets.getMarketsByType(shippingType, getProducersByItems(draft)).map((market) => {
                return (
                  <div
                    className={classNames([
                      classes.marketWrapper,
                      selectedMarket === market.id && classes.marketWrapperActive,
                      'noSelect',
                    ])}
                    onClick={() => onMarketClick(market.id)}
                    key={market.id}>
                    <div className={classes.marketImageWrapper}>
                      <img
                        src={market?.image?.thumbSrc || EmptyImage}
                        alt={market.name}
                        className={classes.marketImage}
                      />
                    </div>
                    <div className={classes.marketContentWrapper}>
                      <Typography noWrap paragraph className={classes.marketName}>
                        {market.name}
                      </Typography>
                      <Typography noWrap paragraph className={classes.marketAddress}>
                        {Market.getAddress(market)}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={classes.controls}>
              <BlackButton
                disabled={internalLoading || marketLoading || searchResultsLoading}
                onClick={onDone}
                className={classes.control}>
                <FormattedMessage id="checkout.marketSelect.selectButton" />
              </BlackButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

AddressWidthMarketsSelect.propTypes = propTypes;
AddressWidthMarketsSelect.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    customer: state.customer.object,
    markets: state.producer.markets,
    draft: state.draft.object,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetDraftAddress: (address, onSuccess, onError) => dispatch(_setDraftAddress(address, onSuccess, onError)),
    onAddAddress: (type, data, onSuccess, onError) => dispatch(_addCustomerAddress(type, data, onSuccess, onError)),
    onInitMarkets: () => dispatch(initMarkets()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressWidthMarketsSelect);
