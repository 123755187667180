import React, { useState } from 'react';
import { getToken, setProducerPreviewHash } from '../../helpers';
import { withRouter } from 'react-router-dom';
import { apiSetPreviewProducer } from '../../api';
import { Spinner } from '../../components';
import { refreshEverything, signOut } from '../../store/actions';
import configureStore from '../../store/configure';
import { connect } from 'react-redux';

function PreviewScene(props) {
  const { onForceUserSignOut, onRefreshEverything } = props;
  const { history } = props;

  useState(async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const currentUserToken = getToken();
      const token = params && params.get('token');
      const decodedToken = atob(token).split('.');
      const hash = decodedToken[1];
      const producer = props.producers.find((one) => one.id === decodedToken[0]);

      if (producer) {
        if (!!currentUserToken) {
          onForceUserSignOut();
        }
        configureStore(() => {
          apiSetPreviewProducer(
            {
              hash,
            },
            () => {
              setProducerPreviewHash(hash);

              onRefreshEverything(() => {
                history.push(`/producer/${producer.handle}`);
              });
            },
            (error) => {
              configureStore(() => {
                history.push('/');
              });
              console.log('apiSetPreviewProducer.error', error, error.response);
            }
          );
        }, hash);
      } else {
        console.log('Something went wrong ');
        history.push('/');
      }
    } catch (e) {
      console.log('Something went wrong ');
      history.push('/');
    }
  }, []);

  return (
    <div
      style={{
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Spinner size={40} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    order: state.draft.object,
    market: state.market.object,
    producers: state.producer.producers,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onForceUserSignOut: () => dispatch(signOut(false)),
    onRefreshEverything: (onSuccess) => dispatch(refreshEverything(onSuccess)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewScene));
