import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import BreadcrumbsWrapper from '../../../components/BreadcrumbsWrapper';

const styles = () => ({
  rootWrapperClass: {
    paddingLeft: 0,
    marginBottom: 24,
    marginTop: 24,
  },
});

const AccountOrdersBreadcrumbs = ({ location, classes, intl }) => {
  const breadcrumbNameMap = {
    '/account': { title: intl.formatMessage({ id: 'global.breadcrumbs.orders' }), url: '/account' },
  };
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <BreadcrumbsWrapper rootWrapperClass={classes.rootWrapperClass}>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="textPrimary" key={to}>
            {breadcrumbNameMap[to].title}
          </Typography>
        ) : (
          <Link color="inherit" to={breadcrumbNameMap[to].url} key={to}>
            {breadcrumbNameMap[to].title}
          </Link>
        );
      })}
    </BreadcrumbsWrapper>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(AccountOrdersBreadcrumbs)));
