import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createTheme } from '@material-ui/core';

class BlackButton extends React.Component {
  constructor(props) {
    super(props);

    const filled = {
      fontFamily: "'Noto Sans HK', sans-serif",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.1875,
      background: '#363636',
      borderRadius: 6,
      color: '#ffffff',
      border: '1px solid #000000',
      height: 43,
      transition: 'all 0.5s ease',
      textTransform: 'none',
      padding: '0 37px !important',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0)',
      '&:hover, &:active, &:focus': {
        color: 'rgba(255,255,255, 0.8)',
        background: '#363636',
        border: '1px solid #363636',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
        backgroundColor: '#363636d9 !important',
      },
      '&:disabled': {
        color: '#ffffff',
        border: '1px solid rgba(0,0,0, 0.5)',
        background: '#363636d9 !important',
      },
    };

    const outlined = {
      fontFamily: "'Noto Sans HK', sans-serif",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.1875,
      background: '#ffffff',
      borderRadius: 6,
      color: '#000000',
      border: '1px solid #000000',
      height: 43,
      transition: 'all 0.5s ease',
      textTransform: 'none',
      padding: '0 37px !important',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0)',
      '&:hover, &:active': {
        background: '#fff',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
        color: '#363636',
        border: '1px solid rgba(0,0,0, 0.5)',
        backgroundColor: 'rgba(54,54,54, 0.2)',
      },
      '&:focus': {
        background: '#fff',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.16)',
        color: '#363636',
        border: '1px solid rgba(0,0,0, 0.5)',
        backgroundColor: 'rgba(54,54,54, 0.2)',
      },
      '&:disabled': {
        color: '#363636',
        border: '1px solid rgba(0,0,0, 0.5)',
        backgroundColor: 'rgba(54,54,54, 0.2)',
      },
    };

    this.theme = createTheme({
      overrides: {
        MuiButton: {
          root: props.variant === 'outlined' ? outlined : filled,
        },
      },
    });
  }

  render = () => {
    const { classes, isLoading, variant, className, ...other } = this.props;

    return (
      <MuiThemeProvider theme={this.theme}>
        <Button disabled={isLoading} className={className} {...other}>
          {this.props.children}
          {isLoading && (
            <CircularProgress style={{ marginLeft: 10, color: variant === 'outlined' ? '#111' : 'white' }} size={22} />
          )}
        </Button>
      </MuiThemeProvider>
    );
  };
}

export default BlackButton;
