export const STEPS = {
  PICK_UP_OR_DELIVERY: 'pickUpOrDelivery',
  DATE_SELECT: 'dateSelect',
  MARKET_SELECT: 'marketSelect',
  CLEAR_AND_COMPLETE: 'clearAndComplete',
  PRODUCER_SELECT: 'producerSelect',
};

export const TABS = {
  DELIVERY: 0,
  PICKUP: 1,
};
