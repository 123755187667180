const styles = {
  wrapper: {
    paddingBottom: 37,
  },
  controls: {
    margin: '27px 0 0 0',
    textAlign: 'center',
  },
  control: {
    borderRadius: 40,
    padding: '0 105px',
  },
};

export default styles;
