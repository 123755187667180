import axios from 'axios';
import moment from 'moment';
import { getRequestConfig, REQUEST_TYPES, replaceUrlPlaceholder } from '../request';
import API_END_POINTS from '../endpoints';

/**
 * Gets producers
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiGetProducers = function (onSuccess, onError) {
  return new Promise((resolve, reject) => {
    axios(getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_PRODUCERS))
      .then((response) => {
        onSuccess(response);
        resolve(response?.data);
      })
      .catch((error) => {
        onError(error);
        reject(error);
      });
  });
};

/**
 * Gets shipping data
 * @param {string} draftId
 * @return {Promise<any>|Promise<T | never>}
 */
export const apiGetShipping = function (draftId) {
  return new Promise((resolve, reject) => {
    const options = getRequestConfig(
      REQUEST_TYPES.GET,
      replaceUrlPlaceholder(API_END_POINTS.GET_SHIPPING, 'dr', draftId)
    );

    axios(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Get markets
 * @param {object} draft
 * @return {Promise<any>|Promise<T | never>}
 */
export const apiGetMarkets = function (draft) {
  return new Promise((resolve, reject) => {
    const options = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_MARKETS),
      { shipping } = draft;
    // Filters
    shipping &&
      (options.params = {
        ...(shipping.date ? { date: moment(shipping.date).format('YYYY-MM-DD').toLowerCase() } : {}),
        ...(shipping.type ? { type: shipping.type } : {}),
      });

    axios(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 *
 * @param {object} coords
 * @param {float} coords.customerLatitude - (default: null)
 * @param {float} coords.customerLongitude - (default: null)
 * @param onSuccess
 * @param onError
 */
export const apiSetCustomerGeolocation = function (coords = {}, onSuccess = () => {}, onError = () => {}) {
  let config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.SET_CUSTOMER_MARKETS_GEOLOCATION);
  config.data = coords;

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Get all markets
 * @return {Promise<any>|Promise<T | never>}
 */
export const apiGetAllMarkets = function () {
  return new Promise((resolve, reject) => {
    const options = getRequestConfig(REQUEST_TYPES.GET, API_END_POINTS.GET_MARKETS);

    axios(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Request info
 * @param {object} data
 * @return {Promise<any>|Promise<T | never>}
 */
export const apiProducerRequestInfo = function (data) {
  return new Promise((resolve, reject) => {
    const options = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.PRODUCER_REQUEST_INFO);
    options.data = data;
    axios(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Set Preview Producer
 * @param {string} data
 * @param {function} onSuccess
 * @param {function} onError
 * @return {Promise<any>|Promise<T | never>}
 */
export const apiSetPreviewProducer = function (data, onSuccess = () => {}, onError = () => {}) {
  let config = getRequestConfig(REQUEST_TYPES.PATCH, API_END_POINTS.SET_PREVIEW_PRODUCER);
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
