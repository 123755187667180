import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Noto Sans HK', sans-serif",
        fontWeight: 400,
        fontSize: 12,
        borderRadius: 0,
        color: '#ffffff',
        minHeight: 18,
        padding: '4px 5px',
      },
    },
  },
});

class FourthButton extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme}>
      <Button {...this.props}>{this.props.children}</Button>
    </MuiThemeProvider>
  );
}

export default FourthButton;
