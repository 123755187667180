import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ErrorBox,
  Spinner,
  BlackButton,
  BlackThirdButton,
  DialogContentWrapper,
  DialogActionsWrapper,
} from '../../../../components';
import { capitalizeFirstLetter, paymentMethodBrandToAbbr, CHECK, STRIPE_CARD, STRIPE_ACH } from '../../../../helpers';
import styles from './styles';
import MapControl from '../../../../components/MarketsMap/MapControl';
import MyZoomButton from '../../../../components/MarketsMap/MyZoomButton';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import classNames from 'classnames';
import { Hidden } from '@material-ui/core';

const propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  customer: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  draft: PropTypes.object,
};
const defaultProps = {
  loading: false,
};

class CompletedStage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      zoom: 16,
    };
    this.props.stageRef && this.props.stageRef(this);
  }

  onViewOrderDetails = (order) => {
    const { subscriptionId, history, onClose } = this.props;

    if (!!subscriptionId) {
      onClose();
      history.push(`/account/subscription/${subscriptionId}`);
      return;
    }

    onClose();
    history.push('/order/' + order.id);
  };

  getShippingTypeAndDate = () => {
    const { classes, order, intl } = this.props;
    const isSubscription = order?.subscriptionLineItems?.length;

    let shippingMessageId = `global.${order.shipping.type}`;

    if (isSubscription) {
      shippingMessageId = `checkout.subscription.${order.shipping.type}`;
    }

    return (
      <p className={classNames([classes.row, classes.fw700])}>
        {capitalizeFirstLetter(intl.formatMessage({ id: shippingMessageId }))}:&nbsp;
        {moment(order.shipping.date).format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK)}
      </p>
    );
  };

  render() {
    const { classes, order, subscriptionId, customer, loading, onDone, intl } = this.props;

    const Map = () => {
      const defaultMapOptions = {
        disableDefaultUI: true,
        styles: [
          {
            featureType: 'all',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ],
      };

      const customerAddress = {
        lat: order?.customer?.shippingAddress?.latitude || 0,
        lng: order?.customer?.shippingAddress?.longitude || 0,
      };
      const marketAddress = {
        lat: order?.shipping?.location?.latitude || 0,
        lng: order?.shipping?.location?.longitude || 0,
      };
      const center = order.shipping.type === 'delivery' ? customerAddress : marketAddress;

      return (
        <GoogleMap onMap defaultZoom={30} zoom={this.state.zoom} center={center} defaultOptions={defaultMapOptions}>
          <MapControl position={window.google.maps.ControlPosition.RIGHT_TOP}>
            <div style={{ marginTop: 30 }}>
              <MyZoomButton zoom={this.state.zoom} onZoom={(zoom) => this.setState({ zoom })} />
            </div>
          </MapControl>
          {order.shipping.type !== 'delivery' && (
            <Marker
              key={'marketPosition'}
              position={marketAddress}
              icon={{
                path: 'M-7,0a7,7 0 1,0 14,0a7,7 0 1,0 -14,0',
                fillColor: '#017aff',
                strokeColor: '#ffffff',
                strokeWeight: 2,
                fillOpacity: 1,
                anchor: new window.google.maps.Point(0, 0),
                scale: 1.2,
              }}
            />
          )}
          {order.customer && order.customer.shippingAddress && order.shipping.type === 'delivery' && (
            <Marker
              key={'customerPosition'}
              position={customerAddress}
              icon={{
                path: 'M-7,0a7,7 0 1,0 14,0a7,7 0 1,0 -14,0',
                fillColor: '#017aff',
                strokeColor: '#ffffff',
                strokeWeight: 2,
                fillOpacity: 1,
                anchor: new window.google.maps.Point(0, 0),
                scale: 1.2,
              }}
            />
          )}
        </GoogleMap>
      );
    };

    const WrappedMap = withGoogleMap(Map);
    return (
      <React.Fragment>
        <DialogContentWrapper pt0>
          {this.state.error && <ErrorBox error={this.state.error} />}
          {loading && <Spinner size={40} />}

          <p className={classes.title}>{intl.formatMessage({ id: 'global.thankYou' }) + customer.firstName}</p>

          <div className={classes.googleMap}>
            <WrappedMap
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%`, width: '100%' }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>

          <div>
            <p className={classNames([classes.row, classes.fw700])}>
              {intl.formatMessage({ id: 'checkout.orderConfirmed' }, { orderNumber: order.name })}
            </p>
            <div className={classes.divider} />
            {this.getShippingTypeAndDate()}
            {order.shipping.type === 'delivery' ? (
              <React.Fragment>
                <p className={classes.row}>{order.customer.shippingAddress.address1}</p>
                <p className={classes.row}>
                  {order.customer.shippingAddress.city +
                    ', ' +
                    order.customer.shippingAddress.province +
                    ' ' +
                    order.customer.shippingAddress.zip}
                </p>
                <p className={classes.row}>{order.customer.phone}</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className={classes.row}>{order.shipping.location.name}</p>
                <p className={classes.row}>
                  {order.shipping.location.address1 +
                    ' ' +
                    order.shipping.location.city +
                    ', ' +
                    order.shipping.location.province +
                    ' ' +
                    order.shipping.location.zip}
                </p>
              </React.Fragment>
            )}
            <div className={classes.divider} />
            <p className={classNames([classes.row, classes.fw700])}>
              {intl.formatMessage({ id: 'checkout.completedStage.method' })}
            </p>
            <div className={classes.row}>
              {order.payment.method.method === CHECK ? (
                <p className={classes.row}>{intl.formatMessage({ id: 'global.check' })}</p>
              ) : (
                ''
              )}
              {order.payment.method.method === STRIPE_CARD || order.payment.method.method === STRIPE_ACH ? (
                <React.Fragment>
                  <p className={classes.row}>
                    {paymentMethodBrandToAbbr(order.payment.method)} (
                    {intl.formatMessage(
                      { id: 'checkout.paymentMethod.endingIn' },
                      { last4: order.payment.method.last4 }
                    )}
                    )
                  </p>
                </React.Fragment>
              ) : (
                ''
              )}
              {order.payment.method.method === 'cod' && <p>{intl.formatMessage({ id: 'checkout.payAtPickup' })}</p>}
            </div>
          </div>
          {/*<BlackInfoBox title={intl.formatMessage({ id: 'global.orderSummary' })}>*/}
          {/*  <p className={classes.row}>*/}
          {/*    <FormattedMessage id="order.number" />: {order.name}*/}
          {/*  </p>*/}
          {/*  <p className={classes.row}>{order.producers.map((item) => item.name).join(', ')}</p>*/}
          {/*  <p className={classes.row}>*/}
          {/*    {capitalizeFirstLetter(intl.formatMessage({ id: 'global.' + order.shipping.type }))}&nbsp;-&nbsp;*/}
          {/*    {moment(order.shipping.date).format(process.env.REACT_APP_DATE_FORMAT_WITH_FULL_DAY_OF_WEEK)}*/}
          {/*  </p>*/}
          {/*  <p className={classes.row}>*/}
          {/*    <FormattedMessage id="payment.methods" />:*/}
          {/*  </p>*/}
          {/*  <p className={classes.row}>*/}
          {/*    {order.payment.method.method === CHECK ? <FormattedMessage id="global.check" /> : ''}*/}
          {/*    {order.payment.method.method === STRIPE_CARD || order.payment.method.method === STRIPE_ACH*/}
          {/*      ? paymentMethodBrandToAbbr(order.payment.method) + ' **** ' + order.payment.method.last4*/}
          {/*      : ''}*/}
          {/*  </p>*/}
          {/*  {(order.payment.method.method === STRIPE_CARD || order.payment.method.method === STRIPE_ACH) && (*/}
          {/*    <p className={classes.row}>*/}
          {/*      (*/}
          {/*      {customer.autoCharge ? (*/}
          {/*        <FormattedMessage id="global.automatic" />*/}
          {/*      ) : (*/}
          {/*        <FormattedMessage id="global.manual" />*/}
          {/*      )}*/}
          {/*      )*/}
          {/*    </p>*/}
          {/*  )}*/}
          {/*</BlackInfoBox>*/}
        </DialogContentWrapper>
        <Hidden xsDown>
          <DialogActionsWrapper className={classes.actionsWrapper}>
            <div className={classes.controls}>
              <BlackButton disabled={loading} onClick={onDone} className={classes.rectButton}>
                <FormattedMessage id="checkout.completedStage.backToFF" />
              </BlackButton>
              <BlackThirdButton onClick={() => this.onViewOrderDetails(order)} className={classes.viewOrderButton}>
                {intl.formatMessage({
                  id: !!subscriptionId ? 'order.viewSubscriptions' : 'order.viewDetails',
                })}
              </BlackThirdButton>
            </div>
          </DialogActionsWrapper>
        </Hidden>
      </React.Fragment>
    );
  }
}

CompletedStage.propTypes = propTypes;
CompletedStage.defaultProps = defaultProps;

export default withRouter(withStyles(styles, { withTheme: true })(injectIntl(CompletedStage)));
