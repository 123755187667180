import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  MAKE_INITIALIZED,
  MAKE_NON_INITIALIZED,
  SET_SHIPPING_OPTIONS,
  REFRESH_EVERYTHING_START,
  REFRESH_EVERYTHING_DONE,
  commonConstants,
  TOGGLE_RIGHT_SIDE_MENU_DIALOG,
  SEND_SENTRY_ERROR,
} from '../constants';
import {
  CLOSE_CHECKOUT,
  OPEN_CHECKOUT,
  SET_VIEW_TYPE,
  TOGGLE_INFO_DIALOG,
  SET_INFO_DIALOG_CONTENT,
} from '../constants/common';
import { getProducers, initMarkets } from './producer';
import { checkDraftForProblems, initDraft } from './draft';
import { apiGetShipping } from '../../api';
import { saveObjectToStorage } from '../../helpers';

export function refreshEverything(onReady) {
  return (dispatch, getState) => {
    dispatch({ type: REFRESH_EVERYTHING_START });

    dispatch(getProducers()).then(() => {
      dispatch(initDraft()).then(() => {
        dispatch(initMarkets()).then(() => {
          const draft = getState().draft.object;

          // Get shipping options
          apiGetShipping(draft.id)
            .then((shippingOptions) => {
              dispatch(setShippingOptions(shippingOptions.data));
              // Check draft for problems
              dispatch(checkDraftForProblems(draft));
            })
            .catch((error) => {
              console.log('Something went wrong during the process of getting shipping options', error);
            });

          dispatch({ type: REFRESH_EVERYTHING_DONE });
          onReady && onReady();
        });
      });
    });
  };
}

export function refreshShipping(onReady) {
  return (dispatch, getState) => {
    dispatch({ type: commonConstants.REFRESH_SHIPPING_START });
    const draft = getState().draft.object;

    // Get shipping options
    apiGetShipping(draft.id)
      .then((shippingOptions) => {
        dispatch(setShippingOptions(shippingOptions.data));
        // Check draft for problems
        dispatch(checkDraftForProblems(draft));

        dispatch({ type: commonConstants.REFRESH_SHIPPING_END });
        onReady && onReady();
      })
      .catch((error) => {
        dispatch({ type: commonConstants.REFRESH_SHIPPING_ERROR, payload: error });
      });
  };
}

export function silenceRefreshShippingOptions() {
  return (dispatch, getState) => {
    const draft = getState().draft.object;

    apiGetShipping(draft.id)
      .then((shippingOptions) => {
        dispatch(setShippingOptions(shippingOptions.data));
      })
      .catch((error) => {
        dispatch({ type: commonConstants.REFRESH_SHIPPING_ERROR, payload: error });
      });
  };
}

export function showNotification(message) {
  return (dispatch) => {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: {
        notification: message,
      },
    });
  };
}

export function hideNotification() {
  return (dispatch) => {
    dispatch({ type: HIDE_NOTIFICATION });
  };
}

export function makeInitialized() {
  return (dispatch) => {
    dispatch({ type: MAKE_INITIALIZED });
  };
}

export function makeNonInitialized() {
  return (dispatch) => {
    dispatch({ type: MAKE_NON_INITIALIZED });
  };
}

export function setShippingOptions(options) {
  return (dispatch) => {
    dispatch({
      type: SET_SHIPPING_OPTIONS,
      payload: options,
    });
  };
}

export function openCheckout(stage) {
  return (dispatch) => {
    dispatch({ type: OPEN_CHECKOUT, stage });
  };
}

export function closeCheckout() {
  return (dispatch) => {
    dispatch({ type: CLOSE_CHECKOUT });
  };
}

export function setViewType(boxView) {
  saveObjectToStorage('viewType.data', { boxView });

  return (dispatch) => {
    dispatch({
      type: SET_VIEW_TYPE,
      payload: boxView,
    });
  };
}

export function toggleInfoDialog() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_INFO_DIALOG,
    });
  };
}

export function setInfoDialogContent(content) {
  return (dispatch) => {
    dispatch({
      type: SET_INFO_DIALOG_CONTENT,
      payload: content,
    });
  };
}

export function toggleRightSideMenuDialog() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_RIGHT_SIDE_MENU_DIALOG,
    });
  };
}

export function sentrySendError(error) {
  return (dispatch) => {
    dispatch({
      type: SEND_SENTRY_ERROR,
      payload: {
        error: error,
      },
    });
  };
}
