import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import {
  Spinner,
  Icon
} from '../../../components';
import InboxIcon from '@material-ui/icons/Inbox';
import styles from './styles';
import { apiSubTypesSearch } from '../../../api';

const SubTypesList = ({ order,
                        checked,
                        classes,
                        onChangeSelection,
                        selectedProducers,
                        preItems }) => {
  const
    // Selected entry
    [subTypes, setSubTypes] = useState([]),
    [loading, setLoading] = useState(true)
  ;

  useEffect(() => {
    setLoading(true);
    setSubTypes([]);

    apiSubTypesSearch(order, selectedProducers)
      .then(data => {
        setSubTypes(data);
        setLoading(false);
      })
  }, [
    order,
    selectedProducers
  ]);

  const handleToggle = (key) => {
    onChangeSelection &&
    onChangeSelection(key);
  };

  return (
    <List subheader={(
      <ListSubheader disableSticky className={classes.title} component="div">
        <FormattedMessage id="global.categories"/>
      </ListSubheader>
    )}>

      {preItems && preItems()}

      {!!subTypes.length && (
        <React.Fragment>
          <ListItem  className={checked === "" ? classes.subTypeWrapperSelected : classes.subTypeWrapper}
                     button onClick={() => handleToggle("")}>
            <ListItemText classes={{primary: classes.subTypeText}} primary={<FormattedMessage id="global.all"/>} />
          </ListItem>

          {subTypes.map((subType, index) => (
            <ListItem  className={subType.name === checked ? classes.subTypeWrapperSelected : classes.subTypeWrapper}
                       button onClick={() => handleToggle(subType.name)} key={index}>
              <ListItemText classes={{primary: classes.subTypeText}} primary={subType.name} />
            </ListItem>
          ))}
        </React.Fragment>
      )}

      {loading && (
        <Spinner size={50} />
      )}

      {!!!subTypes.length && !loading && (
        <ListItem className={classes.emptyWrapper}>
          <Icon icon={InboxIcon}/>
          <ListItemText className={classes.empty}
                        primary={<FormattedMessage id="messages.noActiveSubTypes"/>} />
        </ListItem>
      )}
    </List>
  );
};

SubTypesList.propTypes = {
  order: PropTypes.object.isRequired,
  onChangeSelection: PropTypes.func.isRequired,
  checked: PropTypes.any,
  preItems: PropTypes.func,
};

export default withRouter(
  withStyles(styles, {withTheme: true})(SubTypesList)
);


