import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 25,
    fontWeight: 500,
    marginTop: 35,
    color: colors.text,
    paddingLeft: 6,
    marginBottom: 20,
  },
  subscriptionPaper: {
    padding: 15,
    borderRadius: 6,
    marginBottom: 20,
    boxShadow: '0px 3px 6px #00000029',
  },
  subscriptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  imageWrapper: {
    overflow: 'hidden',
    borderRadius: 4,
    maxWidth: 64,
    maxHeight: 55,
    width: '100%',
    minWidth: 64,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 64,
      maxHeight: 55,
    },
    '& > span': {
      width: 'auto',
      maxHeight: 55,
      [theme.breakpoints.down('xs')]: {
        maxHeight: 55,
      },
    },
  },
  contentWrapper: {
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  endButtonWrapper: {
    marginLeft: 40,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  productName: {
    marginBottom: 2,
    fontSize: 12,
  },
  item: {
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemsWrapper: {
    marginTop: 20,
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  columnRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

const SubscriptionSkeleton = (props) => {
  const { numberOfOrders, numberOfProducts } = props;

  const classes = useStyles();

  const drawItems = (numberOfItems = 1) => {
    const items = new Array(numberOfItems).fill();

    return (
      <>
        {items.map(() => (
          <div className={classes.item}>
            <div className={classes.subscriptionWrapper}>
              <div className={classes.imageWrapper}>
                <Skeleton width={65} height={55} />
              </div>
              <div className={classes.contentWrapper}>
                <Skeleton count={1} className={classes.productName} style={{ width: '30%', minWidth: 250 }} />
                <Skeleton count={1} className={classes.productName} style={{ width: '40%', minWidth: 300 }} />
                <Skeleton count={1} className={classes.productName} style={{ width: '30%', minWidth: 250 }} />
              </div>
              <div className={classes.endButtonWrapper}>
                <Skeleton width={70} height={20} />
              </div>
            </div>
            <Skeleton count={1} height={1} className={classes.divider} style={{ width: '100%' }} />
          </div>
        ))}
      </>
    );
  };

  return (
    <div>
      <div className={classes.subscriptionPaper}>
        <Skeleton width={300} height={25} className={classes.title} style={{ marginBottom: 10 }} />

        <div className={classes.column}>
          <Skeleton count={1} className={classes.productName} style={{ width: '30%', minWidth: 250 }} />
          <Skeleton count={1} className={classes.productName} style={{ width: '40%', minWidth: 300 }} />
          <Skeleton count={1} className={classes.productName} style={{ width: '30%', minWidth: 250 }} />
        </div>

        <div className={classes.itemsWrapper}>{drawItems(numberOfProducts)}</div>

        <div className={classes.rightAlign}>
          <div className={classes.columnRight}>
            <Skeleton count={1} className={classes.productName} style={{ width: '30%', minWidth: 250 }} />
            <Skeleton count={1} className={classes.productName} style={{ width: '40%', minWidth: 300 }} />
            <Skeleton count={1} className={classes.productName} style={{ width: '30%', minWidth: 250 }} />
          </div>
        </div>
      </div>

      <Skeleton width={300} height={25} className={classes.title} />

      <div className={classes.subscriptionPaper}>
        <div className={classes.itemsWrapper}>{drawItems(numberOfOrders)}</div>
      </div>

      <Skeleton width={300} height={25} className={classes.title} />

      <div className={classes.subscriptionPaper}>
        <div className={classes.itemsWrapper}>{drawItems(numberOfOrders)}</div>
      </div>
    </div>
  );
};

SubscriptionSkeleton.propTypes = {
  numberOfOrders: PropTypes.number,
  numberOfProducts: PropTypes.number,
};
SubscriptionSkeleton.defaultProps = {
  numberOfOrders: 5,
  numberOfProducts: 5,
};

export default SubscriptionSkeleton;
