const styles = () => ({
  newProducerTooltipContent: {
    cursor: 'pointer',
    fontWeight: 700
  },
  selected: {
    backgroundColor: '#eeeeee'
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0
  },
  default: {
  },
  title: {
    fontSize: 20,
    color: '#212121',
  },
});

export default styles;