import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Noto Sans HK', sans-serif",
        fontSize: 12,
        textTransform: 'normal',
        background: 'transparent',
        borderRadius: 0,
        fontWeight: 'bold',
        textDecoration: 'underline',
        color: '#707070',
        minHeight: 18,
        padding: '4px 5px',
        transition: 'color .3s ease-in-out',
        height: 34,
        '&:hover': {
          color: '#363636',
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

class BlackThirdButton extends React.Component {
  render = () => (
    <MuiThemeProvider theme={theme}>
      <Button {...this.props} styles={!!this.props.view ? this.props.view : {}}>
        {this.props.children}
      </Button>
    </MuiThemeProvider>
  );
}

export default BlackThirdButton;
