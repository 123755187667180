import { CUSTOMER_TYPES } from './constants';

/** Check if products have not active variants and Default Title*/
export const checkProductsByVariants = (products) => {
  let arr = [];
  products.forEach((oneProduct) => {
    if (oneProduct.variants.filter((v) => v.name !== 'Default Title' && v.active).length > 0) {
      if (oneProduct.variants[0].name === 'Default Title') {
        oneProduct.variants.splice(0, 1);
      }
      arr.push(oneProduct);
    }
  });
  return arr;
};

export const isWholesale = (customer) => {
  return customer.type === CUSTOMER_TYPES.WHOLESALE;
};

export const isCustomerHasUniqueItemsByProducerId = (customer, producerId) => {
  return customer.hasUniqueItems && customer.hasUniqueItems[producerId];
};
