export default {
  button: {
    fontSize: 14,
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    borderRadius: 6,
    paddingLeft: 10,
    paddingRight: 10,
    color: '#363636',
    '&:link': {
      color: '#363636'
    },
    transition: 'all 0.5s ease',
    '&:hover, &:active, &:focus': {
      color: '#363636',
      background: '#efefef',
      outline: 'none'
    },
    '&:active': {
      background: '#e0e0e0',
    }
  },
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

};
