import { marketConstants } from '../constants';
import { Market, Product } from '../../entities';

const initialState = {
  object: null,
  preselectedMarket: null,
  counter: 0,
  marketsDialog: false,
  marketsDialogForProduct: null,
  onMarketSelected: () => {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case marketConstants.SET_MARKET:
      return { ...state, object: new Market(action.payload.market) };
    case marketConstants.SET_MARKET_AUTOMATIC:
      return { ...state, preselectedMarket: action.payload.market ? new Market(action.payload.market) : null };
    case marketConstants.CLEAR_MARKET:
      return { ...state, object: null };

    case marketConstants.MARKETS_UPDATER:
      return { ...state, counter: ++state.counter };

    // Markets dialog
    case marketConstants.OPEN_MARKETS_DIALOG:
      return {
        ...state,
        marketsDialog: true,
        marketsDialogForProduct: action.payload.forProduct ? new Product(action.payload.forProduct) : null,
        onMarketSelected: action.payload.onDone,
      };
    case marketConstants.CLOSE_MARKETS_DIALOG:
      return {
        ...state,
        marketsDialog: false,
        marketsDialogForProduct: null,
        onMarketSelected: () => {},
      };

    default:
      return state;
  }
};

export default reducer;
