import { authConstants } from '../constants';

const initialState = {
  authenticated: false,
  guest: false,
  token: null,
  dialogStage: '',
  fromCheckout: false,
  forceAuthDialog: false,
  createBusinessAccount: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.SIGN_IN: {
      const { token, guest } = action.payload;

      return {
        ...state,
        authenticated: true,
        guest,
        token,
      };
    }
    case authConstants.SIGN_OUT: {
      return {
        ...state,
        authenticated: true,
        guest: true,
        token: null,
      };
    }
    case authConstants.OPEN_LOG_IN_AND_SIGN_UP_DIALOG: {
      const { dialogStage, options, createBusinessAccount } = action.payload;

      return {
        ...state,
        dialogStage,
        ...(options && options.fromCheckout
          ? {
              fromCheckout: options.fromCheckout,
            }
          : {}),
        createBusinessAccount: createBusinessAccount,
      };
    }
    case authConstants.CLOSE_LOG_IN_AND_SIGN_UP_DIALOG: {
      return {
        ...state,
        dialogStage: '',
        fromCheckout: false,
      };
    }
    case authConstants.OPEN_FORCE_AUTH_DIALOG: {
      return {
        ...state,
        forceAuthDialog: true,
      };
    }
    case authConstants.CLOSE_FORCE_AUTH_DIALOG: {
      return {
        ...state,
        forceAuthDialog: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
